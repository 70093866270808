import { Modal } from 'antd'
import React from 'react'
import Rejectdata from './Rejectdata'

const KycRejectModal = ({handleReject, isRejectOpen, handlecancel, setrejectionReason, rejectionReason}) => {
  return (
    <Modal
        okText="Reject"
        className="custom-modal verificationmodal red-btn"
        width={606}
        open={isRejectOpen}
        onOk={handleReject}
        onCancel={handlecancel}
        centered
        closeIcon={true}
        maskClosable={false}
      >
        
        <Rejectdata rejectionReason={rejectionReason} setrejectionReason={setrejectionReason}/>
      </Modal>
  )
}

export default KycRejectModal