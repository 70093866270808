import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { NON_REFERENCE } = CONSTANTS.API_ENDPOINTS;

const getNonReference = createAsyncThunk(
  "getNonReference",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, offset, limit } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${NON_REFERENCE.GET_NON_REFERENCE_DEATILS}/${offset}/${limit}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateNonReferenceTxn = createAsyncThunk(
  "updateNonReferenceTxn",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...restData } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${NON_REFERENCE.UPDATE_NON_REFERENCE_DETAILS}`,
        data: restData,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

export const nonReferenceActions = {
  getNonReference,
  updateNonReferenceTxn,
};
