import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import QuestionaireList from "./QuestionaireList";
import { QuestionaireService } from "../../../services/questionaire.service";

const Questionaire = ({ data, type }) => {
  console.log({ questionaire: data });
  console.log({ type: type });

  const [questions, setQuestions] = useState([]);

  const getData = () => {
    const { getIndividualQuestionnaire, getBusinessQuestionnaire } =
      QuestionaireService;
    let newData = [];
    if (type === "INDIVIDUAL") {
      newData = getIndividualQuestionnaire(data).slice();
    } else if(type === "BUSINESS") {
      newData = getBusinessQuestionnaire(data).slice();
    }
    setQuestions(newData);
  };

  useEffect(() => {
    getData();
  }, [type]);

  const { Title } = Typography;
  return (
    <>
      <Title level={5}>Questionaire</Title>

      {questions.length > 0 && <QuestionaireList data={questions} />}
    </>
  );
};

export default Questionaire;
