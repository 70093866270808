import React, { useEffect, useState } from "react";
// -import { Button, Modal, Row, Col, Divider } from "antd";
import { Button, Modal, Typography } from "antd";
import bankcard from "../../Images/card.svg";
import style from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import CommanInfo from "./CommanInfo";

const DetailModal = ({isModalOpen, handleCancel}) => {
  const navigate = useNavigate();
  const { Text, Title } = Typography;


  const handleOk = () => {
    navigate("/user-directory-view");
  };


  const cardlist = [
    {
      id: 1,
      Category: "Card Category 1",
      cardnumber: "XXXXXXXXXXXXXXXX",
      status: <Text type="success">Active</Text>,
      card: bankcard,
    },

    {
      id: 2,
      Category: "Card Category 2",
      cardnumber: "XXXXXXXXXXXXXXXX",
      status: <Text>In Active</Text>,
      card: bankcard,
    },
  ];



  return (
    <>
      
      <Modal
        className="custom-modal"
        okText="View"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={979}
        centered
        closable={false}
        maskClosable={false}
      >
        <div className={style.detailmodalContent}>
          <CommanInfo />
          <Title level={5}>Card Information</Title>
          {cardlist.map((text) => (
            <div key={text.id} className={style.cardInfo_main}>
              <div className={style.cardInfo_main_cardPart}>
                <img width={32} src={text.card} alt="icon" />
                <span style={{ marginBottom: "unset" }}>
                  <b> {text.Category}</b>
                  <br />
                  {text.cardnumber}
                </span>
              </div>
              <h5>{text.status}</h5>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default DetailModal;
