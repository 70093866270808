import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isInitialPageLoaded: false,
  routes:[]
 
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
   goBackInitialPage:(state) => {
    state.isInitialPageLoaded = !state.isInitialPageLoaded;
   },

   setRoutes:(state, action) => {
    state.routes = action.payload
   }

  },
});

// Action creators are generated for each case reducer function
export const { goBackInitialPage, setRoutes } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
