import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { AUTH } = CONSTANTS.API_ENDPOINTS;

const login = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: AUTH.LOGIN,
        data: credentials,
        method: "POST",
        assignedHeaders: { withToken: false, isMultipart: false },
      },
      { rejectWithValue, dispatch }
    );
  }
);

const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: AUTH.CHANGE_PASSWORD,
        data,
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: false },
      },
      {
        rejectWithValue,
        dispatch,
      }
    );
  }
);

const updateProfile = createAsyncThunk(
  "auth/update-profile",
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: AUTH.UPDATE_PROFILE,
        data,
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: false },
      },
      {
        rejectWithValue,
        dispatch,
      }
    );
  }
);

export const checkSessionBeforeLogin = createAsyncThunk(
  "auth/checkSessionBeforeLogin",
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: AUTH.CHECK_SESSION,
        data,
        method: "POST",
        assignedHeaders: { withToken: false, isMultipart: false },
      },
      {
        rejectWithValue,
        dispatch,
      }
    );
  }
);

const logout = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: AUTH.LOGOUT,
        data,
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false },
      },
      {
        rejectWithValue,
        dispatch,
      }
    );
  }
);

export const AuthActions = {
  login,
  changePassword,
  checkSessionBeforeLogin,
  logout,
  updateProfile,
};
