import React, { useEffect } from "react";
import { useState } from "react";
import { Tabs } from "antd";
import FiatWallet from "./FiatWallet";
import CryptoWallet from "./CryptoWallet";
import { WalletActions } from "../../../redux/features/wallet/walletActions";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  finishLoading,
} from "../../../redux/features/loading/loadingSlice";
import toast from "../../../services/toast.service";
import { CONSTANTS } from "../../../constants";
import { UserActions } from "../../../redux/features/user/userActions";

const { UNDEFINED_CLIENTID } = CONSTANTS.VALIDATION_ERRORS;

const onChange = (key) => {
  console.log(key);
};

const items = [
  {
    key: "1",
    label: "Fiat Wallet",
    children: <FiatWallet />,
  },
  {
    key: "2",
    label: "Crypto Wallet",
    children: <CryptoWallet />,
  },
];

const Balance = () => {
  const [balanceData, setBalanceData] = useState([]);

  const selectedUserData = useSelector((state) => state.user.data);
  const selectedTab = useSelector((state) => state.user.selectedTab);
  console.log("Selected data", selectedUserData);

  const [activeKey, setActiveKey] = useState([]);

  const dispatch = useDispatch();
  const [accountData, setAccountData] = useState([]);

  const getBalance = async () => {
    try {
      const clientId = selectedUserData?.user?.clientId;
      if (!clientId) return;
      const data = {
        // clientId: "957d71a0-c4f1-11ee-9d10-42010a01a002",
        clientId: selectedUserData?.user?.clientId,
      };
      await dispatch(startLoading());
      const res = await dispatch(WalletActions.getBalance(data)).unwrap();
      console.log({ resData: res });
      if (res && res.length > 0) {
        const formattedUserData = formatBalances(res);
        setBalanceData({
          list: formattedUserData,
          totalCount: +res.totalCount,
        });
      } else {
        setBalanceData({
          list: [],
          totalCount: 0,
        });
      }
      // setBalanceData(res);
      console.log("mine data", res);
    } catch (error) {
      toast.error(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  const formatBalances = (data) => {
    // const { checkEmptyString } = Utils.common;
    return data.map((obj, index) => {
      return {
        number: index + 1,
        currency: obj.iconUrl,
        totalBalance: 0,
        avlBalance: obj.balance,
        blocked: obj.lockedBalance,
        coin: obj.coin,
        isToken: obj.isToken,
      };
    });
  };

  const onChange = (key) => {
    if (key === "1") {
      const userId = selectedUserData?.user?.userId;
      getBankAccount(userId);
    }
    if (key === "2") {
      getBalance();
    }

    setActiveKey(key);
  };

  const getBankAccount = async (userId) => {
    if (!userId) return;
    try {
      dispatch(startLoading());
      const data = {
        userId,
      };
      const res = await dispatch(UserActions.getBankAccount(data)).unwrap();
      if (res) {
      }
      if (res.accounts?.length > 0) {
        setAccountData(res?.accounts);
      } else {
        setAccountData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    if (selectedTab === "Accounts") {
      setActiveKey("1");
      const userId = selectedUserData?.user?.userId;
      getBankAccount(userId);
    }
  }, [selectedTab]);

  const items = [
    {
      key: "1",
      label: "Fiat Wallet",
      children: <FiatWallet data={accountData} />,
    },
    {
      key: "2",
      label: "Crypto Wallet",
      children: <CryptoWallet data={balanceData} />,
    },
  ];

  return (
    <>
      <div className="Custom-tabBtn">
        <Tabs
          defaultActiveKey="1"
          items={items}
          activeKey={activeKey}
          onChange={onChange}
          animated={true}
        />
      </div>
    </>
  );
};

export default Balance;
