import { Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { FlagOutlined } from "@ant-design/icons";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { AdminActions } from "../../redux/features/admin/adminActions";
import {
  checkEmptyString,
  formatDate,
  handleHideId,
} from "../../utils/common.utils";
import { useNavigate } from "react-router-dom";
import Edit from "../../Assets/Images/greyEdit.svg";
import toast from "../../services/toast.service";
import PermissionCheck from "../../Components/PermissionCheck/PermissionCheck";

function RoleManagement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getRoleData, setRoleData] = useState({
    list: [],
    totalCount: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getRolePermissions = async (obj) => {
    console.log(obj);
    try {
      const data = {
        roleId: obj.roleId,
      };
      dispatch(startLoading());
      const res = await dispatch(
        AdminActions.getSelectedRolePermissions(data)
      ).unwrap();
      if (res && res.list && res.list.length > 0) {
        console.log({ res });
        goToUpdatePermissions(res.list, obj.name);
      } else {
        toast.error("No permissions found to update");
      }
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const goToUpdatePermissions = (permissionList, roleName) => {
    navigate("/role-management/update-permission", {
      state: { data: permissionList, roleName },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name ",
      key: "name",
      render: (_, record) => {
        return checkEmptyString(record.name);
      },
    },
    {
      title: "Role",
      dataIndex: "role ",
      key: "role",
      render: (_, record) => {
        return checkEmptyString(record.role);
      },
    },
    {
      title: "Role Id",
      dataIndex: "roleId ",
      key: "roleId",
      render: (_, record) => {
        return checkEmptyString(handleHideId(record.roleId));
      },
    },
    {
      title: "Status",
      dataIndex: "status ",
      key: "status",
      render: (_, record) => {
        return checkEmptyString(record.status);
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt ",
      key: "updatedAt",
      render: (_, record) => {
        return checkEmptyString(formatDate(record.updatedAt));
      },
    },
    {
      title: "Actions",
      render: (_, record) => {
        return (
          <PermissionCheck>
            <Space size={30}>
              <img
                src={Edit}
                alt="icon"
                style={{ cursor: "pointer" }}
                onClick={() => getRolePermissions(record)}
              />
            </Space>
          </PermissionCheck>
        );
      },
    },
  ];

  const getRoles = async () => {
    try {
      dispatch(startLoading());
      const res = await dispatch(AdminActions.getRoles()).unwrap();
      console.log(res, "sssssss");
      if (res && (res.list || res.totalCount)) {
        setRoleData({
          list: res.list,
          totalCount: res.totalCount,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const goToPermissions = () => {
    navigate("/role-management/set-permission");
  };

  useEffect(() => {
    getRoles();
  }, []);
  return (
    <>
      <div className="table-design">
        <div className="title-filter">
          <h3>Role Management</h3>
          <PermissionCheck>
            <ButtonCommon
              icon={<FlagOutlined />}
              height={40}
              type="primary"
              text="Add Role"
              onClick={() => goToPermissions()}
            />
          </PermissionCheck>
        </div>
        <div className="custom-table">
          <Table
            columns={columns}
            pagination={false}
            dataSource={getRoleData.list}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
    </>
  );
}

export default RoleManagement;
