import React, { useEffect, useState } from "react";
import KmsManagement from "./KmsManagement";
import KmsManagementVerification from "./KmsManagementVerification";
import { useSelector } from "react-redux";

function KmsManagementList() {
  const [showKmsList, setShowKmsList] = useState(false);

  const { isInitialPageLoaded } = useSelector((state) => state.dashboard);

  useEffect(() => {
    setShowKmsList(false);
  }, [isInitialPageLoaded]);

  return (
    <div>
      {showKmsList ? (
        <KmsManagement />
      ) : (
        <KmsManagementVerification setShowKmsList={setShowKmsList} />
      )}
    </div>
  );
}

export default KmsManagementList;
