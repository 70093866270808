import React, { useState, useEffect, useMemo } from "react";
import CommanInfo from "./CommanInfo";
import { Space, Typography } from "antd";
import { Select, Form, Switch, Row, Col, Popover, Modal, Input } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import toast from "../../services/toast.service";
import { useDispatch } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";
import { UserActions } from "../../redux/features/user/userActions";
import { useForm, Controller } from "react-hook-form";
import PermissionCheck from "../../Components/PermissionCheck/PermissionCheck";
import { setSelectedApiData } from "../../redux/features/user/userSlice";
import { formatDateTime } from "../../utils/common.utils";

const PersonalInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [reason, setReason] = useState("");

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const adminData = useSelector((state) => state.admin.data);

  const defaultVal = user?.data?.user?.isBlocked === false ? "ACTIVE" : "INACTIVE";

  const [modalTitle, setModalTitle] = useState("");
  const [activityValue, setActivityValue] = useState(defaultVal);
  const defaultTooltipReason = "The reason for the status change will be displayed here when the user is blocked or activated by the admin.";
  const [tooltipReason, setTooltipReason] = useState(defaultTooltipReason);

  useEffect(() => {
    setTooltipReason(`User Status Change Reason: ${user?.data?.user?.reasonToBlockUser}`);
  }, [user]); // Dependency array ensures this runs when user changes

  const [modifierName, setModifierName] = useState(
    user?.data?.user?.lastAdminAction
  );

  const { Title } = Typography;
  const { TextArea } = Input;

  const { control, setValue } = useForm({
    mode: "all",
  });

  const [settingToggles, setSettingToggles] = useState({
    FIAT_INTERNAL_TRANSFER: user?.data?.user?.fiatInternalTxnStatus,
    FIAT_WITHDRAW: user?.data?.user?.fiatWithdrawStatus,
    CRYPTO_INTERNAL_TRANSFER: user?.data?.user?.cryptoInternalTxnStatus,
    CRYPTO_WITHDRAW: user?.data?.user?.cryptoWithdrawStatus,
    AUTH_2FA_STATUS: user?.data?.user?.auth2FaStatus,
    SWAP: user?.data?.user?.swapStatus,
  });

  const fiat_internal_transfer_updatedAt = user?.data?.user?.fiat_internal_transfer?.updatedAt
  const fiat_internal_transfer_firstName = user?.data?.user?.fiat_internal_transfer?.admin?.firstName
  const fiat_internal_transfer_lastName = user?.data?.user?.fiat_internal_transfer?.admin?.lastName
  const fiat_internal_transfer_title = user?.data?.user?.fiat_internal_transfer?.admin?.title

  const fiat_withdraw_updatedAt = user?.data?.user?.fiat_withdraw?.updatedAt
  const fiat_withdraw_firstName = user?.data?.user?.fiat_withdraw?.admin?.firstName
  const fiat_withdraw_lastName = user?.data?.user?.fiat_withdraw?.admin?.lastName
  const fiat_withdraw_title = user?.data?.user?.fiat_withdraw?.admin?.title

  const crypto_internal_transfer_updatedAt = user?.data?.user?.crypto_internal_transfer?.updatedAt
  const crypto_internal_transfer_firstName = user?.data?.user?.crypto_internal_transfer?.admin?.firstName
  const crypto_internal_transfer_lastName = user?.data?.user?.crypto_internal_transfer?.admin?.lastName
  const crypto_internal_transfer_title = user?.data?.user?.crypto_internal_transfer?.admin?.title

  const crypto_withdraw_updatedAt = user?.data?.user?.crypto_withdraw?.updatedAt
  const crypto_withdraw_firstName = user?.data?.user?.crypto_withdraw?.admin?.firstName
  const crypto_withdraw_lastName = user?.data?.user?.crypto_withdraw?.admin?.lastName
  const crypto_withdraw_title = user?.data?.user?.crypto_withdraw?.admin?.title


  const swap_updatedAt = user?.data?.user?.swap?.updatedAt
  const swap_firstName = user?.data?.user?.swap?.admin?.firstName
  const swap_lastName = user?.data?.user?.swap?.admin?.lastName
  const swap_title = user?.data?.user?.swap?.admin?.title

  const userStatus = user?.data?.user?.userStatusLog?.updatedAt
  const userStatus_firstName = user?.data?.user?.userStatusLog?.admin?.firstName
  const userStatus_lastName = user?.data?.user?.userStatusLog?.admin?.lastName
  const userStatus_title = user?.data?.user?.userStatusLog?.admin?.title






  console.log(user?.data?.user?.fiat_withdraw, "useruseruser");

  const handleCancel = () => {
    setIsModalOpen(false);
    if (modalTitle === "ACTIVE") {
      setActivityValue("INACTIVE");
    } else if (modalTitle === "INACTIVE") {
      setActivityValue("ACTIVE");
    }
  };

  const handleOk = async () => {
    try {
      if (!reason) throw "Please enter reason";

      submitReason();

      setReason("");
      setIsModalOpen(false);
    } catch (error) {
      toast.error(error);
    }
  };
  const getUserDetails = async (userId) => {
    const data = await dispatch(UserActions.getUserDetail({ userId })).unwrap();
    if (data && data?.user && data?.kyc) {
      dispatch(setSelectedApiData(data));
    }
  };
  const submitReason = async () => {
    try {
      const data = {
        userId: user.data.user.userId,
        status: modalTitle.toUpperCase(),
        reason: reason ? reason : undefined,
      };
      dispatch(startLoading());
      await dispatch(UserActions.blockUnblockUser(data)).unwrap();
      setModifierName(adminData.firstName + " " + adminData.lastName);
      setTooltipReason(reason);

      getUserDetails(user.data.user.userId);
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const handleChange = (value) => {
    setIsModalOpen(true);
    setModalTitle(value);
    if (value === "ACTIVE") {
      setActivityValue("ACTIVE");
    } else if (value === "INACTIVE") {
      setActivityValue("INACTIVE");
    }
  };

  const onChange = async (checked, name) => {
    try {
      dispatch(startLoading());
      const response = await dispatch(
        UserActions.updateFiat({
          userId: user.data.user.userId,
          status: checked == true ? "ENABLE" : "DISABLE",
          statusType: name,
        })
      ).unwrap();
      getUserDetails(user.data.user.userId);
      setSettingToggles((s) => {
        return {
          ...s,
          [name]: checked,
        };
      });
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  return (
    <>
      <CommanInfo rejectApprove />
      {/* <PermissionCheck> */}
      <section style={{ marginTop: "20px" }}>
        <Title level={5}>Settings</Title>
        <Form name="trigger" layout="vertical" autoComplete="off">
          <div>
            <Space>
              <span>User Status</span>

              <Popover placement="topLeft" content={tooltipReason}>
                <QuestionCircleOutlined style={{ color: "#9ea8b1" }} />
              </Popover>
            </Space>
          </div>

          <Controller
            defaultValue={activityValue}
            name="activityValue"
            control={control}
            render={({ field }) => (
              <Select
                style={{
                  width: "100%",
                  maxWidth: "480px",
                }}
                {...field}
                onChange={handleChange}
                value={activityValue}
                options={[
                  {
                    value: "ACTIVE",
                    label: "Active",
                  },
                  {
                    value: "INACTIVE",
                    label: "Blocked",
                  },
                ]}
              />
            )}
          />
          {/* <div style={{ margin: "20px 0" }}>
            {tooltipReason && (
              <span>
                {activityValue === "INACTIVE"
                  ? `Blocked By ${modifierName}`
                  : `Unblocked By ${modifierName}`}
              </span>
            )}
          </div> */}
          <div style={{ marginTop: "10px",color:'#9ea8b1',fontSize:'10px' }} >
            <div>
              {userStatus && `Updated At : ${formatDateTime(userStatus)}`}
            </div>
            <div> {userStatus_firstName && userStatus_lastName && `Updated By : ${userStatus_firstName + " " + userStatus_lastName}   ${userStatus_title && `(${userStatus_title})`} `}</div>
          </div>
          <Row
            align="middle"
            justify="space-between"
            className={style.switchlist}
          >
            <Col xs={24} lg={8}>
              <div style={{ paddingLeft: "0px" }} className="main">
                <Title level={5}>Fiat</Title>
                <div className="main_toggel-blocks">
                  <Form.Item
                    hasFeedback
                    label="Fiat internal transfer"
                    name="fiatInternalTransfer"
                    validateTrigger="onBlur"
                    rules={false}
                    style={{ color: "#9ea8b1"}}
                  >

                    <Switch
                      checked={settingToggles.FIAT_INTERNAL_TRANSFER}
                      onChange={(checked) =>
                        onChange(checked, "FIAT_INTERNAL_TRANSFER")
                      }
                    />
                    <div style={{ marginTop: "10px",fontSize:'10px'  }} >
                      <div> {fiat_internal_transfer_updatedAt && `Updated At : ${formatDateTime(fiat_internal_transfer_updatedAt)}`}</div>
                      <div> {fiat_internal_transfer_firstName && fiat_internal_transfer_lastName && `Updated By : ${fiat_internal_transfer_firstName + " " + fiat_internal_transfer_lastName}   ${fiat_internal_transfer_title && `(${fiat_internal_transfer_title})`} `}</div>
                    </div>

                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    label="Fiat Withdraw"
                    name="fiatWithdraw"
                    validateTrigger="onBlur"
                    rules={false}
                    style={{ color: "#9ea8b1" }}
                  >
                    <Switch
                      checked={settingToggles.FIAT_WITHDRAW}
                      onChange={(e) => onChange(e, "FIAT_WITHDRAW")}
                    />
                    <div style={{ marginTop: "10px",fontSize:'10px' }}>
                      <div>
                        {
                          fiat_withdraw_updatedAt && `Updated At : ${formatDateTime(fiat_withdraw_updatedAt)}`
                        }
                      </div>
                      <div>
                        {
                          fiat_withdraw_firstName && fiat_withdraw_lastName && `Updated By : ${fiat_withdraw_firstName + " " + fiat_withdraw_lastName} (${fiat_withdraw_title})`
                        }
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={8}>
              <div className="main midblock">
                <Title level={5}>Crypto</Title>
                <div className="main_toggel-blocks ">
                  <Form.Item
                    hasFeedback
                    label="Crypto internal transfer"
                    name="cryptoInternalTransfer"
                    validateTrigger="onBlur"
                    rules={false}
                    style={{color:'#9ea8b1'}}
                  >
                    <Switch
                      // checked={user?.data?.user?.cryptoDepositStatus}
                      // onChange={() => onChange("CRYPTO_DEPOSIT")}
                      checked={settingToggles.CRYPTO_INTERNAL_TRANSFER}
                      onChange={(e) => onChange(e, "CRYPTO_INTERNAL_TRANSFER")}
                    />
                    <div style={{ marginTop: "10px",fontSize:'10px' }}>
                      <div>
                        {
                          crypto_internal_transfer_updatedAt && `Updated At : ${formatDateTime(crypto_internal_transfer_updatedAt)}`
                        }
                      </div>
                      <div>{crypto_internal_transfer_firstName && crypto_internal_transfer_lastName && `Updated By : ${crypto_internal_transfer_firstName + " " + crypto_internal_transfer_lastName} ${crypto_internal_transfer_title && `(${crypto_internal_transfer_title})`} `}</div>
                    </div>
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    label="Crypto Withdraw"
                    name="cryptoWithdraw"
                    validateTrigger="onBlur"
                    rules={false}
                    style={{color:'#9ea8b1'}}

                  >
                    <Switch
                      // checked={user?.data?.user?.cryptoWithdrawStatus}
                      // onChange={() => onChange("CRYPTO_WITHDRAW")}
                      checked={settingToggles.CRYPTO_WITHDRAW}
                      onChange={(e) => onChange(e, "CRYPTO_WITHDRAW")}
                    />
                    <div style={{ marginTop: "10px",fontSize:'10px' }}>
                      <div>
                        {
                          crypto_withdraw_updatedAt && `Updated At : ${formatDateTime(crypto_withdraw_updatedAt)}`
                        }
                      </div>
                      <div>{crypto_withdraw_firstName && crypto_withdraw_lastName && `Updated By : ${crypto_withdraw_firstName + " " + crypto_withdraw_lastName}   ${crypto_withdraw_title && `(${crypto_withdraw_title})`}  `}</div>
                    </div>

                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={6}>
              <div className="main">
                <Title level={5}>Other</Title>
                <div className="main_toggel-blocks">
                  {/* <Form.Item
                    hasFeedback
                    label="Authorization 2FA"
                    name="authorization"
                    validateTrigger="onBlur"
                    rules={false}
                  >
                    <Switch
                      checked={settingToggles.AUTH_2FA_STATUS}
                      onChange={(e) => onChange(e, "AUTH_2FA_STATUS")}
                    />
                  </Form.Item> */}
                  <Form.Item
                    hasFeedback
                    label="Swap"
                    name="swap"
                    validateTrigger="onBlur"
                    rules={false}
                    style={{color:'#9ea8b1'}}

                  >
                    <Switch
                      checked={settingToggles.SWAP}
                      onChange={(e) => onChange(e, "SWAP")}
                    />
                    <div style={{ marginTop: "10px",fontSize:'10px' }}>
                      <div>
                        {
                          swap_updatedAt && `Updated At : ${formatDateTime(swap_updatedAt)}`
                        }
                      </div>
                      <div>{swap_firstName && swap_lastName && `Updated By : ${swap_firstName + " " + swap_lastName} ${swap_title && `(${swap_title})`}`}</div>
                    </div>
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </section>

      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className="custom-modal verificationmodal"
          width={403}
          centered
          closeIcon={true}
          maskClosable={false}
        >
          {/* <TextArea rows={4} placeholder="Enter Reason" value={rejectionReason} onChange={e=>setrejectionReason(e.target.value)} /> */}
          <p style={{ padding: "48px auto 18px auto", textAlign: "center" }}>
            Are you sure you want to change status to {modalTitle}?
          </p>
          <TextArea
            rows={4}
            placeholder="Enter Reason"
            onChange={(e) => setReason(e.target.value)}
          />
        </Modal>
      )}
      {/* </PermissionCheck> */}
    </>
  );
};

export default PersonalInfo;
