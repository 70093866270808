import React from "react";
import { Badge, Typography } from "antd";

const TextStatusBadge = ({ condition, trueText, falseText }) => {
  const { Text } = Typography;

  return condition ? (
    <Text code>
      <Badge status="success" />
      {trueText}
    </Text>
  ) : (
    <Text code>
      <Badge status="error" />
      {falseText}
    </Text>
  );
};

export default TextStatusBadge;
