import React, { useEffect, useState } from "react";
import { WalletOutlined, DownOutlined } from "@ant-design/icons";
import { Tag, Badge, Typography, Space, Divider, Select } from "antd";
import { Link, useLocation } from "react-router-dom";
import style from "./style.module.scss";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import {
  checkEmptyString,
  createFullName,
  formatAdminNameWithRole,
  formatDateTime,
  handleHideId,
} from "../../utils/common.utils";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard";
import { useDispatch } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";
import { ComplianceActions } from "../../redux/features/compliance/complianceAtion";

const { Title, Paragraph } = Typography;

const TransationAbout = ({data}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [txnData, setTxnData] = useState(null);

  const items = [
    {
      label: <a href="https://www.antgroup.com">1st menu item</a>,
      key: "0",
    },
    {
      label: <a href="https://www.aliyun.com">2nd menu item</a>,
      key: "1",
    },
  ];
  
  const status = location.state?.status;
  const typeId = location.state?.typeId

  const getTransactionStatus = async () => {
    try {
      dispatch(startLoading());
      let newData = {
        txnId: typeId || data?.data?.txnId,
      };
      let res = await dispatch(
        ComplianceActions.getTransactionStatus(newData)
      ).unwrap();
      console.log(res);
      if (res) {
        setTxnData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getTransactionStatus();
  }, []);
  return (
    <>
      <div className={`${style.spaceMange}`}>
        <div className={`${style.spaceMange_groupBtn}`}>
        </div>
       <div style={{width:'100%',display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <div>
        <Title level={2} style={{fontSize:'25px'}}>{'KYT Detail'}</Title> 
        </div>
       <div>
       <Title level={2} >
          <Space align="start">
            <WalletOutlined />
            {data?.data?.info?.amount ? data?.data?.info?.amount : "-"}{" "}
            <span>
              {checkEmptyString(data?.data?.info?.currencyCode).toUpperCase()}
            </span>
            <Tag
              color={
                status == "PENDING"
                  ? "yellow"
                  : status == "REJECTED"
                  ? "red"
                  : "green"
              }
            >
              <Badge
                color={
                  status == "PENDING"
                    ? "yellow"
                    : status == "REJECTED"
                    ? "red"
                    : "green"
                }
                status={
                  status == "PENDING"
                    ? "yellow"
                    : status == "REJECTED"
                    ? "error"
                    : "success"
                }
                text={status}
              />
            </Tag>
          </Space>
        </Title>
        <section>
          <Space
            wrap
            split={<Divider style={{ height: "5rem" }} type="vertical" />}
          >
            <Title level={5}>
              Transation ID <br />
              <strong>
                {data?.data?.txnId ? (
                  <>
                    {data?.data?.txnId}
                    <CopyToClipboard
                      userId={data?.data?.txnId}
                      position="top"
                    />
                  </>
                ) : (
                  "-"
                )}
              </strong>
            </Title>
            <Title level={5}>
              Last Updated <br />
              <strong>{formatDateTime(data?.data?.txnDate)}</strong>
            </Title>

            <Title level={5}>
              Approved By
              <br />
              <strong>
              {formatAdminNameWithRole(
                createFullName(
                  txnData?.admin?.firstName,
                  txnData?.admin?.lastName
                ),
                txnData?.admin?.title
              )}
              </strong>
            </Title>
          </Space>
        </section>
       </div>
       </div>
      </div>
    </>
  );
};

export default TransationAbout;
