import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Space } from 'antd';
import PersonalInfo from './PersonalInfo';
import UserInformation from '../KycVerification/UserInformation/UserInformation';
import Balance from './balance/Balance.jsx';
import Transations from './transations/Transations.jsx';
import CardInfo from './cardInfo/CardInfo.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTab } from '../../redux/features/user/userSlice.js';
import { useLocation } from 'react-router-dom';
import { getAdminVerificationStatus } from '../../services/user.service';
import DownloadFile from '../../Components/Common/DownloadFile';
import RiskManagement from '../RiskManagement/RiskManagement';
import {
  formatAdminNameWithRole,
  formatDateTime,
} from '../../utils/common.utils';
import { CONSTANTS } from '../../constants';
import Settings from './Settings/Settings.jsx';
import Rewards from './rewards/Rewards';

const UserDirectoryViewMain = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [adminStatus, setAdminStatus] = useState('');
  const pdfGenerateRef = useRef();
  const { APPROVED } = CONSTANTS.KYC_STATUS;

  const setDefault = () => {
    dispatch(setSelectedTab('Personal Information'));
  };
  const onChange = (key) => {
    console.log(key);
    const keySelected = +key;
    if (location.pathname === '/user-directory-view') {
      if (keySelected === 1) {
        dispatch(setSelectedTab('Personal Information'));
      } else if (keySelected === 2) {
        dispatch(setSelectedTab('KYC Verification'));
      } else if (keySelected === 3) {
        dispatch(setSelectedTab('Risk Management'));
      } else if (keySelected === 4) {
        dispatch(setSelectedTab('Cards Information'));
      } else if (keySelected === 5) {
        dispatch(setSelectedTab('Accounts'));
      } else if (keySelected === 6) {
        dispatch(setSelectedTab('Transactions'));
      } else if (keySelected === 7) {
        dispatch(setSelectedTab('Rewards'));
      }
      // else if (keySelected === 7) {
      //   dispatch(setSelectedTab("Settings"));
      // }
    }
  };

  useEffect(() => {
    setDefault();
    showBadgeData();
  }, [location.pathname]);

  const data = useSelector((state) => state.user.data);
  console.log({ newMyDataa: data });

  const showBadgeData = () => {
    const userData = data?.user;
    const kycData = data?.kyc;
    if (userData && kycData) {
      if (userData.accountType) {
        if (userData.accountType === 'BUSINESS') {
          if (kycData.businessProfile) {
            setAdminStatus(kycData.businessProfile.adminKycStatus);
          }
        } else {
          if (kycData.individualProfile) {
            setAdminStatus(kycData.individualProfile.adminKycStatus);
          }
        }
      } else {
        setAdminStatus('PENDING');
      }
    } else {
      setAdminStatus('PENDING');
    }
  };

  const getProfileData = (data) => {
    if (data) {
      if (data.kyc && data.user) {
        if (data.user.accountType === 'BUSINESS') {
          return data.kyc.businessProfile;
        } else if (data.user.accountType === 'INDIVIDUAL') {
          return data.kyc.individualProfile;
        }
      } else if (
        data.user &&
        (!data.kyc || Object.keys(data.kyc.length === 0))
      ) {
        return {};
      } else if (!data.user || !data.kyc) {
        return {};
      }
      return {};
    }
  };

  const kycProfileData = getProfileData(data);
  const adminNameRole = formatAdminNameWithRole(
    data?.user?.lastApprovedBy,
    kycProfileData?.updatedByRole
  );

  const items = [
    {
      key: '1',
      label: 'Personal Information',
      children: (
        <>
          <PersonalInfo />
        </>
      ),
    },
    {
      key: '2',
      label: 'KYC Verification',
      children: (
        <>
          <div className='topBlock'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>KYC Verification</p>
              {adminStatus === APPROVED && (
                <div style={{ marginTop: '10px' }}>
                  <p>Approved by: {adminNameRole}</p>
                  <p>
                    Approved on: {formatDateTime(kycProfileData?.updatedAt)}
                  </p>
                </div>
              )}
            </div>

            <Space size={10}>
              {adminStatus && <>{getAdminVerificationStatus(adminStatus)}</>}
              <DownloadFile
                fileName='client_report.pdf'
                type='PDF'
                path='USER_DIRECTORY_VIEW'
                targetRef={pdfGenerateRef}
              />
            </Space>
          </div>
          <div ref={pdfGenerateRef}>
            <UserInformation Checkbox={false} data={data} />
          </div>
        </>
      ),
    },
    {
      key: '3',
      label: 'Risk Management',
      children: <RiskManagement dataUser={data} />,
    },
    {
      key: '4',
      label: 'Cards Information',
      children: <CardInfo />,
    },
    {
      key: '5',
      label: 'Accounts',
      children: <Balance />,
    },
    {
      key: '6',
      label: 'Transactions',
      children: <Transations />,
    },
    {
      key: '7',
      label: 'Rewards',
      children: <Rewards />,
    },
    // {
    //   key: "7",
    //   label: "Settings",
    //   children: <Settings user={data} />,
    // },
  ];

  return (
    <>
      <div className='custom-tabs'>
        <Tabs
          defaultActiveKey={location?.state?.fromReward ? '7' : '1'}
          items={items}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default UserDirectoryViewMain;
