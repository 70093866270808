import React, { useEffect, useState } from "react";
import CustomDragUpload from "../../../Components/customDragUpload/CustomDragUpload";
import { Tabs, Pagination } from "antd";
import RemoveTable from "./RemoveTable";
import Uploadtable from "./Uploadtable";
import { FileUploadService } from "../../../services/file-upload.service";
import { setSelectedBanner } from "../../../redux/features/marketting/markettingSlice";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANTS } from "../../../constants";
import { finishLoading, startLoading } from "../../../redux/features/loading/loadingSlice";
import { MarkettingActions } from "../../../redux/features/marketting/markettingActions";
import PermissionCheck from "../../../Components/PermissionCheck/PermissionCheck";

const DasboardContent = ({ banners, getBanners, setBanners }) => {
  const [activeKey, setActiveKey] = useState("1");
  const dispatch = useDispatch();
  const { type } = useSelector((state) => state.marketting.selectedBanner);
  const [page, setPage] = useState(1);

  const onChange = (key) => {
    console.log("mobile", key);
    setActiveKey(key);
    getBanners({
      page: 1,
      limit: 10,
      type: "MOBILE",
      isDeleted: key === "1" ? false : true,
    });
  };

  

  const items = [
    {
      key: "1",
      label: "Uploaded",
      children: (
        <Uploadtable
          banners={banners}
          getBanners={getBanners}
          page={page}
          setPage={setPage}
          setBanners={setBanners}
          type={"MOBILE"}
        />
      ),
    },
    {
      key: "2",
      label: "Removed",
      children: <RemoveTable banners={banners} page={page} setPage={setPage} type={"MOBILE"} getBanners={getBanners}/>,
    },
  ];

  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", CONSTANTS.BANNER_TYPE.MOBILE);
      console.log([...formData]);
      await dispatch(startLoading());
      const res = await dispatch(
        MarkettingActions.uploadBanner(formData)
      ).unwrap();
      getBanners({
        page:1,
        type: "MOBILE",
        limit:10,
        isDeleted: activeKey === "1" ? false : true,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    console.log({ type });
    if (type === "MOBILE") {
      setActiveKey("1");
      const data = {
        offset:(page-1)*10,
        limit: 10,
        isDeleted: false,
        type,
      };
      getBanners(data);
    }
  }, [type]);



  const allowedTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/svg",
  ];

  const {HEIGHT, WIDTH} = CONSTANTS.BANNER_DIMENSIONS.MOBILE

  const dimensions = {
    width:WIDTH,
    height:HEIGHT
  }


  return (
    <div>
      <div style={{ maxWidth: "50%" }}>
        <PermissionCheck>
        <CustomDragUpload allowedTypes={allowedTypes} uploadFile={uploadFile} dimensions={dimensions}/>
        </PermissionCheck>
      </div>
      <div style={{ marginTop: "20px" }} className="table-design">
        <div className="custom-tabs">
          <Tabs
            defaultActiveKey={"1"}
            items={items}
            onChange={onChange}
            animated={true}
            activeKey={activeKey}
          />
        </div>
      </div>
    </div>
  );
};

export default DasboardContent;
