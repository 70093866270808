import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SettingActions } from '../../../redux/features/setting/settingActions';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import { Table } from 'antd';
import CustomPagination from '../../../Components/CustomPagination/CustomPagination';
import CustomTable from '../../../Components/CustomTable/CustomTable';
import { handleHideId } from '../../../utils/common.utils';
import CopyToClipboard from '../../../Components/CopyToClipboard/CopyToClipboard';

function Rewards() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [userReferralList, setUserReferralList] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const selectedTab = useSelector((state) => state.user.selectedTab);

  const handlePageChange = (page, pageSize) => {
    setPageSize(pageSize);
    setPage(page);
  };
  const getReferralList = async () => {
    try {
      const payload = {
        userId: user?.data?.user?.userId,
        limit: pageSize,
        offset: (page - 1) * pageSize,
      };
      dispatch(startLoading());
      const res = await dispatch(
        SettingActions.getReferralsByUserId(payload)
      ).unwrap(); // need to change URL of API
      if (res?.data?.length > 0) {
        setUserReferralList(res?.data);
        setTotalCount(res?.totalRowCount);
      } else {
        setUserReferralList([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getReferralList();
  }, [page, pageSize]);

  useEffect(() => {
    if (selectedTab !== "Rewards") return
    setUserReferralList([]);
    setTotalCount(0);
    setPage(1)
    setPageSize(10)

  }, [selectedTab])
  const columns = [
    // {
    //   title: 'Referral Id',
    //   dataIndex: 'referrerId',
    //   key: 'referrerId',
    // },

    {
      title: 'Referee Id',
      dataIndex: 'refereeId',
      key: 'refereeId',
      render: (_, record) => {
        return (
          <>
            {record?.refereeId ? (
              <>
                {handleHideId(record?.refereeId)}
                <CopyToClipboard userId={record?.refereeId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Reward Type',
      dataIndex: 'pointsType',
      key: 'pointsType',
    },
    {
      title: 'Reward (USD)',
      dataIndex: 'points',
      key: 'points',
    },

    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        return new Date(text).toLocaleDateString();
        // return text;
      },
    },
  ];
  const data = [
    {
      userId: 'f91bf74e-2f6a-435d-8191-00b99aefc73d',
      refereeId: '928a46b2-112b-4ea4-af49-bafb39f38906',
      referrerId: 'f91bf74e-2f6a-435d-8191-00b99aefc73d',
      pointsType: 'DEPOSIT',
      points: 24,
      createdAt: 'Tue Aug 13 2024 10:50:24 GMT+0530 (India Standard Time)',
    },
    {
      userId: 'f91bf74e-2f6a-435d-8191-00b99aefc73d',
      refereeId: '928a46b2-112b-4ea4-af49-bafb39f38906',
      referrerId: 'f91bf74e-2f6a-435d-8191-00b99aefc73d',
      pointsType: 'KYC_DONE',
      points: 7,
      createdAt: 'Tue Aug 13 2024 10:31:03 GMT+0530 (India Standard Time)',
    },
    {
      userId: '928a46b2-112b-4ea4-af49-bafb39f38908',
      refereeId: '928a46b2-112b-4ea4-af49-bafb39f38906',
      referrerId: '928a46b2-112b-4ea4-af49-bafb39f38908',
      pointsType: 'KYC_DONE',
      points: 10,
      createdAt: 'Mon Aug 12 2024 12:37:11 GMT+0530 (India Standard Time)',
    },
  ];
  return (
    // <div className='table-design' style={{ marginTop: '20px' }}>
    //   <div className='title-filter'>
    //     <h3>Rewards</h3>
    //   </div>
    //   <div className='custom-table'>
    //     <Table dataSource={data} columns={columns} pagination={false} />
    //   </div>
    //   <CustomPagination
    //       totalRecords={1000}
    //       defaultCurrent={page}
    //       total={1000}
    //       pageSize={pageSize}
    //       onChange={handlePageChange}
    //       current={page}
    //     />
    // </div>
    <div className='UserDirectory-page'>
      <div className='table-design'>
        <div className='title-filter'>
          <h3>Rewards</h3>
        </div>
        <CustomTable dataSource={userReferralList} columns={columns} />
      </div>
      <CustomPagination
        totalRecords={totalCount}
        defaultCurrent={page}
        total={totalCount}
        pageSize={pageSize}
        onChange={handlePageChange}
        current={page}
      />
    </div>
  );
}

export default Rewards;
