import moment from 'moment';
import { CONSTANTS } from '../constants';
import BigNumber from 'bignumber.js';

const { DATE_FORMAT } = CONSTANTS;
export const checkEmptyString = (value) => {
  if (value === undefined) {
    return '-';
  } else if (value.trim() === '') {
    return '-';
  }
  return value;
};

export const formatDateTime = (dateString) => {
  if (!dateString) return '-';
  return moment(dateString).format(DATE_FORMAT.TIME);
};

export function formatNumber(value, precision = 8) {
  if (value !== undefined) {
    return Number(value) / Number(1000000)
  }
}

export const formatDate = (dateString) => {
  if (!dateString) return '-';
  return moment(new Date(dateString)).format(DATE_FORMAT.DATE);
};

export const sliceString = (value, end) => {
  if (!value || (!end && typeof end === 'number') || !end) return '';
  if (value.length > end) {
    value = value.slice(0, end).concat('...');
  }

  return value;
};

export const formatTxnDate = (dateString) => {
  if (!dateString) return '-';
  return moment(new Date(dateString)).format(DATE_FORMAT.TXNDATE);
};

export const renderConditionalElement = (
  condition,
  trueContent,
  falseContent
) => {
  const content = condition ? trueContent : falseContent;
  return content;
};

export const percentageChanged = (value, initialValue) => {
  let finalValue = value + initialValue;
  return ((finalValue - initialValue) / initialValue) * 100;
};

export const capitalizeFirstLetter = (value) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
};

export const formatUserRole = (role) => {
  return role ? role.split('_').join(' ') : '';
};

export const formatDigits = (value, power) => {
  if (value === 0) {
    return 0;
  } else {
    let f = Math.pow(10, power);
    return value / f;
  }
};

export const checkFalsyValues = (value) => {
  if (typeof value === 'number') {
    if (!value) return 0;
  } else {
    if (!value || value.trim() === '') {
      return '-';
    }
  }
  return value;
};
export const checkFalsyFeeValue = (value) => {
  if (!value) return 0;
  return value;
};

export const divideBySmallestUnit = (value, coin) => {
  if (value && coin) {
    let coinValue = coin.toUpperCase();
    const constantValue = CONSTANTS.SMALLEST_UNIT[coinValue];
    if (constantValue) {
      const x = new BigNumber(value);
      const y = new BigNumber(constantValue);
      console.log(x.dividedBy(y).toFixed(8), 'constantValue:::::');
      return String(x.dividedBy(y));
    } else return value;
  }
};

export const blobToBase64 = (blob) => {
  return new Promise(async (resolve, _) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
  });
};

export const handleHideId = (userId) => {
  if (!userId) return '-';
  if (userId.length < 10) return userId;
  const firstDigits = userId?.slice(0, 5);
  const lastDigits = userId?.slice(-5);
  const stars = '*'.repeat(4);

  return `${firstDigits}${stars}${lastDigits}`;
};

export const calculatePercentage = (value, total) => {
  let percentage = 0;
  if (!isNaN(value) && !isNaN(total)) {
    value = Number(value);
    total = Number(total);
  }
  if (value != 0 && total != 0) {
    percentage = parseFloat((value / total) * 100);
  }
  return percentage ? percentage.toFixed(2) : 0;
};

export const isJsonValid = (value) => {
  if (value) {
    if (typeof value === 'string') {
      if (value != '{}' && value != '[object Object]' && value.length > 0) {
        return true;
      }
    }
  }
  return false;
};

export const formatFiatValues = (value) => {
  if (value && isNaN(value) === false) {
    return Number(value).toFixed(2);
  }

  return 0;
};

export const getColorRiskManagement = (value) => {
  if (typeof value !== 'number' || isNaN(value)) return '#000';

  const riskLevels = {
    20: '#529E00',
    27: '#FFB800',
    33: '#FF4C00',
    default: '#803D20',
  };

  const level = Object.keys(riskLevels).find((level) => value <= level);
  return riskLevels[level ?? 'default'];
};

const capitalizeOnlyFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const formatTransactionType = (value) => {
  if (value) {
    if (String(value).indexOf('_') !== -1) {
      let words = value.split('_');
      if (words.length == 2) {
        let first = capitalizeOnlyFirstLetter(words[0]);
        let second = capitalizeOnlyFirstLetter(words[1]);
        return first + ' ' + second;
      }
    } else {
      return capitalizeOnlyFirstLetter(value);
    }
  }
  return '';
};

export const formatAdminNameWithRole = (name, role) => {
  if (!name) {
    return '-';
  } else if (!!name && !role) {
    return name;
  }
  return `${name} (${role})`;
};

export const createFullName = (firstName, lastName) => {
  return checkEmptyString(
    (capitalizeFirstLetter(firstName?.trim()) ?? '') +
      ' ' +
      (lastName?.trim() ?? '')
  );
};

export const iconColor = '#363636';
export const iconSize = '20px';
