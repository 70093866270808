import React, { useState } from "react";
import { Button, Input, Modal, Space, Upload } from "antd";
import { UploadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { FileUploadService } from "../../services/file-upload.service";
import { CONSTANTS } from "../../constants";
import toast from "../../services/toast.service";
import ButtonCommon from "../Buttoncommon/ButtonCommon";

import { Validations } from "../../validations";

const CustomUpload = ({
  allowedTypes,
  uploadFile,
  setDocTitle,
  docTitle,
  setShowDocTitleModal,
  showDocTitleModal,
}) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [pdfIcon, showPdfIcon] = useState(false);

  const [error, setError] = useState("");
  const props = {
    multiple: false,
    async beforeUpload(file) {
      console.log(file);
      const isFileTypeValid = FileUploadService.isFileTypeValid(
        file,
        allowedTypes
      );
      if (!isFileTypeValid) {
        toast.error(`File type ${file.type} not allowed to upload`);
        return false;
      }
      const limitSize = CONSTANTS.FILE_UPLOAD_SETTINGS.BANNER_SIZE_LIMIT;
      const isFileSizeValid = FileUploadService.isFileSizeValid(
        file,
        limitSize
      );
      console.log({ isFileSizeValid });
      if (!isFileSizeValid) {
        toast.error(`File size exceeds ${limitSize / 1024 / 1024} MB`);
        return false;
      }
    },
    async customRequest({ file, onError }) {
      try {
        if (file.type === "application/pdf") {
          showPdfIcon(true);
          
        } else {
          const res = await FileUploadService.getFilePreview(file);
          setPreviewUrl(res);
        }
        setSelectedFile(file);
      } catch (error) {
        onError(error);
      }
    },
  };

  const upload = async () => {
    setShowDocTitleModal(true);
  };

  const handleRemoveFile = () => {
    setPreviewUrl(null);
    showPdfIcon(false);
    setSelectedFile(null);
  };

  const handleCancelDocTitleModal = () => {
    setShowDocTitleModal(false);
    setDocTitle("");
    setError("");
  };

  const submitDocumet = async () => {
    try {
      await Validations.uploadKycDocSchema.validate({ docTitle });
      await uploadFile(selectedFile);
      console.log("Document submitted:", docTitle,"selectedFile::",selectedFile);
      setShowDocTitleModal(false);
      setDocTitle("");
      setPreviewUrl(null);
      showPdfIcon(false)
      console.log('UPLOADED PDF');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <div style={{ margin: "20px 0" }}>
        <Upload {...props} fileList={fileList}>
          <Button icon={<UploadOutlined />}>Select File</Button>
          <p className="ant-upload-hint">Only image/pdf can be uploaded.</p>
        </Upload>
      </div>

      {previewUrl && (
        <div className="imageUpload-withdeleteIcon">
          <img
            src={previewUrl}
            alt="icon"
            style={{
              width: "200px",
              height: "auto",
              borderRadius: "8px",
              display: "block",
              marginBottom: "20px",
            }}
          />
          <Space size={20} wrap>
            <Button
              type="primary"
              onClick={() => upload()}
              // disabled={fileList.length === 0}
              style={
                {
                  // marginTop: 16,
                }
              }
            >
              Upload
            </Button>
            <Button danger type="primary" onClick={handleRemoveFile}>
              Delete
            </Button>
          </Space>
        </div>
      )}

      {pdfIcon && (
        <>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <FilePdfOutlined style={{ fontSize: "50px" }} />
            <Button
              type="primary"
              onClick={() => upload()}
              // disabled={fileList.length === 0}
              style={{
                marginTop: 16,
              }}
            >
              Upload
            </Button>
            <ButtonCommon
              danger
              type="primary"
              text="Delete"
              onClick={handleRemoveFile}
            />
          </div>
        </>
      )}

      <Modal
        text="More Details"
        title="Enter document title"
        onCancel={handleCancelDocTitleModal}
        open={showDocTitleModal}
        maskClosable={false}
        width={"25%"}
        footer={[
          <Space key="footer-space">
            <Button
              type="primary"
              htmlType="button"
              danger
              key="cancel-button"
              onClick={handleCancelDocTitleModal}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="button"
              key="submit-button"
              onClick={submitDocumet}
            >
              Submit
            </Button>
          </Space>,
        ]}
      >
        <Input
          value={docTitle}
          placeholder="Document title"
          onChange={(e) => {
            setDocTitle(e.target.value);
            setError("");
          }}
        />
        {error && <div style={{ color: "red" }}>{error}</div>}
      </Modal>
    </>
  );
};

export default CustomUpload;
