import React from "react";
import greeenArrow from "../../Assets/Images/arrowGreen.svg";

const BlockCard = (props) => {
  const { icons, titleMain, title, valueUp, valueUp1, style } = props;
  return (
    <div style={style} className="blockcard">
      <div className="blockcard_imgBlock">
        <span className="blockcard_imgBlock_bg">
          <img src={icons} alt="icon" />
        </span>
        <p>
          {title}
          <br />
          <strong> {titleMain}</strong>
        </p>
      </div>
      <p>
        <strong style={{ marginRight: "5px" }} className="green">
          <img style={{ marginRight: "2px" }} src={greeenArrow} alt="icon" />
          {valueUp}
        </strong>
        in last
        <strong> 7 Days</strong>
      </p>
      <p>
        <strong style={{ marginRight: "5px" }} className="green">
          <img style={{ marginRight: "2px" }} src={greeenArrow} alt="icon" />
          {valueUp1}
        </strong>
        in last
        <strong> 30 Days</strong>
      </p>
    </div>
  );
};

export default BlockCard;
