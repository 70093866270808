import React from "react";
import { Button, Space } from "antd";
import { useNavigate } from "react-router-dom";

const NotAuthorised = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="centerContent">
    <Space size={20}>
    <p>Sorry you don't have access to this page</p>
      <Button type="primary" onClick={goBack}>
        Back{" "}
      </Button>

    </Space>
      
    </div>
  );
};

export default NotAuthorised;
