export const convertJsonToCsv = (headers, data) => {
    const csvRows = [];
    // getting headers. 
    const headerValues = headers.map(header => header.label);
    csvRows.push(headerValues.join(","));

    for (const row of data) {
        const rowValues = headers.map(header => {
            const escaped = ("" + row[header.key]).replace(`/"/g, '\\"’`); 
            return `"${escaped}"`; 
        });
        csvRows.push(rowValues.join(",")); 
    }

    return csvRows.join("\n"); 
};