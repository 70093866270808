import { Link, useLocation, useNavigate } from "react-router-dom";

import dashboard from "../Assets/Images/dashboard.svg";
import clientmangement from "../Assets/Images/clent.svg";
import clientDirectory from "../Assets/Images/directoryClientnew.svg";
import clientVerification from "../Assets/Images/clientVerifications.svg";
import transation from "../Assets/Images/trasation.svg";
import faittransation from "../Assets/Images/faitnew.svg";
import cryptoTransations from "../Assets/Images/cryptoTranstions.svg";
import swapTransation from "../Assets/Images/swapTranstion.svg";
import clientNew from "../Assets/Images/clentnew.svg";
import contentImg from "../Assets/Images/content.svg";
import banner from "../Assets/Images/banner.svg";
import maket from "../Assets/Images/market.svg";
import complaince from "../Assets/Images/complince.svg";
import sanction from "../Assets/Images/sanction.svg";
import settingIcon from "../Assets/Images/settingIcon.svg";

import React, { useEffect, useState } from "react";
import {
  goBackInitialPage,
  setRoutes,
} from "../redux/features/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";

const SidebarMenu = () => {
  const dispatch = useDispatch();
  const [openKeys, setOpenKeys] = useState(["1"]);
  const [selectedTab, setSelectedTab] = useState(null);
  const pathName = useLocation();
  const path = pathName.pathname.split("/")[1];

  const adminData = useSelector((state) => state.admin);
  const adminPermissions = adminData?.data?.permissions;

  const navigate = useNavigate();

  const goBack = () => {
    dispatch(goBackInitialPage());
  };

  const [defaultRoute] = useState("");
  const handleSidebar = (keys) => {
    const latestOpenKey = keys?.find((key) => openKeys?.indexOf(key) === -1);
    if (latestOpenKey?.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const sidebarData = [
    {
      key: "1",
      icon: (
        <img src={dashboard} alt="icon" />
      ),
      label: <Link to={"/dashboard"}>Dashboard</Link>,
    },
    {
      key: "2",
      icon: (
        <img src={clientmangement} alt="icon" />
      ),
      label: "Client Management",
      children: [
        {
          label: <Link to={"/user-directory"}>Client Directory</Link>,
          key: "3",
          icon: (
            <img src={clientDirectory} alt="icon" />
          ),
        },
        {
          label: (
            <Link to={"/user-verification"} onClick={() => goBack()}>
              Client Verification
            </Link>
          ),
          key: "4",
          icon: (
            <img src={clientVerification} alt="icon" />
          ),
        },
      ],
    },
    {
      key: "5",
      icon: (
        <img src={transation} alt="icon" />
      ),
      label: "Reports",
      children: [
        {
          label: <Link to={"/all-transaction"}>All Transactions</Link>,
          key: "6",
          icon: (
            <>
              <img src={faittransation} alt="icon" />
            </>
          ),
        },
        {
          label: <Link to={"/fiat-transaction"}>Fiat Transactions</Link>,
          key: "7",
          icon: (
            <>
              <img src={faittransation} alt="icon" />
            </>
          ),
        },
        {
          label: <Link to={"/crypto-transaction"}>Crypto Transactions</Link>,
          key: "8",
          icon: (
            <>
              <img src={cryptoTransations} alt="icon" />
            </>
          ),
        },
        {
          label: <Link to={"/swap-transaction"}>Swap Transactions</Link>,
          key: "9",
          icon: (
            <img src={swapTransation} alt="icon" />
          ),
        },
      ],
    },
    {
      key: "14",
      icon: (
        <img src={clientNew} alt="icon" />
      ),
      label: <Link to={"/client-support-communication"}>Client Support</Link>,
    },
    {
      key: "18",
      icon: <img src={cryptoTransations} alt="icon" />,
      label: "Activity",
      children: [
        {
          label: <Link to={"/logs"}>Logs</Link>,
          key: "19",
          icon: <img src={transation} alt="icon" />,
        },
      ],
    },
    {
      key: "20",
      label: "Marketing",
      icon: <img src={maket} alt="icon" />,
      children: [
        {
          label: <Link to="/content">Content</Link>,
          key: "20",
          icon: <img src={contentImg} alt="icon" />,
        },
        {
          label: <Link to="/banner">Banner</Link>,
          key: "21",
          icon: <img src={banner} alt="icon" />,
        },
      ],
    },
    {
      key: "22",
      icon: <img src={settingIcon} alt="icon" />,
      label: "Setting",
      children: [
        {
          label: <Link to={"/transaction-limits"}>Fee Settings</Link>,
          key: "22",
          icon: (
            <>
              <img src={cryptoTransations} alt="icon" />
            </>
          ),
        },
        {
          key: "23",
          icon: (
            <img src={clientmangement} alt="icon" />
          ),
          label: <Link to="/user-management">User Management</Link>,
        },
        

        {
          key: "24",
          icon: (
            <img
              style={{ width: "18px", height: "18px" }}
              src={clientmangement}
              alt="icon"
            />
          ),
          label: <Link to={"/role-management"}>Role Management</Link>,
        },
        
        {
          key: "25",
          icon: (
            <img
              style={{ width: "18px", height: "18px" }}
              src={clientmangement}
              alt="icon"
            />
          ),
          label: <Link to={"/trade-management"}>Trade Management</Link>,
        },
    

        {
          key: "26",
          icon: (
            <img
              style={{ width: "18px", height: "18px" }}
              src={clientmangement}
              alt="icon"
            />
          ),
          label: (
            <Link to={"/kms-management"} onClick={() => goBack()}>
              Kms Management
            </Link>
          ),
        },
        {
          key: "27",
          icon: (
            <img
              style={{ width: "18px", height: "18px" }}
              src={clientmangement}
              alt="icon"
            />
          ),
          label: (
            <Link to={"/referral-program"} onClick={() => goBack()}>
              Referral Program
            </Link>
          ),
        },
      ],
    },
    {
      key: "28",
      icon: <img src={complaince} alt="icon" />,
      label: "Compliance",
      children: [
        {
          label: <Link to="/transactions-main">Transactions Screening</Link>,
          key: "29",
          icon: (
            <>
              <img src={cryptoTransations} alt="icon" />
            </>
          ),
        },
        {
          label: <Link to="/sanction-report">Verifications Screening</Link>,
          key: "30",
          icon: (
            <>
              <img src={sanction} alt="icon" />
            </>
          ),
        },

        // {
        //   label: <Link to="/verifications-main">Verifications Screening</Link>,
        //   key: "32",
        //   icon: (
        //     <>
        //       <img src={cryptoTransations} alt="icon" />
        //     </>
        //   ),
        // },
      ],
    },
  ];

  const [permissionsSidebar, setPermissionsSidebar] = useState([]);

  const getActiveKey = () => {
    let mappedSidebar = sidebarData.map(extractLabelValue);
    let foundObj = null;
    for (const item of mappedSidebar) {
      if (item.children) {
        for (const child of item.children) {
          if (path.indexOf(child.route.substring(1)) != -1) {
            foundObj = child;
          }
        }
      } else {
        if (path.indexOf(item.route.substring(1)) != -1) {
          foundObj = item;
        }
      }
    }
    return foundObj?.key || "1";
  };

  const convertToModuleName = (value) => {
    let result = "";
    if (value) {
      if (value.indexOf("_") !== -1) {
        result = value.split("_");
        result = result
          .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
          .join(" ");
      } else {
        result = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      }
    }

    return result;
  };

  const renderFilteredSidebar = () => {
    let mappedSidebar = sidebarData.map(extractLabelValue);

    let filteredSidebar = [];

    if (adminPermissions && adminPermissions.length > 0) {
      adminPermissions.forEach((obj) => {
        if (obj.rolePermission) {
          let foundModule = mappedSidebar.find(
            (x) => x.text === convertToModuleName(obj.rolePermission.module)
          );
          if (foundModule) {
            let foundChild = null;
            if (foundModule.children) {
              foundChild = foundModule.children.find(
                (child) => child.text === obj.rolePermission.name
              );
            }
            let foundIndex = filteredSidebar.findIndex(
              (x) => x.text === convertToModuleName(obj.rolePermission.module)
            );
            if (foundChild) {
              foundChild.permissionType = obj.permissionType;
              if (foundIndex === -1) {
                // If the module is not already in filteredSidebar, add it with only the permitted child
                filteredSidebar.push({
                  ...foundModule,
                  children: [foundChild],
                });
              } else {
                // If the module is already in filteredSidebar, add the permitted child to it
                filteredSidebar[foundIndex].children.push(foundChild);
                filteredSidebar[foundIndex].children = filteredSidebar[
                  foundIndex
                ].children.sort((a, b) => a.key - b.key);
              }
            } else {
              foundModule.permissionType = obj.permissionType;
              // If the module is not already in filteredSidebar, add it with only the permitted child

              if (foundIndex === -1) {
                // If the module is not already in filteredSidebar, add it with only the permitted child
                filteredSidebar.push({
                  ...foundModule,
                });
              }
            }
          }
        }
      });
    }

    filteredSidebar = filteredSidebar.sort((a, b) => a.key - b.key);

    console.log({ filteredSidebar });
    if (filteredSidebar.length > 0) {
      setPermissionsSidebar(filteredSidebar);
    } else {
      setPermissionsSidebar(mappedSidebar);
    }
  };

  const createAppRoutes = (item) => {
    if (item.children) {
      return item.children.map((child) => {
        return {
          text: child.text,
          route: child.route,
          permissionType: child.permissionType,
        };
      });
    } else {
      return {
        text: item.text,
        route: item.route,
        permissionType: item.permissionType,
      };
    }
  };

  useEffect(() => {
    if (permissionsSidebar.length > 0) {
      let mappedRoutes = permissionsSidebar.map(createAppRoutes).flat();
      dispatch(setRoutes(mappedRoutes));
    }
  }, [permissionsSidebar]);

  // function to extract text from label and create route property
  const extractLabelValue = (item) => {
    if (item.label) {
      if (item.children) {
        let mappedChildren = item.children.map((child) => {
          if (child.label.props) {
            return {
              ...child,
              text: child.label.props.children,
              route: child.label.props.to,
            };
          } else {
            return { ...child, text: child.label, route: child.label.to };
          }
        });
        if (item.label.props) {
          return {
            ...item,
            children: mappedChildren,
            text: item.label.props,
            route: item.label.props.to,
          };
        }
        return {
          ...item,
          children: mappedChildren,
          text: item.label,
        };
      } else {
        if (item.label.props) {
          return {
            ...item,
            text: item.label.props.children,
            route: item.label.props.to,
          };
        } else {
          return { ...item, text: item.label };
        }
      }
    }

    return "";
  };

  useEffect(() => {
    renderFilteredSidebar();
  }, [adminPermissions]);

  // useEffect(() => {
  //   let defaultRoute = "";
  //   if(permissionsSidebar.length > 0) {
  //    permissionsSidebar.forEach(x => {
  //     if(x.children && x.children.length > 0){
  //       defaultRoute = x.children[0].route;
  //     } else {
  //       defaultRoute = x.route;
  //     }
  //    })
  //   }
  //   setDefaultRoute(defaultRoute);
  // },[permissionsSidebar])

  useEffect(() => {
    if (defaultRoute) {
      navigate(defaultRoute);
    }
  }, [defaultRoute]);

  useEffect(() => {
    const routes = getActiveKey();
    console.log({ routes });
    setSelectedTab(routes);
  }, [pathName]);

  return (
    <>
    <div className='listing'>
    <Menu
        openKeys={openKeys}
        onOpenChange={handleSidebar}
        mode="inline"
        // defaultSelectedKeys={selectedTab}
        activeKey={selectedTab}
        selectedKeys={selectedTab}
        items={permissionsSidebar}
      />
    </div>
    </>
  );
};

export default SidebarMenu;
