import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { useDispatch } from "react-redux";
import { setSelecteClientSupportTab } from "../../redux/features/clientSupport/clientSupportSlice";
function Headingview({ heading, title, link }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (link === "/client-support-communication") {
      dispatch(setSelecteClientSupportTab("Pending"));
    }
  }, [link]);
  return (
    <div className={style.headview}>
      <h4>{heading}</h4>
      <Link to={link}>{title}</Link>
    </div>
  );
}
export default Headingview;
