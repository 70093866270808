import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  selectedFiatTab:"1"
};

export const nonRefernceSlice = createSlice({
  name: "non-reference",
  initialState,
  reducers: {
    setSelectedFiatTab:(state,action)=>{
       state.selectedFiatTab = action.payload
    }
  },
  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const {setSelectedFiatTab} = nonRefernceSlice.actions;

export default nonRefernceSlice.reducer;
