import React from "react";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../utils/common.utils";
function Welcomecomp() {
  const { data } = useSelector(state => state.admin);
  console.log("data::",data);
  return (
    <>
      {data && (
        <div className={styles.welcomeComp}>
          <h1>
            Welcome back {data?.firstName} {data?.lastName}
          </h1>
          <p>Last Login {
           data?.lastLoginTime ?
              formatDateTime(data?.lastLoginTime)
              : formatDateTime(new Date())
          } </p>
          <p>IP {
            data?.sessions && data.sessions.length > 0 ?
              data.sessions[0].ip :
              '0:0:0:0'
          }</p>

        </div>
      )}
    </>
  );
}

export default Welcomecomp;
