import React, { useState } from "react";
import { Button, Flex, Modal, Space } from "antd";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import Chat from "../../Components/Chat/Chat";
import CommanInfo from "../UserDirectory/CommanInfo";
import DetailInfo from "./DetailInfo";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { useLocation } from "react-router-dom";
import { checkEmptyString } from "../../utils/common.utils";
import PermissionCheck from "../../Components/PermissionCheck/PermissionCheck";

const TicketInfo = () => {
  const [showClientSupportModal, setShowClientSupportModal] = useState(false);
  const location = useLocation();
  return (
    <div className="Ticket-infoPage">
      <div className="topBlock">
        <p>Ticket #{checkEmptyString(location.state?.record?.ticketId)}</p>
        <PermissionCheck>
        <Space>
          {location.state?.record?.status == "RESOLVED" && (
            <ButtonCommon
              text={location.state?.record?.status == "RESOLVED" && " Resolved"}
              disabled={location.state?.record?.status == "RESOLVED"}
              onClick={() => setShowClientSupportModal(true)}
            />
          )}

          {/* {location.state?.record?.status == "RESOLVED" && (
            <ButtonCommon
              text={location.state?.record?.status == "RESOLVED" && " Reopen"}
              disabled={location.state?.record?.status == "RESOLVED"}
              onClick={() => setShowClientSupportModal(true)}  
            />
            // can be use in futute and also remove disabled to reopen ticket status
          )} */}

          {console.log(location.state?.record?.status, "statsuss")}

          {location.state?.record?.status == "PENDING" && (
            <ButtonCommon
              type="primary"
              text={
                location.state?.record?.status == "PENDING" &&
                "Mark as Resolved"
              }
              onClick={() => setShowClientSupportModal(true)}
            />
          )}
        </Space>
        </PermissionCheck>
      </div>

      <section>
        <DetailInfo
          showClientSupportModal={showClientSupportModal}
          setShowClientSupportModal={setShowClientSupportModal}
        />
      </section>
      {/* <section>
        <Chat />
      </section> */}
    </div>
  );
};

export default TicketInfo;
