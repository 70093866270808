import React, { useEffect, useState } from "react";
import { Table, Pagination, Switch, Space } from "antd";
import Edit from "../../../Assets/Images/greyEdit.svg";
import { useDispatch } from "react-redux";
import { MarkettingActions } from "../../../redux/features/marketting/markettingActions";
import parse from "html-react-parser";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import { checkEmptyString, formatAdminNameWithRole, formatDateTime } from "../../../utils/common.utils";
import PermissionCheck from "../../../Components/PermissionCheck/PermissionCheck.jsx";
const Content = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contents, setContents] = useState();

  console.log(contents, "contents");
  const getContents = async () => {
    try {
      dispatch(startLoading());

      const res = await dispatch(MarkettingActions.getContents()).unwrap();

      setContents(res.list);
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getContents();
  }, []);
  const handleEditContent = async (record) => {
    let title = record?.title;
    title = title.replace(/\s/g, "").toLowerCase();
    navigate(`${"/content-update"}`, {
      state: { title: record?.title, data: record },
    });
  };

  const handleStatus = async (checked, record) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        MarkettingActions.updateContent({
          isDisabled: checked,
          contentId: record.contentId,
        })
      ).unwrap();
      getContents();
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (_, record) => {
        // return       record?.content ? parse(record?.content) : "-";
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "normal",
              maxWidth: "900px",
              minWidth: "200px",
            }}
            dangerouslySetInnerHTML={{
              __html: checkEmptyString(record?.content),
            }}
          ></div>
        );
      },
    },
    {
      title:"Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (_, record) => {
        return formatAdminNameWithRole(record.updatedBy, record.updatedByRole)
      }

    },

    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (_, record) => {
        return formatDateTime(record.updatedAt);
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <>
            <PermissionCheck>
              <Space size={10}>
                <img
                  src={Edit}
                  style={{ cursor: "pointer" }}
                  alt="icon"
                  onClick={() => handleEditContent(record)}
                />
                <Switch
                  onChange={(e) => handleStatus(e, record)}
                  // checkedChildren={record.isDisabled == true ? "Enable" : "Disable"}
                  checked={record.isDisabled == true ? true : false}
                  // unCheckedChildren="Disable"
                  // defaultChecked
                />
              </Space>
            </PermissionCheck>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="table-design">
        <div className="title-filter">
          <h3>Content</h3>
        </div>
        <div className="custom-table">
          <Table
            dataSource={contents}
            columns={columns}
            pagination={false}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
    </>
  );
};

export default Content;
