import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedKyc:null,
    txnDocList : []
}


export const kycSlice = createSlice({
    name: 'kyc',
    initialState,
    reducers: {
        setSelectedKYC:(state, action) => {
            state.selectedKyc = action.payload;
            
        },

        setDocList:(state, action) => {
            state.txnDocList = action.payload;
        },
        
    },
  })
  
  // Action creators are generated for each case reducer function
  export const {setSelectedKYC,setDocList} = kycSlice.actions
  
  export default kycSlice.reducer