import React from "react";
import BasicSettings from "../BasicSettings/BasicSettings";
import ChangePassword from "../ChangePassword/ChangePassword";
import style from "./style.module.scss";

function SettingComp(props) {
  
  return (
    <div className={style.setting}>
      <BasicSettings style={style}  {...props}/>
      <ChangePassword style={style} />
    </div>
  );
}

export default SettingComp;
