import { CONSTANTS } from "../constants";

const isFileTypeValid = (file, types) => {
  if (types.length > 0) {
    return types.includes(file.type);
  }
  return false;
};

const isFileSizeValid = (file, limit) => {
  console.log({ fileSize: file.size });

  const fileSize = file.size;
  if (fileSize > limit) {
    return false;
  } else {
    return true;
  }
};

const isFileDimensionValid = async (file, dimensions) => {
  const reader = new FileReader();
  const dataURL = await new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const image = new Image();
  image.src = dataURL;

  await new Promise((resolve) => {
    if (image.complete) {
      resolve();
    } else {
      image.onload = resolve;
    }
  });
  let width = image.naturalWidth;
  let height = image.naturalHeight;
  console.log(width, height)
  if(width < dimensions.width || height < dimensions.height) {
    return false;
  }


  let newWidth = width;
  let newHeight = height;
  const isValid = isAspectRatioValid(newWidth, newHeight, 2.8)
  return isValid
  

};

const isAspectRatioValid = (width, height, ratio) => {
  const aspectRatio = width / height;
  console.log({aspectRatio})
  const tolerance = 0.01;
  return Math.abs(aspectRatio - ratio) < tolerance;
}

const getFilePreview = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const FileUploadService = {
  isFileTypeValid,
  isFileSizeValid,
  isFileDimensionValid,
  getFilePreview,
};
