import { Pagination, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import TableFilterTittle from "../../../Components/TableFilterTittle/TableFilterTittle";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Validations } from "../../../validations";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import { nonReferenceActions } from "../../../redux/features/nonReference/nonReferenceActions";
import { useDispatch, useSelector } from "react-redux";
import RejectReferenceTransaction from "./RejectReferenceTransaction";
import CopyToClipboard from "../../../Components/CopyToClipboard/CopyToClipboard";
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
} from "../../../utils/common.utils";
import { Utils } from "../../../utils";
import { formatTxnType } from "../../../services/transaction.service";
import ApproveReferenceTransaction from "./ApproveReferenceTransaction";

function NonReferenceTransactions({ activeKey }) {
  const dispatch = useDispatch();
  const { formatFiatValues } = Utils.common;
  const form = useForm({
    mode: "all",
    resolver: yupResolver(Validations.nonReferenceValidation),
  });
  const [showNonReferenceModal, setShowNonReferenceModal] = useState(false);
  const [showRejectReferenceModal, setShowRejectReferenceModal] =
    useState(false);
  const [detail, setDetails] = useState("");
  const [fiatNonRefTxn, setFiatNonRefTxn] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const showApproveReferenceModal = (record) => {
    setShowNonReferenceModal(true);
    setDetails(record);
  };

  const handleRejectReferenceTxn = (record) => {
    setShowRejectReferenceModal(true);
    setDetails(record);
  };
  const selectedFiatTab = useSelector(
    (state) => state?.nonReferences?.selectedFiatTab
  );
  const handleCancel = () => {
    setShowNonReferenceModal(false);
    setDetails("");
    form.reset();
  };

  const getNonReferenceDetails = async (data) => {
    try {
      dispatch(startLoading());

      const res = await dispatch(
        nonReferenceActions.getNonReference(data)
      ).unwrap();
      if (res.totalRowCount > 0) {
        console.log(res, "afterUpdate:");
        setFiatNonRefTxn({
          list: res?.list,
          totalCount: res?.totalRowCount,
        });
      } else {
        setFiatNonRefTxn({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      setFiatNonRefTxn({
        list: [],
        totalCount: 0,
      });
    } finally {
      dispatch(finishLoading());
    }
  };
  const handlePageChange = async (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    const data = {
      apiAccessKey: "FIAT_TRANSACTIONS",
      offset: page,
      limit: pageSize,
    };
    getNonReferenceDetails(data);
  };

  useEffect(() => {
    const data = {
      apiAccessKey: "FIAT_TRANSACTIONS",
      offset: 0,
      limit: 10,
    };
    if (selectedFiatTab === "Un-Mapped-Transactions") {
      getNonReferenceDetails(data);
    }
  }, [selectedFiatTab]);

  const columns = [
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (_, record) => {
        return typeof record.currency === "string"
          ? record.currency.toUpperCase()
          : "-";
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        return formatFiatValues(record.amount);
      },
    },

    {
      title: "Type Id",
      dataIndex: "txnId",
      key: "txnId",
      render: (_, record) => {
        return (
          <>
            {record?.txnId ? (
              <>
                {handleHideId(record?.txnId)}
                <CopyToClipboard userId={record?.txnId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => {
        return (
          <>
            <p>{checkEmptyString(record.txnType)}</p>
          </>
        );
      },
    },
    {
      title: "Created on",
      dataIndex: "created",
      key: "created",
      render: (_, record) => {
        return formatDateTime(record.createdAt);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return checkEmptyString(record.status);
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (_, record) => {
        return (
          <>
            <Space>
              <ButtonCommon
                className="filter-btn"
                reject="approve"
                width={45}
                onClick={() => showApproveReferenceModal(record)}
              >
                <CheckOutlined />
              </ButtonCommon>

              <ButtonCommon
                className="filter-btn"
                type={"default"}
                onClick={() => handleRejectReferenceTxn(record)}
                htmlType="button"
                reject={"reject"}
              >
                <CloseOutlined />
              </ButtonCommon>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="table-design">
        <TableFilterTittle title="Un-Mapped Transactions" />

        <div className="custom-table">
          <Table
            className="last-center"
            columns={columns}
            dataSource={fiatNonRefTxn?.list}
            pagination={false}
          />
        </div>
      </div>

      <ApproveReferenceTransaction
        detail={detail}
        getNonReferenceDetails={getNonReferenceDetails}
        showNonReferenceModal={showNonReferenceModal}
        setShowNonReferenceModal={setShowNonReferenceModal}
        setDetails={setDetails}
        page={page}
        pageSize={pageSize}
      />
      <RejectReferenceTransaction
        detail={detail}
        getNonReferenceDetails={getNonReferenceDetails}
        showRejectReferenceModal={showRejectReferenceModal}
        setShowRejectReferenceModal={setShowRejectReferenceModal}
        page={page}
        pageSize={pageSize}
      />

      <div className="pagination_block">
        <span className="records" style={{ marginTop: "10px" }}>
          Total Records: {fiatNonRefTxn?.totalCount}
        </span>
        {fiatNonRefTxn?.totalCount > 10 && (
          <Pagination
            defaultCurrent={page}
            total={fiatNonRefTxn.totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
            current={page}
          />
        )}
      </div>
    </>
  );
}

export default NonReferenceTransactions;
