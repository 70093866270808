import FiatLimitForm from "./CryptoLimitForm";

const TransactionLimitSetting = ({key})=> {
    return(
        <div>
           <FiatLimitForm key={key}/>
        </div>
    )
}

export default TransactionLimitSetting;