import React from "react";
import { useForm } from "react-hook-form";
import { Validations } from "../../../validations";
import { Button } from "antd";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import { nonReferenceActions } from "../../../redux/features/nonReference/nonReferenceActions";
import TransactionRef from "../../../Components/TransactionRef/TransactionRef";

function ApproveReferenceTransaction({
  detail,
  getNonReferenceDetails,
  showNonReferenceModal,
  setShowNonReferenceModal,
  setDetails,
  page,
  pageSize,
}) {
  const dispatch = useDispatch();
  const form = useForm({
    mode: "all",
    resolver: yupResolver(Validations.nonReferenceValidation),
  });

  const handleCancel = () => {
    setShowNonReferenceModal(false);
    setDetails("");
    form.reset({
      transactionReference: "",
      amount: "",
    });
  };

  const handleUpdatedResponse = () => {
    const data = {
      apiAccessKey: "FIAT_TRANSACTIONS",
      offset: page,
      limit: pageSize,
    };
    getNonReferenceDetails(data);
    setShowNonReferenceModal(false);
    form.reset({
      transactionReference: "",
      amount: "",
    });
  };

  const handleUpdate = async (record) => {
    try {
      const data = {
        transactionId: detail.txnId,
        amount: record.amount,
        referenceId: record.transactionReference,
        status: "APPROVED",
        apiAccessKey: "FIAT_TRANSACTIONS",
      };

      dispatch(startLoading());

      let res = await dispatch(
        nonReferenceActions.updateNonReferenceTxn(data)
      ).unwrap();
      if (res) {
        handleUpdatedResponse();
      }
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  return (
    <>
      <CustomModal
        text="Update Role"
        title="Un-mapped-transaction"
        open={showNonReferenceModal}
        footer={[
          <Button type="default" htmlType="button" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="button"
            onClick={form.handleSubmit(handleUpdate)}
          >
            Ok
          </Button>,
        ]}
      >
        {" "}
        <TransactionRef form={form} />
      </CustomModal>
    </>
  );
}

export default ApproveReferenceTransaction;
