export const API_ENDPOINTS = {
  DASHBOARD: {},
  KYC: {
    GET_KYCS: 'kyc/get-kycs',
    UPDATE_KYC_STATUS: 'kyc/update-kyc-status',
    GET_USER_DETAIL: 'user/get-user-detail',
    UPLOAD_KYC_DOC: 'kyc/upload-kyc-doc',
    GET_TXN_DETAILS: 'kyc/get-txn-detail-by-txn-id',
    GET_KYT_DOC_LIST: 'kyc/transaction-doc-list',
  },
  ADMIN: {
    GET_DETAILS: 'admin/get-admin-details',
    GET_ROLES: 'admin-role/get-roles',
    GET_ADMINS: 'admin/user-management/get-admins',
    GET_PERMISSIONS: 'admin/permision/get-permissions',
    CREATE_ROLE_PERMISSIONS: 'admin/user-management/create-role-permissions',
    CREATE_ADMIN: 'admin/user-management/create-admin',
    GET_QR: 'admin/enable-2fa',
    VERIFY_PIN: 'admin/verify-2fa',
    VERIFY_2FA_LOGIN: 'admin/verify-2fa-login',
    GET_ROLE_PERMISSIONS: 'admin/user-management/get-role-permissions',
    UPDATE_PERMISSIONS: 'admin/user-management/update-role-permissions',
    BLOCK_UNBLOCK_ADMIN: 'admin/block-unblock',
    DELETE_ADMIN: 'admin/delete-admin',
    UPDATE_ROLE: 'admin/user-management/update-admin',
    UPLOAD_KYT_DOC:'admin/upload-kyt-doc',
    RENAME_KYT_DOCUMENT: 'admin/rename-kyt-doc',
    DELETE_KYT_DOCUMENT: 'admin/remove-kyt-doc',
    
  },
  AUTH: {
    LOGIN: 'admin/login',
    CHANGE_PASSWORD: 'admin/change-password',
    LOGOUT: 'admin/logout',
    CHECK_SESSION: 'admin/check-session-before-login',
    UPDATE_PROFILE: 'admin/update-profile',
  },
  USER: {
    GET_USERS: 'user/get-users',
    GET_USER_DETAIL: 'user/get-user-detail',
    RENAME_ADDTIONAL_DOCUMENT: 'kyc/rename-additional-doc',
    DELETE_ADDTIONAL_DOCUMENT: 'kyc/remove-additional-doc',
    BLOCK_UNBLOCK: 'user/update-block-unblock',
    DELETE_USERS: 'user/delete-user',

    ENABLE_DISABLE: 'user/update-txn-status',
    GET_USER_COUNTS: 'user/get-user-counts',
    GET_ALL_CRYPTO_TRANSACTIONS: 'wallet/transaction/get-crypto-transactions',
    GET_SWAP_TRANSACTIONS_ONLY: 'wallet/transaction/get-swap-transactions',
    GET_BANK_ACCOUNT: 'banking/get-bank-account',
    UPDATE_ACCOUNT_STATUS: 'banking/update-account-status',
    UPLOAD_KYC_DOC: 'kyc/upload-kyc-doc',
    GET_ALL_TRANSACTIONS: 'wallet/transaction/get-all-transactions',
    GET_TRANSACTIONS_DETAILS_BY_ID:
      'wallet/transaction/get-transaction-detail-by-id',
    GET_USER_ID_BY_CLIENT_ID: 'user/get-userId-by-clientId',
    GET_TRANSACTION_LIMIT_DETAIL: 'setting/get-fiat-daily-limit',
    GET_CRYPTO_TRANSACTION_LIMIT_DETAIL: 'setting/get-crypto-daily-limit',

    UPDATE_USER_TRANSACTION_LIMIT_DETAIL: 'setting/edit-fiat-daily-limit',
    UPDATE_CRYPTO_USER_TRANSACTION_LIMIT_DETAIL:
      'setting/edit-crypto-daily-limit',
  },
  ACTIVITY: {
    GET_ACTIVITIES: 'activity/get-activities',
    GET_ACTIVITIES_DETAIL: 'activity/get-activity-detail',
  },

  WALLET: {
    GET_TRANSACTIONS: 'wallet/transaction/get-transactions',
    GET_BALANCE: 'wallet/get-client-balance',
    GET_TRANSACTIONS_BY_CLIENT_ID:
      'wallet/transaction/transactions-by-clinte-id',
    GET_TRANSACTIONS_DETAILS_BY_ID:
      'wallet/transaction/get-transaction-detail-by-id',
    GET_ALL_CRYPTO_TRANSACTIONS: 'wallet/transaction/get-crypto-transactions',
    GET_LIQUIDITY_ASSETS: 'wallet/get-central-balance',
    GET_SWAP_TRANSACTIONS_ONLY: 'wallet/transaction/get-swap-transactions',
    GET_ALL_TRANSACTIONS: 'wallet/transaction/get-all-transactions',
    GET_SWAP_TRANSACTION_DETAIL: 'wallet/transaction/get-swap-transaction',
    GET_ALL_ACCOUNTS: 'banking/get-all-accounts',
    UPDATE_TRANSACTION_STATUS: 'wallet/transaction/approve-reject-transaction',
  },
  SETTING: {
    GET_WITHDRAW_FEE: 'setting/get-withdrawal-fee-list',
    EDIT_WITHDRAW_FEE: 'setting/edit-withdrawal-fee',
    GET_SWAP_WITHDRAW_FEE: 'wallet/transaction/get-swap-settings',
    EDIT_SWAP_WITHDRAW_FEE: 'wallet/transaction/update-swap-settings',
    EDIT_FIAT_WITHDRAW_FEE: 'setting/edit-fiat-withdrawal-fee',
    GET_KMS_LIST: 'kms/get-kms',
    SEND_OTP: 'admin/send-kms-otp',
    VERIFY_OTP: 'admin/verify-otp',
    TRADE_MANAGEMENT: 'swap/get-trading-pairs',
    UPDATE_TRADE_MANAGEMENT_STATUS: 'swap/update-trading-pairs',

    UPDATE_KMS: 'kms/update-kms',
    GET_REFERRAL: 'referral/get-referral-settings',
    UPDATE_REFERRAL: 'referral/update-referral-settings',
    GET_USER_REFERRAL: 'referral/get-user-referral-history',
    GET_ALL_REFERRAL: 'referral/get-all-referrals',
  },
  MARKETTING: {
    UPLOAD_BANNER: 'marketing/banners/create-banner',
    GET_BANNERS: 'marketing/banners/get-banners',
    DELETE_BANNER: 'marketing/banners/delete-banner',
    UPDATE_BANNER: 'marketing/banners/update-banner-marketing-link',
    GET_CONTENTS: 'marketing/content/get-contents',
    GET_CONTENTS_BY_ID: 'marketing/content/get-content',
    UPDATE_CONTENT: 'marketing/content/update-content',
  },
  COMPLIANCE: {
    GET_KYT_DETAILS: 'kyc/get-all-kyts',
    UPDATE_KYT_STATUS: 'wallet/transaction/approve-transactions-status',
    GET_KYC_DETAILS: '',
    UPDATE_KYC_STATUS: '',
    GET_COMPLIANCE_KYC: 'kyc/get-compliance-kycs',
    GET_COMPLIANCE_DATA: 'kyc/get-verifications-dashboard',
    GET_USER_DETAIL: 'user/get-user-detail',
    UPDATE_KYC_STATUS: 'kyc/update-kyc-status',
    GET_TRANSACTION_STATUS: 'kyc/get-transaction-status',
  },
  CLIENTSUPPORT: {
    GET_CLIENT_SUPPORT_DETAILS: 'user/get-support-tickets',
    GET_USER_DETAIL: 'user/get-user-detail',
    UPDATE_SUPPORT_TICKET_STATUS: 'user/update-support-ticket-status',
    GET_TICKET_DETAIL: 'user/get-support-ticket-detail',
  },
  RISKMANAGEMENT: {
    GET_QUESTIONNAIRE: 'kyc/get-questionnaire',
    UPDATE_MANUAL_SCORE: 'kyc/update-manual-score',
  },
  BANKING: {
    GET_FIAT_BALANCES: 'banking/get-central-balance',
    GET_TRANSACTIONS_DETAILS_BY_ID: 'banking/get-transaction-detail-by-id',
    GET_FIAT_FEE_LIST: 'banking/get-fiat-fee-list',
    CANCEL_FIAT_TRANSACTIONS: 'banking/update-transaction-status',
    UPDATE_FIAT_STATUS_COMPLIANCE: 'banking/update-fiat-status-compliance',
    UPDATE_FIAT_REAPPROVE_STATUS_COMPLIANCE: 'banking/re-approve-fiat-trxn',
    GET_FEE_STATUS: 'banking/get-fee-status',
  },
  SWAP: {
    GET_SWAP_BALANCE: 'swap/get-swap-balance',
  },
  NON_REFERENCE: {
    GET_NON_REFERENCE_DEATILS: 'banking/get-non-reference-trxn',
    UPDATE_NON_REFERENCE_DETAILS: 'banking/update-non-reference-fiat-trxn',
  },
};
