import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONSTANTS } from '../../../constants';
import { Utils } from '../../../utils';

const { SETTING } = CONSTANTS.API_ENDPOINTS;

const getWithdrawFee = createAsyncThunk(
  'setting/getWithdrawFee',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.GET_WITHDRAW_FEE}`,
        data: {},
        method: 'GET',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'FEE_SETTING',
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const editWithdrawFee = createAsyncThunk(
  'setting/editWithdrawFee',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.EDIT_WITHDRAW_FEE}`,
        data: data,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'FEE_SETTING',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getSwapWithdrawFee = createAsyncThunk(
  'setting/getSwapWithdrawFee',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.GET_SWAP_WITHDRAW_FEE}`,
        data: {},
        method: 'GET',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'FEE_SETTING',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const editSwapWithdrawFee = createAsyncThunk(
  'setting/editSwapWithdrawFee',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.EDIT_SWAP_WITHDRAW_FEE}`,
        data,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'FEE_SETTING',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const editFiatWithdrawFee = createAsyncThunk(
  'setting/editFiatWithdrawFee',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.EDIT_FIAT_WITHDRAW_FEE}`,
        data,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'FEE_SETTING',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getKmsList = createAsyncThunk(
  'setting/getKmsList',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.GET_KMS_LIST}/${data.offset}/${data.size}`,
        data: {},
        method: 'GET',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'KMS_MANAGEMENT',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getReferralList = createAsyncThunk(
  'setting/getReferralList',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.GET_REFERRAL}`,
        data: {},
        method: 'GET',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'KMS_MANAGEMENT',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getAllReferralList = createAsyncThunk(
  'setting/getAllReferralList',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.GET_ALL_REFERRAL}/${data.offset}/${data.limit}`,
        data: {},
        method: 'GET',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'KMS_MANAGEMENT',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getReferralsByUserId = createAsyncThunk(
  'setting/getReferralsByUserId',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.GET_USER_REFERRAL}?userId=${data.userId}&offset=${data.offset}&limit=${data.limit}`,
        data: {},
        method: 'GET',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'KMS_MANAGEMENT',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateReferralList = createAsyncThunk(
  'setting/updateReferralList',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.UPDATE_REFERRAL}`,
        data,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'KMS_MANAGEMENT',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const sendOtp = createAsyncThunk(
  'setting/sendOtp',
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...restdata } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.SEND_OTP}`,
        data,
        method: 'get',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: apiAccessKey,
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const verifyOtp = createAsyncThunk(
  'setting/verifyOtp',
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...restdata } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.VERIFY_OTP}`,
        data: restdata,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: apiAccessKey,
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateKms = createAsyncThunk(
  'setting/updateKms',
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.UPDATE_KMS}`,
        data,
        method: 'PUT',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'KMS_MANAGEMENT',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

export const SettingActions = {
  getWithdrawFee,
  editWithdrawFee,
  getSwapWithdrawFee,
  editSwapWithdrawFee,
  editFiatWithdrawFee,
  getKmsList,
  sendOtp,
  verifyOtp,
  updateKms,
  getReferralList,
  updateReferralList,
  getAllReferralList,
  getReferralsByUserId,
};
