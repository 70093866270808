import React from "react";
import Filters from "./Filters";

const UserDirectoryFilterBar = (props) => {
  const { title, filterData } = props;
  return (
    <>
      <div className="title-filter">
        <h3>{title}</h3>
        <Filters pageSize={props.pageSize} filterData={filterData} setPage={props.setPage} data={props.data} /> 
      </div>
    </>
  );
};

export default UserDirectoryFilterBar;
