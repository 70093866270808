import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { ADMIN } = CONSTANTS.API_ENDPOINTS;

const getDetails = createAsyncThunk(
  "admin/details",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: ADMIN.GET_DETAILS,
        data: data,
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);


const getAdmins = createAsyncThunk(
  "admin/getAdmins",
  async ({ limit = 10, page = 1 }, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.GET_ADMINS}/${page}/${limit}`,
        data: {},
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey:"USER_MANAGEMENT" },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const getRoles = createAsyncThunk(
  "admin/getRoles",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.GET_ROLES}`,
        data: {},
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey:"USER_MANAGEMENT" },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);



const getQrCode = createAsyncThunk(
  "admin/qr",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: ADMIN.GET_QR,
        data: {},
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);




const verifyPin = createAsyncThunk(
  "admin/verify",
  async (pin, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: ADMIN.VERIFY_PIN,
        data: {token:pin},
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: false },
      },
      { rejectWithValue, dispatch }
    );
  }
);

const verifyLoginPin = createAsyncThunk(
  "admin/loginVerify",
  async (data, { rejectWithValue, dispatch }) => {
     const res = Utils.api.baseApiCall(
      {
        endpoint: ADMIN.VERIFY_2FA_LOGIN,
        data,
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: false },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }

  }
);

const getPermissions = createAsyncThunk(
  "admin/getPermissions",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: ADMIN.GET_PERMISSIONS,
        data: {},
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey:"USER_MANAGEMENT" },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const createRolePermissions = createAsyncThunk(
  "admin/createRolePermissions",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: ADMIN.CREATE_ROLE_PERMISSIONS,
        data,
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey:"ROLE_MANAGEMENT" },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const getSelectedRolePermissions = createAsyncThunk(
  "admin/getSelectedRolePermissions",
  async ({roleId=-1}, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.GET_ROLE_PERMISSIONS}/${roleId}`,
        data: {},
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey:"ROLE_MANAGEMENT" },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const updatePermissions = createAsyncThunk(
  "admin/updatePermissions",
  async (data, { rejectWithValue, dispatch }) => {
    let newData = {...data};
    const {roleId} = newData;
    delete data.roleId;
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.UPDATE_PERMISSIONS}/${roleId}`,
        data,
        method: "PUT",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey:"ROLE_MANAGEMENT" },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const blockUnblockAdmin = createAsyncThunk(
  "user/blockUnblockAdmin",
  async (data, { rejectWithValue, dispatch }) => {
    let newData = {...data};
    const {adminId,statusReason} = newData;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.BLOCK_UNBLOCK_ADMIN}/${adminId}`,
        data:{statusReason:statusReason},
        method: "PUT",
        assignedHeaders:{withToken: true, isMultipart:false, apiAccessKey:"USER_MANAGEMENT"},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);


const deleteAdmin = createAsyncThunk(
  "user/deleteAdmin",
  async (data, { rejectWithValue, dispatch }) => {
    let newData = {...data};
    const {adminId,statusReason} = newData;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.DELETE_ADMIN}/${adminId}`,
        data:{statusReason:statusReason},
        method: "PUT",
        assignedHeaders:{withToken: true, isMultipart:false, apiAccessKey:"USER_MANAGEMENT"},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const createAdmin = createAsyncThunk(
  "user/createAdmin",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.CREATE_ADMIN}`,
        data,
        method: "POST",
        assignedHeaders:{withToken: true, isMultipart:false, apiAccessKey:"USER_MANAGEMENT"},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateRole = createAsyncThunk(
  "user/updateRole",
  async (data, { rejectWithValue, dispatch }) => {
    const newData = {...data};
    delete newData.adminId
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.UPDATE_ROLE}/${data.adminId}`,
        data:newData,
        method: "PUT",
        assignedHeaders:{withToken: true, isMultipart:false, apiAccessKey:"USER_MANAGEMENT"},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

export const AdminActions = {
  getDetails,
  getRoles,
  getAdmins,
  getQrCode,
  verifyPin,
  verifyLoginPin,
  getPermissions,
  createRolePermissions,
  getSelectedRolePermissions,
  updatePermissions,
  blockUnblockAdmin,
  deleteAdmin,
  createAdmin,
  updateRole
}
