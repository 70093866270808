const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
const API_URL =  process.env.REACT_APP_API_URL;
const BASE_API_URL =  process.env.REACT_APP_BASE_API_URL;
const APP_ENV = process.env.REACT_APP_ENV;
const KYC_DOCS_URL = process.env.REACT_APP_KYC_DOC_URL
const TRANSACTION_KEY = process.env.REACT_APP_TRANSACTION_KEY

export const Enviroments = {
    ENCRYPTION_KEY,
    API_URL,
    APP_ENV,
    KYC_DOCS_URL,
    TRANSACTION_KEY,
    BASE_API_URL
}