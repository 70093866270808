import { baseApiCall } from "./api.utils";
import {
  checkEmptyString,
  formatDateTime,
  renderConditionalElement,
  percentageChanged,
  formatDate,
  capitalizeFirstLetter,
  formatUserRole,
  checkFalsyValues,
  divideBySmallestUnit,
  blobToBase64,
  calculatePercentage,
  isJsonValid,
  formatFiatValues,
  formatTransactionType,
  formatAdminNameWithRole,
  createFullName
} from "./common.utils";

export const Utils = {
  api: {
    baseApiCall,
  },
  common: {
    checkEmptyString,
    formatDateTime,
    renderConditionalElement,
    percentageChanged,
    formatDate,
    capitalizeFirstLetter,
    formatUserRole,
    checkFalsyValues,
    divideBySmallestUnit,
    blobToBase64,
    calculatePercentage,
    isJsonValid,
    formatFiatValues,
    formatTransactionType,
    formatAdminNameWithRole,
    createFullName
  },
};
