import React, { useState } from 'react';
import ReferralProgramLists from './ReferralProgramLists';
import UpdateReferralprogram from './UpdateReferralprogram';
import { useDispatch } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice';
import { SettingActions } from '../../redux/features/setting/settingActions';
import AllReferrals from './AllReferrals';

function ReferralProgram() {
  const dispatch = useDispatch();
  const [editReferralItem, setEditreferralItem] = useState(null);
  const [referralProgramList, setReferralProgramList] = useState([]);

  const getReferralList = async () => {
    try {
      dispatch(startLoading());
      const res = await dispatch(SettingActions.getReferralList()).unwrap();

      if (res?.referralSettings?.length > 0) {
        setReferralProgramList(res);
      } else {
        setReferralProgramList([]);
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  return (
    <div>
      <div className='table-design' style={{maxHeight:'550px'}}>
        <div className='title-filter'>
          <h3>Referral Program</h3>
        </div>
        <UpdateReferralprogram
          editReferralItem={editReferralItem}
          getReferralList={getReferralList}
          referralProgramList={referralProgramList}
          setEditreferralItem={setEditreferralItem}
        />
        <div style={{ marginTop: '50px' }}>
          <ReferralProgramLists
            setEditreferralItem={setEditreferralItem}
            getReferralList={getReferralList}
            referralProgramList={referralProgramList}
          />
        </div>
      </div>
      <AllReferrals/>
    </div>
  );
}

export default ReferralProgram;
