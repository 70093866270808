import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { SWAP } = CONSTANTS.API_ENDPOINTS;

const getSwapBalance = createAsyncThunk(
  "wallet/swap/balance",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SWAP.GET_SWAP_BALANCE}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

export const SwapActions = {
  getSwapBalance,
};
