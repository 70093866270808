import React, { useEffect, useState } from "react";
import TableFilterTittle from "../../../Components/TableFilterTittle/TableFilterTittle";
import { Button, Modal, Switch, Table } from "antd";
import { checkEmptyString, formatDateTime } from "../../../utils/common.utils";
import { useDispatch } from "react-redux";
import { SettingTradeActions } from "../../../redux/features/setting/tradeManagentActions";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import PermissionCheck from "../../../Components/PermissionCheck/PermissionCheck";
import ScreenBreakpoints from "../../../Components/CustomHook/ScreenBreakpoints.jsx";
import CustomModal from "../../../Components/CustomModal/CustomModal";

function TradeManagement() {
  const dispatch = useDispatch();
  const [tradingDetails, setTradingDetails] = useState([]);
  const [isLargeScreen, isSmallScreen] = ScreenBreakpoints();
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [pairRecord, setPairRecord] = useState("");
  const [updatePairStatus, setUpdatePairStatus] = useState();
  const tableSize = isLargeScreen
    ? "large"
    : isSmallScreen
    ? "middle"
    : "small";

  const getTradDetails = async () => {
    try {
      const data = {
        apiAccessKey: "TRADE_MANAGEMENT",
      };
      await dispatch(startLoading());
      const res = await dispatch(
        SettingTradeActions.getTradeManagement(data)
      ).unwrap();

      if (res?.length !== 0) {
        setTradingDetails(res);
      } else {
        setTradingDetails([]);
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getTradDetails();
  }, []);

  const handleUpdateStatus = async (checked, details) => {
    setIsActionModalOpen(true);
    setPairRecord(details);
    setUpdatePairStatus(checked);
  };

  const handleUpdatePair = async () => {
    try {
      const data = {
        apiAccessKey: "TRADE_MANAGEMENT",
        pairName: pairRecord?.pairName,
        isActive: updatePairStatus,
      };
      await dispatch(startLoading());
      const res = await dispatch(
        SettingTradeActions.updateTradeManagementStatus(data)
      ).unwrap();

      getTradDetails();
      setIsActionModalOpen(false);
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handleCancelPair = () => {
    setIsActionModalOpen(false);
  };

  const columns = [
    {
      title: "Pair Name",
      dataIndex: "pairName",
      key: "pairName",
      render: (_, record) => checkEmptyString(record.pairName),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (record.isActive == true ? "ACTIVE" : "INACTIVE"),
    },
    {
      title: "created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => formatDateTime(record.createdAt),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (_, record) => formatDateTime(record.updatedAt),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <div style={{display:"flex",justifyContent:"center"}}>
            <PermissionCheck>
              <Switch
                checkedChildren="Inactive"
                unCheckedChildren="Active"
                defaultChecked={record.isActive === true ? true : false}
                checked={record.isActive === true ? true : false}
                onChange={(checked) => handleUpdateStatus(checked, record)}
              />
            </PermissionCheck>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="table-design">
        <TableFilterTittle title="Trade/Swap Management" />

        <div className="custom-table">
          <Table
            className="last-center"
            columns={columns}
            dataSource={tradingDetails}
            pagination={false}
          />
        </div>
      </div>

      <CustomModal
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            Are you sure you want to{" "}
            {pairRecord.isActive == true ? "INACTIVE" : "ACTIVE"}
          </div>
        }
        open={isActionModalOpen}
        footer={[
          <Button
            key="cancel"
            type="default"
            htmlType="button"
            onClick={handleCancelPair}
          >
            Cancel
          </Button>,
          <Button
            key="yes"
            type="primary"
            htmlType="button"
            onClick={handleUpdatePair}
          >
            Yes
          </Button>,
        ]}
      ></CustomModal>
    </>
  );
}

export default TradeManagement;
