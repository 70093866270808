import { Badge, Typography, Tag } from "antd";
import { Utils } from "../utils";
import { CONSTANTS } from "../constants";

const { Text } = Typography;

export const getUserVerificationStatus = (status) => {
  console.log("status here", status);
  if (status === "APPROVED") {
    return (
      <Text code>
        <Badge status="success" />
        VERIFIED
      </Text>
    );
  } else if (status === "PENDING"){
    return (
      <Text code>
        <Badge status="warn" />
        PENDING
      </Text>
    );
  } else if(status === "REJECTED"){
    return (
      <Text code>
      <Badge status="danger" />
      REJECTED
    </Text>
    )
   
  }

  
};

export const getAdminVerificationStatus = (status) => {
  if (status === "APPROVED") {
    return (
      <Tag style={{fontSize:"14px",padding:"10px 15px" }} color="green">{"VERIFIED"}</Tag>
    );
  } else if (status === "PENDING"){
    return (
      <Tag style={{fontSize:"14px",padding:"10px 15px" }} color="yellow">{status}</Tag>
    );
  } else if(status === "REJECTED"){
    return (
      <Tag style={{fontSize:"14px",padding:"10px 15px" }} color="red">{status}</Tag>
    )
   
  }
}

export const getSumsumVerificationStatus = (status) => {
  const {APPROVED,FINAL_REJECTED,INITIATED,PENDING,REJECTED} = CONSTANTS.KYC_STATUS
  if (status === APPROVED) {
    return (
      <Tag style={{fontSize:"16px",padding:"10px 15px" }} color="green">{"VERIFIED"}</Tag>
    );
  } else if (status === INITIATED || status === PENDING){
    return (
      <Tag style={{fontSize:"16px",padding:"10px 15px" }} color="yellow">{"Awaiting sumsub approval"}</Tag>
    );
  } else if(status === FINAL_REJECTED || status === REJECTED){
    return (
      <Tag style={{fontSize:"16px",padding:"10px 15px" }} color="red">{REJECTED}</Tag>
    )
   
  }
}


export const showName = (record) => {
  const { checkEmptyString, capitalizeFirstLetter } = Utils.common;
  if(record?.accountType){
    if(record?.accountType === "INDIVIDUAL"){
      return checkEmptyString(
        (capitalizeFirstLetter(record.firstName?.trim()) ?? "") +
        " " +
        (record.lastName?.trim() ?? "")
      )
    } else {
      return checkEmptyString(
       record?.businessName
      )
    }
  }
  return "-"
}

