import axios from "axios";
import EncryptionService from "./encryption.service";
import { toast } from "react-toastify";
export { _fetch as fetch };

axios.interceptors.request.use((config) => {
  /* some logic */
  const controller = new AbortController();

  setTimeout(() => controller.abort(), 20000);

  return {
    ...config,
    signal: controller.signal,
  };
});

function handleError(error, reject) {
  if (axios.isCancel(error)) {
    // Handle cancellation error
    error.message = "please try again or reload the system";
    console.error("please try again or reload the system");
    reject(error);
  }
  if (error?.response?.status === 401) {
    reject(error);
  }
  if (error?.code === "ERR_NETWORK") {
    error.message = "Network error";
    reject(error);
  }
  if (error?.data?.message) {
    // Stop execution after this toast
    reject(error);
  }

  if (error?.response?.data?.response?.message) {
    // Stop execution after this toast
    reject(error);
  }

  if (error?.data?.errors?.message) {
    // Stop execution after this toast
    reject(error);
  }

  if (error?.message) {
    // Stop execution after this toast
    reject(error);
  }
}

function handleResponse(successs, resolve) {
  resolve(successs);
}

function setMethod(method, path, body, headers, encrypt, params) {
  let config = {};

  if (headers || Object.keys(headers).length > 0) {
    config.headers = { ...headers };
  }

  if (headers) {
    if (headers.jwt) {
      config.headers = {
        "api-access-token": headers?.jwt,
      };
      delete config.headers.jwt;
    }
  }
  params = params ? "?" + new URLSearchParams(params).toString() : "";
  if (encrypt) {
    body = EncryptionService.encrypt(body);
  }
  if (method === "get" || method === "delete") {
    return axios[method](`${path}${params}`, config);
  }
  if (method === "post" || method === "put" || method === "patch") {
    return axios[method](`${path}`, body, config);
  }
}

function _fetch(method, path, body, headers, encrypt, params) {
  return new Promise((resolve, reject) => {
    return setMethod(method, path, body, headers, encrypt, params)
      .then((response) => {
        handleResponse(response, resolve);
        return;
      })
      .catch((error) => {
        handleError(error, reject);
        return;
      });
  });
}
