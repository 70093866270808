import React from "react";
import style from "./style.module.scss";

function Usercard({ img, NoUser, k, invalue, value, invalue1, value1 }) {
  return (
    <div className={style.users}>
      <div className={style.users_imagesec}>
        <span>{img}</span>
        <p>
          {NoUser} <h4>{k}</h4>
        </p>
      </div>
      <div className={style.users_description}>
        <h4 className={invalue.className}>{invalue.content}</h4>
        <p>{value}</p>
      </div>
      <div className={style.users_description}>
        <h4 className={invalue1.className}>{invalue1.content}</h4>
        <p>{value1}</p>
      </div>
    </div>
  );
}

export default Usercard;
