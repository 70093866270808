import React from "react";
import { Checkbox } from "antd";

const CustomCheckbox = ({
  onChange, value, checked, name
}) => {
 
  return (
    <>
      <Checkbox onChange={onChange} value={value} checked={checked} name={name}></Checkbox>
    </>
  );
};

export default CustomCheckbox;
