import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedComplianceTab: "All",
  selectedSanctionTab: "Individual",
  selectedKey: 1,
  selectedSanctionType: "",
  selectedVerificationTab: "Rejected",
  transactionDetail:{}
};

export const complianceSlice = createSlice({
  name: "Compliance",
  initialState,
  reducers: {
    setSelectedComplianceTab: (state, action) => {
      state.selectedComplianceTab = action.payload;
    },
    setSelectedSanctionTab: (state, action) => {
      state.selectedSanctionTab = action.payload;
    },

    setSelectedVerificationTab: (state, action) => {
      state.selectedVerificationTab = action.payload;
    },
    setSelectedKey: (state, action) => {
      state.selectedKey = action.payload;
    },
    setSelectedSanctionType: (state, action) => {
      state.selectedSanctionType = action.payload;
    },
    setTransactionDetail: (state, action) => {
      state.transactionDetail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedComplianceTab,
  setSelectedVerificationTab,
  setSelectedKey,
  setSelectedSanctionTab,
  setSelectedSanctionType,
  setTransactionDetail
} = complianceSlice.actions;

export default complianceSlice.reducer;
