import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        startLoading: () => ({ isLoading: true }),
        finishLoading: () => ({ isLoading: false }),
    },
});

export const { startLoading, finishLoading } = loadingSlice.actions;
export default loadingSlice.reducer;