import { createSlice } from '@reduxjs/toolkit'
import { BankingActions } from './bankingActions'

const initialState = {
    data: null
}


export const bankSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        setCancelFiatTxn: (state, action) => {
            state.data = action.payload;
          },
    },
   
  })
  
  // Action creators are generated for each case reducer function
  
  export default bankSlice.reducer