import React from 'react'
import SettingComp from '../../Components/SettingComponent/SettingComp'
import { useSelector } from 'react-redux'


function Setting() {
    const {data} = useSelector(state => state.admin)

    return (
        <div>
            <SettingComp email={data?.email} firstName={data?.firstName} lastName={data?.lastName} twoFa={data?.is2FaEnable} lastLoginTime={data?.lastLoginTime}/>
        </div>
    )
}

export default Setting