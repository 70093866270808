import React from "react";
import { Input } from "antd";
import style from "./style.module.scss";
function Rejectdata({setrejectionReason, rejectionReason}) {

  
  const { TextArea } = Input;
  return (
    <div>
      
      <p className="suretext rejectText">Are you sure, you want to reject?</p>
      <TextArea rows={4} placeholder="Enter Reason" value={rejectionReason} onChange={e=>setrejectionReason(e.target.value)} />

    
     
    </div>
  );
}

export default Rejectdata;
