import React, { useEffect, useState } from "react";
import { Table, Pagination, Button, Typography, Tabs } from "antd";
import TableFilterTittle from "../../Components/TableFilterTittle/TableFilterTittle.jsx";
import DetailModal from "../UserDirectory/DetailModal.jsx";
import Chat from "../../Components/Chat/Chat.jsx";
import { useDispatch, useSelector } from "react-redux";
import { ClientSupportActions } from "../../redux/features/clientSupport/clientSupportActions.js";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice.js";
import {
  checkEmptyString,
  formatDate,
  handleHideId,
  sliceString,
} from "../../utils/common.utils.js";
import Pending from "./Pending.jsx";
import Completed from "./Completed.jsx";
import { useLocation } from "react-router-dom";
import { setSelecteClientSupportTab } from "../../redux/features/clientSupport/clientSupportSlice.js";

const SupportCommunication = () => {
  const selectedClientSupportTab = useSelector(
    (state) => state?.clietSupport?.selectedClientSupportTab
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState("1");
  const { Text } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedSupportTab, setSelectedSupportTab] = useState("pending");

  const onChange = (key) => {
    console.log(key);
    setSelectedKey(key);
    if (key == "1") {
      setSelectedSupportTab("pending");
      dispatch(setSelecteClientSupportTab("Pending"));
    }
    if (key == 2) {
      setSelectedSupportTab("completed");
      dispatch(setSelecteClientSupportTab("Resolved"));
    }
  };

  useEffect(() => {
    if (selectedClientSupportTab === "Pending") {
      onChange("1");
    } else {
      onChange("2");
    }
  }, [selectedClientSupportTab]);

  const items = [
    {
      key: "1",
      label: "Pending",
      children: <Pending selectedSupportTab={selectedSupportTab} />,
    },
    {
      key: "2",
      label: "Resolved",
      children: <Completed selectedSupportTab={selectedSupportTab} />,
    },
  ];

  console.log(selectedClientSupportTab, "selectedClientSupportTab");
  return (
    <>
      <div className="Custom-tabBtn">
        <Tabs
          defaultActiveKey={selectedKey}
          activeKey={selectedKey}
          items={items}
          onChange={onChange}
          animated={true}
        />
      </div>

      {/* <Chat /> */}
      {isModalOpen && (
        <DetailModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          // handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default SupportCommunication;
