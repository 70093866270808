import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { MarkettingActions } from "../../../redux/features/marketting/markettingActions";
import { useDispatch } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";

function UpdateContent() {
  const [value, setValue] = useState({ content: "" });
  const [requireInput, setRequireInput] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const quillRef = useRef(null);
  const dispatch = useDispatch();
  const contentId = location.state.data.contentId;

  const getDetailsById = async () => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        MarkettingActions.getContentsById(contentId)
      ).unwrap();
      setValue({ content: res.content });
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  console.log(value, "contentsById");

  useEffect(() => {
    getDetailsById();
  }, []);

  const handleChange = (value) => {
    // const quill = quillRef.current.getEditor();
    // console.log({length:quill.getLength()});
    // quill.on("text-change", function (delta, oldDelta, source) {
    //   if (quill.getLength() >= MAX_CONTENT_LENGTH) {
    //     quill.deleteText(MAX_CONTENT_LENGTH, quill.getLength());
    //   }
    // });
    setValue({ content: value });
  };

  const handleSubmit = async () => {
    try {
      if (
        value.content.trim() === "<p><br></p>" ||
        value.content.trim() === ""
      ) {
        setRequireInput(true);
      } else {
        dispatch(startLoading());
        setRequireInput(false);
        const res = await dispatch(
          MarkettingActions.updateContent({ content: value.content, contentId })
        ).unwrap();
        navigate("/content");
      }

      // setValue({ content: res.content });
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };
  const handleCancel = () => {
    setRequireInput(false);
    value !== "" && setValue("");
    navigate(-1);
  };

  console.log(value, "valuevalue");
  return (
    <>
      <div className="table-design aboutText">
        <div className="title-filter">
          <h3>{location.state.title}</h3>
        </div>

        <ReactQuill
          theme="snow"
          ref={quillRef}
          value={value.content}
          onChange={handleChange}
        />
        <span style={{ color: "red", paddingLeft: "4px" }}>
          {" "}
          {requireInput && "Field is Required"}
        </span>
        <div className="aboutUs">
          <Button type="primary" danger onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}

export default UpdateContent;
