import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const ComplianceFilterTittle = ({ title }) => {
  return (
    <>
      <h3>{title}</h3>
    </>
  );
};

export default ComplianceFilterTittle;
