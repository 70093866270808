import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import "../KmsManagement/verification.scss";
import { Button } from "antd";
import { toast } from "react-toastify";
import { SettingActions } from "../../redux/features/setting/settingActions";
import { useDispatch, useSelector } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";

export default function KmsManagementVerification({ setShowKmsList }) {
  const { isInitialPageLoaded } = useSelector((state) => state.dashboard);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [otpTitle, setOtpTitle] = useState("Please request for OTP");
  const [checkEmail, setCheckEmail] = useState("");
  const state = useSelector((state) => state.admin);
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();

  const handleVerifyOtpRequest = async () => {
    if (otp == "") return toast.error("Please enter OTP");

    try {
      if (!otp) return;
      const data = {
        email: state?.data?.email,
        method: "EMAIL",
        service: "KMS",
        otp: otp,
        apiAccessKey: "KMS_MANAGEMENT",
      };

      dispatch(startLoading());
      const res = await dispatch(SettingActions.verifyOtp(data)).unwrap();
      setDisableButton(false);
      setShowKmsList(true);
      setOtp("");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const handleSendOtpRequest = async () => {
    try {
      const data = {
        apiAccessKey: "KMS_MANAGEMENT",
      };

      dispatch(startLoading());
      const res = await dispatch(SettingActions.sendOtp(data)).unwrap();
      console.log(res, "resresresaaaaaaa");
      setOtpTitle("Please verify OTP");
      setCheckEmail("OTP has been sent to your email");

      resendOTP();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else if (seconds === 0) {
      setDisableButton(false);
      setCheckEmail("");
      setOtpTitle("Please request for OTP");
      setOtp("");
    }
  }, [seconds]);

  const resendOTP = () => {
    setSeconds(59);
    setDisableButton(true);
  };
  useEffect(() => {
    setDisableButton(false);
    setCheckEmail("");
    setOtpTitle("Please request for OTP");
    setOtp("");
    setSeconds(0);
  }, [isInitialPageLoaded]);

  return (
    <div className="table-design">
      <div className="title-filter">
        <h3>KMS Verification</h3>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <h3>{otpTitle}</h3>
        <h4>{checkEmail}</h4>
        <h3>{seconds > 0 && seconds}</h3>
      </div>

      <OtpInput
        containerStyle="otp-container"
        inputStyle="otp-input"
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderInput={(props) => <input {...props} disabled={!disableButton} />}
      />

      <div className="kmsverificationbtns">
        <Button
          onClick={handleSendOtpRequest}
          disabled={disableButton}
          type={disableButton ? "default" : "primary"}
          style={{ marginRight: "10px" }}
        >
          Send OTP
        </Button>

        <Button
          onClick={handleVerifyOtpRequest}
          htmlType="submit"
          disabled={!disableButton}
          type={disableButton ? "primary" : "default"}
        >
          Verify OTP
        </Button>
      </div>
    </div>
  );
}
