import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import TableFilterTittle from "../../Components/TableFilterTittle/TableFilterTittle.jsx";
import Crypto from "../UserDirectory/transations/Crypto.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";
import { Utils } from "../../utils/index.js";
import { WalletActions } from "../../redux/features/wallet/walletActions.js";
import { useLocation, useNavigate } from "react-router-dom";
import Swap from "../UserDirectory/transations/Swap.jsx";
import {
  checkFalsyValues,
} from "../../utils/common.utils.js";
import { formatTxnType } from "../../services/transaction.service.js";
import DownloadFile from "../../Components/Common/DownloadFile.jsx";
import { setHeaderAndRows } from "../../redux/features/download/downloadSlice.js";
import { CONSTANTS } from "../../constants/index.js";

const SwapTransactions = () => {
  const download = useSelector((state) => state.download);
  const { Text } = Typography;
  const [transactionData, setTransactionData] = useState({
    list: [],
    totalCount: 0,
  });
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const formatTransactionData = (data) => {
    console.log({ cryptoData: data });
    const { formatDateTime, divideBySmallestUnit } = Utils.common;
    return data.map((obj, index) => {
      return {
        key: index + 1,
        ...obj,
        createdAt: formatDateTime(obj.createdAt),
      };
    });
  };

  const formatDownloadData = (data) => {
    if (data && data.length > 0) {
      return data.map((obj) => {
        return {
          ...obj,
        };
      });
    }
    return [];
  };

  const getSwapTransactionsOnly = async (data) => {
    try {
      const updatedState = { ...location.state, ...data }; // Add or update state
      navigate("/swap-transaction", { state: { ...updatedState } }); // Navigate to the same path with new state
      await dispatch(startLoading());
      const res = await dispatch(
        WalletActions.getSwapTransactionsOnly(data)
      ).unwrap();

      if (res && res.totalCount > 0) {
        const txnData = formatTransactionData(res.list);
        const downloadData = formatDownloadData(res.list);
        if (!download.isEnabled && download.path === "NONE") {
          setTransactionData({ list: txnData, totalCount: res.totalCount });
        } else {
          dispatch(
            setHeaderAndRows({
              headers: CONSTANTS.SWAP_CSV_HEADERS,
              rows: txnData,
            })
          );
        }
      } else {
        setTransactionData({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      //  console.log("my ere :  ",error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  const obj = { ...location.state };
  useEffect(() => {
    const data = {
      offset: "0",
      limit: "10",
      // userId: "4a894656-31c6-4ad3-b93c-92f27c0e00e6",
    };
    getSwapTransactionsOnly(data);
  }, []);

  return (
    <>
      <div className="table-designa">
        <TableFilterTittle title="Swap Transactions" />
        {/* <DownloadFile
          fileName={`swap_txns_${new Date().toDateString()}_${new Date().toTimeString()}.csv`}
          type="CSV"
          path="SWAP_TRANSACTIONS"
        /> */}
        {/* <div className="custom-table">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div> */}
        {/* <Crypto data={transactionData.list} totalCount={transactionData.totalCount} getCryptoTransactions={getCryptoTransactions}/> */}
        <Swap
          data={transactionData.list}
          totalCount={transactionData.totalCount}
          getSwapTransactionsOnly={getSwapTransactionsOnly}
        />
      </div>
    </>
  );
};

export default SwapTransactions;
