import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Rewards from "./Rewards";

import DasboardContent from "./DasboardContent";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { Utils } from "../../../utils";
import { CONSTANTS } from "../../../constants";
import { MarkettingActions } from "../../../redux/features/marketting/markettingActions";
import { setSelectedBanner } from "../../../redux/features/marketting/markettingSlice";
import { createFullName, formatAdminNameWithRole } from "../../../utils/common.utils";

const Banner = () => {
  const [banners, setBanners] = useState({
    list: [],
    totalCounts: 0,
  });

  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("1");
  const onChange = (key) => {
    setActiveKey(key);
    dispatch(
      setSelectedBanner({
        type: key === "1" ? "WEB" : "MOBILE",
      })
    );
  };

  const formatBanners = (data) => {
    const { checkEmptyString, formatDateTime } = Utils.common;
    return data.map((banner) => {
      let deletedBy = createFullName(banner?.deletedAdmin?.firstName, banner?.deletedAdmin?.lastName);
      let deletedByRole = banner?.deletedAdmin?.title;
      let uploadedBy = createFullName(banner?.createdAdmin?.firstName, banner?.createdAdmin?.lastName);
      let uploadedByRole = banner?.createdAdmin?.title;
      let updatedAdmin = createFullName(banner?.updatedAdmin?.firstName, banner?.updatedAdmin?.lastName);
      let updatedAdminRole = banner?.updatedAdmin?.title;

      return {
        bannerId: banner.bannerId,
        imageIcon: `${CONSTANTS.ENV.KYC_DOCS_URL + banner.image}`,
        ip: banner.ip,
        createdAt: formatDateTime(banner?.createdAt),
        updatedAt: formatDateTime(banner?.updatedAt),
        deletedBy: formatAdminNameWithRole(deletedBy,deletedByRole),
        uploadedBy:formatAdminNameWithRole(uploadedBy,uploadedByRole),
        marketingLink: banner?.marketingLink,
        updatedAdmin:formatAdminNameWithRole(updatedAdmin, updatedAdminRole)
      };
    });
  };

  const isNameValid = (name) => {
    if (name) return name.trim();
    else return "";
  };

  const getBanners = async (data) => {
    try {
      dispatch(startLoading());

      const res = await dispatch(MarkettingActions.getBanners(data)).unwrap();
      const newRes = { ...res };
      if (newRes && (newRes.list.length > 0 || newRes.totalCounts > 0)) {
        setBanners((x) => {
          return {
            ...banners,
            list: formatBanners([...newRes.list]).slice(),
            totalCounts: newRes.totalCounts,
          };
        });
      } else {
        setBanners({
          list: [],
          totalCounts: 0,
        });
      }
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    const data = {
      limit: 10,
      offset: 0,
      type: "WEB",
      isDeleted: false,
    };
    getBanners(data);
  }, []);

  const items = [
    {
      key: "1",
      label: "Web",
      children: (
        <Rewards
          banners={banners}
          getBanners={getBanners}
          setBanners={setBanners}
        />
      ),
    },
    {
      key: "2",
      label: "Mobile",
      children: (
        <DasboardContent
          banners={banners}
          getBanners={getBanners}
          setBanners={setBanners}
        />
      ),
    },
  ];

  return (
    <>
      <div className="Custom-tabBtn">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          animated={true}
          activeKey={activeKey}
        />
      </div>
    </>
  );
};

export default Banner;
