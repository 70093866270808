import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, route }) => {
  let { routes } = useSelector((state) => state.dashboard);
  let admin = useSelector((state) => state.admin);
  let role = admin?.data?.title || "";
  

  console.log("routes in app", routes);
  const getPermissions = () => {
    if(routes){
      let allPermissions = routes.map((obj) => {
        return obj.route;
      });
      return allPermissions;

    } 
    return [];
  };

  const hasPermission = (route) => {
    const userPermissions = getPermissions();
    return userPermissions.length == 0 || userPermissions.includes(route) || role === "Master Admin";
  };

  return hasPermission(route) ? (
    children
  ) : (
    <Navigate to="/not-authorized" replace />
  );
};

export default ProtectedRoute;
