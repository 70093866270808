import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { RISKMANAGEMENT } = CONSTANTS.API_ENDPOINTS;

const getQuestionnaire = createAsyncThunk(
  "user/getQuestionnaire",
  async ({ userId = -1 }, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${RISKMANAGEMENT.GET_QUESTIONNAIRE}/${userId}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const updateManualScore = createAsyncThunk(
  "user/updateManualScore",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${RISKMANAGEMENT.UPDATE_MANUAL_SCORE}`,
        data,
        method: "PATCH",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      await dispatch(getQuestionnaire({ userId: res.userId }));
      return res;
    }
  }
);

export const riskManagementActions = {
  getQuestionnaire,
  updateManualScore,
};
