import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { CONSTANTS } from "../../constants";

const PermissionCheck = ({ children }) => {
  const location = useLocation();
  const dashboardData = useSelector((state) => state.dashboard);
  const adminData = useSelector((state) => state.admin);
  const role = adminData.data?.title;

  const { routes } = dashboardData;
  const currentRoute = location.pathname;

  const canWrite = () => {
    const { PERMISSION_TYPE } = CONSTANTS;
    if (routes && routes.length > 0) {
      const foundPermission = routes.find(
        (obj) => currentRoute.indexOf(obj.route) != -1
      );
      // console.log({ permissionType: foundPermission.permissionType, role });
      return (
        foundPermission?.permissionType === PERMISSION_TYPE.READWRITE ||
        role === "Master Admin"
      );
    }
  };

  return <>{canWrite() ? children : null}</>;
};

export default PermissionCheck;
