import React from "react";
import { Select, Form } from "antd";
import ScreenBreakpoints from "../CustomHook/ScreenBreakpoints";

const CustomSelect = (props) => {
  const { label, options, defaultValue, showSearch, ...rest } = props;
  const [isLargeScreen, isSmallScreen] = ScreenBreakpoints();
  const selectSize = isLargeScreen
    ? "large"
    : isSmallScreen
    ? "middle"
    : "small";
  console.log({ props });
  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="custom-inputDesign">
      {/* <Form.Item style={{ marginBottom: "unset" }} name="note" label={label}> */}
        <Select
          {...rest}
          size={selectSize}
          showSearch={showSearch}
          defaultValue={defaultValue}
          optionFilterProp="children"
          filterOption={filterOption}
          options={options}
        />
      {/* </Form.Item> */}
    </div>
  );
};

export default CustomSelect;
