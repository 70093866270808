import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoutes() {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  return auth.isLoggedIn && auth.jwtToken ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
}

export default PrivateRoutes;