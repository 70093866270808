import React, { useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Modal,
  Form,
  Switch,
  Popover,
  Input,
  Button,
  Space,
} from "antd";
import dollar from "../../../Assets/Images/dollar.png";
import euro from "../../../Assets/Images/euro.png";
import pound from "../../../Assets/Images/bnb.png";
import bit from "../../../Assets/Images/bnb.png";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";
import { CopyOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import Edit from "../../../Assets/Images/Edit.svg";
import Edit from "../../../Assets/Images/greyEdit.svg";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Validations } from "../../../validations";
import { Utils } from "../../../utils";
import { useDispatch } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import { SettingActions } from "../../../redux/features/setting/settingActions";
import { FormError } from "../../../Components/FormErrors/FormError";
import { TransactionFeeService } from "../../../services/transaction-fee.service";
import PermissionCheck from "../../../Components/PermissionCheck/PermissionCheck";
import { checkEmptyString, createFullName } from "../../../utils/common.utils";
import { BankingActions } from "../../../redux/features/banking/bankingActions";
import { CONSTANTS } from "../../../constants";

const SwapTransationSystem = ({ settings, getWithdrawFee }) => {
  const [selectedCoin, setSelectedCoin] = useState();

  const [switchValue, setSwitchValue] = useState({
    min: "",
    max: "",
    fee: "",
  });

  const [feeDetails, setFeeDetails] = useState({
    name: "",
    role: "",
    updatedAt: "",
  });

  const onChange = (checked, name) => {
    console.log("setting   ", switchValue.fee);
    setSwitchValue((prevState) => ({
      ...prevState,
      [name]: checked ? "ENABLED" : "DISABLED",
    }));
    console.log(`switch to ${checked}`);
  };

  const dispatch = useDispatch();

  const values = { ...selectedCoin };

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    reset,
    trigger,
    clearErrors,
  } = useForm({
    resolver: yupResolver(Validations.swapFeeValidation),
    mode: "all",
  });

  const cars = [
    {
      id: 1,
      title: "Address:",
      Detail: (
        <>
          {selectedCoin?.address}...
          <span>
            <CopyOutlined />
          </span>
        </>
      ),
    },
    { id: 2, title: "Balance (HOT):", Detail: "0.75 ($1728.83)" },
  ];
  const afterPoints = [
    {
      id: 1,
      title: "Last Updated On:",
      Detail: Utils.common.formatDateTime(feeDetails?.updatedAt),
    },
    {
      id: 2,
      title: "Last Updated By:",
      Detail: Utils.common.formatAdminNameWithRole(
        feeDetails?.name,
        feeDetails?.role
      ),
    },
  ];

  console.log({ afterPoints });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (record) => {
    console.log({ record });
    setSelectedCoin(record);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setError("platformFee", "");

    reset({
      minAmount: "",
      maxAmount: "",
    });
  };

  const formatTransactionSettings = (data) => {
    return data.map((record) => {
      return {
        currency: record.coinName,
        maxAmount: record.maxAmount,
        minAmount: record.minAmount,
        platFormFee: record.platFormFee,
      };
    });
  };

  const columns = [
    {
      title: "Base Currency",
      dataIndex: "currency",
      key: "currency",

      render: (_, record) => {
        return (
          <span className="inlineFlex" style={{ gap: "5px" }}>
            <img
              src={TransactionFeeService.getImageByTokenName(record.currency)}
              style={{ width: "30px", height: "auto" }}
            />
            {checkEmptyString(record.currency?.toUpperCase())}
          </span>
        );
      },
    },

    {
      title: "Platform Fee (%)",
      dataIndex: "platFormFee",
      key: "platFormFee",
    },
    {
      title: "Min Swap Amount",
      dataIndex: "minAmount",
      key: "minAmount",
      render: (_, record) => checkEmptyString(record.minAmount),
    },
    {
      title: "Max Swap Amount",
      dataIndex: "maxAmount",
      key: "maxAmount",
      render: (_, record) => checkEmptyString(record.maxAmount),
    },
    // {
    //   title: "Min. Amount (%)",
    //   dataIndex: "minAmount",
    //   key: "minAmount",
    // },
    // {
    //   title: "Max. Amount",
    //   dataIndex: "maxAmount",
    //   key: "maxAmount",
    // },
    {
      title: "Action",
      render: (_, record) => (
        <PermissionCheck>
          {/* <ButtonCommon
            type="primary"
            text="More Details"
            onClick={() => showModal(record)}
          /> */}
          <img
            src={Edit}
            style={{ cursor: "pointer" }}
            alt="icon"
            onClick={() => {
              showModal(record);
            }}
          />
        </PermissionCheck>
      ),
    },
  ];

  const onSubmit = (obj) => {
    const data = {
      platFormFee: obj.platformFee,
      coinName: selectedCoin.currency,
      minAmount: obj.minAmount,
      maxAmount: obj.maxAmount,
    };
    editWithdrawFee(data);
  };

  const editWithdrawFee = async (data) => {
    try {
      await dispatch(startLoading());
      await dispatch(SettingActions.editSwapWithdrawFee(data));
      setIsModalOpen(false);
      getWithdrawFee({
        page: 1,
        limit: 10,
      });
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const getFeeStatus = async () => {
    try {
      dispatch(startLoading());
      console.log({ selectedCoin });
      let data = {
        moduleId: selectedCoin?.currency,
        module: "SWAP_FEE_SETTINGS",
      };
      const res = await dispatch(BankingActions.getFeeStatus(data)).unwrap();
      if (res) {
        setFeeDetails({
          name: createFullName(res.admin?.firstName, res.admin?.lastName),
          role: res.admin?.title,
          updatedAt: res.updatedAt,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    setValue("platformFee", selectedCoin?.platFormFee);
    setValue("minAmount", selectedCoin?.minAmount);
    setValue("maxAmount", selectedCoin?.maxAmount);

    getFeeStatus();
  }, [selectedCoin]);

  const handleMinValueChange = () => {
    console.log(
      "getValues().minAmount > getValues().maxAmount::",
      Number(getValues().minAmount) > Number(getValues().maxAmount)
    );
    if (getValues().minAmount > getValues().maxAmount) {
      setError("minAmount", "min amount should be less");
    }
  };
  return (
    <>
      <div className="table-design">
        <div className="title-filter">
          <h3>Coins</h3>
        </div>
        <div className="custom-table">
          <Table
            dataSource={formatTransactionSettings(settings.list)}
            columns={columns}
            pagination={false}
          //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>

      <Modal
        className="custom-modal small-btn"
        title="Swap Wallet"
        open={isModalOpen}
        okText="Submit"
        closeIcon={true}
        centered
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            key="1"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>,
          <Button type="link" key="2" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div className="modal-inner">
          <span
            style={{
              display: "inline-flex",
              gap: "10px",
              alignItems: "center",
              // margin: "30px 0 20px 0",
            }}
          >
            {/* <img width={30} src={selectedCoin?.image} alt="icon" /> */}
            <span
              style={{
                display: "inline-flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <img
                src={TransactionFeeService.getImageByTokenName(
                  selectedCoin?.currency
                )}
                style={{ width: "30px", height: "auto" }}
              />
              <b>{checkEmptyString(selectedCoin?.currency?.toUpperCase())}</b>
            </span>
          </span>
          {/* <ul>
            {cars.map((point) => (
              <li key={point.id}>
                <span>{point.title}</span>
                <span>{point.Detail}</span>
              </li>
            ))}
          </ul> */}
          <form className="withdrawFee">
            {/* Min. Withdrawal */}
            <Controller
              name="platformFee"
              control={control}
              render={({ field }) => (
                <>
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    Platform Fee(%)
                    <Popover
                      placement="topLeft"
                      content="Please set the platform fee for swap (%)"
                    >
                      <QuestionCircleOutlined style={{ color: "grey" }} />
                    </Popover>
                  </span>
                  <Input
                    placeholder="0.00"
                    {...field} // Bind input to controller
                    suffix={<img src={Edit} alt="icon" />}
                  />
                  {errors.platformFee && (
                    <FormError message={errors.platformFee.message} />
                  )}{" "}
                </>
              )}
            />
            {/* Min Fee and Max Fee */}
            <Space>
              <Controller
                name="minAmount"
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Min Amount
                      <Popover
                        placement="topLeft"
                        content="Please set the minimum amount for swap"
                      >
                        <QuestionCircleOutlined style={{ color: "grey" }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder="0.00"
                      {...field} // Bind input to controller
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors("minAmount");
                          clearErrors("maxAmount"); // Clear max fee error if conditions are met
                        }
                      }}
                      suffix={<img src={Edit} alt="icon" />}
                    />
                    {errors.minAmount && (
                      <FormError message={errors.minAmount.message} />
                    )}{" "}
                  </>
                )}
              />
              <Controller
                name="maxAmount"
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Max Amount
                      <Popover
                        placement="topLeft"
                        content="Please set the maximum amount for swap"
                      >
                        <QuestionCircleOutlined style={{ color: "grey" }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder="0.00"
                      {...field} // Bind input to controller
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors("minAmount");
                          clearErrors("maxAmount"); // Clear min fee error if conditions are met
                        }
                      }}
                      suffix={<img src={Edit} alt="icon" />}
                    />
                    {errors.maxAmount && (
                      <FormError message={errors.maxAmount.message} />
                    )}{" "}
                  </>
                )}
              />
            </Space>
            {/* Max. Withdrawal */}

            {/* Daily Withdrawal */}

            {/* Withdrawal Fee */}
          </form>
          <ul style={{ marginTop: "100px" }}>
            {feeDetails.role !== "" && (
              <>
                {afterPoints.map((point) => (
                  <>
                    <li key={point.id}>
                      <span>{point.title}</span>
                      <span>{point.Detail}</span>
                    </li>
                  </>
                ))}
              </>
            )}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default SwapTransationSystem;
