import React, { useState } from "react";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, message, Upload ,Space } from "antd";
import dragImage from "../../Assets/Images/DragImage.svg";
import { FileUploadService } from "../../services/file-upload.service";
import toast from "../../services/toast.service";
import { CONSTANTS } from "../../constants";
import ButtonCommon from "../Buttoncommon/ButtonCommon";

const { Dragger } = Upload;

const CustomDragUpload = ({ allowedTypes, uploadFile, dimensions }) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState([]);

  
  const handleRemoveFile = () => {
    setPreviewUrl(null);
    setSelectedFile(null);
  };

  const fileSize = (CONSTANTS.FILE_UPLOAD_SETTINGS.BANNER_SIZE_LIMIT/1024/1024);


  const obj = {
    name: "file",
    multiple: false,
    async beforeUpload(file) {
      const isFileTypeValid = FileUploadService.isFileTypeValid(
        file,
        allowedTypes
      );
      if (!isFileTypeValid) {
        toast.error(`File type ${file.type} not allowed to upload`);
        return false;
      }
      const limitSize = CONSTANTS.FILE_UPLOAD_SETTINGS.BANNER_SIZE_LIMIT;
      const isFileSizeValid = FileUploadService.isFileSizeValid(
        file,
        limitSize
      );
      console.log({ isFileSizeValid });
      if (!isFileSizeValid) {
        toast.error(`File size exceeds ${limitSize / 1024 / 1024} MB`);
        return false;
      }

      const isFileDimensionValid = await FileUploadService.isFileDimensionValid(file, dimensions);
      if(!isFileDimensionValid){
        toast.error(`File dimensions can't be less than ${dimensions.width} X ${dimensions.height} and aspect ratio is 28:10`);
        return false;
      }
    },
    async customRequest({ file, onError }) {
      try {
        console.log(fileList);
        const res = await FileUploadService.getFilePreview(file);
        setPreviewUrl(res);
        setSelectedFile(file);
      } catch (error) {
        onError(error);
      }
    },
  };

  const upload = async () => {
    await uploadFile(selectedFile)
    handleRemoveFile()
  };


  return (
    <>
      {!previewUrl && (
        <Dragger {...obj} fileList={fileList}>
          <p className="ant-upload-drag-icon">
            <img src={dragImage} alt="icon" />
          </p>
          <p className="ant-upload-text">Max. size : {fileSize} MB</p>
          <p className="ant-upload-hint">Only media in a 28:10 aspect ratio (Dimensions {dimensions.width}x{dimensions.height} pixels) can be uploaded.</p>

          {/* <p className="ant-upload-hint">Only upload media of size *{dimensions.width}x{dimensions.height} aspect ratio is 28:10</p> */}
        </Dragger>
      )}
      {previewUrl && (
        <div className="imageUpload-withdeleteIcon">
          <img
            src={previewUrl}
            alt="icon"
            style={{ width: "200px", height: "auto",borderRadius:"8px" ,display:"block",marginBottom:"20px" }}
          />
          <Space  size={20} wrap>
          <ButtonCommon  danger type="primary" text="Delete" onClick={handleRemoveFile} />      
          <ButtonCommon type="primary" text="Upload" onClick={upload} />
          </Space>
        </div>
      )}
    </>
  );
};

export default CustomDragUpload;
