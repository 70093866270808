import React, { useEffect, useState } from 'react';
import { formatFiatValues, handleHideId } from '../../utils/common.utils';
import { TransactionFeeService } from '../../services/transaction-fee.service';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import { CONSTANTS } from '../../constants';
import style from '../../Pages/UserDirectory/style.module.scss';
import { Modal, Typography } from 'antd';
import ButtonCommon from '../Buttoncommon/ButtonCommon';
import { useDispatch, useSelector } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice';
import { BankingActions } from '../../redux/features/banking/bankingActions';
import { toast } from 'react-toastify';
import { ComplianceActions } from '../../redux/features/compliance/complianceAtion';
import { setTransactionDetail } from '../../redux/features/compliance/complianceSlice';
import CustomModal from '../CustomModal/CustomModal';

const TransactionDetailsController = ({ data, type, clickedType }) => {

  const { transactionDetail } = useSelector((state) => state?.compliance);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [reApproveDetails, setReApproveDetails] = useState(null);
  const [selectedObj, setSelectedObj] = useState([]);
  const [clickedStatus, setClickedStatus] = useState('');
  const [showCancelFiat, setShowCancelFiat] = useState(false);
  const { Title } = Typography;
  const dispatch = useDispatch();
  const getTxnId = data?.txnId;

  let allObj = [
    {
      title: 'Coin',
      detail: data.coin,
    },
    {
      title: 'Amount',
      detail: (
        <>
          {TransactionFeeService.getFeeAmountAfterPrecision(
            data.coin,
            data.amount
          )}
        </>
      ),
    },
    {
      title: 'Txn ID',
      detail: (
        <>
          {data?.txId ? (
            <>
              {handleHideId(data?.txId)}
              <CopyToClipboard userId={data?.txId} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'From',
      detail: (
        <>
          {handleHideId(data.fromAddress)}
          <CopyToClipboard userId={data.fromAddress} position='top' />
        </>
      ),
    },
    {
      title: 'To',
      detail: (
        <>
          {data.toAddress ? (
            <>
              {handleHideId(data.toAddress)}
              <CopyToClipboard userId={data.toAddress} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Status',
      detail: (
        <>
          <>{ data?.hasOwnProperty('trxStatus') ? data.trxStatus : CONSTANTS.TRANSACTION_STATUS[data.status]}</>
        </>
      ),
    },
  ];

  let swapObj = [
    {
      title: 'Order Id',
      detail: (
        <>
          {data?.orderId ? (
            <>
              {handleHideId(data?.orderId)}
              <CopyToClipboard userId={data?.orderId} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'From Currency',
      detail: data.fromCurrency,
    },
    {
      title: 'From Amount',
      detail: data.fromAmount,
    },
    {
      title: 'To Amount',
      detail: data.toAmount,
    },
    {
      title: 'To Currency',
      detail: data.toCurrency,
    },
    {
      title: 'Status',
      detail: data.status,
    },
    {
      title: 'User Id',
      detail: (
        <>
          {data?.userId ? (
            <>
              {handleHideId(data?.userId)}
              <CopyToClipboard userId={data?.userId} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Fee',
      detail: +data.fee === 0 ? 0 : data.fee,
    },
  ];

  let fiatObj = [
    {
      title: 'Currency',
      detail: data.currency,
    },
    {
      title: 'Amount',
      detail: (
        <>
          {clickedType?.type === 15
            ? formatFiatValues(data.amountWithFee)
            : formatFiatValues(data.amount)}
        </>
      ),
    },
    {
      title: 'Fee',
      detail: formatFiatValues(data.fee),
    },
    {
      title: 'Txn ID',
      detail: (
        <>
          {data?.txnId ? (
            <>
              {handleHideId(data?.txnId)}
              <CopyToClipboard userId={data?.txnId} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'From',
      detail: data.fromAccount ? (
        <>
          {handleHideId(data?.fromAccount)}
          <CopyToClipboard userId={data?.fromAccount} position='top' />
        </>
      ) : (
        '-'
      ),
    },
    {
      title: 'To',
      detail: data.toAccount ? (
        <>
          {handleHideId(data?.toAccount)}
          <CopyToClipboard userId={data?.toAccount} position='top' />
        </>
      ) : (
        '-'
      ),
    },
    {
      title: 'Status',
      detail: data.status,
    },
  ];

  let sameObj = [
    {
      title: 'Type Id',
      detail: data.id,
    },
    {
      title: 'Client ID',
      detail: data.clientId,
    },
    {
      title: 'Coin',
      detail: data.currency,
    },
    {
      title: 'Amount',
      detail:
        data.isCrypto === 1
          ? TransactionFeeService.getFeeAmountAfterPrecision(
              data.currency,
              data.amount
            )
          : formatFiatValues(data.amount),
    },
    {
      title: 'Status',
      detail: data.status,
    },
  ];

  useEffect(() => {
    if (data?.fee !== undefined) {
      allObj.push({
        title: 'Fee',
        detail: (
          <>
            {TransactionFeeService.getFeeAmountAfterPrecision(
              data.coin,
              data.fee
            )}
          </>
        ),
      });
      // if (data?.fee !== undefined) {
      //   console.log(data.amount + data.fee, "checkData");
      //   allObj.push({
      //     title: "Fee with Amount",
      //     detail: (
      //       <>
      //         {TransactionFeeService.getFeeAmountAfterPrecision(
      //           data.coin,
      //           data.fee + data.amount
      //         )}
      //       </>
      //     ),
      //   });
      // }

      allObj = allObj.sort((a, b) => a.position - b.position);
    }
  }, [data]);

  useEffect(() => {
    createListData(type);
    return () => {
      dispatch(setTransactionDetail({})); // empty transaction state from compliance
    };
  }, [type]);

  const createListData = (type) => {
    if (type === 'DEFAULT') {
      setSelectedObj(sameObj);
    } else if (type === 'CRYPTO') {
      setSelectedObj(allObj);
    } else if (type === 'SWAP') {
      setSelectedObj(swapObj);
    } else if (type === 'FIAT') {
      setSelectedObj(fiatObj);
    }
  };

  const showModal = (data) => {
    setIsModalOpen(true);
    setReApproveDetails(data);
  };
  const ReApprovedFiatComplianceStatus = async () => {
    try {
      dispatch(startLoading());
      let data = {
        transactionId: reApproveDetails?.txnId,
      };
      await dispatch(
        BankingActions.updateComplianceReApproveFiatStatus(data)
      ).unwrap();
      setIsModalOpen(false);
      getFiatTransactionDetails(getTxnId);
      getTxnStatus(getTxnId);
      dispatch(setTransactionDetail({ ...transactionDetail, status: '' }));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };
  const getTxnStatus = async (id) => {
    try {
      const data = await dispatch(
        ComplianceActions.getTransactionStatus({ txnId: id })
      ).unwrap();
      return data;
    } catch (error) {
      toast.error(error);
    }
  };

  const getFiatTransactionDetails = async (txnId) => {
    try {
      const res = await dispatch(
        BankingActions.getFiatTransactionDetails({
          transactionId: txnId,
          apiAccessKey: 'ALL_TRANSACTIONS',
        })
      ).unwrap();
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelReApproveFiatTxb = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    let requestPayload = {};
    try {
      requestPayload = {
        coin: transactionDetail.coinName.toLowerCase(),
        status: 'COMPLETED',
        typeId: transactionDetail.typeId,
        amount: transactionDetail.amount,
        type: 'suspicious-trx',
        trxType: transactionDetail.txnType,
        clientId: transactionDetail.clientId,
      };

      await dispatch(startLoading());
      await dispatch(
        ComplianceActions.updateToKytStatus(requestPayload)
      ).unwrap();
      dispatch(setTransactionDetail({}));
    } catch (error) {
    } finally {
      setApproveModalOpen(false);

      await dispatch(finishLoading());
    }
  };

  const handleCancel = () => {
    setApproveModalOpen(false);
  };

  const updateComplianceFiatStatus = async () => {
    try {
      dispatch(startLoading());
      let data = {
        status: clickedStatus === 'reject' ? 'REJECTED' : 'APPROVED',
        transactionId: transactionDetail?.txnId,
      };
      await dispatch(BankingActions.updateComplianceFiatStatus(data)).unwrap();
      setShowCancelFiat(false);
      dispatch(setTransactionDetail({}));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  return (
    <>
      <div className={style.detailmodalContent_personalInfo}>
        <Title level={3}>
          Transaction Details
          {transactionDetail?.status == 'CANCELLED' && (
            <span
              style={{
                float: 'right',
              }}
            >
              <ButtonCommon
                className='filter-btn'
                reject='approve'
                onClick={() => showModal(data, 'approve')}
              >
                <span style={{ color: 'black' }}>Re-Approve</span>
              </ButtonCommon>
            </span>
          )}
          {transactionDetail?.txnStatus == 'PENDING' && (
            <span
              style={{
                float: 'right',
              }}
            >
              <ButtonCommon
                className='filter-btn'
                reject='approve'
                onClick={() => setApproveModalOpen(true)}
              >
                <span style={{ color: 'black' }}>Approve</span>
              </ButtonCommon>
            </span>
          )}
          {transactionDetail?.txnStatus === 'REJECTED' &&
            transactionDetail?.type === 'FIAT' && (
              <span style={{ float: 'right' }}>
                <ButtonCommon
                  className='filter-btn'
                  reject='approve'
                  onClick={() => {
                    setShowCancelFiat(true);
                    setClickedStatus('approve');
                  }}
                >
                  <span style={{ color: 'black' }}>Approve</span>
                </ButtonCommon>
                &nbsp;
                <ButtonCommon
                  className='filter-btn'
                  reject='reject'
                  onClick={() => {
                    setShowCancelFiat(true);
                    setClickedStatus('reject');
                  }}
                >
                  <span style={{ color: 'black' }}>Reject</span>
                </ButtonCommon>
              </span>
            )}
          {/* CONSTANTS.TRANSACTION_STATUS[data.status] */}
        </Title>
        <div style={{ marginTop: '50px' }}></div>
        <ul style={{ padding: 'unset' }}>
          {selectedObj.map((x, index) => (
            <>
              <li key={index}>
                <span>{x.title}</span>
                <span>{x.detail}</span>
              </li>
            </>
          ))}
        </ul>
        <div style={{ marginTop: '50px' }}></div>
      </div>
      <Modal
        className='logout custom-modal'
        width={545}
        open={isModalOpen}
        onOk={handleCancelReApproveFiatTxb}
        onCancel={handleCancelReApproveFiatTxb}
        centered
        maskClosable={false}
      >
        <div className='modal-inner'>
          <h3>Are you sure want to Re-Approve?</h3>
          <p></p>
          <div className='logout_btn'>
            <ButtonCommon
              text='No'
              width={176}
              height={46}
              transBtn='transBtn'
              custmcls='cursorpointer'
              onClick={handleCancelReApproveFiatTxb}
            />
            <ButtonCommon
              type='primary'
              text='Yes'
              width={176}
              onClick={ReApprovedFiatComplianceStatus}
              customBlueBtn='customBlueBtn'
              height={46}
            />
          </div>
        </div>
      </Modal>
      {/* ********************** When Status is PENDING and want to approve************** */}
      <Modal
        className='logout custom-modal'
        width={545}
        open={approveModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        maskClosable={false}
      >
        <div className='modal-inner'>
          <h3>Are you sure want to Approve?</h3>
          <p></p>
          <div className='logout_btn'>
            <ButtonCommon
              text='No'
              width={176}
              height={46}
              transBtn='transBtn'
              custmcls='cursorpointer'
              onClick={handleCancel}
            />
            <ButtonCommon
              type='primary'
              text='Yes'
              width={176}
              onClick={handleOk}
              customBlueBtn='customBlueBtn'
              height={46}
            />
          </div>
        </div>
      </Modal>

      {/* ********************* For Fiat Approve/Reject ******************** */}
      <CustomModal
        title={`Are you sure, you want to ${clickedStatus} this transaction?`}
        open={showCancelFiat}
        onOk={updateComplianceFiatStatus}
        onCancel={() => setShowCancelFiat(false)}
      ></CustomModal>
    </>
  );
};

export default TransactionDetailsController;
