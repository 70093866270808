import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const tradeManageMentSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const {} = tradeManageMentSlice.actions;

export default tradeManageMentSlice.reducer;
