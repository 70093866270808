import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Tabs } from "antd";
// import CryptoWallet from "../UserDirectory/balance/CryptoWallet";
// import FiatWallet from "../UserDirectory/balance/FiatWallet";
import LiquidityFiat from "./LiquidityFiat";
import LiquidityCrypto from "./LiquidityCrypto";

import { WalletActions } from "../../../redux/features/wallet/walletActions";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  finishLoading,
} from "../../../redux/features/loading/loadingSlice";
import { toast } from "react-toastify";
import { TransactionFeeService } from "../../../services/transaction-fee.service";
import { BankingActions } from "../../../redux/features/banking/bankingActions";
import LiquiditySwap from "./LiquiditySwap";
import { SwapActions } from "../../../redux/features/swap/swapActions";
import { setSelectedWallet } from "../../../redux/features/swap/swapSlice";
// import { formatDigits } from "../../../utils/common.utils";
// import toast from "../../../services/toast.service";
// import { CONSTANTS } from "../../../constants";
const { useGetLiquidityAndAssetsQuery } =
  WalletActions.getLiquidityAndAssetsRtk;

export const Liquidity = () => {
  const { error, data, isLoading, isFetching } = useGetLiquidityAndAssetsQuery(
    undefined,
    { pollingInterval: 10000 }
  );
  const selectedWallet = useSelector(
    (state) => state.swapLiquidy.selectedWallet
  );
  const [selectedKey, setSelectedKey] = useState("1");
  const onChange = (key) => {
    setSelectedKey(key);
    if (key === "1") {
      dispatch(setSelectedWallet("Crypto Wallet"));
      getLiquidityAssets();
    } else if (key === "2") {
      dispatch(setSelectedWallet("Fiat Wallet"));

      const data = {
        limit: 10,
        offset: 0,
      };
      getFiatBalances(data);
    } else if (key === "3") {
      dispatch(setSelectedWallet("Swap Wallet"));
      getSwapBalances();
    }
  };

  useEffect(() => {
    if (selectedWallet == "Crypto Wallet") {
      setSelectedKey("1");
    }
    if (selectedWallet == "Fiat Wallet") {
      setSelectedKey("2");
    }
    if (selectedWallet == "Swap Wallet") {
      setSelectedKey("3");
    }
  }, [selectedWallet]);
  const [liquidityAssets, setLiquidityAssets] = useState([]);
  const [fiatBalances, setFiatBalances] = useState([]);
  const [swapBalance, setSWapBalance] = useState([]);

  const dispatch = useDispatch();

  const getLiquidityAssets = async () => {
    try {
      if (!data) {
        dispatch(startLoading());
      } else {
        const { adminCurrencyInfo, adminWalletBalance, cmcPrices } =
          data.response.data;

        if (
          adminWalletBalance &&
          adminWalletBalance.length > 0 &&
          cmcPrices &&
          cmcPrices.length > 0 &&
          adminCurrencyInfo &&
          adminCurrencyInfo.length > 0
        ) {
          console.log("adminWalletBalance::",adminWalletBalance);
          const data = addUsdValueInWallets(
            adminWalletBalance,
            cmcPrices,
            adminCurrencyInfo
          );
          const formattedUserData = formatBalances(data);
          setLiquidityAssets(formattedUserData);
        } else {
          setLiquidityAssets([]);
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  const addUsdValueInWallets = (
    adminWalletBalance,
    cmcPrices,
    adminCurrencyInfo
  ) => {
    const liquidities = [];
    for (const wallet of adminWalletBalance) {
      let walletCoin = wallet.coin.toLowerCase();
      walletCoin = walletCoin.split("(")[0];
      if (walletCoin === "mat-usdt") walletCoin = "usdt";
      else if (walletCoin === "mat-usdc") walletCoin = "usdc";
      let myWallet = { ...wallet };

      const currency = adminCurrencyInfo.find((c) => c.coin === wallet.coin);
      if (currency) {
        myWallet.fee = TransactionFeeService.getFeeAmountAfterPrecision(
          currency.coin,
          currency.totalFee
        );
      }

      const cmc = cmcPrices.find(
        (cmc) => cmc.cmcId.toLowerCase() === walletCoin
      );
      if (cmc) myWallet.usdValue = wallet.balance * cmc.usdPrice || 0.0;
      liquidities.push(myWallet);
    }
     console.log('liquidities',liquidities);
    return liquidities;
  };

  const formatBalances = (data) => {
    console.log("data::",data);
    return data.map((obj, index) => {
      return {
        number: index + 1,
        totalBalance: obj.balance,
        coin: obj.coin,
        coinUrl: obj.iconUrl,
        hot: obj.hot,
        deposit: obj.deposit,
        fee: obj.fee,
        usdValue: obj.usdValue,
        network: obj.network,
        gasFeeBalance: obj.gasFeeBalance,
      };
    });
  };

  const getFiatBalances = async () => {
    try {
      dispatch(startLoading());
      let res = await dispatch(BankingActions.getFiatBalances()).unwrap();
      console.log({ response: res });
      if (res && res.data) {
        setFiatBalances(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const checkType = (res) =>  {
    if (Array.isArray(res)) {
      return 'array';
    } else if (typeof res === 'object' && res !== null) {
      return 'object';
    }
  }

  const getSwapBalances = async () => {
    let data = {
      apiAccessKey: "DASHBOARD",
    };
    try {
      dispatch(startLoading());
      let res = await dispatch(SwapActions.getSwapBalance(data)).unwrap();
      let resultData = [];
      if (res) {
        let resType = checkType(res)
        if(resType === "object"){
         if(Object.keys(res).length === 0){
          resultData = [];
         } else {
          resultData.push(res)
         }
          setSWapBalance(resultData);
        } else {
          setSWapBalance(res);
        }
      } else {
        setSWapBalance([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getLiquidityAssets();
  }, [data, isLoading]);

  useEffect(() => {
    getFiatBalances();
  }, []);
console.log("liquidityAssets::",liquidityAssets);
  const items = [
    {
      key: "1",
      label: "Crypto Wallet",
      children: <LiquidityCrypto data={liquidityAssets} />,
    },
    {
      key: "2",
      label: "Fiat Wallet",
      children: <LiquidityFiat data={fiatBalances} />,
    },
    {
      key: "3",
      label: "Swap Wallet",
      children: <LiquiditySwap data={swapBalance} />,
    },
  ];

  return (
    <>
      <div className="Custom-tabBtn">
        <Tabs
          defaultActiveKey={selectedWallet}
          activeKey={selectedKey}
          items={items}
          onChange={onChange}
          animated={true}
        />
      </div>
    </>
  );
};
