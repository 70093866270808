import React, { useEffect, useState } from "react";
import { Image, Pagination, Table } from "antd";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";
import { DeleteIcon } from "../../../Assets/Icons/Icons";
import doc from "../../../Assets/Images/documentimg.png";
import { useDispatch } from "react-redux";
import {
  startLoading,
  finishLoading,
} from "../../../redux/features/loading/loadingSlice";
import { MarkettingActions } from "../../../redux/features/marketting/markettingActions";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { EditOutlined } from "@ant-design/icons";
import BannerLink from "./BannerLink";
import Edit from "../../../Assets/Images/greyEdit.svg";
import PermissionCheck from "../../../Components/PermissionCheck/PermissionCheck";
import { formatDateTime } from "../../../utils/common.utils";

const Uploadtable = ({
  banners,
  getBanners,
  page,
  setPage,
  setBanners,
  type,
}) => {
  const dispatch = useDispatch();
  const [showDeleteBannerModal, setShowDeleteBannerModal] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [showBannerLinkModal, setShowBannerLinkModal] = useState();
  const [bannerId, setBannerId] = useState("");
  const openDeleteBannerModal = (obj) => {
    setSelectedBanner(obj);
    setShowDeleteBannerModal(true);
  };

  const handleCancel = () => {
    setShowDeleteBannerModal(false);
  };

  const handleOk = () => {
    deleteBanner(selectedBanner);
  };

  const deleteBanner = async (obj) => {
    try {
      const data = {
        id: obj.bannerId,
        isDeleted: true,
      };
      dispatch(startLoading());
      const res = await dispatch(MarkettingActions.deleteBanner(data)).unwrap();
      setBanners((x) => {
        return {
          list: x.list
            .filter((banner) => banner.bannerId != obj.bannerId)
            .slice(),
          totalCounts: x.totalCounts - 1,
        };
      });
    } catch (error) {
    } finally {
      dispatch(finishLoading());
      setShowDeleteBannerModal(false);
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "imageIcon",
      key: "imageIcon",
      render: (_, record) => (
        <>
          <Image
            src={record.imageIcon}
            alt="banner"
            center
            style={{ width: "200px", height: "auto" }}
          />
        </>
      ),
    },
    {
      title: "Uploaded On",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Uploaded By",
      dataIndex: "uploadedBy",
      key: "uploadedBy",
    },
    {
      title: "Updated By",
      dataIndex: "updatedAdmin",
      key: "updatedAdmin",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      
    },
    {
      title: "Marketing Link",
      dataIndex: "marketingLink",
      key: "marketingLink",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip ",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <PermissionCheck>
            <div style={{ display: "flex", paddingBlock: "20px" }}>
              <img
                src={Edit}
                style={{ cursor: "pointer" }}
                alt="icon"
                onClick={() => {
                  setShowBannerLinkModal(!showBannerLinkModal);
                  setBannerId(record.bannerId);
                }}
              />

              <ButtonCommon
                icon={<DeleteIcon style={{ color: "red" }} />}
                onClick={() => openDeleteBannerModal(record)}
                type="text"
              />
            </div>
          </PermissionCheck>
        </>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
    const data = {
      offset: (page - 1) * 10,
      limit: 10,
      isDeleted: false,
      type,
    };
    getBanners(data);
  };
  return (
    <>
      <div className="custom-table">
        <Table
          dataSource={banners?.list}
          columns={columns}
          pagination={false}
          // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
        />
      </div>

      <div className="pagination_block" style={{ margin: "20px" }}>
        <span className="records">Total Records: {banners?.totalCounts}</span>
        {banners?.totalCounts > 10 && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              defaultCurrent={page}
              total={banners?.totalCounts}
              pageSize={10}
              onChange={handlePageChange}
            />
          </div>
        )}

        <CustomModal
          title="Delete Banner"
          open={showDeleteBannerModal}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Are you sure you want to delete this banner ? </p>
        </CustomModal>
        {
          <BannerLink
            bannerId={bannerId}
            showBannerLinkModal={showBannerLinkModal}
            setShowBannerLinkModal={setShowBannerLinkModal}
            setBanners={setBanners}
            type={type}
            banners={banners}
          />
        }
      </div>
    </>
  );
};

export default Uploadtable;
