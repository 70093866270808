import React from "react";
import InputCustom from "../InputCustom/InputCustom";
import ButtonCommon from "../Buttoncommon/ButtonCommon.jsx";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Validations } from "../../validations";
import { DevTool } from "@hookform/devtools";
import { useDispatch } from "react-redux";
import { AuthActions } from '../../redux/features/auth/authActions'
import { FormError } from "../FormErrors/FormError.jsx";
import { Form } from "antd";
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice'

const ChangePassword = ({ style, email }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(Validations.changePassword),
    mode: "all",
  });

  const resetForm = () => {
    reset({
      old_password: "",
      new_password: "",
      reenter_password: "",

    });
  }

  const onSubmit = async (values) => {
    try {
      await dispatch(startLoading());
      await dispatch(AuthActions.changePassword({
        email: email,
        oldPassword: values.old_password,
        newPassword: values.new_password
      }))
    } catch (error) {

    } finally {
      await dispatch(finishLoading());
    }
  };

  return (
    <>
      <div className={style.setting_right}>
        <h5>Change Password</h5>
        <div className={style.setting_right_input}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="old_password"
              control={control}
              render={({ field }) => (
                <InputCustom
                  type="password"
                  settingInput="settingInput"
                  custommClass1="custommClass1"
                  placeholder={"*******************"}
                  label={"Old Password"}
                  borderRadius={10}
                  height={46}
                  {...field}
                />
              )}
            />
            <FormError message={errors?.old_password?.message} />


            <Controller
              name="new_password"
              control={control}
              render={({ field }) => (
                <InputCustom
                  type="password"
                  settingInput="settingInput"
                  custommClass1="custommClass1"
                  placeholder={"*******************"}
                  label={"New Password"}
                  borderRadius={10}
                  height={46}
                  {...field}
                />
              )}
            />
            <FormError message={errors?.new_password?.message} />

            <Controller
              name="reenter_password"
              control={control}
              render={({ field }) => (
                <InputCustom
                  type="password"
                  settingInput="settingInput"
                  custommClass1="custommClass1"
                  placeholder={"*******************"}
                  label={"Re-enter New Password"}
                  borderRadius={10}
                  height={46}
                  {...field}
                />
              )}
            />
            <FormError message={errors?.reenter_password?.message} />

            <div className={style.setting_right_input__button}>
              <ButtonCommon
                text="Reset"
                height={46}
                transBtn="transBtn"
                htmlType={"button"}
                onClick={resetForm}
              />
              <ButtonCommon
                text="Save Changes"
                height={46}
                customBlueBtn="customBlueBtn"
                htmlType={"submit"}
              />
            </div>
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </>
  );
};

export default ChangePassword;
