import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { MARKETTING } = CONSTANTS.API_ENDPOINTS;

const uploadBanner = createAsyncThunk(
  "marketting/uploadBanner",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${MARKETTING.UPLOAD_BANNER}`,
        data,
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: true, apiAccessKey: "BANNER" },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getBanners = createAsyncThunk(
  "marketting/getBanners",
  async (
    { offset = 0, limit = 10, isDeleted = true, type = "WEB" },
    { rejectWithValue, dispatch }
  ) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${MARKETTING.GET_BANNERS}/${offset}/${limit}`,
        data: {},
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey: "BANNER" },
        params: { isDeleted, type },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);
const getContents = createAsyncThunk(
  "marketting/getContents",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${MARKETTING.GET_CONTENTS}`,
        data: {},
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey: "CONTENT" },
      },
      { rejectWithValue, dispatch }
    );
    console.log(res, "ppppppppres");
    if (res) {
      return res;
    }
  }
);
const getContentsById = createAsyncThunk(
  "marketting/getContents_ById",
  async (contentId, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${MARKETTING.GET_CONTENTS_BY_ID}/${contentId}`,
        data: {},
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey: "CONTENT" },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateContent = createAsyncThunk(
  "marketting/updateContent",
  async (data, { rejectWithValue, dispatch }) => {
    console.log(data, "datadata");
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${MARKETTING.UPDATE_CONTENT}/${data.contentId}`,
        data,
        method: "PUT",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey: "CONTENT" },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const deleteBanner = createAsyncThunk(
  "marketting/deleteBanner",
  async (data, { rejectWithValue, dispatch }) => {
    const newData = { ...data };
    delete data.id;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${MARKETTING.DELETE_BANNER}/${newData.id}`,
        data,
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: false, apiAccessKey: "BANNER" },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateBanner = createAsyncThunk(
  "marketting/update_banner",
  async (data, { rejectWithValue, dispatch }) => {
    const newData = { ...data };
    delete data.id;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${MARKETTING.UPDATE_BANNER}/${data?.bannerId}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "BANNER"
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

export const MarkettingActions = {
  uploadBanner,
  getBanners,
  deleteBanner,
  updateBanner,
  getContents,
  getContentsById,
  updateContent,
};
