import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";

export function InputCustom({
  custommClass,
  borderRadius,
  placeholder,
  height,
  maxWidth,
  iconAddon,
  imgWidth,
  imgHeight,
  label,
  custommClass1,
  settingInput,
  inputLabel,
  imgleft,
  type,
  value,
  ...rest
}) {
  const [isInputClicked, setIsInputClicked] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsInputClicked(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
  }, []);

  const handleInputClick = () => {
    setIsInputClicked(true);
  };
  return (
    <div
      className={`${styles.inputSearch} ${custommClass ? custommClass : ""} ${
        settingInput ? settingInput : ""
      }`}
    >
      <div
        className={`${isInputClicked ? "activeInput" : ""} ${
          styles.inputSearch_input
        }`}
      >
        {label ? <p className="inputLabel">{label}</p> : ""}
        {imgleft ? (
          <div
            className={` ${styles.inputSearch_addonBefore} ${
              custommClass1 ? custommClass1 : ""
            }`}
          >
            <img
              src={iconAddon}
              alt="Image"
              width={imgWidth}
              height={imgHeight}
            />
          </div>
        ) : (
          ""
        )}
        <input
          {...rest}
          type={type}
          value={value}
          placeholder={placeholder}
          style={{
            borderRadius: borderRadius,
            height: height,
            maxWidth: maxWidth,
          }}
          ref={inputRef}
          onClick={handleInputClick}
        />
      </div>
    </div>
  );
}
export default InputCustom;
