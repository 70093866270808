import React from "react";
import { Input, Form } from "antd";
import ScreenBreakpoints from "../CustomHook/ScreenBreakpoints.jsx";

const CustomInput = (props) => {
  const [isLargeScreen, isSmallScreen] = ScreenBreakpoints();
  const InputSize = isLargeScreen
    ? "large"
    : isSmallScreen
    ? "middle"
    : "small";
  const { label, placeholder, name, type, onChange, value } = props;
  console.log({ value });
  return (
    <div className="custom-inputDesign">
    
        <Input
          size={InputSize}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          name={name}
          disabled={props.disabled}
        />
     
    </div>
  );
};

export default CustomInput;
