import { createSlice } from "@reduxjs/toolkit";
import { ActivityActions } from "./activityActions";

const initialState = {
  data: null,
};

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ActivityActions.getActivities.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(ActivityActions.getActivities.rejected, (state, action) => {
        state.data = null;
      });
  },
});


export default activitySlice.reducer;
