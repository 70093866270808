import React from "react";
import { Row, Col } from "antd";
import style from "./style.module.scss";
import Currencycard from "./Currencycard";

function Currencylist({ liquidities }) {

  return (
    <>
      <Row gutter={[26, 14]} className={style.currencylist}>
        {liquidities.map((item, index) => (
          <Col xs={24} md={12} xl={8}>
            <Currencycard key={index} {...item} />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default Currencylist;
