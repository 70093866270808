import React from "react";
import style from "./style.module.scss";
import Usercard from "./Usercard";
import withUserAnalyticsCard from "../../HOC/withUserAnalyticsCard.jsx";
import { DashboardService } from "../../services/dashboard.service";

function UserList({ userCountData }) {
  const {
    lastSevenDaysUserCount,
    lastThirtyDaysUserCount,
    totalUserCount,
    totalIndividualUserCount,
    totalBusinessUserCount,
    totalVerifiedUserCount,
  } = userCountData.user;

  const { userData, createNoOfUserData, createOtherCards } = DashboardService;

  const NumUsersCard = withUserAnalyticsCard(
    Usercard,
    createNoOfUserData(
      totalUserCount,
      lastSevenDaysUserCount,
      lastThirtyDaysUserCount
    )
  );
  const NumVerifiedUsersCard = withUserAnalyticsCard(
    Usercard,
    createOtherCards(
      userData[1],
      totalVerifiedUserCount,
      totalIndividualUserCount,
      totalBusinessUserCount
    )
  );
  const TrxVolumeCard = withUserAnalyticsCard(Usercard, userData[2]);
  const TrxFlaggedCard = withUserAnalyticsCard(Usercard, userData[3]);

  return (
    <>
      <div className={style.userdetails}>
        <NumUsersCard />
        <NumVerifiedUsersCard />
        <TrxVolumeCard />
        <TrxFlaggedCard />
      </div>
    </>
  );
}

export default UserList;
