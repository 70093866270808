import React from "react";
import { Pagination } from "antd";
import ScreenBreakpoints from "../CustomHook/ScreenBreakpoints.jsx";

const CustomPagination = (props) => {
  const [isLargeScreen] = ScreenBreakpoints();
  const { totalRecords, defaultCurrent, total, pageSize, onChange , current} = props;
  return (
    <>
      <div className="pagination_block">
        <span className="records" style={{ marginTop: "10px" }}>
          Total Records:{totalRecords}
        </span>
        {totalRecords > 10 && (
          <Pagination
            size={isLargeScreen ? "default" : "small"}
            defaultCurrent={defaultCurrent}
            total={total}
            pageSize={pageSize}
            onChange={onChange}
            current={current}
          />
        )}
      </div>
    </>
  );
};

export default CustomPagination;
