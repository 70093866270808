import React, { useState } from "react";
import { Table, Pagination, Select, Input, Card } from "antd";
import {
  DownloadOutlined,
  SearchOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";
import card from "../../../Assets/Images/card.svg";
import flag from "../../../Assets/Images/flag.svg";
// import Filttercustom from "../Filttercustom";

const CardInfo = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const data = [
    {
      key: "1",
      created: "19 Dec 2023 14:48:18",
      amount: "$10",
      currency: "USD",
      txnId: "#28323",
      fees: "0.05",
      from: (
        <>
          42150336C...
          <span>
            <CopyOutlined />
          </span>
        </>
      ),
      to: (
        <>
          58150336C...
          <span>
            <CopyOutlined />
          </span>
        </>
      ),
      bankAccount: "ABC",
      type: "Deposit",
    },
    {
      key: "2",
      created: "19 Dec 2023 14:48:18",
      amount: "$10",
      currency: "USD",
      txnId: "#28323",
      fees: "0.05",
      from: (
        <>
          42150336C...
          <span>
            <CopyOutlined />
          </span>
        </>
      ),
      to: (
        <>
          58150336C...
          <span>
            <CopyOutlined />
          </span>
        </>
      ),
      bankAccount: "ABC",
      type: "Completed",
    },
    {
      key: "3",
      created: "19 Dec 2023 14:48:18",
      amount: "$10",
      currency: "USD",
      txnId: "#28323",
      fees: "0.05",
      from: (
        <>
          42150336C...
          <span>
            <CopyOutlined />
          </span>
        </>
      ),
      to: (
        <>
          58150336C...
          <span>
            <CopyOutlined />
          </span>
        </>
      ),
      bankAccount: "ABC",
      type: "Deposit",
    },
    {
      key: "4",
      created: "19 Dec 2023 14:48:18",
      amount: "$10",
      currency: "USD",
      txnId: "#28323",
      fees: "0.05",
      from: (
        <>
          42150336C...
          <span>
            <CopyOutlined />
          </span>
        </>
      ),
      to: (
        <>
          58150336C...
          <span>
            <CopyOutlined />
          </span>
        </>
      ),
      bankAccount: "ABC",
      type: "Withdrawal",
    },
  ];

  const columns = [
    {
      title: "Created on",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => a.created.localeCompare(b.created),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Txn ID",
      dataIndex: "txnId",
      key: "txnId",
    },
    {
      title: "Fees",
      dataIndex: "fees",
      key: "fees",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Bank Account",
      dataIndex: "bankAccount",
      key: "bankAccount",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
  ];

  const points = [
    { id: 1, title: "Card Number:", detail: "$1,000" },
    { id: 2, title: "Name:", detail: "Dee Jay" },
    { id: 3, title: "Expiry Date:", detail: "01-25" },
    { id: 4, title: "Expiry Date:", detail: "01-25" },
  ];

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
  };
  return (
    <>
      <section>
        <p>Coming soon...</p>
        {/* <div className="col-main">
          <div className="col-Equal card">
            <img src={card} alt="pic" />
          </div>
          <div className="col-Equal detail">
            <div className="top">
              <Select
                defaultValue="All time"
                style={{
                  width: "100%",
                  maxWidth: "480px",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "All time",
                    label: "All time",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
              <ButtonCommon type="primary">
                <span style={{ marginRight: "5px" }}>
                  <img src={flag} alt="icon" />
                </span>
                Virtual Card
              </ButtonCommon>
            </div>
            <ul>
              {points.map((text, id) => (
                <li key={text.id}>
                  <span>{text.title}</span>
                  <span>{text.detail}</span>
                </li>
              ))}
            </ul>
          </div>
        </div> */}
        
      </section>
      {/* <div className="table-design">
        <div className="title-filter  flexEnd">
          <div className="title-filter_rightSec">
            <Input
              style={{ width: "230px" }}
              prefix={<SearchOutlined />}
              type="search"
              placeholder="Search by: TXN ID, Address"
            />
            <Select
              defaultValue="lucy"
              style={{
                minWidth: "150px",
              }}
              onChange={handleChange}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "Yiminghe",
                  label: "yiminghe",
                },
                {
                  value: "disabled",
                  label: "Disabled",
                  disabled: true,
                },
              ]}
            />
            <ButtonCommon custmcls="height40">
              <DownloadOutlined style={{ color: "grey" }} />
            </ButtonCommon>
           
          </div>
        </div>
        <div className="custom-table">
          <Table
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            pagination={false}
            sortDirections={["ascend", "descend", "ascend"]}
            scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
           
          />
        </div>
      </div> */}
      {/* <div className="pagination_block">
        <span className="records">Total Records: 50</span>
        <Pagination
         
          pageSize={10}
         
        />
      </div> */}
    </>
  );
};

export default CardInfo;
