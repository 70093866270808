import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isEnabled: false,
    headers: [],
    rows: [],
    path: 'NONE'
};

export const downloadSlice = createSlice({
    name: "activity",
    initialState,
    reducers: {
        setDownloadStatus: (state, action) => {
            state.isEnabled = action.payload.isEnabled;
            state.path = action.payload.path;
        },
        setHeaderAndRows: (state, action) => {
            state.headers = action.payload.headers;
            state.rows = action.payload.rows;
        }
    },
});

export const { setDownloadStatus, setHeaderAndRows } = downloadSlice.actions;

export default downloadSlice.reducer;
