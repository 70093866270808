import React from "react";
import "../../App.scss";
import { Button } from "antd";
import ScreenBreakpoints from "../CustomHook/ScreenBreakpoints";

const ButtonCommon = ({
  text,
  maxWidth,
  width,
  borderRadius,
  height,
  custmcls,
  transBtn,
  approve,
  reject,
  customBlueBtn,
  onClick,
  transgreen,
  type,
  htmlType,
  marginLeft,
  children,
  ClassName,
  icon,
  icons,
  imgs,
  size,
  main,
  disable,
  ...rest
}) => {
  let btnClass = main ? "main-btn" : "second-btn";
  const [isLargeScreen, isSmallScreen] = ScreenBreakpoints();
  const btnSize = isLargeScreen ? "large" : isSmallScreen ? "middle" : "small";

  return (
    <Button
      {...rest}
      size={btnSize}
      icon={icon}
      onClick={onClick}
      type={type}
      className={` ${btnClass} ${ClassName}  ${custmcls ? custmcls : ""} ${
        customBlueBtn ? customBlueBtn : ""
      } ${approve ? approve : ""} ${reject ? reject : ""}  ${disable ? disable : ""} `}
      style={{
        borderRadius: borderRadius,
        height: height,
        maxWidth: maxWidth,
        width: width,
        marginLeft,
        
      }}
      htmlType={htmlType}
    >
      {text}
      {children}
    </Button>
  );
};

export default ButtonCommon;
