import React, { useEffect, useState } from "react";
import Crypto from "../UserDirectory/transations/Crypto";

const TransactionRecord = () => {
  const [transationData, setTransactionData] = useState([]);

  useEffect(()=>{
   
  },[])

  return (
    <div>
      <Crypto data={transationData}/>
    </div>
  );
};

export default TransactionRecord;
