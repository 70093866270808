import React, { useCallback, useEffect, useState } from "react";
import Currencylist from "../../Components/currencycard/Currencylist.jsx";
import Welcomecomp from "../../Components/WelcomeComponent/Welcomecomp";
import UserList from "../../Components/usercard/Userlist";
import styles from "./style.module.scss";
import Headingview from "../../Components/headingview/Headingview";
import SupportTicket from "../../Components/supportTable/SupportTicket";
import { useDispatch } from "react-redux";
import { UserActions } from "../../redux/features/user/userActions.js";
import Slickslider from "../../Components/Slider/Slickslider.jsx";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice.js";
import { WalletActions } from "../../redux/features/wallet/walletActions.js";
import { toast } from "react-toastify";

const { useGetLiquidityAndAssetsQuery } =
  WalletActions.getLiquidityAndAssetsRtk;

function Dashboard() {
  const { error, data, isLoading, isFetching, isError } =
    useGetLiquidityAndAssetsQuery(undefined, { pollingInterval: 10000 });
  const dispatch = useDispatch();
  const [liquidities, setLiquidities] = useState([]);

  const [userCountData, setUserCountData] = useState({
    user: {
      lastSevenDaysUserCount: 0,
      lastThirtyDaysUserCount: 0,
      totalBusinessUserCount: 0,
      totalIndividualUserCount: 0,
      totalUserCount: 0,
      totalVerifiedUserCount: 0,
    },
  });

  const getUserCounts = useCallback(async () => {
    try {
      const res = await dispatch(UserActions.getUserCounts()).unwrap();
      if (res.user) setUserCountData({ user: res.user });
    } catch (error) {
      throw error;
    }
  }, [dispatch]);

  const getLiquidityAssets = async () => {
    try {
      // refetch(true)
      if (isLoading) {
        await dispatch(startLoading());
      } else {
        const { adminWalletBalance, cmcPrices } = data.response.data;
        if (
          adminWalletBalance &&
          adminWalletBalance.length > 0 &&
          cmcPrices &&
          cmcPrices.length > 0
        ) {
          const [...newData] = addUsdValueInWallets(
            adminWalletBalance,
            cmcPrices
          );
          setLiquidities(newData);
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  const addUsdValueInWallets = (adminWalletBalance, cmcPrices) => {
    const newArray = [];

    for (const wallet of adminWalletBalance) {
      let walletCoin = wallet.coin.toLowerCase();
      walletCoin = walletCoin.split("(")[0];
      console.log('walletCoin',walletCoin);

      if (walletCoin === "mat-usdt") walletCoin = "usdt";
      else if (walletCoin === "mat-usdc") walletCoin = "usdc";

      const cmc = cmcPrices.find(
        (cmc) => {
          console.log('wallet',wallet.balance ,cmc.usdPrice);
          return cmc.cmcId.toLowerCase() === walletCoin.toLowerCase()
        }
      );
      let walletUsdValue = 0;
      if (cmc) walletUsdValue = wallet.balance * cmc.usdPrice || 0.0;
      newArray.push({
        img: <img src={wallet.iconUrl} alt={walletCoin} width={60} />,
        heading: walletCoin.toUpperCase(),
        data: wallet.network.toUpperCase(),
        rightdata: `$ ${walletUsdValue.toFixed(2)}`,
      });
      console.log('newArray',newArray);
      if (newArray.length > 5) break;
      console.log({ balances: walletUsdValue.toFixed(2), coin: walletCoin });
    }

    return newArray;
  };

  useEffect(() => {
    if (data) getLiquidityAssets();
  }, [data, isLoading, isError]);

  useEffect(() => {
    getUserCounts();
  }, [getUserCounts]);

  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboard_sec}>
        <div className={styles.dashboard_sec__left}>
          <Welcomecomp />
          <section>
            <Slickslider />
          </section>
          <section style={{ marginTop: "20px" }}>
            <UserList userCountData={userCountData} />
          </section>
          <section>
            {data ? (
              <>
                <Headingview
                  heading="Liquidity & Assets"
                  title="View More"
                  link="/liquidity-assets"
                />

                <Currencylist liquidities={liquidities} />
              </>
            ) : (
              <Currencylist liquidities={[]} />
            )}
          </section>
          <section>
            <Headingview
              heading="Support Tickets"
              title="View All"
              link="/client-support-communication"
            />
            <SupportTicket />
          </section>
        </div>
      </div>
    </div>
  );
}

// function Dashboard() {
//   return <p>Dashboard</p>;
// }

export default Dashboard;
