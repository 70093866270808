import React, { useEffect, useState } from "react";
import CustomCheckbox from "../../Components/CustomCheckbox/CustomCheckbox";
import { Table } from "antd";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import CustomModal from "../../Components/CustomModal/CustomModal";
import PermissionEdit from "./PermissionEdit";
import { useLocation, useNavigate } from "react-router-dom";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { AdminActions } from "../../redux/features/admin/adminActions";
import * as _ from "lodash";
import toast from "../../services/toast.service";
import { CONSTANTS } from "../../constants";
import InputCustom from "../../Components/InputCustom/InputCustom";

const UpdatePermission = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showModal = () => {
    setIsModalOpen(true);
  };

  console.log({ locationState: location.state });
  const [permissions, setPermissions] = useState(null);
  const [permissionsGroupData, setPermissionsGroupData] = useState(null);
  const [roleTitle, setRoleTitle] = useState(location.state?.roleName);

  const handleOk = () => {
    // navigate("/set-permission");
    discardChanges();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getPermission = async () => {
    try {
      await dispatch(startLoading());
      const res = await dispatch(AdminActions.getPermissions()).unwrap();
      console.log(res);
      if (res && res.list && res.list.length > 0) {
        let selectedPermissions = [...location.state.data];
        console.log({ apiData: res.list, selectedPermissions });
        const newData = res.list.map((x) => {
          for (let obj of selectedPermissions) {
            if (obj.rolePermission.permissionId === x.permissionId) {
              return {
                ...x,
                checked: true,
                permissionType: fillPermissionTypes(obj.permissionType),
                isModule: false,
              };
            }
          }
          return {
            ...x,
            checked: false,
            permissionType: { read: false, write: false },
            isModule: false,
          };
        });
        const permissionData = groupByModules(newData);

        let mappedData = Object.entries(permissionData).map((entry) => {
          const [name, value] = entry;
          if (value && value.length > 1) {
            let newArray = [...value];
            let isAllReadOptionsChecked = false;
            let isAllWriteOptionsChecked = false;

            isAllReadOptionsChecked = newArray.every(
              (x) => x.permissionType.read === true
            );
            isAllWriteOptionsChecked = newArray.every(
              (x) => x.permissionType.write === true
            );
            newArray.push({
              name,
              checked: isAllReadOptionsChecked,
              permissionType: {
                read: isAllReadOptionsChecked,
                write: isAllWriteOptionsChecked,
              },
              permissionId: value[0].permissionId,
              isModule: true,
              id: 0,
              subModule: value[0].subModule,
            });
            return newArray.sort((a, b) => a.id - b.id);
          } else if (value && value.length == 1) {
            let newArray = [];
            if (value[0].subModule === null) {
              newArray.push({
                name,
                checked: false,
                permissionType: value[0].permissionType,
                permissionId: value[0].permissionId,
                isModule: true,
                id: 0,
              });
            } else {
              let newValue = { ...value[0] };
              newValue.isModule = true;
              newValue.id = 0;
              newValue.name = newValue.module;
              newArray.push(newValue);

              newArray.push({
                name: value[0].name,
                checked: false,
                permissionType: value[0].permissionType,
                permissionId: value[0].permissionId,
                isModule: false,
                id: 1,
              });
            }
            // return value
            return newArray.sort((a, b) => a.id - b.id);
          }
        });
        console.log({ mappedData });
        setPermissions(mappedData);
        setPermissionsGroupData(mappedData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handleChange = (e, type) => {
    console.log({ permissionsGroupData });
    const { value, checked } = e.target;
    console.log({ value, checked, type });
    const newPermissions = [...permissions];
    if (newPermissions) {
      // find selected obj index by name
      newPermissions.forEach((permission, index) => {
        let idx = permission.findIndex((x) => {
          return x.name === value.name;
        });
        if (idx != -1) {
          console.log("found element ", permission[idx]);
          console.log("found here", index, idx);
          permission[idx].checked = checked;
          if (permission[idx].isModule) {
            // select and unselect all by permission type, if module is selected
            permission.forEach((x) => {
              if (type === "read") {
                x.permissionType.read = checked;
              } else if (type === "write") {
                x.permissionType.write = checked;
              }
            });
          } else {
            if (type === "read") {
              // submodule check uncheck
              permission[idx].permissionType.read = checked;

              if (checked) {
                // if all submodules are checked, module should be checked in read
                if (
                  permission
                    .filter((x) => x.isModule == false)
                    .every((x) => x.permissionType.read === true)
                ) {
                  permission.find((x) => x.isModule).permissionType.read = true;
                }
              } else {
                // if any submodules are unchecked, module should be unchecked in read
                if (
                  permission
                    .filter((x) => x.isModule == false)
                    .some((x) => x.permissionType.read === false)
                ) {
                  permission.find(
                    (x) => x.isModule
                  ).permissionType.read = false;
                }
              }
            } else if (type === "write") {
              permission[idx].permissionType.write = checked;

              if (checked) {
                // if all submodules are checked, module should be checked in write
                if (
                  permission
                    .filter((x) => x.isModule == false)
                    .every((x) => x.permissionType.write === true)
                ) {
                  permission.find(
                    (x) => x.isModule
                  ).permissionType.write = true;
                }
              } else {
                // if any submodules are unchecked, module should be unchecked in write
                if (
                  permission
                    .filter((x) => x.isModule == false)
                    .some((x) => x.permissionType.write === false)
                ) {
                  permission.find(
                    (x) => x.isModule
                  ).permissionType.write = false;
                }
              }
            }
          }
        }
      });

      setPermissions(newPermissions);
    }
  };

  const fillPermissionTypes = (type) => {
    const { PERMISSION_TYPE } = CONSTANTS;
    if (type === PERMISSION_TYPE.READ) {
      return {
        read: true,
        write: false,
      };
    } else if (type === PERMISSION_TYPE.READWRITE) {
      return {
        read: true,
        write: true,
      };
    }
  };

  const groupByModules = (data) => {
    return _.chain(data).groupBy("module").value();
  };

  const formatModuleName = (name) => {
    if (name) {
      return name.split("_").join(" ");
    }
    return "";
  };

  const columns = [
    {
      title: "Permission",
      dataIndex: "permission",
      key: "permission",
      render: (_, record) => {
        console.log("records", record);
        return (
          <ul style={{ listStyle: "disc" }}>
            <li style={{ listStyle: "none" }}>
              {formatModuleName(record[0].name)}
            </li>
            {record[0].subModule && (
              <>
                {record
                  .filter((x) => x.isModule === false)
                  .map((x) => {
                    return <li>{x.name}</li>;
                  })}
              </>
            )}
          </ul>
        );
      },
    },
    {
      title: "Read",
      dataIndex: "view",
      key: "view",
      render: (_, record) => {
        return (
          <ul>
            <li>
              <CustomCheckbox
                value={record[0]}
                onChange={(e) => handleChange(e, "read")}
                checked={record[0].permissionType["read"] === true}
              />
            </li>
            {record[0].subModule && (
              <>
                {record
                  .filter((x) => x.isModule === false)
                  .map((x) => {
                    console.log({ checkbox: x });
                    return (
                      <li>
                        <CustomCheckbox
                          value={x}
                          onChange={(e) => handleChange(e, "read")}
                          checked={x.permissionType["read"] === true}
                        />
                      </li>
                    );
                  })}
              </>
            )}
          </ul>
        );
      },
    },
    {
      title: "Write",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => {
        return (
          <ul>
            <li>
              <CustomCheckbox
                value={record[0]}
                onChange={(e) => handleChange(e, "write")}
                checked={record[0].permissionType["write"] === true}
              />
            </li>
            {record[0].subModule && (
              <>
                {record
                  .filter((x) => x.isModule === false)
                  .map((x) => {
                    return (
                      <li>
                        <CustomCheckbox
                          value={x}
                          onChange={(e) => handleChange(e, "write")}
                          checked={x.permissionType["write"] === true}
                        />
                      </li>
                    );
                  })}
              </>
            )}
          </ul>
        );
      },
    },
  ];
  const checkAlphanumberic = () => {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    if (!regex.test(roleTitle)) {
      return false;
    }
    return true;
  };
  const handleSubmit = () => {
    console.log(permissions);
    // showModal()
    let isAlphaNumeric = checkAlphanumberic();
    if (!isAlphaNumeric) {
      toast.error("Please enter only alphanumeric characters in Role Title");
      return;
    }
    let permissionData = [...permissions];
    let permissionFlatData = permissionData
      .flat()
      .filter(
        (x) => x.isModule === false || (x.isModule === true && !x.subModule)
      );
    console.log({ permissionFlatData });
    permissionData = permissionFlatData.map((permission) => {
      return {
        permissionId: permission.permissionId,
        permissionType: formatPermissionType(permission.permissionType),
      };
    });

    let roleId = -1;

    if (location.state) {
      const selectedData = location.state.data;
      roleId = selectedData[0].roleId;
    }

    let apiData = {
      rolePermissions: permissionData,
      role: roleTitle,
      roleId,
    };

    updateAdminRoles(apiData);
  };

  const updateAdminRoles = async (data) => {
    try {
      if (
        data &&
        data.rolePermissions &&
        data.rolePermissions.length > 0 &&
        data.roleId != -1
      ) {
        let isPermissionsEmpty = data.rolePermissions.every((entry) => {
          return entry.permissionType == "";
        });
        if (isPermissionsEmpty) {
          toast.error("Please select permissions");
          return;
        }
      }
      let permissionData = data.rolePermissions;
      permissionData = permissionData.filter(
        (permission) => permission.permissionType !== ""
      );
      data.rolePermissions = permissionData;
      console.log({ finalApiData: data });

      dispatch(startLoading());
      const res = await dispatch(AdminActions.updatePermissions(data)).unwrap();
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const formatPermissionType = (obj) => {
    const { PERMISSION_TYPE } = CONSTANTS;
    let permissions = [];
    if (obj) {
      if (obj.read && !obj.write) {
        permissions.push(PERMISSION_TYPE.READ);
      } else if (!obj.read && obj.write) {
        permissions.push(PERMISSION_TYPE.READWRITE);
      } else if (obj.read && obj.write) {
        permissions.push(PERMISSION_TYPE.READWRITE);
      }

      return permissions.join("-");
    } else {
      return "";
    }
  };

  const checkPermissionState = () => {
    if (!location.state || Object.keys(location.state).length == 0) {
      navigate(-1);
    }
  };

  const askDiscardChanges = () => {
    setIsModalOpen(true);
  };

  const discardChanges = () => {
    if (permissions && permissions.length > 0) {
      let newPermission = [...permissions];
      newPermission = newPermission.map((permission) => {
        let permissionData = permission.map((x) => {
          return {
            ...x,
            checked: false,
            permissionType: {
              read: false,
              write: false,
            },
          };
        });

        return permissionData;
      });
      console.log(newPermission);
      setPermissions(newPermission);
      navigate(-1);
    }
  };

  useEffect(() => {
    getPermission();
  }, []);

  useEffect(() => {
    if (permissions) {
      checkPermissionState();
    }
  }, [permissions]);

  return (
    <>
      <div
        className="table-design"
        style={{ margin: "20px 0", padding: "20px" }}
      >
        <div className="table-filter">
          <h3>Update Role</h3>
        </div>

        <div>
          <InputCustom
            type="text"
            settingInput="settingInput"
            custommClass1="custommClass1"
            placeholder={"Role Title"}
            label={"Enter role Title"}
            borderRadius={10}
            height={46}
            maxWidth={500}
            maxlength={50}
            value={roleTitle}
            onChange={(e) => setRoleTitle(e.target.value)}
          />
        </div>
      </div>
      {permissions && (
        <div className="table-design">
          <div className="title-filter">
            <h3>Update Permission</h3>
          </div>
          <div className="custom-table permission-page">
            <Table
              dataSource={permissions}
              columns={columns}
              pagination={false}
              //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
            />
          </div>
        </div>
      )}

      <div className="rejectBtn">
        <ButtonCommon
          height={46}
          text="Discard"
          reject="reject"
          onClick={askDiscardChanges}
        />
        <ButtonCommon
          height={46}
          text="Submit"
          type="primary"
          onClick={handleSubmit}
        />
      </div>
      {/* <CustomModal
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <PermissionEdit />
      </CustomModal> */}

      <CustomModal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <ButtonCommon
            type="primary"
            htmlType="button"
            key="1"
            onClick={handleCancel}
          >
            Close
          </ButtonCommon>,
          <ButtonCommon
            type="primary"
            htmlType="button"
            key="1"
            onClick={handleOk}
          >
            OK
          </ButtonCommon>,
        ]}
      >
        Are you sure you want to discard changes ?
      </CustomModal>
    </>
  );
};

export default UpdatePermission;
