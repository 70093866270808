import React from "react";
import {Modal} from "antd";
import style from "./style.module.scss";
import { HistoryOutlined} from "@ant-design/icons";
import {getColorRiskManagement} from "../../../utils/common.utils";
import RiskManagementHistoryInfoBox from "./HistoryInfoBox";
const RiskManagementHistoryModal = ({isModalOpen, handleCancel, history}) => {


  return (
    <>
      <Modal
        className="custom-modal edit"
        open={isModalOpen}
        onCancel={handleCancel}
        width={390}
        centered
        footer=""
      >
        <div className={style.edit_header}><HistoryOutlined/> Change History</div>
        <div className={style.hr}/>
        <div>
          <p className={style.edit_label}>Item</p>
          <p className={style.edit_title}>{history?.question?.title}</p>
        </div>

        <div>
          <p className={style.edit_label}>Answer</p>
          <p className={style.edit_descript}>{history?.answer?.title}</p>
        </div>

        <div className={style.hr}/>
        <RiskManagementHistoryInfoBox data={history?.comments} systemScore={history?.answer?.score} />
        <div className={style.hr}/>
        <RiskManagementHistoryInfoBox />

      </Modal>
    </>
  );
};

export default RiskManagementHistoryModal;
