import { Button, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Validations } from '../../../validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormError } from '../../../Components/FormErrors/FormError';
import { useDispatch } from 'react-redux';

function ApproveFiatTxnModal({
  showApproveFiatTxn,
  setShowApproveFiatTxn,
  callback,
  amountToVerify
}) {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(Validations.fiatApproveTxnValidation(amountToVerify)),
    mode: 'all',
  });

  const onSubmit = ({ fiatAmount }) => {
    callback(fiatAmount);
  };

  const handleCancel = () => {
    setShowApproveFiatTxn(false);
  };

  useEffect(() => {
    if (!showApproveFiatTxn) {
      reset();
    }
  }, [showApproveFiatTxn]);

  console.log('AMOUNT TO VALIDATE :',amountToVerify);
  return (
    <div>
      <Modal
        className="custom-modal small-btn"
        title="Are you sure, you  want to approve this transaction?"
        open={showApproveFiatTxn}
        okText="Submit"
        closeIcon={true}
        centered
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            key="1"
            onClick={handleSubmit(onSubmit)}
          >
            Approve
          </Button>,
          <Button type="link" key="2" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div className="modal-inner">
          <form className="withdrawFee">
            <Controller
              name="fiatAmount"
              control={control}
              render={({ field }) => (
                <>
                  <span
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  ></span>
                  <Input type='number' placeholder="Amount" {...field} />
                  {console.log(errors, 'errorserrors')}
                  {errors.fiatAmount && (
                    <FormError message={errors.fiatAmount.message} />
                  )}{' '}
                </>
              )}
            />
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default ApproveFiatTxnModal;
