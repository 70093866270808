import React, { useEffect, useState } from "react";
import TableFilterTittle from "../../Components/TableFilterTittle/TableFilterTittle";
import { Button, Pagination, Table } from "antd";
import {
  checkEmptyString,
  formatDate,
  formatDateTime,
  handleHideId,
  iconColor,
  iconSize,
  sliceString,
} from "../../utils/common.utils";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";
import { ClientSupportActions } from "../../redux/features/clientSupport/clientSupportActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard";
import PermissionCheck from "../../Components/PermissionCheck/PermissionCheck.jsx";
import { EyeOutlined } from "@ant-design/icons";
function Pending({ selectedSupportTab }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedClientSupportTab = useSelector(
    (state) => state?.clietSupport?.selectedClientSupportTab
  );
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [getDetail, setGetDetails] = useState({
    list: [],
    totalCount: 0,
  });

  const getPendingClientSupportDetails = async () => {
    try {
      const data = {
        offset: (page - 1) * pageSize,
        size: pageSize,
        status: "PENDING",
      };
      await dispatch(startLoading());
      const res = await dispatch(
        ClientSupportActions.getClientSupport(data)
      ).unwrap();

      setGetDetails({
        list: res.list,
        totalCount: res.totalCount,
      });
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    if (selectedClientSupportTab === "Pending") {
      getPendingClientSupportDetails();
    }
  }, [selectedClientSupportTab]);
  console.log(selectedClientSupportTab, "selectedClientSupportTab");
  const handlePageChange = async (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    try {
      const data = {
        offset: (page - 1) * pageSize,
        size: pageSize,
        status: "PENDING",
      };
      await dispatch(startLoading());
      const res = await dispatch(
        ClientSupportActions.getClientSupport(data)
      ).unwrap();

      setGetDetails({
        list: res.list,
        totalCount: res.totalCount,
      });

      console.log(res, "paginationRes");
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const columns = [
    {
      title: "Ticket No",
      dataIndex: "ticketId",
      key: "ticketId",
      render: (_, record) => {
        console.log(record?.ticketId, "ticketId");
        return (
          <>
            {record?.ticketId ? (
              <>
                {handleHideId(record?.ticketId)}
                <CopyToClipboard userId={record.ticketId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    // {
    //   title: "UID",
    //   dataIndex: "uId",
    //   key: "uId",
    //   render: (_, record) => {
    //     return (
    //       <>
    //         {record?.uidString ? (
    //           <>
    //             {handleHideId(record?.uidString)}
    //             <CopyToClipboard userId={record.uidString} position="top" />
    //           </>
    //         ) : (
    //           "-"
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Priority",
    //   dataIndex: "priority",
    //   key: "priority",
    //   render: (_, record) => {
    //     return checkEmptyString(record.priority);
    //   },
    // },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (_, record) => {
    //     return checkEmptyString(record.type);
    //   },
    // },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (_, record) => {
        return checkEmptyString(record.subject);
      },
    },

    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (_, record) => {
    //     return checkEmptyString(sliceString(record.description, 50));
    //   },
    // },

    // {
    //   title: "Resolved By",
    //   dataIndex: "resolvedBy",
    //   key: "resolvedBy",
    //   render: (_, record) => {
    //     return checkEmptyString(record.resolvedBy);
    //   },
    // },
    // {
    //   title: "ResolvedOn",
    //   dataIndex: "resolvedOn",
    //   key: "resolvedOn",
    //   render: (_, record) => {
    //     return formatDateTime(record.resolvedOn);
    //   },
    // },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        return formatDateTime(record.createdAt);
      },
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (_, record) => {
        return formatDateTime(record.updatedAt);
      },
    },
    // {
    //   title: "Subject",
    //   dataIndex: "subject",
    //   key: "subject",
    //   render: (_, record) => {
    //     return checkEmptyString(record.subject);
    //   },
    // },

    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (_, record) => {
    //     return checkEmptyString(sliceString(record.description, 2));
    //   },
    // },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (_, record) => {
    //     return checkEmptyString(record.status);
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <PermissionCheck>
            {/* <Button
              type="primary"
              onClick={() =>
                navigate("/client-support-communication-ticket-info", {
                  state: { userId: record.userId, record: record },
                })
              }
            >
              More Details
            </Button> */}
            <EyeOutlined
              style={{ color: iconColor, fontSize: iconSize }}
              onClick={() =>
                navigate("/client-support-communication-ticket-info", {
                  state: { userId: record.userId, record: record },
                })
              }
            />
          </PermissionCheck>
        );
      },
    },
    // {
    //   title: "Update dAt",
    //   dataIndex: "updatedAt",
    //   key: "updatedAt",
    //   render: (_, record) => {
    //     return formatDate(record.updatedAt);
    //   },
    // },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <div className="approvereject cursorpointer">
    //           <p>
    //             <Text type="success">Reply/</Text>
    //             <Text type="danger">Reject</Text>
    //           </p>
    //           <Button onClick={showModal} type="primary">
    //             View Details
    //           </Button>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];
  return (
    <div className="Custom-tabBtn">
      <div className="table-design">
        <TableFilterTittle title="Customer Support" />
        <div className="custom-table">
          <Table
            dataSource={getDetail.list}
            columns={columns}
            pagination={false}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      <div className="pagination_block">
        <span className="records">
          Total Records: {getDetail.totalCount > 0 ? getDetail.totalCount : 0}
        </span>
        {getDetail.totalCount > 10 && (
          <Pagination
            defaultCurrent={page}
            total={getDetail.totalCount}
            pageSize={pageSize}
            onChangeNon-References Transactions={handlePageChange}
            current={page}
          />
        )}
      </div>
    </div>
  );
}

export default Pending;
