import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { BANKING } = CONSTANTS.API_ENDPOINTS;

const getFiatBalances = createAsyncThunk(
  "bank/getFiatBalances",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${BANKING.GET_FIAT_BALANCES}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "DASHBOARD",
        },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const getFiatTransactionDetails = createAsyncThunk(
  "banking/transactions/deatils/byId",
  async (data, { rejectWithValue, dispatch }) => {
    const { transactionId, apiAccessKey } = data;
    console.log({ transactionId })
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${BANKING.GET_TRANSACTIONS_DETAILS_BY_ID}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey
        },
        params: { transactionId },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

const getFiatFeeList = createAsyncThunk(
  "banking/getFiatFeeList",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${BANKING.GET_FIAT_FEE_LIST}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "FEE_SETTING",
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

const cancelFiatTxnStatus = createAsyncThunk(
  "banking/cancelFiatTxn",
  async (data, { rejectWithValue, dispatch }) => {
    let newData = { ...data };
    const { apiAccessKey } = newData || {};
    delete data.apiAccessKey
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${BANKING.CANCEL_FIAT_TRANSACTIONS}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const FiatTApprovexnStatus = createAsyncThunk(
  "banking/fiatTApprovexnStatus",
  async (data, { rejectWithValue, dispatch }) => {
    let newData = { ...data };
    const { apiAccessKey } = newData || {};
    delete data.apiAccessKey
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${BANKING.CANCEL_FIAT_TRANSACTIONS}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const updateComplianceFiatStatus = createAsyncThunk(
  "banking/updateComplianceFiatStatus",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${BANKING.UPDATE_FIAT_STATUS_COMPLIANCE}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "TRANSACTIONS_SCREENING"
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);
const updateComplianceReApproveFiatStatus = createAsyncThunk(
  "banking/updateComplianceReApproveFiatStatus",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${BANKING.UPDATE_FIAT_REAPPROVE_STATUS_COMPLIANCE}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "TRANSACTIONS_SCREENING"
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const getFeeStatus = createAsyncThunk(
  "banking/getFeeStatus",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${BANKING.GET_FEE_STATUS}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "FEE_SETTING"
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);


export const BankingActions = {
  getFiatBalances,
  getFiatTransactionDetails,
  getFiatFeeList,
  cancelFiatTxnStatus,
  FiatTApprovexnStatus,
  updateComplianceFiatStatus,
  updateComplianceReApproveFiatStatus,
  getFeeStatus
};
