import React from "react";
import { checkEmptyString } from "../../../utils/common.utils";

const ShowLessElipsis = ({text}) => {
  return (
    <span
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "200px",
        display: "inline-block",
      }}
    >
      {checkEmptyString(text)}
    </span>
  );
};

export default ShowLessElipsis;
