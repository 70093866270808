import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { ACTIVITY } = CONSTANTS.API_ENDPOINTS;

const getActivities = createAsyncThunk(
  "activity/getActivities",
  async (payload, { rejectWithValue, dispatch }) => {
    const { index } = payload;
    const { fromDate, serviceName, statusCode, toDate } = payload.filter || {};
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${ACTIVITY.GET_ACTIVITIES}/${payload.offset}/${payload.size}`,
        data: {
          limit: payload.size,
          offset: payload.offset,
        },
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "ACTIVITY",
        },
        params: {
          fromDate,
          serviceName,
          statusCode,
          toDate,
          index
        },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      console.log("RESPONSE ::", res);
      return res;
    }
  }
);

const getActivityByLogId = createAsyncThunk(
  "activity/get-activity-detail",
  async (payload, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        // endpoint: `${ACTIVITY.GET_ACTIVITIES}/ZrchvY4B993ZmA8JN-6z`,
        endpoint: `${ACTIVITY.GET_ACTIVITIES_DETAIL}/${payload.logId}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "ACTIVITY",
        },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      console.log("RESPONSE ::", res);
      return res;
    }
  }
);

export const ActivityActions = {
  getActivities,
  getActivityByLogId,
};
