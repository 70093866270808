import React, { useState } from "react";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";
import { KycActions } from "../../redux/features/kyc/kycActions";
import KycApproveModal from "./KycApproveModal";
import KycRejectModal from "./KycRejectModal";
import toast from "../../services/toast.service";
import { CONSTANTS } from "../../constants";
import { Badge, Typography } from "antd";
import { setSelectedVerificationTab } from "../../redux/features/compliance/complianceSlice";
import { ComplianceActions } from "../../redux/features/compliance/complianceAtion";
const { Text } = Typography;

const KycActionButtons = ({
  className,
  sumsubKycStatus,
  adminKycStatus,
  setIsMngView,
}) => {
  console.log({ sumsubKycStatus, adminKycStatus });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [rejectionReason, setrejectionReason] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const selectedData = useSelector((state) => state.user.data);

  const {
    APPROVED,
    REJECTED,
    PENDING,
    INITIATED,
    FINAL_REJECTED,
    TEMPORARY_REJECTED,
  } = CONSTANTS.KYC_STATUS;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showReject = () => {
    setIsRejectOpen(true);
  };

  const handleApprove = () => {
    updateKYCStatus({
      status: APPROVED,
    });
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleReject = async () => {
    try {
      if (!rejectionReason) throw "Please enter rejection reason";
      updateKYCStatus({ status: REJECTED, reason: rejectionReason });
      // console.log("MY Reject");
      setIsRejectOpen(false);
    } catch (error) {
      toast.error(error);
    }
    // updateKYCStatus("REJECTED");
  };
  const handlecancel = () => {
    setIsRejectOpen(false);
  };

  const updateKYCStatus = async (obj) => {
    try {
      if (selectedData) {
        await dispatch(startLoading());
        const data = {
          userId: selectedData.user.userId,
          adminKycStatus: obj.status,
          rejectionReason: obj.reason ? obj.reason : undefined,
        };
        let res = null;

        if (location.pathname === "/sanction-report-kyc") {
          res = await dispatch(
            ComplianceActions.updateKycStatus(data)
          ).unwrap();
          navigate("/sanction-report-verifications-screening");
          setTimeout(() => {
            dispatch(setSelectedVerificationTab("Completed"));
          }, 500);
        } else {
          res = await dispatch(KycActions.updateKycStatus(data)).unwrap();
          setIsMngView(false);
        }
      } else return;
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  return (
    <>
      <div className={className}>
        {(sumsubKycStatus === INITIATED || sumsubKycStatus === PENDING) && (
          <Text code>
            <Badge color="yellow" text="Awaiting sumsub approval" />
          </Text>
        )}
        {(sumsubKycStatus === FINAL_REJECTED ||
          sumsubKycStatus === TEMPORARY_REJECTED) && (
          <>
            {adminKycStatus === PENDING && (
              <>
                <ButtonCommon
                  text="Approve"
                  width={128}
                  height={32}
                  approve="approve"
                  onClick={showModal}
                  custmcls="cursorpointer"
                />
              </>
            )}
          </>
        )}
        {sumsubKycStatus === APPROVED && (
          <>
            {adminKycStatus === REJECTED && (
              <ButtonCommon
                text="Approve"
                width={128}
                height={32}
                approve="approve"
                onClick={showModal}
                custmcls="cursorpointer"
              />
            )}

            {adminKycStatus === APPROVED &&  (
              <ButtonCommon
                text="Reject"
                width={128}
                height={32}
                reject="reject"
                onClick={showReject}
                custmcls="cursorpointer"
              />
            )}

            {adminKycStatus === PENDING && (
              <>
                <ButtonCommon
                  text="Approve"
                  width={128}
                  height={32}
                  approve="approve"
                  onClick={showModal}
                  custmcls="cursorpointer"
                />
                <ButtonCommon
                  text="Reject"
                  width={128}
                  height={32}
                  reject="reject"
                  onClick={showReject}
                  custmcls="cursorpointer"
                />
              </>
            )}
          </>
        )}
      </div>
      <KycApproveModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        handleApprove={handleApprove}
      />

      <KycRejectModal
        handleReject={handleReject}
        isRejectOpen={isRejectOpen}
        handlecancel={handlecancel}
        rejectionReason={rejectionReason}
        setrejectionReason={setrejectionReason}
      />
    </>
  );
};

export default KycActionButtons;
