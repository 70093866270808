import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Select, Input, DatePicker, Button } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import toast from "../../../services/toast.service";
import { CONSTANTS } from "../../../constants";
import { formatDate, formatTxnDate } from "../../../utils/common.utils";
import dayjs from "dayjs";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";

const FiatTransactionFilters = ({
  setPage,
  setFilterData,
  pageSize,
  getFiatTransactions,
  clientId,
  data,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: "all",
  });

  const location = useLocation();

  const obj = { ...location.state };
  const [trans, setTrans] = useState("all");
  /** setting default filters coing from props */
  const [filter, setFilter] = useState({
    coin: obj.coin,
    page: obj.page,
    fromDate: obj.fromDate,
    toDate: obj.toDate,
    txStatus: obj.txStatus,
    type: obj.type,
  });

  const onSubmit = (values) => {
    // receive,send,internal-deposit,internal-withdraw
    let isFiltersValid = validateFilters(values);
    if (!isFiltersValid) return;
    values = convertFilterValues(values);
    if (Object.keys(values).length > 0) {
      setFilterData(values);
      getFiatTransactions(values);
      setPage(1);
    }
  };

  const convertFilterValues = (data) => {
    let values = { ...data };

    if (values.fromDate && !values.toDate)
      values.toDate = formatTxnDate(new Date().toDateString());
    if (values.toDate && !values.fromDate)
      return toast.error("Please select from date");
    values.page = 1;
    values.size = pageSize;
    values.txStatus = values.status;
    values.coin = "";
    values.clientId =
      location.pathname !== "/crypto-transaction" ? clientId : "";

    if (values.fromDate) values.fromDate = formatTxnDate(values.fromDate);
    if (values.toDate) {
      values.toDate = formatTxnDate(values.toDate);
      setValue("toDate", dayjs(values.toDate));
    }
    if (typeof values.status === "undefined") values.txStatus = "ALL";
    if (typeof values.type === "undefined") values.type = "credit,debit,internal-transfer";

    return values;
  };

  const checkEmptyFilters = (data) => {
    let filterMessage = "";
    let isFiltersValid = true;
    isFiltersValid = Object.values(data).some((value) => !!value);
    if (!isFiltersValid) {
      if (data && data.length === 0) {
        isFiltersValid = true;
      } else {
        filterMessage = "Please select filters first";
        toast.error(filterMessage);
      }
    }

    return isFiltersValid;
  };

  const validateFilters = (data) => {
    const { VALIDATION_ERRORS } = CONSTANTS;
    let isFiltersValid = checkEmptyFilters(data);
    if (data.fromDate && data.toDate) {
      if (
        new Date(data.fromDate).getDate() > new Date(data.toDate).getDate()
      ) {
        isFiltersValid = false;
        toast.error(VALIDATION_ERRORS.START_DATE_GREATER_THAN_END_DATE);
      }
    }

    return isFiltersValid;
  };

  const clearFilters = () => {
    let isFiltersValid = checkEmptyFilters(getValues());
    if (isFiltersValid) {
      reset();
      const data = {
        clientId: location.pathname !== "/fiat-transaction" ? clientId : "",
        coin: "",
        page: 1,
        size: pageSize,
        fromDate: "",
        toDate: "",
        txStatus: "ALL",
        type: "credit,debit,internal-transfer",
      };
      setPage(1);
      setFilterData(data);
      getFiatTransactions(data);
    }
  };

  const setRangePickerValue = () => {
    return {
      fromDate: dayjs(filter.fromDate),
      toDate: dayjs(filter.toDate),
    };
  };

  useEffect(() => {
    console.log("filters:- ", filter);
    setValue("coin", filter.coin);
    if (filter.fromDate) {
      setValue("fromDate", setRangePickerValue().fromDate);
    }
    if (filter.toDate) {
      setValue("toDate", setRangePickerValue().toDate);
    }
    setValue("type", filter.type);
    setValue("status", filter.txStatus);
  }, [filter]);

  return (
    <form
      style={{ display: "inline-flex", gap: "15px", flexFlow: "wrap" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Controller
        name="fromDate"
        control={control}
        render={({ field }) => (
          <DatePicker {...field} placeholder="From date" />
        )}
      />

      <Controller
        name="toDate"
        control={control}
        render={({ field }) => <DatePicker {...field} placeholder="To date" />}
      />

      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <Select
            placeholder="Type"
            {...field}
            style={{
              minWidth: "150px",
            }}
            options={[
              {
                value: "credit,debit,internal-transfer",
                label: "ALL",
              },
              {
                value: "credit",
                label: "CREDIT",
              },
              {
                value: "debit",
                label: "DEBIT",
              },
              {
                value: "internal-transfer",
                label: "INTERNAL-TRANSFER",
              },

              // { value: "fiat-swap", label: "FIAT-SWAP" },
            ]}
          />
        )}
      />

      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <Select
            placeholder="Status"
            {...field}
            style={{
              minWidth: "150px",
            }}
            options={[
              // { value: "", label: "", disabled: true },
              {
                value: "ALL",
                label: "ALL",
              },
              {
                value: "PENDING",
                label: "PENDING",
              },
              {
                value: "COMPLETED",
                label: "COMPLETED",
              },
              {
                value: "FAILED",
                label: "FAILED",
              },
            ]}
          />
        )}
      />

      <ButtonCommon
        text="Submit"
        type="primary"
        transBtn="transBtn"
        htmlType={"submit"}
      />

      <ButtonCommon
        className="filter-btn"
        // onClick={toggleFilterVisibility}
        type={"default"}
        onClick={clearFilters}
        htmlType="button"
        reject={"reject"}
      >
        <CloseOutlined />
      </ButtonCommon>
    </form>
  );
};

export default FiatTransactionFilters;
