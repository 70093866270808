import React, { useEffect } from "react";
import TextArea from "antd/es/input/TextArea";
import {Controller } from "react-hook-form";
import { FormError } from "../../Components/FormErrors/FormError";

const UpdateKmsManagement = ({form, selectedKey}) => {
  const {
    formState: { errors },
    control,
    setValue
  } = form

  useEffect(()=>{
    setValue("kmsText",selectedKey?.keyValue)
  },[selectedKey])

  return (
    <div className="table-designz">
     
      <form  noValidate>
        <div
          style={{
            display: "flex",
            padding: "10px 30px 24px",
            flexDirection: "column",
          }}
        >
         <label htmlFor="">Key Name :- {selectedKey?.keyName}</label>
        </div>

        <div
          style={{
            display: "flex",
            padding: "10px 30px 24px",
            flexDirection: "column",
          }}
        >
          <Controller
            className="custom-inputDesign"
            name="kmsText"
            control={control}
            render={({ field }) => <TextArea rows={3} {...field} />}
          />
          <FormError message={errors?.kmsText?.message} />
        </div>

       
      </form>
    </div>
  );
};

export default UpdateKmsManagement;
