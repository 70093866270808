import { Typography } from "antd";
import { PersonalInfoService } from "../../../services/personalInfo.service.js";
import PersonalInfoData from "./PersonalInfoData.jsx";
import { useEffect, useState } from "react";
import { checkEmptyString } from "../../../utils/common.utils.js";

const PersonalInfoList = ({ data }) => {
  console.log({ personalInfoList: data });
  const { Title } = Typography;

  return (
    <>
      <Title level={5}>Personal Info</Title>
      <ul className="builtPoints">
        {console.log('Personal Info',data)}
        {data.length > 0 && data.map((item) => <PersonalInfoData {...item} />)}
      </ul>
      
    
    </>
  );
};

export default PersonalInfoList;
