import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import style from "../../../UserDirectory/style.module.scss";
import { Table, Input, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import KycVerification from "../../../KycVerification/KycVerification.jsx";
import Rejectdata from "../../../KycVerification/Rejectdata.jsx";
import Filttercustom from "../../../UserDirectory/Filttercustom.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CollapseProps } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import {
  startLoading,
  finishLoading,
} from "../../../../redux/features/loading/loadingSlice";
import { KycActions } from "../../../../redux/features/kyc/kycActions";
import { setSelectedKYC } from "../../../../redux/features/kyc/kycSlice";
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
} from "../../../../utils/common.utils";
import { showName } from "../../../../services/user.service";
import CopyToClipboard from "../../../../Components/CopyToClipboard/CopyToClipboard.jsx";

const SanctionVerification = () => {
  const [isModalOpenReject, setIsModalOpenReject] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const { isInitialPageLoaded } = useSelector((state) => state.dashboard);
  const [pageSize, setPageSize] = useState(10);

  const showModalReject = () => {
    setIsModalOpenReject(true);
  };

  const handleOkReject = () => {
    setIsModalOpenReject(false);
  };

  const handleCancelReject = () => {
    setIsModalOpenReject(false);
  };

  // ====================================================Reject==
  const [isModalOpenApprove, setIsModalOpenApprove] = useState(false);
  const [IsMngView, setIsMngView] = useState(true);

  const showVerificationDetails = async (userId) => {
    try {
      await dispatch(setSelectedKYC({ userId }));
      await getUserDetails(userId);

      setIsMngView(true);
    } catch (error) {}
  };

  const handleOkApprove = () => {
    setIsModalOpenApprove(false);
  };

  const handleCancelApprove = () => {
    setIsModalOpenApprove(false);
  };
  // ================================================================
  const [isModalOpen, setIsModalOpen] = useState(false);

  const viewKYCApplication = async (userId) => {
    try {
      await dispatch(setSelectedKYC({ userId }));
      await getUserDetails(userId, false);
      setIsMngView(true);
    } catch (error) {}
  };

  const getUserDetails = async (id) => {
    try {
      const data = await dispatch(
        KycActions.getUserDetail({ userId: id })
      ).unwrap();
      setSelectedData(data);
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // =======================================================

  const dispatch = useDispatch();

 
  

 

  const showAdminActions = (status) => {
    switch (status) {
      case "REJECTED":
        return <span className="green">Approve</span>;
      case "APPROVED":
        return <span className="red">Reject</span>;
      case "PENDING":
        return (
          <>
            <span className="green">Approve</span>
            <span className="red">/Reject</span>
          </>
        );
    }
  };


 

  return (
    <>
     
        <KycVerification
          userInformatoin={true}
          datamodalapprove={true}
          datamodalreject={true}
          innervalue={true}
          setIsMngView={setIsMngView}
          data={selectedData}
        />
     

     
    </>
  );
};

export default SanctionVerification;
