import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  selectedWallet: "Crypto Wallet",
};

export const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {
    setSelectedWallet: (state, action) => {
      state.selectedWallet = action.payload;
    },
  },

  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const { selectedWallet, setSelectedWallet } = swapSlice.actions;

export default swapSlice.reducer;
