import React from "react";
import style from "./style.module.scss";
import {getColorRiskManagement} from "../../../utils/common.utils";
const RiskManagementHistoryInfoBox = ({ data = [], systemScore }) => {

  return (
    <div className={style.scrollableContainer} >
      {data.map((item) => (
        <div key={item.id}>
          <p className={style.edit_label}>20:21 21.08.2024</p>
          <div className={style.userInfo}>
            <p className={style.userInfo_avatar}>{item?.admin?.firstName?.slice(0, 1)}{item?.admin?.lastName?.slice(0, 1)}</p>
            <p className={style.userInfo_name}>{item.admin.firstName} {item.admin.lastName} ({item?.admin?.role.name})</p>
          </div>
          <p className={style.edit_descript}>{item?.text}</p>
          <div className={style.historyScore}>
            <div className={style.historyScore_box}>
              <span>System Score:</span>
              <span
                style={{ background: getColorRiskManagement(parseInt(systemScore)) }}
                className={style.historyScore_box_score}
              >
                {systemScore}
              </span>
            </div>
            <div className={style.historyScore_box}>
              <span>Manual Score:</span>
              <span
                style={{ background: getColorRiskManagement(parseInt(item?.manualScore)) }}
                className={style.historyScore_box_score}
              >
                {item?.manualScore}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RiskManagementHistoryInfoBox;
