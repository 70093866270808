import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Select, DatePicker, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import toast from "../../services/toast.service";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import CustomSelect from "../../Components/CustomSelect/CustomSelect";
import { CONSTANTS } from "../../constants";
import { formatDate, formatTxnDate } from "../../utils/common.utils";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
const ActivityLogFilters = ({
  setPage,
  setPageSize,
  setFilterData,
  pageSize,
  getActivities,
  logId,
  data,
}) => {
  const location = useLocation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: "all",
  });

  const validateFilters = (data) => {
    console.log("DATA::",data);
    const { VALIDATION_ERRORS } = CONSTANTS;
    let isFiltersValid = checkEmptyFilters(data);
    if (data.fromDate && data.toDate) {
      if (new Date(data.fromDate).getDate() > new Date(data.toDate).getDate()) {
        isFiltersValid = false;
        toast.error(VALIDATION_ERRORS.START_DATE_GREATER_THAN_END_DATE);
      }
    }

    return isFiltersValid;
  };

  const onSubmit = (values) => {
    let isFiltersValid = validateFilters(values);
    if (!isFiltersValid) return;
    values = convertFilterValues(values);
    if (Object.keys(values).length > 0) {
      setFilterData(values);
      const data = {
        offset: 0,
        size: pageSize,
        filter: values,
      };
      getActivities(data);
      setPage(1);
    }
  };

  const convertFilterValues = (data) => {
    let values = { ...data };

    if (values.fromDate && !values.toDate)
      values.toDate = formatDate(new Date().toDateString());
    if (values.toDate && !values.fromDate)
      return toast.error("Please select from date");

    if (values.fromDate) values.fromDate = formatDate(values.fromDate);
    if (values.toDate) {
      values.toDate = formatDate(values.toDate);
      setValue("toDate", dayjs(values.toDate));
    }
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        if (
          typeof values[key] === 'undefined' ||
          values[key] == '' ||
          values[key] == null
        )
          delete values[key];
      }
    }
    return values;
  };

  const checkEmptyFilters = (data) => {
    let filterMessage = "";
    let isFiltersValid = true;
    isFiltersValid = Object.values(data).some((value) => !!value);
    if (!isFiltersValid) {
      if (data && data.length === 0) {
        isFiltersValid = true;
      } else {
        filterMessage = "Please select filters first";
        toast.error(filterMessage);
      }
    }

    return isFiltersValid;
  };

  const clearFilters = () => {
    if (data.length > 0) {
      let isFiltersValid = checkEmptyFilters(getValues());
      if (isFiltersValid) {
        reset();
        setPage(1);
        setPageSize(10)
        getActivities({
          offset: 0,
          size: 10,
          filter: {},
        });
      }
    } else {
      reset();
      setPage(1);
      setPageSize(10)
      getActivities({
        offset: 0,
        size: 10,
        filter: {},
      });
    }
  };

  useEffect(() => {
    let { fromDate, toDate, serviceName, statusCode } =
      location.state?.filter || {};
    if (fromDate) {
      setValue("fromDate", dayjs(fromDate));
    }
    if (toDate) {
      setValue("toDate", dayjs(toDate));
    }
    if (serviceName) {
      setValue("serviceName", serviceName);
    }
    if (statusCode) {
      setValue("statusCode", statusCode);
    }
  }, [location.state]);

  return (
    <form
      style={{ display: "inline-flex", gap: "15px", flexFlow: "wrap" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Controller
        name="fromDate"
        control={control}
        render={({ field }) => (
          <DatePicker {...field} placeholder="From date" />
        )}
      />

      <Controller
        name="toDate"
        control={control}
        render={({ field }) => <DatePicker {...field} placeholder="To date" />}
      />

      <Controller
        className="custom-inputDesign"
        name="serviceName"
        control={control}
        render={({ field }) => (
          <CustomSelect
            className="custom-inputDesign"
            placeholder="Service"
            {...field}
            // defaultValue={getValues('service')}
            // value={'All'}
            style={{
              minWidth: "150px",
            }}
            options={[
              {
                value: "users",
                label: "USERS",
              },
              {
                value: "kyc",
                label: "KYC",
              },
              {
                value: "overwallet",
                label: "OVER WALLET",
              },
              {
                value: "admin",
                label: "ADMIN",
              },
            ]}
          />
        )}
      />

      <Controller
        name="statusCode"
        control={control}
        render={({ field }) => (
          <CustomSelect
            placeholder="Status"
            {...field}
            style={{
              minWidth: "150px",
            }}
            defaultValue={getValues("service")}
            options={[
              {
                value: "200",
                label: "200",
              },
              {
                value: "400",
                label: "400",
              },
              {
                value: "401",
                label: "401",
              },
            ]}
          />
        )}
      />

      <ButtonCommon
        type="primary"
        className="filter-btn select-btn"
        text="Submit"
        htmlType={"submit"}
      />

      <ButtonCommon
        className="filter-btn select-btn"
        reject="reject"
        type={"default"}
        onClick={clearFilters}
        htmlType="button"
      >
        <CloseOutlined />
      </ButtonCommon>
    </form>
  );
};

export default ActivityLogFilters;
