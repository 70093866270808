import React from "react";
import { Controller } from "react-hook-form";
import { FormError } from "../FormErrors/FormError";
import InputCustom from "../InputCustom/InputCustom";

const TransactionRef = ({ form }) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = form;
  return (
    <div>
      <form noValidate>
        <div
          style={{
            display: "flex",
            padding: "10px 30px 24px",
            flexDirection: "column",
          }}
        >
          <Controller
            name="transactionReference"
            control={control}
            render={({ field }) => (
              <InputCustom
                type="text"
                settingInput="settingInput"
                custommClass1="custommClass1"
                placeholder={"Transaction Reference"}
                label={"Transaction Reference Id"}
                borderRadius={10}
                height={46}
                {...field}
              />
            )}
          />
          <FormError message={errors.transactionReference?.message} />
        </div>

        <div
          style={{
            display: "flex",
            padding: "10px 30px 24px",
            flexDirection: "column",
          }}
        >
          <Controller
            className="custom-inputDesign"
            name="amount"
            control={control}
            render={({ field }) => (
              <InputCustom
                type="text"
                settingInput="settingInput"
                custommClass1="custommClass1"
                placeholder={"Amount"}
                label={"Amount"}
                borderRadius={10}
                height={46}
                {...field}
              />
            )}
          />
          <FormError message={errors.amount?.message} />
        </div>
      </form>
    </div>
  );
};

export default TransactionRef;
