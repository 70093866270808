import React, { useEffect, useState } from "react";
import CustomDragUpload from "../../../Components/customDragUpload/CustomDragUpload";
import { Tabs, Pagination } from "antd";
import Uploadtable from "./Uploadtable";
import RemoveTable from "./RemoveTable";
import { MarkettingActions } from "../../../redux/features/marketting/markettingActions";
import {
  startLoading,
  finishLoading,
} from "../../../redux/features/loading/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";
import { setSelectedBanner } from "../../../redux/features/marketting/markettingSlice";
import PermissionCheck from "../../../Components/PermissionCheck/PermissionCheck";

const Rewards = ({ banners, getBanners, setBanners }) => {
  const dispatch = useDispatch();
  const { type } = useSelector((state) => state.marketting.selectedBanner);
  const [activeKey, setActiveKey] = useState("1");
  const [page, setPage] = useState(1);

  const onChange = (key) => {
    console.log("web", key);
    setActiveKey(key);
    getBanners({
      page: 1,
      limit: 10,
      type: "WEB",
      isDeleted: key === "1" ? false : true,
    });
  };

  const allowedTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/svg",
  ];

  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", CONSTANTS.BANNER_TYPE.WEB);
      console.log([...formData]);
      await dispatch(startLoading());
      const res = await dispatch(
        MarkettingActions.uploadBanner(formData)
      ).unwrap();
      getBanners({
        page:1,
        type: "WEB",
        limit:10,
        isDeleted: activeKey === "1" ? false : true,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  const items = [
    {
      key: "1",
      label: "Uploaded",
      children: (
        <Uploadtable
          banners={banners}
          getBanners={getBanners}
          page={page}
          setPage={setPage}
          setBanners={setBanners}
          type={"WEB"}
        />
      ),
    },
    {
      key: "2",
      label: "Removed",
      children: (
        <RemoveTable
          banners={banners}
          getBanners={getBanners}
          page={page}
          setPage={setPage}
          setBanners={setBanners}
          type={"WEB"}
        />
      ),
    },
  ];

  useEffect(() => {
    if (type === "WEB") {
      setActiveKey("1");
      const data = {
        offset:(page-1)*10,
        limit: 10,
        isDeleted: false,
        type,
      };
      getBanners(data);
    }
  }, [type]);

  const {HEIGHT, WIDTH} = CONSTANTS.BANNER_DIMENSIONS.WEB

  const dimensions = {
    width:WIDTH,
    height:HEIGHT
  }


  return (
    <>
      <div style={{ maxWidth: "50%" }}>
        <PermissionCheck>
        <CustomDragUpload allowedTypes={allowedTypes} uploadFile={uploadFile} dimensions={dimensions}/>
        </PermissionCheck>
      </div>
      <div style={{ marginTop: "20px" }} className="table-design">
        <div className="custom-tabs">
          <Tabs
            defaultActiveKey={"1"}
            items={items}
            onChange={onChange}
            animated={true}
            activeKey={activeKey}
          />
        </div>
      </div>
    </>
  );
};

export default Rewards;
