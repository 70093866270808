
import React, { useState } from "react";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

function CopyToClipboard({ userId, position }) {
  const [copiedId, setCopiedId] = useState(null);

  const handleCopyClipboard = async (userId) => {
    if (!userId) return
    navigator.clipboard.writeText(userId).then(() => {
      setCopiedId(userId);
      setTimeout(() => {
        setCopiedId(null);
      }, 1000); // Change delay time according to your preference
    });
  };

  const isCopied = copiedId === userId;
  const text = isCopied ? "Copied" : "Click to copy";

  return (
    <span>
      <Tooltip placement={position} title={text} >
        {
          isCopied ? <CheckOutlined style={{ color: "green" }} /> :
            <CopyOutlined
              onClick={() => handleCopyClipboard(userId)}
              style={{ color: isCopied ? "lightgray" : "inherit", cursor: "pointer" }}
            />
        }

      </Tooltip>

    </span>
  );
}

export default CopyToClipboard;
