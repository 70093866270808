import React, { useState } from "react";
import styles from "./style.module.scss";
import Logo from "../../Assets/Images/Logo.svg";
import InputCustom from "../../Components/InputCustom/InputCustom";
import { Button, Modal, Typography } from "antd";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import { AuthActions } from "../../redux/features/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Validations } from "../../validations";
import { useNavigate } from "react-router-dom";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";
import { FormError } from "../../Components/FormErrors/FormError";
import { CONSTANTS } from "../../constants";
import { Link } from "react-router-dom";
import { AdminActions } from "../../redux/features/admin/adminActions";
import style from "../../Components/SettingComponent/style.module.scss";
import CustomModal from "../../Components/CustomModal/CustomModal";

const { Title } = Typography;
const { MODAL_TEXTS } = CONSTANTS;

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [is2FaModalOpen, setIs2FaModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isSessionEnabled, setIsSessionEnabled] = useState(false);
  const dashboardData = useSelector((state) => state.dashboard);
  const { routes } = dashboardData;
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(Validations.login(is2FaModalOpen)),
    mode: "all",
  });

  const onSubmit = async (data) => {
    setEmail(data.email);
    try {
      await dispatch(startLoading());
      const res = await checkSessionBeforeLogin(data);
      if (res && res.is2FaEnabled && res.isSessionExist) {
        setIsSessionEnabled(true);
        open2FaModal();
      } else if (res && res.isSessionExist === false && res.is2FaEnabled) {
        open2FaModal();
      } else if (res && res.isSessionExist && res.is2FaEnabled === false) {
        openSignInModal();
      } else {
        signIn();
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const checkSessionBeforeLogin = async (data) => {
    try {
      const res = await dispatch(
        AuthActions.checkSessionBeforeLogin(data)
      ).unwrap();
      return res;
    } catch (error) {
      throw error;
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openSignInModal = () => {
    setIsModalOpen(true);
  };

  const open2FaModal = () => {
    setValue("otp", "");
    setIs2FaModalOpen(true);
  };

  const handleOk = () => {
    signIn();
  };

  const signIn = async () => {
    const values = getValues();
    try {
      await dispatch(startLoading());
      await dispatch(AuthActions.login(values)).unwrap();
      const { role } = await dispatch(AdminActions.getDetails()).unwrap();
      if (role && role.rolePermissions.length > 0) {
        const permission = role.rolePermissions[0];
        const {rolePermission} = permission
          let route = CONSTANTS.MODULE_ROUTES[rolePermission.subModule];
          if (route === null || route === undefined)
            route = CONSTANTS.MODULE_ROUTES[rolePermission.module];
          navigate(route);
        
      }

      setIsSessionEnabled(false);
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handlePinCancel = () => {
    setIs2FaModalOpen(false);
  };

  const handlePinVerify = async (values) => {
    try {
      const data = {
        token: values.otp,
        email: email,
      };
      dispatch(startLoading());
      const res = await dispatch(AdminActions.verifyLoginPin(data)).unwrap();

      setIs2FaModalOpen(false);
      if (isSessionEnabled) {
        openSignInModal();
      } else {
        signIn();
      }
      setEmail("");
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const numbersOnly = (e) => {
    if (isNaN(e.key)) e.preventDefault();
  };

  return (
    <>
      <div className={styles.login}>
        <div className={styles.login_left}>
          <img src={Logo} alt="Logo" />
        </div>
        <div className={styles.login_right}>
          <div className={styles.login_right_logo}>
            <img src={Logo} alt="Logo" />
          </div>
          <div className={styles.login_right_data}>
            <h1>Sign in</h1>
            <p className={styles.login_right_data_subheading}>
              Please login to continue to your account.
            </p>
            <div className={styles.login_right_form}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputCustom
                      custommClass={`${styles.innerspacing}`}
                      placeholder={"Email"}
                      type="email"
                      label={"Email"}
                      borderRadius={10}
                      height={46}
                      {...field}
                    />
                  )}
                />
                <FormError message={errors?.email?.message} />
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <InputCustom
                      custommClass={`${styles.innerspacing}`}
                      placeholder={"Password"}
                      type="password"
                      label={"Password"}
                      borderRadius={10}
                      height={46}
                      {...field}
                    />
                  )}
                />
                <FormError message={errors?.password?.message} />
                <Link className="forgetPassword" to="">
                  Reset Password
                </Link>
                {/* <Checkbox>Keep me logged in</Checkbox> */}

                <div className={styles.loginBtn}>
                  <ButtonCommon
                    text="SIGN IN "
                    type="primary"
                    htmlType="submit"
                  />
                  {/* <button className="customBlueBtn">SIGN IN </button> */}
                </div>
              </form>
            </div>
            <p className={styles.login_right_data__text}>
              Contact master-admin if unable to login
            </p>
          </div>
        </div>
        {/* <DevTool control={control} /> */}
      </div>

      <Modal
        className="logout custom-modal"
        width={545}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        maskClosable={false}
      >
        <div className="modal-inner">
          <h3>{MODAL_TEXTS.SIGNIN}</h3>
          <p style={{ margin: "25px 0" }}>Are you Sure?</p>
          <div
            className="logout_btn"
            styles={{
              paddingBottom: "19px",
              paddingTop: "19px",
              textAlign: "center",
            }}
          >
            <ButtonCommon
              text="Cancel"
              width={176}
              height={46}
              transBtn="transBtn"
              custmcls="cursorpointer"
              onClick={handleCancel}
            />
            <ButtonCommon
              type="primary"
              text="Yes"
              width={176}
              onClick={handleOk}
              customBlueBtn="customBlueBtn"
              height={46}
            />
          </div>
        </div>
      </Modal>

      {is2FaModalOpen && (
        <Modal
          open={is2FaModalOpen}
          className=" custom-modal empty"
          width={403}
          centered
          closeIcon={true}
          maskClosable={false}
          footer={null}
        >
          {
            <p style={{ padding: "48px auto 18px auto", textAlign: "center" }}>
              <b>Enter Code</b>
            </p>
          }

          <form onSubmit={handleSubmit(handlePinVerify)} noValidate>
            <Controller
              name="otp"
              control={control}
              render={({ field }) => (
                <InputCustom
                  type="tel"
                  settingInput="settingInput"
                  custommClass1="custommClass1"
                  placeholder={"6-Digit code"}
                  borderRadius={10}
                  height={46}
                  maxlength={6}
                  onKeyPress={(e) => numbersOnly(e)}
                  {...field}
                />
              )}
            />
            <FormError message={errors?.otp?.message} />

            <div className={style.setting_right_input__button}>
              <ButtonCommon
                text="Close"
                key="cancel"
                type="primary"
                danger={true}
                onClick={handlePinCancel}
              />

              <ButtonCommon
                text="Verify"
                key="verifdy"
                type="primary"
                htmlType={"submit"}
              />
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}

export default Login;
