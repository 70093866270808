import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { KYC } = CONSTANTS.API_ENDPOINTS;

const getKycs = createAsyncThunk(
  "kyc/getKycs",
  async ({ offset = 0, limit = 10, filter }, { rejectWithValue, dispatch }) => {
    const { nationality, userType:accountType, startDate, endDate } = filter;
    return Utils.api.baseApiCall(
      {
        endpoint: `${KYC.GET_KYCS}/${offset}/${limit}`,
        data: {},
        method: "GET",
        assignedHeaders:{withToken: true, isMultipart:false, apiAccessKey:"CLIENT_VERIFICATION"},
        params: {
          nationality,
          accountType,
          startDate,
          endDate,
        },
      },
      { rejectWithValue, dispatch }
    );
  }
);

const updateKycStatus = createAsyncThunk(
  "kyc/updateKycStatus",
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: `${KYC.UPDATE_KYC_STATUS}`,
        data,
        method: "POST",
        assignedHeaders:{withToken: true, isMultipart:false, apiAccessKey:"CLIENT_VERIFICATION"},
      },
      { rejectWithValue, dispatch }
    );
  }
);

const getUserDetail = createAsyncThunk(
  "kyc/getUserDetail",
  async ({ userId = -1 }, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${KYC.GET_USER_DETAIL}/${userId}`,
        data: {},
        method: "GET",
        assignedHeaders:{withToken: true, isMultipart:false, apiAccessKey:"CLIENT_VERIFICATION"},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const uploadKycDoc = createAsyncThunk(
  "kyc/uploadKycDoc",
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: `${KYC.UPLOAD_KYC_DOC}`,
        data,
        method: "POST",
        assignedHeaders:{withToken: true, isMultipart:true, apiAccessKey:"CLIENT_VERIFICATION"},
      },
      { rejectWithValue, dispatch }
    );
  }
);

const getKycTxnDetails = createAsyncThunk(
  "kyc/getKycTxnDetails",
  async (data, { rejectWithValue, dispatch }) => {
    const {apiAccessKey, ...rest} = data
    return Utils.api.baseApiCall(
      {
        endpoint: `${KYC.GET_TXN_DETAILS}`,
        data:rest,
        method: "POST",
        assignedHeaders:{withToken: true, isMultipart:false, apiAccessKey:apiAccessKey || "TRANSACTIONS_SCREENING"},
      },
      { rejectWithValue, dispatch }
    );
  }
);

const getKytDocListByTxnId = createAsyncThunk(
  "user/transactions/details/byId",
  async (data, { rejectWithValue, dispatch }) => {
    
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${KYC.GET_KYT_DOC_LIST}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "TRANSACTIONS_SCREENING",
        },
        // params: { coin, clientId, type, apiKey, typeId, from, to },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

export const KycActions = {
  getKycs,
  updateKycStatus,
  getUserDetail,
  uploadKycDoc,
  getKycTxnDetails,
  getKytDocListByTxnId
};
