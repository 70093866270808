const getIndividualQuestionnaire = (data) => [
  {
    text: "Purpose of Account Opening",
    value: data.purposeOfAccountOpen?.value,
    type: "single",
  },
  {
    text: "Estimated Monthly Volume",
    value: data.estimatedMonthlyVolu?.value,
    type: "single",
  },
  {
    text: "Select Source of Wealth",
    value: data.selectSourceOfWealth?.value,
    type: "single",
  },
  {
    text: "Select Source of Fund",
    value: data.selectSourceOfFund?.value,
    type: "single",
  },
];

const getBusinessQuestionnaire = (data) => [
  {
    text: "Business Activity",
    value: data.businessActivity?.values,
    type: "multi",
  },
  { text: "Brand Name", value: data.brandName?.value, type: "single" },
  { text: "Business Name", value: data.businessName?.value, type: "single" },
  { text: "Source of Funds", value: data.sourceOfFunds?.value, type: "single" },
  {
    text: "Purpose of opening the account",
    value: data.purposeOfOpeningTheA?.value,
    type: "single",
  },
  {
    text: "Source of Wealth",
    value: data.sourceOfWealth?.value,
    type: "single",
  },
  {
    text: "Politically-Exposed-Person Declaration",
    value: data.politicallyexposedpe?.value,
    type: "single",
  },
  { text: "Website URL", value: data.websiteUrl?.value, type: "single" },
  {
    text: "Registration number",
    value: data.registrationNumber?.value,
    type: "single",
  },
  {
    text: "Country of Incorporation",
    value: data.countryOfIncorporati?.value,
    type: "single",
  },
  {
    text: "Incorporation Date",
    value: data.incorporationDate?.value,
    type: "single",
  },
  {
    text: "AML Declaration",
    value: data.amlDeclaration?.value,
    type: "single",
  },
  { text: "Type of company", value: data.typeOfCompany?.value, type: "single" },
];

export const QuestionaireService = {
  getIndividualQuestionnaire,
  getBusinessQuestionnaire,
};
