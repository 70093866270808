import React, { useState } from "react";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";
import { Table, Pagination, Button, Space } from "antd";
import {
  CloseOutlined,
  CheckOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice.js";
import { handleHideId, iconColor, iconSize } from "../../../utils/common.utils";
import CopyToClipboard from "../../../Components/CopyToClipboard/CopyToClipboard";
import {
  formatTxnType,
  getUserIdFromTransaction,
} from "../../../services/transaction.service";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FiatTransactionFilters from "./FiatTransactionFilters";
import TransactionDetails from "./TransactionDetails";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { BankingActions } from "../../../redux/features/banking/bankingActions";
import { Utils } from "../../../utils";
import ApproveFiatTxnModal from "./ApproveFiatTxnModal";
import { WalletActions } from "../../../redux/features/wallet/walletActions";
import { KycActions } from "../../../redux/features/kyc/kycActions";
import { setDocList } from "../../../redux/features/kyc/kycSlice";
import { TransactionFeeService } from "../../../services/transaction-fee.service.js";

const Fait = ({ data, totalCount, getFiatTransactions }) => {
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const selectedUserData = useSelector((state) => state.user.data);
  const user = selectedUserData?.user;
  const [filterData, setFilterData] = useState({});
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [clickedTransaction, setClickedTransaction] = useState(null);
  const [showCancelFiat, setShowCancelFiat] = useState(false);
  const [showApproveFiatTxn, setShowApproveFiatTxn] = useState(false);
  const dispatch = useDispatch();
  const { formatFiatValues } = Utils.common;
  const [selectedType, setSelectedType] = useState({
    type: -1,
    isCrypto: 0,
  });

  const navigate = useNavigate();

  const handleCancel = () => {
    setShowTransactionDetails(false);
  };

  const getUploadedDocList = async (payload) => {
    try {
      const data = await dispatch(
        KycActions.getKytDocListByTxnId(payload)
      ).unwrap();
      return data;
    } catch (error) {
      return {};
    }
  };

  const getTransactionDetails = async (obj) => {
    try {
      dispatch(startLoading());
      setClickedTransaction(obj);
      setSelectedType({
        type: obj?.type,
        isCrypto: obj?.isCrypto,
      });
      let type = checkType(obj);
      let res = null;
      if (type === "FIAT") {
        res = await dispatch(
          BankingActions.getFiatTransactionDetails({
            transactionId: obj.txnId,
            apiAccessKey: "FIAT_TRANSACTIONS",
          })
        ).unwrap();
      } else if (type === "SWAP") {
        res = await dispatch(
          WalletActions.getSwapTransactionDetails({
            typeId: obj.id,
            apiAccessKey: "FIAT_TRANSACTIONS",
          })
        ).unwrap();
      }
      let data = {};
      if (res) {
        if (res.data) {
          data = res.data;
        } else {
          data = res;
        }
      }

      let fiatDetailData = res.data;

      let newRes = {};
      if (type === "FIAT") {
        newRes = await dispatch(
          KycActions.getKycTxnDetails({
            txnId: obj?.txnId,
            apiAccessKey: "FIAT_TRANSACTIONS",
          })
        ).unwrap();
      }
      let userId = "";
      if (type === "FIAT") {
        userId = getUserIdFromTransaction(fiatDetailData, type, obj.type);
      } else if (type === "CRYPTO") {
        // userId = getUserIdFromTransaction()
      }

      let uploadDocData = await getUploadedDocList({
        limit: "10",
        offset: "0",
        userId: userId,
        txnId: obj.txnId,
        docType: "ADDITIONAL",
      });

      dispatch(setDocList(uploadDocData));

      navigate("transactions-main-detail", {
        state: {
          data,
          type,
          clickedType: obj?.type,
          sumsubTxnFullPayload: newRes,
          txnId: obj?.txnId,
          userId: userId,
          typeValue : obj.type,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const showCancelFiatTxn = (record) => {
    setShowCancelFiat(true);
    setSelectedTransaction(record);
  };

  const handleCancelFiatTxn = async () => {
    if (showCancelFiat) {
      try {
        dispatch(startLoading());
        const res = await dispatch(
          BankingActions.cancelFiatTxnStatus({
            status: "REJECTED",
            transactionId: selectedTransaction?.txnId,
            apiAccessKey: "CLIENT_DIRECTORY",
          })
        ).unwrap();
        setShowCancelFiat(false);

        let data = {
          clientId:
            location.pathname !== "/fiat-transaction" ? user?.clientId : "",
          coin: "",
          page,
          size: pageSize,
          txStatus: filterData.status || "ALL",
          type: filterData.type || "credit,debit,internal-transfer",
        };
        getFiatTransactions(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(finishLoading());
      }
    }
  };

  const showApprovedFiatModal = (record) => {
    setShowApproveFiatTxn(true);
    setSelectedTransaction(record);
  };

  const columns = [
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (_, record) => {
        return typeof record.currency === "string"
          ? record.currency.toUpperCase()
          : "-";
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        return formatFiatValues(record.amount);
      },
    },
    {
      title: "Type Id",
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        return (
          <>
            {record?.id ? (
              <>
                {handleHideId(record?.id)}
                <CopyToClipboard userId={record?.id} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => {
        return (
          <>
            <p>{formatTxnType(record?.type)}</p>
          </>
        );
      },
    },
    {
      title: "Created on",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => a.created.localeCompare(b.created),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (_, record) => {
        return (
          <>
            <Space className="groupTxnBtn">
              {record &&
                record?.status === "PENDING" &&
                record?.adminApproval == 1 &&
                record?.isCrypto == 0 && 
                !record?.kytStatus &&
                (
                  <ButtonCommon
                    className="filter-btn"
                    reject="approve"
                    width={45}
                    onClick={() => showApprovedFiatModal(record)}
                  >
                    <CheckOutlined />
                  </ButtonCommon>
                )}
              {record &&
                record?.status === "PENDING" &&
                record?.adminApproval == 1 &&
                record?.isCrypto == 0 &&
                !record?.kytStatus &&
                (
                  <ButtonCommon
                    className="filter-btn"
                    reject="reject"
                    onClick={() => showCancelFiatTxn(record)}
                  >
                    <CloseOutlined />
                  </ButtonCommon>
                )}

              <EyeOutlined
                style={{ color: iconColor, fontSize: iconSize }}
                onClick={() => getTransactionDetails(record)}
              />
            </Space>
          </>
        );
      },
    },
  ];

  const columnsAllDetail = [
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    // {
    //   title: "Coin",
    //   dataIndex: "coin",
    //   key: "coin",
    //   sorter: (a, b) => a.created.localeCompare(b.created),
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        let renderedAmount = 0;
        if (clickedTransaction?.type === 15) {
          renderedAmount = record.amountWithFee;
        } else {
          renderedAmount = record.amount;
        }
        return formatFiatValues(renderedAmount);
      },
    },

    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      render: (_, record) => {
        return formatFiatValues(record.fee);
      },
    },

    {
      title: "Txn ID",
      dataIndex: "txnId",
      key: "txnId",
      render: (_, record) => {
        return (
          <>
            {record?.txnId ? (
              <>
                {handleHideId(record?.txnId)}
                <CopyToClipboard userId={record?.txnId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "From",
      dataIndex: "fromAccount",
      key: "fromAccount",
      render: (_, record) => {
        console.log(record, "recordrecordss");
        return record?.fromAccount;
      },
    },
    {
      title: "To",
      dataIndex: "toAccount",
      key: "toAccount",
      render: (_, record) => {
        return record?.toAccount;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const columnsSwapDetail = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
      render: (_, record) => {
        return (
          <>
            {record?.orderId ? (
              <>
                {handleHideId(record?.orderId)}
                <CopyToClipboard userId={record?.orderId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "From Currency",
      dataIndex: "fromCurrency",
      key: "fromCurrency",
    },
    {
      title: "From Amount",
      dataIndex: "fromAmount",
      key: "fromAmount",
    },
    {
      title: "To Amount",
      dataIndex: "toAmount",
      key: "toAmount",
    },

    {
      title: "To Currency",
      dataIndex: "toCurrency",
      key: "toCurrency",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
      render: (_, record) => {
        return (
          <>
            {record?.userId ? (
              <>
                {handleHideId(record?.userId)}
                <CopyToClipboard userId={record?.userId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
    },
  ];

  const checkType = (obj) => {
    let selectedObj = typeof obj !== "undefined" ? obj : selectedType;
    let type = "";
    if (
      selectedObj.type !== 7 &&
      selectedObj.type !== 8 &&
      selectedObj.type !== 16 &&
      selectedObj.type !== 17
    ) {
      if (selectedObj.isCrypto === 1) {
        type = "CRYPTO";
      } else if (selectedObj.isCrypto === 0) {
        type = "FIAT";
      }
    } else {
      type = "SWAP";
    }
    return type;
  };

  const handlePageChange = (page, pageSize) => {
    let data = {
      clientId: location.pathname !== "/fiat-transaction" ? user?.clientId : "",
      coin: "",
      page,
      size: pageSize,
      txStatus: "ALL",
      type: "credit,debit,internal-transfer",
    };

    if (Object.keys(filterData).length > 0) {
      data = { ...filterData, page, pageSize };
    }
    setPage(page);
    setPageSize(pageSize);

    getFiatTransactions(data);
  };

  const onSubmit = async (fiatAmount) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        BankingActions.cancelFiatTxnStatus({
          status: "APPROVED",
          transactionId: selectedTransaction?.txnId,
          amount: fiatAmount,
          apiAccessKey: "CLIENT_DIRECTORY",
        })
      ).unwrap();
      setShowApproveFiatTxn(false);
      if (Object.keys(filterData).length > 0) {
        data = { ...filterData };
      } else {
        data = {
          clientId:
            location.pathname !== "/fiat-transaction" ? user?.clientId : "",
          coin: "",
          page: page || 1,
          size: pageSize || 10,
          txStatus: "ALL",
          type: "credit,debit,internal-transfer",
        };
      }
      getFiatTransactions(data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  return (
    <>
      <div className="table-design">
        <div className="title-filter flexEnd">
          <FiatTransactionFilters
            setFilterData={setFilterData}
            pageSize={pageSize}
            getFiatTransactions={getFiatTransactions}
            clientId={user?.clientId}
            setPage={setPage}
            data={data}
          />
        </div>
        <div className="custom-table">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            className="last-center"
          />
        </div>
      </div>
      <div className="pagination_block">
        <span className="records">Total Records: {totalCount}</span>
        <Pagination
          defaultCurrent={page}
          total={totalCount}
          pageSize={pageSize}
          onChange={handlePageChange}
          current={page}
        />
      </div>

      <CustomModal
        text="More Details"
        title="Completed Requests Details"
        width={"auto"}
        open={showTransactionDetails}
        footer={[
          <Button
            type="primary"
            htmlType="button"
            key="1"
            onClick={handleCancel}
          >
            Close
          </Button>,
        ]}
      >
        {checkType() === "SWAP" && (
          <TransactionDetails
            data={selectedTransaction}
            columns={columnsSwapDetail}
          />
        )}

        {checkType() === "FIAT" && (
          <TransactionDetails
            data={selectedTransaction}
            columns={columnsAllDetail}
          />
        )}
      </CustomModal>
      <CustomModal
        title="Are you sure, you want to cancel this transaction?"
        open={showCancelFiat}
        onOk={handleCancelFiatTxn}
        onCancel={() => setShowCancelFiat(false)}
      ></CustomModal>
      <ApproveFiatTxnModal
        amountToVerify={
          selectedTransaction?.isCrypto
            ? TransactionFeeService.getFeeAmountAfterPrecision(
                selectedTransaction.currency,
                selectedTransaction.amount
              )
            : formatFiatValues(Number(selectedTransaction?.amount))
        }
        showApproveFiatTxn={showApproveFiatTxn}
        setShowApproveFiatTxn={setShowApproveFiatTxn}
        callback={onSubmit}
      />
    </>
  );
};

export default Fait;
