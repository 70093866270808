import { TrxFlag, TrxVolume, User, Vefifieduser } from '../Assets/Icons/Icons.jsx'
import {Utils} from '../utils'

 const {percentageChanged} = Utils.common
const userData = [
    {
      img: <User />,
      NoUser: "No. of Users",
      k: "45k",
      invalue: <span className="green">percent</span>,
      value: (
        <span className="black">
          in last <b>7 Days</b>
        </span>
      ),
      invalue1: <span className="green">percent</span>,
      value1: (
        <span className="black">
          in last <b>30 Days</b>
        </span>
      ),
    },
    {
      img: <Vefifieduser />,
      NoUser: "Verified User",
      k: "7100",
      invalue: "450",
      value: "Individuals",
      invalue1: "650",
      value1: "Business ",
    },
    {
      img: <TrxVolume />,
      NoUser: "Trx Volume",
      k: "7,100K",
      invalue: "1,000.00",
      value: "Fiat",
      invalue1: "6,654.00",
      value1: "Crypto",
    },
    {
      img: <TrxFlag />,
      NoUser: "Trx Flagged",
      k: "7,100K",
      invalue: "50",
      value: "Fiat",
      invalue1: "60",
      value1: "Crypto",
    },
  ];



  const createNoOfUserData = (k, invalueParam, invalueParam1) => {
    const numUsers = { ...userData[0] };
    numUsers.k = k;
    let change1 = percentageChanged(Number(invalueParam), Number(k));
    let change2 = percentageChanged(Number(invalueParam1), Number(k))
    change1 = change1 ? change1.toFixed(2) : 0;
    change2 = change2 ? change2.toFixed(2) : 0;
    numUsers.invalue = {
      content: <span className={`${change1 >= 0 ? 'green' : 'red'}`}>{change1 >= 0 ? '+' : '-'}{change1}%</span>,
      className: "green",
    };
    numUsers.invalue1 = {
      content: <span className={`${change2 >= 0 ? 'green' : 'red'}`}>{change2 >= 0 ? '+' : '-'}{change2}%</span>,
      className: "green",
    };
    return numUsers;
  };

  const createOtherCards = (data, k, invalueParam, invalueParam1) => {
    const numUsers = {...data} ;
    numUsers.k = k;
    numUsers.invalue = {
      content: `${invalueParam}`,
      className: "green",
    };
    numUsers.invalue1 = {
      content: `${invalueParam1}`,
      className: "green",
    };
    return numUsers;
  };



  export const DashboardService = {
    userData,
    createNoOfUserData,
    createOtherCards
  }