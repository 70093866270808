import { CONSTANTS } from "../constants";
import { fetch } from "./fetch.service";

const {
  ENV: { API_URL },
} = CONSTANTS;


function filterParams(params) {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== undefined && value !== "")
  );

  return filteredParams;
}

const makeRequest = async (
  method,
  postfix,
  data = {},
  headers = {},
  params = {}
) => {
  try {
    let fullUrl = "";
    if (params && Object.keys(params).length > 0) {
      const filteredParams = filterParams(params);
      fullUrl = `${API_URL}/${postfix}?${new URLSearchParams(
        filteredParams
      ).toString()}`;
      if(fullUrl.endsWith("?")){
        fullUrl = fullUrl.substring(0,fullUrl.length-1)
        console.log(fullUrl)
    }
    } else {
      fullUrl = `${API_URL}/${postfix}`;
    }
    return fetch(method, fullUrl, data, headers);
  } catch (error) {
    ;
  }
};

const putMethod = (postfix, data = {}, headers = {}, params = {}) =>
  makeRequest("put", postfix, data, headers, params);

const getMethod = (postfix, data = {}, headers = {}, params = {}) =>
  makeRequest("get", postfix, data, headers, params);

const deleteMethod = (postfix, data = {}, headers = {}, params = {}) =>
  makeRequest("delete", postfix, data, headers, params);

const postMethod = (postfix, data = {}, headers = {}, params = {}) =>
  makeRequest("post", postfix, data, headers, params);

  const patchMethod = (postfix, data = {}, headers = {}, params = {}) =>
  makeRequest("patch", postfix, data, headers, params);

export const ApiService = {
  putMethod,
  patchMethod,
  postMethod,
  deleteMethod,
  getMethod,
};
