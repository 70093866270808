import React from "react";
import style from "./style.module.scss";

const ScoreRange = ({currentValue}) => {

  const positionCurrentScoreStyle = () => {
    let percentageShift = 0

    if (currentValue >= 9 && currentValue <= 20) {
      percentageShift =  ((currentValue - 9) / (20 - 9)) * 25;
    } else if (currentValue > 20 && currentValue <= 27) {
      percentageShift =  25 + ((currentValue - 21) / (27 - 21)) * 25;
    } else if (currentValue > 27 && currentValue <= 33) {
      percentageShift =  50 + ((currentValue - 28) / (33 - 28)) * 25;
    } else if (currentValue > 33) {
      percentageShift =  75 + ((currentValue - 34) / (40 - 34)) * 25;
    }

    percentageShift = Math.max(0, Math.min(100, percentageShift));

    return {
      left: `${percentageShift}%`
    }
  }

  return (
    <div className={style.range}>
      <div style={positionCurrentScoreStyle()} className={style.range_currvalue}>{currentValue}</div>
      <div className={style.range_line}>
        <div className={style.range_line_section}></div>
        <div className={style.range_line_section}></div>
        <div className={style.range_line_section}></div>
        <div className={style.range_line_section}></div>
      </div>
      <div>
        <div className={style.range_text}>
          <div className={style.range_text_item}>Low (9-20)</div>
          <div className={style.range_text_item}>Medium (21-27)</div>
          <div className={style.range_text_item}>High(28-33)</div>
          <div className={style.range_text_item}>Unnacceptable (+34)</div>
        </div>
      </div>
    </div>
  );
};

export default ScoreRange;
