import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedClientSupportTab: "Pending",
  selectedKey: "1",
};

export const clientSupportSlice = createSlice({
  name: "clientSupport",
  initialState,
  reducers: {
    setSelecteClientSupportTab: (state, action) => {
      state.selectedClientSupportTab = action.payload;
    },
    setSelectedClientSupportKey: (state, action) => {
      state.selectedKey = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelecteClientSupportTab, setSelectedClientSupportKey } =
  clientSupportSlice.actions;

export default clientSupportSlice.reducer;
