export function TrxFlag() {
  return (
    <span className="search-icon">
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9523 7.91L19.8848 8L19.9523 8.09L22.5023 11.49C22.597 11.6163 22.6547 11.7664 22.6688 11.9237C22.683 12.0809 22.6531 12.2389 22.5825 12.3801C22.5119 12.5213 22.4034 12.6401 22.2691 12.7231C22.1349 12.806 21.9801 12.85 21.8223 12.85H9.82227H9.67227V13V20V20.15H9.82227H10.8223C11.0477 20.15 11.2639 20.2396 11.4233 20.399C11.5827 20.5584 11.6723 20.7746 11.6723 21C11.6723 21.2254 11.5827 21.4416 11.4233 21.601C11.2639 21.7604 11.0477 21.85 10.8223 21.85H6.82227C6.59683 21.85 6.38063 21.7604 6.22123 21.601C6.06182 21.4416 5.97227 21.2254 5.97227 21C5.97227 20.7746 6.06182 20.5584 6.22123 20.399C6.38063 20.2396 6.59683 20.15 6.82227 20.15H7.82227H7.97227V20V3C7.97227 2.77457 8.06182 2.55837 8.22123 2.39896C8.38063 2.23955 8.59683 2.15 8.82227 2.15C9.0477 2.15 9.2639 2.23955 9.42331 2.39896C9.58271 2.55837 9.67227 2.77457 9.67227 3V3.15H9.82227H21.8223C21.9801 3.15 22.1349 3.19396 22.2691 3.27695C22.4034 3.35994 22.5119 3.47868 22.5825 3.61987C22.6531 3.76106 22.683 3.91912 22.6688 4.07633C22.6547 4.23355 22.597 4.38371 22.5023 4.51L19.9523 7.91ZM18.1423 8.51L18.1422 8.50991C18.0317 8.36286 17.972 8.18392 17.972 8C17.972 7.81608 18.0317 7.63714 18.1422 7.49009L18.1423 7.49L19.9423 5.09L20.1223 4.85H19.8223H9.82227H9.67227V5V11V11.15H9.82227H19.8223H20.1223L19.9423 10.91L18.1423 8.51Z"
          fill="#0C253D"
          stroke="#F4F6F9"
          stroke-width="0.3"
        />
      </svg>
    </span>
  );
}
export function TrxVolume() {
  return (
    <span className="search-icon">
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3947_15831)">
          <path
            d="M11.9855 15.6553C11.0663 15.6553 10.1826 15.3928 9.42965 14.8949C7.59565 13.6831 6.84665 11.2401 7.68752 9.21269C8.40152 7.49157 10.1441 6.33569 12.0227 6.33569C12.325 6.33569 12.6282 6.36544 12.924 6.42451C15.0926 6.86026 16.6668 8.78482 16.6668 11.0003C16.6668 12.2052 16.1842 13.3921 15.3442 14.2549C14.4771 15.1452 13.253 15.6553 11.9855 15.6553ZM12.0231 7.21026C10.4936 7.21026 9.07659 8.14913 8.49602 9.54738C7.81265 11.195 8.42165 13.1799 9.91221 14.1652C10.5216 14.5677 11.2387 14.7803 11.9855 14.7803C13.0185 14.7812 14.0138 14.3669 14.7168 13.6446C15.4002 12.9428 15.7918 11.979 15.7918 10.9999C15.7918 9.22844 14.485 7.63069 12.7516 7.28201C12.5127 7.23432 12.2673 7.21026 12.0231 7.21026Z"
            fill="#0C253D"
          />
          <path
            d="M11.999 13.8438C11.7575 13.8438 11.5615 13.6482 11.5615 13.4062V12.5312C11.5615 12.2893 11.7575 12.0938 11.999 12.0938C12.2405 12.0938 12.4365 12.2893 12.4365 12.5312V13.4062C12.4365 13.6482 12.2405 13.8438 11.999 13.8438Z"
            fill="#0C253D"
          />
          <path
            d="M12.001 9.90625C11.7595 9.90625 11.5635 9.71069 11.5635 9.46875V8.59375C11.5635 8.35181 11.7595 8.15625 12.001 8.15625C12.2425 8.15625 12.4385 8.35181 12.4385 8.59375V9.46875C12.4385 9.71069 12.2425 9.90625 12.001 9.90625Z"
            fill="#0C253D"
          />
          <path
            d="M12.9834 12.9688H11.0146C10.7731 12.9688 10.5771 12.7732 10.5771 12.5312C10.5771 12.2893 10.7731 12.0938 11.0146 12.0938H12.5459V11.4375H11.0164C10.7749 11.4375 10.5789 11.2419 10.5789 11V9.46875C10.5789 9.22681 10.7749 9.03125 11.0164 9.03125H12.9851C13.2271 9.03125 13.4226 9.22681 13.4226 9.46875C13.4226 9.71069 13.2271 9.90625 12.9851 9.90625H11.4539V10.5625H12.9834C13.2253 10.5625 13.4209 10.7581 13.4209 11V12.5312C13.4209 12.7732 13.2253 12.9688 12.9834 12.9688Z"
            fill="#0C253D"
          />
          <path
            d="M7.77343 7.20776H4.70874C4.46724 7.20776 4.27124 7.0122 4.27124 6.77026C4.27124 6.52833 4.46724 6.33276 4.70874 6.33276H7.77343C8.01493 6.33276 8.21093 6.52833 8.21093 6.77026C8.21093 7.0122 8.01493 7.20776 7.77343 7.20776Z"
            fill="#0C253D"
          />
          <path
            d="M19.2919 15.6663H16.2268C15.9849 15.6663 15.7893 15.4707 15.7893 15.2288C15.7893 14.9868 15.9849 14.7913 16.2268 14.7913H19.2919C19.5339 14.7913 19.7294 14.9868 19.7294 15.2288C19.7294 15.4707 19.5339 15.6663 19.2919 15.6663Z"
            fill="#0C253D"
          />
          <path
            d="M4.70819 8.37508C4.7025 8.37508 4.69725 8.37508 4.69156 8.3742C4.58394 8.3707 4.48637 8.32826 4.41244 8.25958L1.20556 5.34408C1.11456 5.26139 1.0625 5.14414 1.0625 5.02033C1.0625 4.89739 1.11456 4.77926 1.20556 4.69658L4.41375 1.7802C4.5415 1.66383 4.72613 1.6332 4.88538 1.70408C5.04375 1.77408 5.14569 1.9307 5.14569 2.10395V2.83283H8.35388C8.59538 2.83283 8.79138 3.02839 8.79138 3.27033C8.79138 3.51226 8.59538 3.70783 8.35388 3.70783H4.70819C4.46669 3.70783 4.27069 3.51226 4.27069 3.27033V3.0927L2.15013 5.02033L4.27069 6.94795V6.77033C4.27069 6.52839 4.46669 6.33283 4.70819 6.33283C4.94969 6.33283 5.14569 6.52839 5.14569 6.77033V7.93758V7.93845V7.93933C5.14525 8.0487 5.105 8.14801 5.0385 8.2237C5.02012 8.24514 4.99956 8.26483 4.97725 8.28189C4.90987 8.33483 4.82631 8.36808 4.73575 8.3742C4.72656 8.3742 4.71738 8.37508 4.70819 8.37508Z"
            fill="#0C253D"
          />
          <path
            d="M16.2292 11.5828C15.9873 11.5828 15.7917 11.3872 15.7917 11.1453C15.7917 7.04458 12.4554 3.70776 8.35425 3.70776C8.11275 3.70776 7.91675 3.5122 7.91675 3.27026C7.91675 3.02833 8.11275 2.83276 8.35425 2.83276C12.9379 2.83276 16.6667 6.56158 16.6667 11.1453C16.6667 11.3872 16.4712 11.5828 16.2292 11.5828Z"
            fill="#0C253D"
          />
          <path
            d="M15.6458 19.1663C11.0621 19.1663 7.33325 15.4374 7.33325 10.8538C7.33325 10.6118 7.52925 10.4163 7.77075 10.4163C8.01225 10.4163 8.20825 10.6118 8.20825 10.8538C8.20825 14.9544 11.5446 18.2913 15.6458 18.2913C15.8877 18.2913 16.0833 18.4868 16.0833 18.7288C16.0833 18.9707 15.8877 19.1663 15.6458 19.1663Z"
            fill="#0C253D"
          />
          <path
            d="M19.2919 20.3327C19.2319 20.3327 19.172 20.3208 19.1151 20.295C18.9568 20.2259 18.8544 20.0684 18.8544 19.8952V19.1663H15.6458C15.4038 19.1663 15.2083 18.9707 15.2083 18.7288C15.2083 18.4868 15.4038 18.2913 15.6458 18.2913H19.2919C19.5338 18.2913 19.7294 18.4868 19.7294 18.7288V18.9064L21.8499 16.9788L19.7294 15.0511V15.2288C19.7294 15.4707 19.5338 15.6663 19.2919 15.6663C19.0499 15.6663 18.8544 15.4707 18.8544 15.2288V14.0624C18.8544 14.0615 18.8544 14.0615 18.8544 14.0615C18.8544 14.0615 18.8544 14.0615 18.8544 14.0606C18.8544 14.0606 18.8544 14.0607 18.8544 14.0598C18.8548 13.9504 18.8959 13.8498 18.9633 13.7737C18.9804 13.754 18.9988 13.736 19.0193 13.7198C19.088 13.6652 19.1746 13.6302 19.2683 13.6249C19.2809 13.624 19.2936 13.6249 19.3054 13.6249C19.4144 13.6284 19.5128 13.6708 19.5876 13.7395L22.7945 16.655C22.8855 16.7377 22.9376 16.855 22.9376 16.9788C22.9376 17.1017 22.8855 17.2198 22.7945 17.3025L19.5863 20.2189C19.5041 20.2933 19.3986 20.3327 19.2919 20.3327Z"
            fill="#0C253D"
          />
          <path
            d="M22.5 0.9375H17.25C17.0081 0.9375 16.8125 0.741938 16.8125 0.5C16.8125 0.258063 17.0081 0.0625 17.25 0.0625H22.5C22.7419 0.0625 22.9375 0.258063 22.9375 0.5C22.9375 0.741938 22.7419 0.9375 22.5 0.9375Z"
            fill="#0C253D"
          />
          <path
            d="M22.5 3.5625H17.25C17.0081 3.5625 16.8125 3.36694 16.8125 3.125C16.8125 2.88306 17.0081 2.6875 17.25 2.6875H22.5C22.7419 2.6875 22.9375 2.88306 22.9375 3.125C22.9375 3.36694 22.7419 3.5625 22.5 3.5625Z"
            fill="#0C253D"
          />
          <path
            d="M22.5 6.1875H17.25C17.0081 6.1875 16.8125 5.99194 16.8125 5.75C16.8125 5.50806 17.0081 5.3125 17.25 5.3125H22.5C22.7419 5.3125 22.9375 5.50806 22.9375 5.75C22.9375 5.99194 22.7419 6.1875 22.5 6.1875Z"
            fill="#0C253D"
          />
          <path
            d="M6.75 21.9375H1.5C1.2585 21.9375 1.0625 21.7419 1.0625 21.5C1.0625 21.2581 1.2585 21.0625 1.5 21.0625H6.75C6.9915 21.0625 7.1875 21.2581 7.1875 21.5C7.1875 21.7419 6.9915 21.9375 6.75 21.9375Z"
            fill="#0C253D"
          />
          <path
            d="M6.75 19.3125H1.5C1.2585 19.3125 1.0625 19.1169 1.0625 18.875C1.0625 18.6331 1.2585 18.4375 1.5 18.4375H6.75C6.9915 18.4375 7.1875 18.6331 7.1875 18.875C7.1875 19.1169 6.9915 19.3125 6.75 19.3125Z"
            fill="#0C253D"
          />
          <path
            d="M6.75 16.6875H1.5C1.2585 16.6875 1.0625 16.4919 1.0625 16.25C1.0625 16.0081 1.2585 15.8125 1.5 15.8125H6.75C6.9915 15.8125 7.1875 16.0081 7.1875 16.25C7.1875 16.4919 6.9915 16.6875 6.75 16.6875Z"
            fill="#0C253D"
          />
        </g>
        <defs>
          <clipPath id="clip0_3947_15831">
            <rect width="24" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
export function User() {
  return (
    <span className="search-icon">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
          stroke="#0C253D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
          stroke="#0C253D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 21.0001V19.0001C22.9993 18.1139 22.7044 17.2529 22.1614 16.5524C21.6184 15.852 20.8581 15.3517 20 15.1301"
          stroke="#0C253D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 3.13013C16.8604 3.35043 17.623 3.85083 18.1676 4.55244C18.7122 5.25405 19.0078 6.11696 19.0078 7.00513C19.0078 7.8933 18.7122 8.75621 18.1676 9.45782C17.623 10.1594 16.8604 10.6598 16 10.8801"
          stroke="#0C253D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
export function Vefifieduser() {
  return (
    <span className="search-icon">
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.4142 17.9062C7.61108 17.4544 7.89326 17.0381 8.24858 16.6828C8.99576 15.9356 10.0092 15.5156 11.0658 15.5156H14.8158C15.8723 15.5156 16.8858 15.9356 17.6329 16.6828C17.9883 17.0381 18.2705 17.4544 18.4673 17.9062C18.623 18.2616 19.0373 18.4247 19.3936 18.2691C19.7489 18.1144 19.912 17.6991 19.7564 17.3438C19.4892 16.7316 19.1086 16.1691 18.6276 15.6881C17.617 14.6775 16.2455 14.1094 14.8158 14.1094C13.597 14.1094 12.2845 14.1094 11.0658 14.1094C9.63608 14.1094 8.26451 14.6775 7.25389 15.6881C6.77295 16.1691 6.39233 16.7316 6.12514 17.3438C5.96951 17.6991 6.13264 18.1144 6.48795 18.2691C6.8442 18.4247 7.25858 18.2616 7.4142 17.9062Z"
          fill="#0C253D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9407 4.73438C9.96505 4.73438 7.55005 7.14937 7.55005 10.125C7.55005 13.1006 9.96505 15.5156 12.9407 15.5156C15.9163 15.5156 18.3313 13.1006 18.3313 10.125C18.3313 7.14937 15.9163 4.73438 12.9407 4.73438ZM12.9407 6.14062C15.14 6.14062 16.925 7.92563 16.925 10.125C16.925 12.3244 15.14 14.1094 12.9407 14.1094C10.7413 14.1094 8.9563 12.3244 8.9563 10.125C8.9563 7.92563 10.7413 6.14062 12.9407 6.14062Z"
          fill="#0C253D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.1547 9.36281C23.3722 10.2056 23.4875 11.0897 23.4875 12C23.4875 17.8209 18.7616 22.5469 12.9407 22.5469C7.11974 22.5469 2.3938 17.8209 2.3938 12C2.3938 6.17906 7.11974 1.45312 12.9407 1.45312C13.851 1.45312 14.735 1.56844 15.5779 1.78594C15.9538 1.8825 16.3372 1.65656 16.4338 1.28062C16.5313 0.904687 16.3044 0.52125 15.9285 0.42375C14.9732 0.178125 13.9719 0.046875 12.9407 0.046875C6.34349 0.046875 0.987549 5.40281 0.987549 12C0.987549 18.5972 6.34349 23.9531 12.9407 23.9531C19.5379 23.9531 24.8938 18.5972 24.8938 12C24.8938 10.9688 24.7625 9.9675 24.5169 9.01219C24.4194 8.63625 24.036 8.40938 23.66 8.50688C23.2841 8.60344 23.0582 8.98688 23.1547 9.36281Z"
          fill="#0C253D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.6057 5.95215V7.40434C20.6057 7.79246 20.9207 8.10746 21.3088 8.10746C21.697 8.10746 22.012 7.79246 22.012 7.40434V5.95215C22.012 5.56402 21.697 5.24902 21.3088 5.24902C20.9207 5.24902 20.6057 5.56402 20.6057 5.95215Z"
          fill="#0C253D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.3336 4.40626L18.0764 5.13189C17.7398 5.32595 17.6245 5.75626 17.8186 6.09282C18.0126 6.42845 18.443 6.54376 18.7795 6.3497L20.0367 5.62407C20.3733 5.43001 20.4886 4.9997 20.2945 4.66314C20.1005 4.32751 19.6701 4.2122 19.3336 4.40626Z"
          fill="#0C253D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.0367 2.53123L18.7795 1.80467C18.443 1.6106 18.0126 1.72592 17.8186 2.06248C17.6245 2.3981 17.7398 2.82842 18.0764 3.02248L19.3336 3.74904C19.6701 3.9431 20.1005 3.82779 20.2945 3.49123C20.4886 3.1556 20.3733 2.72529 20.0367 2.53123Z"
          fill="#0C253D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.012 2.20219V0.75C22.012 0.361875 21.697 0.046875 21.3088 0.046875C20.9207 0.046875 20.6057 0.361875 20.6057 0.75V2.20219C20.6057 2.59031 20.9207 2.90531 21.3088 2.90531C21.697 2.90531 22.012 2.59031 22.012 2.20219Z"
          fill="#0C253D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.2842 3.74904L24.5424 3.02248C24.878 2.82842 24.9933 2.3981 24.8002 2.06248C24.6061 1.72592 24.1749 1.6106 23.8392 1.80467L22.5811 2.53123C22.2455 2.72529 22.1302 3.1556 22.3242 3.49123C22.5183 3.82779 22.9486 3.9431 23.2842 3.74904Z"
          fill="#0C253D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.5811 5.62407L23.8392 6.3497C24.1749 6.54376 24.6061 6.42845 24.8002 6.09282C24.9933 5.75626 24.878 5.32595 24.5424 5.13189L23.2842 4.40626C22.9486 4.2122 22.5183 4.32751 22.3242 4.66314C22.1302 4.9997 22.2455 5.43001 22.5811 5.62407Z"
          fill="#0C253D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.3088 1.49902C19.8867 1.49902 18.7307 2.65402 18.7307 4.07715C18.7307 5.50027 19.8867 6.65527 21.3088 6.65527C22.732 6.65527 23.887 5.50027 23.887 4.07715C23.887 2.65402 22.732 1.49902 21.3088 1.49902ZM21.3088 2.90527C21.9557 2.90527 22.4807 3.43027 22.4807 4.07715C22.4807 4.72402 21.9557 5.24902 21.3088 5.24902C20.6629 5.24902 20.137 4.72402 20.137 4.07715C20.137 3.43027 20.6629 2.90527 21.3088 2.90527Z"
          fill="#0C253D"
        />
      </svg>
    </span>
  );
}
export function logobg() {
  return (
    <svg
      width="146"
      height="142"
      viewBox="0 0 146 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3" clip-path="url(#clip0_3947_14410)">
        <path
          d="M90.1038 -35.6951C78.5165 -35.2014 66.8572 -32.4508 55.8456 -27.3023C44.7621 -22.1538 35.3339 -15.0306 27.705 -6.63783L90.1038 42.167V-35.6951Z"
          stroke="#E3E3E3"
          stroke-width="2"
        />
        <path
          d="M174.096 13.3204C163.589 -8.11985 145.092 -23.3537 123.789 -30.7591V110.648L10.4346 22.0658C2.94964 42.8713 3.59735 66.4979 14.1771 87.9382C35.3365 130.96 88.3071 149.156 132.425 128.562C176.543 107.968 195.256 56.3421 174.096 13.3204Z"
          stroke="#E3E3E3"
          stroke-width="2"
        />
        <path
          d="M108.526 98.6593L34.8281 41.0385C32.8849 52.464 34.3963 64.5946 39.9381 75.879C53.6845 103.949 87.3669 116.362 116.587 104.936L108.598 98.6593H108.526Z"
          stroke="#E3E3E3"
          stroke-width="2"
        />
        <path
          d="M90.1069 -7.83791C82.6939 -7.34422 75.2808 -5.51051 68.1557 -2.26625C61.1026 1.04853 55.057 5.49174 50.0191 10.7813L90.1069 42.1659V-7.83791Z"
          stroke="#E3E3E3"
          stroke-width="2"
        />
        <path
          d="M148.401 25.3128C142.859 14.099 134.151 5.35363 123.859 -0.359073V101.482C151.136 86.46 162.147 53.1711 148.401 25.2423V25.3128Z"
          stroke="#E3E3E3"
          stroke-width="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_3947_14410">
          <rect width="146" height="142" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function NotificationIcon() {
  return (
    <span className="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
      >
        <g clip-path="url(#clip0_3947_513)">
          <path
            d="M8.47327 15.5348C8.99383 15.5348 9.49308 15.3313 9.86117 14.969C10.2293 14.6066 10.4361 14.1152 10.4361 13.6028H6.51048C6.51048 14.1152 6.71728 14.6066 7.08537 14.969C7.45346 15.3313 7.95271 15.5348 8.47327 15.5348ZM9.44976 1.14037C9.46345 1.00605 9.4484 0.870394 9.40556 0.742153C9.36272 0.613913 9.29306 0.495934 9.20105 0.395825C9.10905 0.295717 8.99674 0.215701 8.87139 0.160938C8.74604 0.106175 8.61042 0.0778809 8.47327 0.0778809C8.33613 0.0778809 8.2005 0.106175 8.07515 0.160938C7.9498 0.215701 7.8375 0.295717 7.74549 0.395825C7.65349 0.495934 7.58382 0.613913 7.54098 0.742153C7.49814 0.870394 7.48309 1.00605 7.49678 1.14037C6.38753 1.36245 5.39034 1.95506 4.67407 2.81784C3.95779 3.68062 3.56645 4.76055 3.5663 5.87476C3.5663 6.93544 3.07561 11.6708 1.60352 12.6368H15.343C13.8709 11.6708 13.3802 6.93544 13.3802 5.87476C13.3802 3.53703 11.6922 1.5857 9.44976 1.14037Z"
            fill="#FFB800"
          />
        </g>
        <defs>
          <clipPath id="clip0_3947_513">
            <rect
              x="0.745117"
              y="0.0778809"
              width="15.4569"
              height="15.4569"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
export function MessageIcon() {
  return (
    <span className="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
      >
        <path
          d="M14.0551 1.78198H3.75048C3.04204 1.78198 2.4624 2.36388 2.4624 3.07509V13.1549C2.4624 13.7303 3.15797 14.0213 3.56371 13.614L5.03856 12.1269H14.0551C14.7636 12.1269 15.3432 11.545 15.3432 10.8338V3.07509C15.3432 2.36388 14.7636 1.78198 14.0551 1.78198Z"
          fill="#FFB800"
        />
      </svg>
    </span>
  );
}
export function DollarIcon() {
  return (
    <span className="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
      >
        <path
          d="M11.25 3V21"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.8747 5H8.93721C8.07857 5 7.2551 5.36875 6.64795 6.02513C6.0408 6.6815 5.69971 7.57174 5.69971 8.5C5.69971 9.42826 6.0408 10.3185 6.64795 10.9749C7.2551 11.6313 8.07857 12 8.93721 12H13.5622C14.4208 12 15.2443 12.3687 15.8515 13.0251C16.4586 13.6815 16.7997 14.5717 16.7997 15.5C16.7997 16.4283 16.4586 17.3185 15.8515 17.9749C15.2443 18.6313 14.4208 19 13.5622 19H5.69971"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
export function ReceiveIcon() {
  return (
    <span className="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
      >
        <path
          d="M3.8501 20L18.6501 4"
          stroke="#14FF00"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.3999 4H18.6499V14"
          stroke="#14FF00"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
export function SendIcon() {
  return (
    <span className="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
      >
        <path
          d="M4.7749 20L19.5749 4"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.1001 21L3.8501 21L3.8501 11"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
export function CartIcon() {
  return (
    <span className="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
      >
        <path
          d="M17.7248 21C18.2357 21 18.6498 20.5523 18.6498 20C18.6498 19.4477 18.2357 19 17.7248 19C17.2139 19 16.7998 19.4477 16.7998 20C16.7998 20.5523 17.2139 21 17.7248 21Z"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.4001 21C9.91096 21 10.3251 20.5523 10.3251 20C10.3251 19.4477 9.91096 19 9.4001 19C8.88923 19 8.4751 19.4477 8.4751 20C8.4751 20.5523 8.88923 21 9.4001 21Z"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2 3H5.36364L7.61727 13.7117C7.69417 14.08 7.90479 14.4109 8.21225 14.6464C8.51972 14.8818 8.90445 15.0069 9.29909 14.9997H17.4727C17.8674 15.0069 18.2521 14.8818 18.5596 14.6464C18.867 14.4109 19.0776 14.08 19.1545 13.7117L20.5 6.9999H6.20455"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
export function TeaIcon() {
  return (
    <span className="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="21"
        viewBox="0 0 23 21"
        fill="none"
      >
        <path
          d="M16.8267 9H17.5667C18.3517 9 19.1046 9.36875 19.6597 10.0251C20.2148 10.6815 20.5267 11.5717 20.5267 12.5C20.5267 13.4283 20.2148 14.3185 19.6597 14.9749C19.1046 15.6313 18.3517 16 17.5667 16H16.8267"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.95166 9H16.8267V17.3077C16.8267 18.287 16.4612 19.2261 15.8107 19.9185C15.1602 20.611 14.2779 21 13.3579 21H6.42041C5.50044 21 4.61815 20.611 3.96763 19.9185C3.31712 19.2261 2.95166 18.287 2.95166 17.3077V9Z"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.1001 3V6"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.3999 3V6"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.69971 3V6"
          stroke="#FC1E64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
export function CopyIcon() {
  return (
    <span className="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <g clip-path="url(#clip0_3947_400)">
          <path
            d="M3.83325 6.91064C3.83325 5.26098 3.83325 4.43556 4.346 3.92339C4.85817 3.41064 5.68359 3.41064 7.33325 3.41064H9.08325C10.7329 3.41064 11.5583 3.41064 12.0705 3.92339C12.5833 4.43556 12.5833 5.26098 12.5833 6.91064V9.82731C12.5833 11.477 12.5833 12.3024 12.0705 12.8146C11.5583 13.3273 10.7329 13.3273 9.08325 13.3273H7.33325C5.68359 13.3273 4.85817 13.3273 4.346 12.8146C3.83325 12.3024 3.83325 11.477 3.83325 9.82731V6.91064Z"
            stroke="black"
            stroke-width="1.5"
          />
          <path
            opacity="0.5"
            d="M3.83325 11.5773C3.36912 11.5773 2.924 11.3929 2.59582 11.0647C2.26763 10.7366 2.08325 10.2914 2.08325 9.82731V6.32731C2.08325 4.12756 2.08325 3.02739 2.76692 2.34431C3.45 1.66064 4.55017 1.66064 6.74992 1.66064H9.08325C9.54738 1.66064 9.9925 1.84502 10.3207 2.17321C10.6489 2.5014 10.8333 2.94652 10.8333 3.41064"
            stroke="black"
            stroke-width="1.5"
          />
        </g>
        <defs>
          <clipPath id="clip0_3947_400">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(0.333252 0.493896)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
export function EyeIcon() {
  return (
    <span className="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.1127 12.7536C17.6987 15.2928 15.0172 16.8702 12.1159 16.8702C9.21283 16.8702 6.53133 15.2928 5.11732 12.7536C5.00842 12.5564 5.00842 12.348 5.11732 12.151C6.53133 9.61186 9.21283 8.03467 12.1159 8.03467C15.0172 8.03467 17.6987 9.61186 19.1127 12.151C19.2235 12.348 19.2235 12.5564 19.1127 12.7536ZM20.0159 11.6476C18.4198 8.78072 15.3927 7 12.1159 7C8.83726 7 5.81022 8.78072 4.21407 11.6476C3.92864 12.1591 3.92864 12.7455 4.21407 13.2567C5.81022 16.1235 8.83726 17.9046 12.1159 17.9046C15.3927 17.9046 18.4198 16.1235 20.0159 13.2567C20.3014 12.7456 20.3014 12.1591 20.0159 11.6476ZM12.1159 14.5747C13.2858 14.5747 14.2379 13.6227 14.2379 12.4522C14.2379 11.2818 13.2858 10.3297 12.1159 10.3297C10.9442 10.3297 9.99212 11.2818 9.99212 12.4522C9.99212 13.6227 10.9442 14.5747 12.1159 14.5747ZM12.1159 9.29508C10.3733 9.29508 8.95745 10.7115 8.95745 12.4523C8.95745 14.1934 10.3733 15.6093 12.1159 15.6093C13.8567 15.6093 15.2725 14.1934 15.2725 12.4523C15.2725 10.7115 13.8567 9.29508 12.1159 9.29508Z"
          fill="#2A85DC"
        />
      </svg>
    </span>
  );
}

export function DeleteIcon() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.51613 15.4194V10.9032C9.51613 10.7236 9.5875 10.5513 9.71454 10.4242C9.84158 10.2972 10.0139 10.2258 10.1935 10.2258C10.3732 10.2258 10.5455 10.2972 10.6726 10.4242C10.7996 10.5513 10.871 10.7236 10.871 10.9032V15.4194C10.871 15.599 10.7996 15.7713 10.6726 15.8984C10.5455 16.0254 10.3732 16.0968 10.1935 16.0968C10.0139 16.0968 9.84158 16.0254 9.71454 15.8984C9.5875 15.7713 9.51613 15.599 9.51613 15.4194ZM13.8065 16.0968C13.9861 16.0968 14.1584 16.0254 14.2855 15.8984C14.4125 15.7713 14.4839 15.599 14.4839 15.4194V10.9032C14.4839 10.7236 14.4125 10.5513 14.2855 10.4242C14.1584 10.2972 13.9861 10.2258 13.8065 10.2258C13.6268 10.2258 13.4545 10.2972 13.3274 10.4242C13.2004 10.5513 13.129 10.7236 13.129 10.9032V15.4194C13.129 15.7933 13.4325 16.0968 13.8065 16.0968ZM19 6.3871C19 6.56676 18.9286 6.73906 18.8016 6.8661C18.6745 6.99314 18.5022 7.06452 18.3226 7.06452H18.0968V18.129C18.0963 18.7876 17.8344 19.4192 17.3687 19.8849C16.903 20.3506 16.2715 20.6124 15.6129 20.6129H8.3871C7.72848 20.6124 7.09698 20.3506 6.63126 19.8849C6.16555 19.4192 5.9037 18.7876 5.90323 18.129V7.06452H5.67742C5.49776 7.06452 5.32545 6.99314 5.19841 6.8661C5.07137 6.73906 5 6.56676 5 6.3871C5 6.20743 5.07137 6.03513 5.19841 5.90809C5.32545 5.78105 5.49776 5.70968 5.67742 5.70968H8.6129V5.48387C8.61338 4.82525 8.87523 4.19375 9.34094 3.72804C9.80665 3.26232 10.4382 3.00048 11.0968 3H12.9032C13.5618 3.00048 14.1933 3.26232 14.6591 3.72804C15.1248 4.19375 15.3866 4.82525 15.3871 5.48387V5.70968H18.3226C18.5022 5.70968 18.6745 5.78105 18.8016 5.90809C18.9286 6.03513 19 6.20743 19 6.3871ZM9.96774 5.70968H14.0323V5.48387C14.0323 4.86155 13.5255 4.35484 12.9032 4.35484H11.0968C10.4745 4.35484 9.96774 4.86155 9.96774 5.48387V5.70968ZM16.7419 7.06452H7.25806V18.129C7.25806 18.7514 7.76477 19.2581 8.3871 19.2581H15.6129C16.2352 19.2581 16.7419 18.7514 16.7419 18.129V7.06452Z"
          fill="red"
        />
      </svg>
    </>
  );
}
