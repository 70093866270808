import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PublicRoutes = () => {
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.dashboard.routes);

  let routeToRedirect = roles && roles.length > 0 ? roles[0]?.route : "/dashboard";  
  
  return auth.isLoggedIn && auth.jwtToken ? (
    <Navigate to={routeToRedirect} replace />
    ) : (
      <Outlet />
  );
};