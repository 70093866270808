import React from "react";
import { Modal } from "antd";
const CustomModal = (props) => {
  const { title, children, footer, onCancel, onOk, open, width } = props;

  return (
    <>
      <Modal
        className="custom-modal small-btn"
        closable={null}
        title={title}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        footer={footer}
        centered
        width={width}
        
      >
        {children}
      </Modal>
    </>
  );
};
export default CustomModal;
