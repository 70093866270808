import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import FaitTransactions from "./FaitTransactions";
import NonReferenceTransactions from "./NonReferenceTransactions";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedFiatTab } from "../../../redux/features/nonReference/nonReferenceSlice";

function FiatStransactionScreening() {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("1");
  const selectedFiatTab = useSelector(
    (state) => state?.nonReferences?.selectedFiatTab
  );
  const onChange = (key) => {
    if (key == "1") {
      dispatch(setSelectedFiatTab("Fiat Transactions"));
    }
    if (key == "2") {
      dispatch(setSelectedFiatTab("Un-Mapped-Transactions"));
    }
  };

  useEffect(() => {
    if (selectedFiatTab === "Fiat Transactions") {
      setActiveKey("1");
    }
    if (selectedFiatTab === "Un-Mapped-Transactions") {
      setActiveKey("2");
    }
  }, [selectedFiatTab]);

  const items = [
    {
      key: "1",
      label: "  Fiat Transactions ",
      children: <FaitTransactions activeKey={activeKey} />,
    },
    {
      key: "2",
      label: "Un-mapped-transactions ",
      children: <NonReferenceTransactions activeKey={activeKey} />,
    },
  ];

  return (
    <>
      <div className="Custom-tabBtn">
        <Tabs
          defaultActiveKey={"1"}
          activeKey={activeKey}
          items={items}
          onChange={onChange}
          animated={true}
        />
      </div>
    </>
  );
}

export default FiatStransactionScreening;
