import React from "react";
import { Modal } from "antd";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { nonReferenceActions } from "../../../redux/features/nonReference/nonReferenceActions";

function RejectReferenceTransaction({
  showRejectReferenceModal,
  setShowRejectReferenceModal,
  getNonReferenceDetails,
  detail,
  page,
  pageSize,
}) {
  const dispatch = useDispatch();
  const handleCancel = () => {
    setShowRejectReferenceModal(false);
  };

  const handleUpdatedResponse = () => {
    const data = {
      apiAccessKey: "FIAT_TRANSACTIONS",
      offset: page,
      limit: pageSize,
    };
    getNonReferenceDetails(data);
    setShowRejectReferenceModal(false);
  };
  const handleOk = async () => {
    try {
      const data = {
        transactionId: detail.txnId,
        status: "REJECTED",
        apiAccessKey: "FIAT_TRANSACTIONS",
      };

      dispatch(startLoading());

      let res = await dispatch(
        nonReferenceActions.updateNonReferenceTxn(data)
      ).unwrap();
      if (res) {
        handleUpdatedResponse();
      }
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  return (
    <div>
      <Modal
        className="logout custom-modal"
        width={545}
        open={showRejectReferenceModal}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        maskClosable={false}
      >
        <div className="modal-inner">
          <h3>Are you sure want to decline?</h3>
          <p></p>
          <div className="logout_btn">
            <ButtonCommon
              text="No"
              width={176}
              height={46}
              transBtn="transBtn"
              custmcls="cursorpointer"
              onClick={handleCancel}
            />
            <ButtonCommon
              type="primary"
              text="Yes"
              width={176}
              onClick={handleOk}
              customBlueBtn="customBlueBtn"
              height={46}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default RejectReferenceTransaction;
