import { Modal } from 'antd'
import React from 'react'

const KycApproveModal = ({isModalOpen, handleCancel, handleApprove}) => {



  return (
    <Modal
        className="custom-modal verificationmodal"
        okText="Approve"
        width={403}
        open={isModalOpen}
        onOk={handleApprove}
        onCancel={handleCancel}
        centered
        closeIcon={true}
        maskClosable={false}
      >
        <p style={{ padding: "48px auto 18px auto", textAlign: "center" }}>
          Are you sure you want to approve?
        </p>
      </Modal>
  )
}

export default KycApproveModal