import { Table } from "antd";
import React from "react";
import { handleHideId } from "../../../utils/common.utils";
import { formatTxnType } from "../../../services/transaction.service";
import { CONSTANTS } from "../../../constants";

const TransactionDetails = ({ data, columns }) => {
  console.log({data, columns})
  const getTransactionData = (data) => {
    const arr = [];
    arr.push(data);
    return arr;
  };

  const getColumns = () => {
    return columns.filter((x) => x.title !== "Action");
  };

  return (
    <div>
      <div>{/* <CloseButtonModal handleCancel={handleCancel} /> */}</div>
      <div>
        <Table
          dataSource={getTransactionData(data)}
          columns={getColumns(columns)}
          pagination={false}
          //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
        />
      </div>
    </div>
  );
};

export default TransactionDetails;
