import React from "react";
import { Col, Typography } from "antd";

const QuestionaireData = ({ text, value, type }) => {
  const { Title, Text } = Typography;
  return (
    <>
      {type === "single" && (
        <li>
          <span ><b>{text}:-</b> </span>
          <span>{value ? value : "N.A"}</span>
        </li>
      )}

      {type === "multi" && (
        <li>
          <span><b>{text}:-</b></span>
          {
            value?.length > 0 ? (
              <>
                { value?.map((x) => (
                <span>{x}</span>
            ))}
              </>
            ) : (
              <span>{" N.A"}</span>
            )
           
          }
        </li>
      )}
    </>
  );
};

export default QuestionaireData;
