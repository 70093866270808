import { createSlice } from "@reduxjs/toolkit";
import { AuthActions } from "./authActions";

const initialState = {
  isLoggedIn: false,
  data: null,
  error: null,
  jwtToken: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    tokenExpired: (state, action) => {
      return {
        ...state,
        error: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AuthActions.login.fulfilled, (state, action) => {
        const newState = { ...state };
        newState.jwtToken = action.payload.token;
        newState.error = null;
        if (!newState.error && newState.jwtToken) {
          newState.isLoggedIn = true;
        }
        return newState;
      })
      .addCase(AuthActions.login.rejected, (state, action) => {
        const newState = { ...state };
        return newState;
      })
      .addCase(AuthActions.changePassword.rejected, (state, action) => {
        const newState = { ...state };
        return newState;
      })
      .addCase(AuthActions.logout.fulfilled, (state, action) => {
        return initialState;
        
      })
      .addCase(AuthActions.logout.rejected, (state, action) => {
        console.log("logout here")
        localStorage.clear();
        return initialState;
      })
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, tokenExpired } = authSlice.actions;

export default authSlice.reducer;
