import React from "react";
import { Col, Typography } from "antd";

const PersonalInfoData = ({ text, value }) => {
  const { Title, Text } = Typography;

  function formatPersonalInfo(text){
      let regularCaseText = text
          .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before each uppercase letter
          .replace(/^[a-z]/, function(match) { return match.toUpperCase(); }); // Capitalize the first letter
  
      return regularCaseText;
  }

  return (
    <>
      <li>
        <span>
          <b>{formatPersonalInfo(text)}:-</b>{" "}
        </span>
        <span>{value ? value : "N.A"}</span>
      </li>
    </>
  );
};

export default PersonalInfoData;
