import { List } from 'antd';
import React from 'react';

const ActivityLogDetails = ({ data }) => {
  return (
    <div>
      <List
        // style={{ width: 600 }}
        dataSource={Object.entries(data)}
        renderItem={([key, value]) => (
          <List.Item>
            <span style={{ fontWeight: 'bold' }}>{key}: </span>
            <span style={{ wordBreak: 'break-all', marginLeft: '10px' }}>
              {value ? value : '-'}
            </span>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ActivityLogDetails;
