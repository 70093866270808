import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const SanctionChart = ({ style, data, type }) => {
  const options = {
    title: `${type} Data`,
    colors:['#10b981', '#1f2937', '#f0bc74']
  };

  const [pieChartData, setPieChartData] = useState([]);

  const mapReturnLabels = (key) => {
    if(key === "alertGenerated"){
      return "Alert Generated"
    } else if(key === "approved"){
      return "Approved"
    } else if(key === "approvedManually"){
      return "Approved Manually"
    }
  }

  const createChartData = (data) => {
    if (data && Object.keys(data).length > 0) {
      let chartData = Object.entries(data).map((entry) => {
        entry[0] = mapReturnLabels(entry[0]);
        entry[1] = +entry[1];
        return entry;
      });

      chartData.unshift(["Users", "Analytics"]);

      setPieChartData(chartData);
    } else {
      setPieChartData([]);
    }
  };

  useEffect(() => {
    createChartData(data);
  }, [data]);

  return (
    <>
      <Col xs={24} xxl={16}>
        {pieChartData && pieChartData.length > 0 ? (
          <div className={style.IndividualPage_pichart}>
            <Chart
              chartType="PieChart"
              data={pieChartData}
              options={options}
              width={"100%"}
              height={"400px"}
              
            />
          </div>
        ) : (
          <>
            <p>No chart data found</p>
          </>
        )}
      </Col>
    </>
  );
};

export default SanctionChart;
