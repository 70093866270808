import React, { useEffect, useState } from 'react';
import { Modal, Popover, Input, Button, Select, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Edit from '../../../Assets/Images/greyEdit.svg';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Validations } from '../../../validations';
import { FormError } from '../../../Components/FormErrors/FormError';
import { Utils } from '../../../utils';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  finishLoading,
} from '../../../redux/features/loading/loadingSlice';
import { SettingActions } from '../../../redux/features/setting/settingActions';
import { BankingActions } from '../../../redux/features/banking/bankingActions';
import { createFullName } from '../../../utils/common.utils';
import { CONSTANTS } from '../../../constants';

const FiatSettingsModal = ({
  selectedCoin,
  isModalOpen,
  setIsModalOpen,
  getFiatFee,
}) => {
  const {
    formatDateTime,
    checkEmptyString,
    formatFiatValues,
    formatTransactionType,
    formatAdminNameWithRole,
  } = Utils.common;

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    resetField,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(Validations.feeSetting(selectedCoin)),
    mode: 'all',
  });

  const dispatch = useDispatch();
  const [feeDetails, setFeeDetails] = useState({
    name: '',
    role: '',
  });
  const [feeType, setFeeType] = useState('');
  const cars = [];
  const afterPoints = [
    {
      id: 1,
      title: 'Last Updated On:',
      Detail: formatDateTime(selectedCoin?.updatedAt),
    },
    {
      id: 2,
      title: 'Last Updated By:',
      Detail: formatAdminNameWithRole(feeDetails?.name, feeDetails?.role),
    },
  ];
  console.log(selectedCoin, 'paylodData');

  const handleOk = () => {
    setIsModalOpen(false);
    getFiatFee();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    reset({
      withdrawFee: '',
      withdrawMinFee: '',
      withdrawMaxFee: '',
    });
    setFormValues();
  };
  const onSubmit = (values) => {
    const data = {
      txnType: selectedCoin.txnType,
      feeType: values.withdrawFeeType,
      internalFee: values.inernalWithdrawFee,
      externalFee: values.externalWithdrawFee,
      currency: selectedCoin.currency,
      minInternalWithdraw: values.withdrawMinFee,
      maxInternalWithdraw: values.withdrawMaxFee,

      minExternalWithdraw: values.externalMinWithdrawFee,
      maxExternalWithdraw: values.externalMaxWithdrawFee,
      docMaximumWithdraw: values.docMaximumWithdraw,
      depositFee: values.depositFee,
    };

    updateFiatWithdrawFee(data);
  };

  const updateFiatWithdrawFee = async (data) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        SettingActions.editFiatWithdrawFee(data)
      ).unwrap();
      console.log(res);
      handleOk();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const setFormValues = () => {
    if (selectedCoin) {
      setValue('withdrawFee', formatFiatValues(selectedCoin.externalFee));
      setValue(
        'withdrawMinFee',
        formatFiatValues(selectedCoin.minInternalWithdraw)
      );
      setValue(
        'withdrawMaxFee',
        formatFiatValues(selectedCoin.maxInternalWithdraw)
      );
      setValue(
        'externalMinWithdrawFee',
        formatFiatValues(selectedCoin.minExternalWithdraw)
      );
      setValue(
        'externalMaxWithdrawFee',
        formatFiatValues(selectedCoin.maxExternalWithdraw)
      );
      setValue('withdrawFeeType', selectedCoin.feeType);
      setValue(
        'inernalWithdrawFee',
        formatFiatValues(selectedCoin.internalFee)
      );
      setValue(
        'externalWithdrawFee',
        formatFiatValues(selectedCoin.externalFee)
      );
      setValue(
        'docMaximumWithdraw',
        formatFiatValues(selectedCoin.docMaximumWithdraw)
      );
      setValue('depositFee', formatFiatValues(selectedCoin.depositFee));
    }
  };

  // console.log("feeType::",feeType);
  const formatSubModuleType = (type) => {
    switch (type) {
      case 'WITHDRAW':
        return 'FIAT_WITHDRAW';
      case 'DEPOSIT':
        return 'FIAT_DEPOSIT';
      case 'INTERNAL_TRANSFER':
        return 'FIAT_INTERNAL';
      default:
        return '-';
    }
  };

  const getFeeStatus = async () => {
    try {
      dispatch(startLoading());
      console.log({ selectedCoin });
      let data = {
        moduleId: selectedCoin?.currency?.toUpperCase(),
        subModuleType: 'FIAT_INTERNAL',
      };
      const res = await dispatch(BankingActions.getFeeStatus(data)).unwrap();
      if (res) {
        setFeeDetails({
          name: createFullName(res.admin?.firstName, res.admin?.lastName),
          role: res.admin?.title,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    setFormValues();
    getFeeStatus();
    setFeeType(selectedCoin?.feeType);
  }, [selectedCoin]);

  return (
    <>
      <Modal
        className='custom-modal small-btn'
        title='Fiat Wallet'
        open={isModalOpen}
        okText='Submit'
        closeIcon={true}
        centered
        footer={[
          <Button
            type='primary'
            htmlType='submit'
            key='1'
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>,
          <Button type='link' key='2' onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div className='modal-inner'>
          <ul>
            {cars.map((point) => (
              <li key={point.id}>
                <span>{point.title}</span>
                <span>{point.Detail}</span>
              </li>
            ))}
          </ul>

          <span
            style={{
              display: 'inline-flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <img
              src={`${
                CONSTANTS.ENV.KYC_DOCS_URL
              }/stage-fcbank/all_icons/${selectedCoin?.currency?.toLowerCase()}.png`}
              style={{ width: '30px', height: 'auto' }}
            />
            <b>{checkEmptyString(selectedCoin?.currency?.toUpperCase())}</b>
          </span>
          <form className='withdrawFee'>
            <Controller
              name='withdrawFeeType'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <span
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    {/* {formatTransactionType(
                      selectedCoin?.txnType === 'WITHDRAW'
                        ? 'External'
                        : 'Internal'
                    )}{' '} */}
                    Fee Type
                    <Popover
                      placement='topLeft'
                      content='Please select the fee type: Flat or Percentage '
                    >
                      <QuestionCircleOutlined style={{ color: 'grey' }} />
                    </Popover>
                  </span>
                  <div>
                    <Select
                      placeholder='Type'
                      {...field}
                      onChange={(value) => {
                        field.onChange(value); // Updates the form state
                        setFeeType(value); // Updates the component state
                      }}
                      style={{
                        minWidth: '150px',
                      }}
                      options={[
                        {
                          value: 'FLAT',
                          label: 'FLAT',
                        },
                        {
                          value: 'PERCENTAGE',
                          label: 'PERCENTAGE',
                        },
                      ]}
                    />
                  </div>

                  {error && <span>{error.message}</span>}
                </>
              )}
            />

            {/* Withdrawal Fee */}
            <Space>
              <Controller
                name='inernalWithdrawFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Internal fee
                      <Popover
                        placement='topLeft'
                        content='Please set the fee for Fiat transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      // suffix={<img src={Edit} alt="icon" />}
                      suffix={
                        feeType === 'PERCENTAGE' ? '%' : selectedCoin.currency
                      }
                      // disabled={switchValue.status === 0}
                    />
                    {errors.inernalWithdrawFee && (
                      <FormError message={errors.inernalWithdrawFee.message} />
                    )}
                  </>
                )}
              />
              <Controller
                name='externalWithdrawFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      External fee
                      <Popover
                        placement='topLeft'
                        content='Please set the fee for Fiat transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      suffix={
                        feeType === 'PERCENTAGE' ? '%' : selectedCoin.currency
                      }
                      // disabled={switchValue.status === 0}
                    />
                    {errors.externalWithdrawFee && (
                      <FormError message={errors.externalWithdrawFee.message} />
                    )}
                  </>
                )}
              />
              <Controller
                name='depositFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Deposit Fee
                      <Popover
                        placement='topLeft'
                        content='Please set the fee for Fiat deposits'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      suffix={
                        feeType === 'PERCENTAGE' ? '%' : selectedCoin.currency
                      }
                    />
                    {errors.depositFee && (
                      <FormError message={errors.depositFee.message} />
                    )}
                  </>
                )}
              />
            </Space>
            {/* Min and Max amount edit inputs */}
            <Space>
              <Controller
                name='withdrawMinFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Minimum Withdraw Internal
                      <Popover
                        placement='topLeft'
                        content='Please set the minimum withdrarwal amount for Fiat transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      // type="number"
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors('withdrawMinFee');
                          clearErrors('withdrawMaxFee'); // Clear max fee error if conditions are met
                        }
                      }}
                      //  suffix={selectedCoin.currency}
                      suffix={selectedCoin.currency}

                      // disabled={switchValue.status === 0}
                    />
                    {errors.withdrawMinFee && (
                      <FormError message={errors.withdrawMinFee.message} />
                    )}
                  </>
                )}
              />
              <Controller
                name='withdrawMaxFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Maximum Withdraw Internal
                      <Popover
                        placement='topLeft'
                        content='Please set the maximum withdrarwal amount for Fiat transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors('withdrawMaxFee');
                          clearErrors('withdrawMinFee'); // Clear min fee error if conditions are met
                        }
                      }}
                      suffix={selectedCoin.currency}
                      // disabled={switchValue.status === 0}
                    />
                    {errors.withdrawMaxFee && (
                      <FormError message={errors.withdrawMaxFee.message} />
                    )}
                  </>
                )}
              />
            </Space>

            {/* For External */}

            <Space>
              <Controller
                name='externalMinWithdrawFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Minimum Withdraw External
                      <Popover
                        placement='topLeft'
                        content='Please set the minimum withdrarwal amount for Fiat transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors('externalMinWithdrawFee');
                          clearErrors('externalMaxWithdrawFee'); // Clear min fee error if conditions are met
                        }
                      }}
                      suffix={selectedCoin.currency}
                      // disabled={switchValue.status === 0}
                    />
                    {errors.externalMinWithdrawFee && (
                      <FormError
                        message={errors.externalMinWithdrawFee.message}
                      />
                    )}
                  </>
                )}
              />
              <Controller
                name='externalMaxWithdrawFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Maximum Withdraw External
                      <Popover
                        placement='topLeft'
                        content='Please set the maximum withdrarwal amount for Fiat transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors('externalMaxWithdrawFee');
                          clearErrors('externalMinWithdrawFee');
                        }
                      }}
                      suffix={selectedCoin.currency}
                      // disabled={switchValue.status === 0}
                    />
                    {errors.externalMaxWithdrawFee && (
                      <FormError
                        message={errors.externalMaxWithdrawFee.message}
                      />
                    )}
                  </>
                )}
              />
            </Space>

            {/*  for daily limit and document */}

            <Space>
              {/* <Controller
                name="dailyTrxLimit"
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Daily Limit (
                      <b>
                        {checkEmptyString(
                          selectedCoin?.currency?.toUpperCase()
                        )}
                      </b>
                      )
                      <Popover
                        placement="topLeft"
                        content="Please set the daily transfer limit amout"
                      >
                        <QuestionCircleOutlined style={{ color: "grey" }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder="0.00"
                      {...field}
                      suffix={<img src={Edit} alt="icon" />}
                      // disabled={switchValue.status === 0}
                    />
                    {errors.dailyTrxLimit && (
                      <FormError message={errors.dailyTrxLimit.message} />
                    )}
                  </>
                )}
              /> */}
              <Controller
                name='docMaximumWithdraw'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Document Maximum Withdraw
                      <Popover
                        placement='topLeft'
                        content='Please set the maximum withdrawal limit with the document for transactions'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      suffix={selectedCoin.currency}
                      // disabled={switchValue.status === 0}
                    />
                    {errors.docMaximumWithdraw && (
                      <FormError message={errors.docMaximumWithdraw.message} />
                    )}
                  </>
                )}
              />
            </Space>
          </form>
          <ul style={{ marginTop: '100px' }}>
            {feeDetails.role !== '' && (
              <>
                {afterPoints.map((point) => (
                  <li key={point.id}>
                    <span>{point.title}</span>
                    <span>{point.Detail}</span>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default FiatSettingsModal;
