import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedBanner:{
        type:""
    }
}


export const markettingSlice = createSlice({
    name: 'marketting',
    initialState,
    reducers: {
     setSelectedBanner:(state, action) => {
        state.selectedBanner = action.payload
     }
    },
    extraReducers : (builder) =>  {
      
    }
  })
  
  // Action creators are generated for each case reducer function
  export const {setSelectedBanner} = markettingSlice.actions
  
  export default markettingSlice.reducer