import { createSlice } from '@reduxjs/toolkit'
import { UserActions } from './userActions'

const initialState = {
    data: null,
    selectedTab:"",
    transactionDetail : {}
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
     setSelectedTab:(state, action) => {
        state.selectedTab = action.payload
     },
     setSelectedApiData:(state, action) => {
        state.data = action.payload
     },
     setTransactionDetail:(state, action) => {
        state.transactionDetail = action.payload
     }
    },
    extraReducers : (builder) =>  {
        builder
        .addCase(UserActions.getUserDetail.fulfilled, (state, action) => {
                     
        })
        .addCase(UserActions.getUserDetail.rejected, (state, action) => {
            state.data = null;
            
        })
    }
  })
  
  // Action creators are generated for each case reducer function
  export const {setSelectedTab, setSelectedApiData,setTransactionDetail} = userSlice.actions
  
  export default userSlice.reducer