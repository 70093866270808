import React, { useState } from "react";
import { Table, Select, Pagination, Modal } from "antd";
import { CONSTANTS } from "../../../constants";

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const LiquidityFiat = ({data}) => {

  const formatBalance = (balance) => {
    if(!balance || isNaN(balance)) return 0;
    return Number(balance)/100;
  }

  const columns = [
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render:(_, record) => (
        <>
          <div>
          <span
            style={{ display: "inline-flex",  gap: "5px", alignItems: "center" }}
          >
            <img
              src={`${CONSTANTS.ENV.KYC_DOCS_URL}/stage-fcbank/all_icons/${record.currency?.toLowerCase()}.png`}
              style={{ width: "30px", height: "auto" }}
            />
            {record.currency}
          </span>
        </div>
        </>
        
      )
    },
    {
      title: "Account Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render:(_, record) => {
        return formatBalance(record.balance)
      }
        
      
    },
  
  ];
  return (
    <>
      <div className="table-design">
        <div className="custom-table">
          <Table
            columns={columns}
            pagination={false}
            dataSource={data}
            // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      
    </>
  );
};

export default LiquidityFiat;
