import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Dropdown, Modal, Space, Breadcrumb, Button } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonCommon from "../Buttoncommon/ButtonCommon";
import { useDispatch, useSelector } from "react-redux";
import { AdminActions } from "../../redux/features/admin/adminActions";
import { AuthActions } from "../../redux/features/auth/authActions";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";
import { Utils } from "../../utils";
import { DownOutlined } from "@ant-design/icons";
import BreadcrumbHeader from "./Breadcrumb/BreadcrumbHeader";
import { toast } from "react-toastify";
import { CONSTANTS } from "../../constants";
import { logout } from "../../redux/features/admin/adminSlice";
// import { BroadcastChannel } from "broadcast-channel";
function Index({ onClick, icon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data } = useSelector((state) => state.admin);
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(data, "datadata");
  const { formatUserRole } = Utils.common;
  // const logoutChannel = new BroadcastChannel("logout");
  // const changePageHeader = () => {
  //   if (location.pathname === "/dashboard") setPageHeader("DashBoard");
  //   if (location.pathname === "/user-directory")
  //     setPageHeader("Client Management");
  //   if (location.pathname === "/user-verification")
  //     setPageHeader("Client Management");
  //   if (location.pathname === "/logs") setPageHeader("Activity");
  //   if (location.pathname === "/fiat-transaction")
  //     setPageHeader("Transactions");
  //   if (location.pathname === "/pending-transaction")
  //     setPageHeader("Transactions");
  //   if (location.pathname === "/crypto-transaction")
  //     setPageHeader("Transactions");
  //   if (location.pathname === "/swap-transaction")
  //     setPageHeader("Transactions");
  //   if (location.pathname === "/client-support-communication")
  //     setPageHeader("Client Support");
  //   if (location.pathname === "/transaction-limits") setPageHeader("Setting");
  //   if (location.pathname === "/activity") setPageHeader("Activity");
  //   if (location.pathname === "/rewards") setPageHeader("Setting");
  // };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleLogout();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleLogout = async () => {
    try {
      await dispatch(startLoading());
      const res = await dispatch(AuthActions.logout()).unwrap();
      dispatch(logout());
      // await logoutChannel.postMessage("Logout");
      setTimeout(async () => {
        navigate("/login");
        await dispatch(finishLoading());
      }, 1500);
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  // const logoutAllTabs = () => {
  //   logoutChannel.onmessage = () => {
  //     handleLogout();
  //     logoutChannel.close();
  //   };
  // };

  const items = [
    {
      key: "1",
      label: (
        <Link rel="noopener noreferrer" to="/setting">
          My Profile
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link rel="noopener noreferrer" to="#" onClick={showModal}>
          Logout
        </Link>
      ),
    },
  ];

  const getAdminDetails = async () => {
    try {
      const res = await dispatch(AdminActions.getDetails()).unwrap();
      // if (res.sessions && res.sessions.length == 0) {
      //   handleLogout();
      // }
    } catch (error) {
      handleLogout();
    }
  };

  useEffect(() => {
    getAdminDetails();
    // logoutAllTabs();
  }, []);

  useEffect(() => {
    const { INVALID_SIGNATURE, JWT_EXPIRED, SESSION_EXPIRED } =
      CONSTANTS.ERROR_401_MESSAGE;
    if (
      error === JWT_EXPIRED ||
      error === SESSION_EXPIRED ||
      error === INVALID_SIGNATURE
    ) {
      toast.error(error);
      handleLogout();
    }
  }, [error]);

  return (
    <>
      <div className={style.header}>
        <div className={style.header_routeNames}>
          {/* // <div className="custom-breadCrumb"> */}
          <div className={style.header_routeNames_heading}>
            <Button
              className="hemberg"
              type="text"
              icon={icon}
              onClick={onClick}
              style={{
                fontSize: "16px",
                // width: 64,
                // height: 64,
              }}
            />
            <BreadcrumbHeader />
            {/* <h3>{pageHeader}</h3> */}
          </div>
          <div className={style.header_profile}>
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <a className="dropHeader" onClick={(e) => e.preventDefault()}>
                <Space>
                  <h3>{`${data?.firstName} ${data?.lastName}`}</h3>
                  <DownOutlined />
                </Space>
                <span>{formatUserRole(data?.title)}</span>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>

      <Modal
        className="logout custom-modal"
        width={545}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        maskClosable={false}
      >
        <div className="modal-inner">
          <h3>You are attempting to logout from FCBank.</h3>
          <p style={{ margin: "25x 0 " }}>Are you Sure?</p>
          <div className="logout_btn">
            <ButtonCommon
              text="Cancel"
              width={176}
              height={46}
              transBtn="transBtn"
              custmcls="cursorpointer"
              onClick={handleCancel}
            />
            <ButtonCommon
              type="primary"
              text="Logout"
              width={176}
              onClick={handleOk}
              customBlueBtn="customBlueBtn"
              height={46}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Index;
