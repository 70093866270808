import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ToastService {

  success(massage,timeout) {
    toast.success(massage,{autoClose:timeout});
  }
  warn(massage) {
    toast.warn(massage);
  }
  error(massage) {
    toast.error(massage);
  }
}

export default new ToastService();
