import React, { useState } from "react";
import { Table, Space, Pagination, Button } from "antd";
import ButtonCommon from "../../../../Components/Buttoncommon/ButtonCommon";
import TableFilterTittle from "../../../../Components/TableFilterTittle/TableFilterTittle";
import CustomModal from "../../../../Components/CustomModal/CustomModal";
import {
  handleHideId,
  iconColor,
  iconSize,
} from "../../../../utils/common.utils";
import { useDispatch } from "react-redux";
import { Utils } from "../../../../utils";
import CopyToClipboard from "../../../../Components/CopyToClipboard/CopyToClipboard";
import { EyeOutlined } from "@ant-design/icons";
const Completed = ({
  complianceKyc,
  getComplianceKyc,
  showVerificationDetails,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { formatAdminNameWithRole } = Utils.common;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "System ID",
      dataIndex: "userId",
      key: "userId",
      render: (_, record) => {
        return (
          <>
            {record?.userId ? (
              <>
                <span style={{ cursor: "pointer" }}>
                  {handleHideId(record?.userId)}
                </span>

                <CopyToClipboard userId={record?.userId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      key: "nationality",
    },
    {
      title: "Kyc Status(ADMIN)",
      dataIndex: "adminKycStatus",
      key: "adminKycStatus",
    },
    {
      title: "Kyc Status(SUMSUB)",
      dataIndex: "sumsubKycStatus",
      key: "sumsubKycStatus",
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
      key: "accountType",
    },
    // {
    //   title:"Updated By",
    //   dataIndex: "adminFullName",
    //   render:(_, record) => {
    //     return formatAdminNameWithRole(record.adminFullName, record.completedBy)
    //   }
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div className="approvereject cursorpointer">
          {/* <span
            onClick={() => {
              {
                showVerificationDetails(record.userId);
              }
            }}
          >
            <Button type="primary">More Details</Button>
          </span> */}
          <EyeOutlined
            style={{ color: iconColor, fontSize: iconSize }}
            onClick={() => showVerificationDetails(record.userId)}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    let data = {
      offset: (page - 1) * pageSize,
      limit: pageSize,
      type: "APPROVED",
    };
    getComplianceKyc(data);
  };

  return (
    <>
      <div className="table-design">
        <TableFilterTittle title="Completed Requests" />
        <div className="custom-table">
          <Table
            dataSource={complianceKyc.list}
            columns={columns}
            pagination={false}
            // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      {complianceKyc.totalCount > 10 && (
        <div className="pagination_block">
          <span className="records">
            Total Records: {complianceKyc.totalCount}
          </span>
          <Pagination
            defaultCurrent={page}
            total={complianceKyc.totalCount}
            pageSize={10}
            onChange={handlePageChange}
          />
        </div>
      )}

      <CustomModal
        // text="Add User"
        // title="Add Role"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h3> Are you sure want to Approve?</h3>
      </CustomModal>
    </>
  );
};

export default Completed;
