import { Divider } from "antd";
import React, { useState } from "react";
import UserInfo from "./UserInfo";
import { useDispatch } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import CustomUpload from "../../../Components/customUpload/CustomUpload";
import { KycActions } from "../../../redux/features/kyc/kycActions";
import { UserActions } from "../../../redux/features/user/userActions";
import { setSelectedKey } from "../../../redux/features/compliance/complianceSlice";
import { setSelectedApiData } from "../../../redux/features/user/userSlice";
import { checkEmptyString, formatDateTime } from "../../../utils/common.utils";
import { useLocation } from "react-router-dom";

const KycUpload = ({ userData }) => {
  const location = useLocation();
  const mb20 = { margin: "20px 0" };
  const dispatch = useDispatch();
  const [docTitle, setDocTitle] = useState("");
  const [showDocTitleModal, setShowDocTitleModal] = useState(false);
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();

      formData.append("file", file);
      const fileType = file.name.split(".").pop();
      formData.append("fileType", fileType);
      formData.append("userId", userData?.userId);
      formData.append("docName", docTitle);

      let res = null;
      dispatch(startLoading());
      if (location.pathname === "/user-directory-view") {
        res = await dispatch(UserActions.uploadKycDoc(formData)).unwrap();
      } else {
        res = await dispatch(KycActions.uploadKycDoc(formData)).unwrap();
      }
      let data = await getUserDetails(userData?.userId);

      dispatch(setSelectedApiData(data));
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const getUserDetails = async (id) => {
    try {
      if (!id) return;
      const data = await dispatch(
        UserActions.getUserDetail({ userId: id })
      ).unwrap();
      return data;
    } catch (error) {}
  };

  const allowedTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/svg",
    "application/pdf",
  ];

  const doc = [
    {
      id: 1,
      title: (
        <>
          Upload Additional Document
          <br style={{ marginBottom: "10px" }} />
          <CustomUpload
            allowedTypes={allowedTypes}
            uploadFile={uploadFile}
            setDocTitle={setDocTitle}
            docTitle={docTitle}
            setShowDocTitleModal={setShowDocTitleModal}
            showDocTitleModal={showDocTitleModal}
          />
        </>
      ),
    },
    // {
    //   id: 2,
    //   title: "Uploaded By",
    //   about: checkEmptyString(userData?.lastUploadedBy),
    // },
    // {
    //   id: 3,
    //   title: "Uploaded On",
    //   about: formatDateTime(userData?.lastUploadedOn),
    // },
  ];

  return (
    <>
      {doc.length > 0 && (
        <>
          <Divider style={mb20} />
          <UserInfo checkdata="Document" list={doc} />
          {/* <UserInfo checkdata="" list={doc} /> */}
        </>
      )}
    </>
  );
};

export default KycUpload;
