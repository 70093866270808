import React from "react";
import { useState } from "react";
import { Table, Pagination, Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";
import TableFilterTittle from "../../Components/TableFilterTittle/TableFilterTittle.jsx";
import KycApproveModal from "../KycVerification/KycApproveModal.jsx";
import KycRejectModal from "../KycVerification/KycRejectModal.jsx";

const WithdrawTransactions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectOpen, setIsRejectOpen] = useState(false);

  const dispatch = useDispatch();

  const { Text } = Typography;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showReject = () => {
    setIsRejectOpen(true);
  };

  const handleApprove = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleReject = () => {
    setIsRejectOpen(false);
  };
  const handlecancel = () => {
    setIsRejectOpen(false);
  };

  const showAdminActions = () => {
    return (
      <>
        <span className="green" onClick={showModal}>
          Approve
        </span>
        <span className="red" onClick={showReject}>
          /Reject
        </span>
      </>
    );
  };

  const columns = [
    {
      title: "S. No",
      dataIndex: "number",
      key: "number",
    },

    {
      title: "Email ID",
      dataIndex: "emailId",
      key: "emailId",
    },
    {
      title: "Transaction ID",
      dataIndex: "transationId",
      key: "transationId",
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Fee (if any)",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Created on",
      dataIndex: "createdOn",
      key: "createdOn",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const dataSource = [
    {
      key: "1",
      number: 1,
      transationId: "#28323",
      emailId: "example@gmail.com",
      transactionType: "Deposit",
      currency: "USD",
      amount: 3216,
      fee: 0,
      status: <Text type="success">Accepted</Text>,
      createdOn: "19 Dec 2023 14:48:18",
      action: (
        <>
          {/* <Button type="primary">View Details</Button> */}
          <p>{showAdminActions()}</p>
        </>
      ),
    },
    {
      key: "2",
      number: 2,
      transationId: "#28323",
      emailId: "example@gmail.com",
      transactionType: "Deposit",
      currency: "USD",
      amount: 3216,
      fee: 0,
      status: <Text type="danger">Rejected</Text>,
      createdOn: "19 Dec 2023 14:48:18",
      action: (
        <>
          {/* <Button type="primary">View Details</Button> */}
          <p>{showAdminActions()}</p>
        </>
      ),
    },
    {
      key: "3",
      number: 3,
      transationId: "#28323",
      emailId: "example@gmail.com",
      transactionType: "Deposit",
      currency: "USD",
      amount: 3216,
      fee: 0,
      status: <Text type="warning">Pending</Text>,
      createdOn: "19 Dec 2023 14:48:18",
      action: (
        <>
          {/* <Button type="primary">View Details</Button> */}
          <p>{showAdminActions()}</p>
        </>
      ),
    },
    {
      key: "4",
      number: 4,
      transationId: "#28323",
      emailId: "example@gmail.com",
      transactionType: "Deposit",
      currency: "USD",
      amount: 3216,
      fee: 0,
      status: <Text type="danger">Rejected</Text>,
      createdOn: "19 Dec 2023 14:48:18",
      action: (
        <>
          {/* <Button type="primary">View Details</Button> */}
          <p>{showAdminActions()}</p>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="table-design">
        <TableFilterTittle title="Withdraw Transactions" />
        <div className="custom-table">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      <Pagination defaultCurrent={1} total={50} />

      <KycApproveModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        handleApprove={handleApprove}
      />

      <KycRejectModal
        handleReject={handleReject}
        isRejectOpen={isRejectOpen}
        handlecancel={handlecancel}
      />
    </>
  );
};

export default WithdrawTransactions;
