import { Button, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Validations } from '../../../validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormError } from '../../../Components/FormErrors/FormError';
import { useDispatch } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import { UserActions } from '../../../redux/features/user/userActions';
import { setSelectedApiData } from '../../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { KycActions } from '../../../redux/features/kyc/kycActions';
import { setDocList } from '../../../redux/features/kyc/kycSlice';

function EditAddtionalDocuments({
  showEditDocModal,
  setShowEditDocModal,
  editDocDetails,
  setEditDocDetails,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(Validations.renameAdditionalDoc),
    mode: 'all',
  });
  console.log(editDocDetails, 'editDocDetailsPayload');

  const onSubmit = async (values) => {
    try {
      let data = {};

      data = {
        id: editDocDetails?.id,
        newTitle: values?.additionalDoc,
      };

      dispatch(startLoading());
      setShowEditDocModal(false);

      if (
        location.pathname === '/user-directory-view' ||
        location.pathname === '/user-verification'  || 
         location.pathname === '/sanction-report-kyc'
      ) {
        console.log('EDIT KYC NAME');
        data = {
          userId: editDocDetails?.userId,
          id: editDocDetails?.id,
          newTitle: values?.additionalDoc,
        };

        dispatch(UserActions.renameAddtionalDocs(data)).unwrap();
        getUserDetails(editDocDetails?.userId);
      } else {
        console.log('KYT EDIT NAME');

        dispatch(UserActions.renameAddtionalDocsKyt(data)).unwrap();

        const updatedDocList = await getUploadedDocList({
          limit: '10',
          offset: '0',
          userId: editDocDetails?.userId,
          txnId: location.state?.txnId,
          docType: 'ADDITIONAL',
        });

        dispatch(setDocList(updatedDocList));
      }

      setEditDocDetails('');
      dispatch(finishLoading());
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const getUserDetails = async (id) => {
    try {
      const data = await dispatch(
        UserActions.getUserDetail({ userId: id })
      ).unwrap();
      if (data?.user && data?.kyc) {
        dispatch(setSelectedApiData(data));
      }
      return data;
    } catch (error) {
      toast.error(error);
    }
  };

  const getUploadedDocList = async (payload) => {
    try {
      const data = await dispatch(
        KycActions.getKytDocListByTxnId(payload)
      ).unwrap();
      return data;
    } catch (error) {
      return {};
    }
  };

  const handleCancel = () => {
    setShowEditDocModal(false);
    setEditDocDetails('');
    reset({
      additionalDoc: '',
    });
  };

  useEffect(() => {
    setValue('additionalDoc', editDocDetails?.docName);
  }, [editDocDetails?.docName]);

  return (
    <div>
      <Modal
        className='custom-modal small-btn'
        title='Enter document title'
        open={showEditDocModal}
        okText='Submit'
        closeIcon={true}
        centered
        footer={[
          <Button
            type='primary'
            htmlType='submit'
            key='1'
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>,
          <Button type='link' key='2' onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div className='modal-inner'>
          <form className=''>
            <Controller
              name='additionalDoc'
              control={control}
              render={({ field }) => (
                <>
                  <span
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  ></span>
                  <Input
                    type='text'
                    placeholder='Edit doc'
                    {...field}
                    // value={editDocDetails?.docName}
                  />
                  {errors.additionalDoc && (
                    <FormError message={errors.additionalDoc.message} />
                  )}{' '}
                </>
              )}
            />
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default EditAddtionalDocuments;
