import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const TableFilterTittle = ({ title }) => {
  return (
    <>
      <div className="title-filter">
        <h3>{title}</h3>
        <div style={{ display: "inline-flex", gap: "15px" }}>
          {/* <Input
            style={{ width: "145px" }}
            prefix={<SearchOutlined />}
            type="search"
            placeholder="Search"
          /> */}
          {/* <DepositFilters /> */}
        </div>
      </div>
    </>
  );
};

export default TableFilterTittle;
