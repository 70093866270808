import React, { useEffect } from 'react';
import { Table, Input, Button } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import Edit from '../../../Assets/Images/greyEdit.svg';
import { FormError } from '../../../Components/FormErrors/FormError';
import TableFilterTittle from '../../../Components/TableFilterTittle/TableFilterTittle';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../../redux/features/user/userActions';
import { Validations } from '../../../validations';
import { yupResolver } from '@hookform/resolvers/yup';

const formBox = {
  width: '30%',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
};

function FiatLimitForm() {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(Validations.fiatTransactionLimitValidation),
    mode: 'all',
  });

  const fiatOnSubmit = async (values) => {
    const result = await dispatch(
      UserActions.updateFiatTransactionLimitsDetail({
        globalLimit: Number(values.globalLimit),
        globalLimit: Number(values.globalLimit),
        dailyBusinessTrxLimit: Number(values.dailyBusinessTrxLimit),
        setGlobal: true,
        apiAccessKey: 'CLIENT_DIRECTORY',
      })
    ).unwrap();
    setDefaultTransactionLimit();
  };

  const setDefaultTransactionLimit = async () => {
    const data = await dispatch(
      UserActions.getFiatTransactionLimitsDetail({
        apiAccessKey: 'CLIENT_DIRECTORY',
      })
    ).unwrap();
    console.log('inFiat', data);
    setValue('globalLimit', data?.globalLimit?.dailyLimit || 0);
    setValue(
      'dailyBusinessTrxLimit',
      data?.globalLimit?.businessDailyLimit || 0
    );
  };

  useEffect(() => {
    setDefaultTransactionLimit();
  }, []);

  return (
    <div>
      <form style={formBox} onSubmit={handleSubmit(fiatOnSubmit)}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <label htmlFor='globalLimit' style={{ minWidth: '100px' }}>
                Individual Limit
              </label>
              <Controller
                name='globalLimit'
                control={control}
                render={({ field }) => (
                  <Input
                    id='globalLimit'
                    placeholder='0'
                    style={{ minWidth: '200px' }}
                    {...field}
                    suffix={<span style={{ color: 'grey' }}>EURO</span>}
                    prefix='€'
                  />
                )}
              />
            </div>
            <p style={{ marginLeft: '110px' }}>
              {errors.globalLimit && (
                <FormError message={errors.globalLimit.message} />
              )}
            </p>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <label
                htmlFor='dailyBusinessTrxLimit'
                style={{ minWidth: '100px' }}
              >
                Business Limit
              </label>
              <Controller
                name='dailyBusinessTrxLimit'
                control={control}
                render={({ field }) => (
                  <Input
                    id='dailyBusinessTrxLimit'
                    placeholder='0'
                    style={{ minWidth: '200px' }}
                    {...field}
                    suffix={<span style={{ color: 'grey' }}>EURO</span>}
                    prefix='€'
                  />
                )}
              />
            </div>
            <p style={{ marginLeft: '110px' }}>
              {errors.dailyBusinessTrxLimit && (
                <FormError message={errors.dailyBusinessTrxLimit.message} />
              )}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              key='1'
              style={{ marginTop: '20px', marginLeft: '80px' }}
            >
              Update
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FiatLimitForm;
