import React, { useState } from "react";
import { Row, Col, Badge } from "antd";
import BlockCard from "../../../Components/blocksCard/BlockCard.jsx";
import style from "../style.module.scss";
import RecentActivity from "../../../Components/recentactivity/RecentActivity.jsx";
import client from "../../../Assets/Images/client.svg";
import alertRaise from "../../../Assets/Images/alertRaised.svg";
import DocomentVerify from "../../../Assets/Images/doumentverify.svg";
import rejectRequest from "../../../Assets/Images/RejectRequest.svg";
import SanctionChart from "./Charts/SanctionChart.jsx";
import { calculatePercentage } from "../../../utils/common.utils.js";

const Business = ({
  setShowVerificationScreen,
  sanctionAnalytics,
  getComplianceAnalytics,
  setIsMngView,
  setSelectedData
}) => {
  const cardsData = [
    {
      id: 1,
      icons: client,
      titleMain: sanctionAnalytics?.cardData.clientVerified?.totalCount,
      valueUp: `${calculatePercentage(
        sanctionAnalytics?.cardData.clientVerified?.lastSevenDayCount,
        sanctionAnalytics?.cardData.clientVerified?.totalCount
      )}%`,
      valueUp1: `${calculatePercentage(
        sanctionAnalytics?.cardData.clientVerified?.lastThirtyDayCount,
        sanctionAnalytics?.cardData.clientVerified?.totalCount
      )}%`,
      title: "Clients Verified",
    },
    {
      id: 2,
      icons: DocomentVerify,
      titleMain: sanctionAnalytics?.cardData?.doucmentsVerified?.totalCount,
      valueUp: `${calculatePercentage(
        sanctionAnalytics?.cardData.doucmentsVerified?.lastSevenDayCount,
        sanctionAnalytics?.cardData?.doucmentsVerified?.totalCount
      )}%`,
      valueUp1: `${calculatePercentage(
        sanctionAnalytics?.cardData.doucmentsVerified?.lastThirtyDayCount,
        sanctionAnalytics?.cardData?.doucmentsVerified?.totalCount
      )}%`,
      title: "Documents Verified",
    },
    {
      id: 3,
      icons: alertRaise,
      titleMain: sanctionAnalytics?.cardData?.alertRaised?.totalCount,
      valueUp: `${calculatePercentage(
        sanctionAnalytics?.cardData.alertRaised?.lastSevenDayCount,
        sanctionAnalytics?.cardData?.alertRaised?.totalCount
      )}%`,
      valueUp1: `${calculatePercentage(
        (sanctionAnalytics?.cardData.alertRaised?.lastThirtyDayCount,
        sanctionAnalytics?.cardData?.alertRaised?.totalCount)
      )}%`,
      title: "Alert Raised",
    },
    {
      id: 4,
      icons: rejectRequest,
      titleMain: sanctionAnalytics?.cardData?.rejectedRequest?.totalCount,
      valueUp: `${calculatePercentage(
        sanctionAnalytics?.cardData.rejectedRequest?.lastSevenDayCount,
        sanctionAnalytics?.cardData?.rejectedRequest?.totalCount
      )}%`,
      valueUp1: `${calculatePercentage(
        sanctionAnalytics?.cardData.rejectedRequest?.lastThirtyDayCount,
        sanctionAnalytics?.cardData?.rejectedRequest?.totalCount
      )}%`,
      title: "Requests Rejected",
    },
  ];

  return (
    <>
      <div className={style.IndividualPage}>
        <section className="mb20">
          <Row gutter={[20, 20]}>
            <SanctionChart
              style={style}
              data={sanctionAnalytics.chartData}
              type="Business"
            />
            <Col xs={24} xxl={8}>
              <div className={style.IndividualPage_cards}>
                {cardsData.map((data) => (
                  <div className={style._cards_block}>
                    <BlockCard
                      icons={data.icons}
                      titleMain={data.titleMain}
                      title={data.title}
                      valueUp1={data.valueUp1}
                      valueUp={data.valueUp}
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </section>
        <section>
          <RecentActivity
            setShowVerificationScreen={setShowVerificationScreen}
            type="BUSINESS"
            getComplianceAnalytics={getComplianceAnalytics}
            data={sanctionAnalytics?.alertData}
            setIsMngView={setIsMngView}
            setSelectedData={setSelectedData}
          />
        </section>
      </div>
    </>
  );
};

export default Business;
