import React, { useEffect, useState } from "react";
import { Table, Pagination, Button, Typography } from "antd";
import TableFilterTittle from "../../Components/TableFilterTittle/TableFilterTittle.jsx";
import DetailModal from "../UserDirectory/DetailModal.jsx";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { WalletActions } from "../../redux/features/wallet/walletActions.js";

const Wallet = () => {
  const { Text } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    console.log("working");
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "S. No",
      dataIndex: "number",
      key: "number",
    },

    {
      title: "Email ID",
      dataIndex: "emailId",
      key: "emailId",
    },
    {
      title: "Transaction ID",
      dataIndex: "transationId",
      key: "transationId",
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Fee (if any)",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Created on",
      dataIndex: "createdOn",
      key: "createdOn",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <>
      <div className="table-design">
        <TableFilterTittle title="Wallet" />
        <div className="custom-table">
          <Table
            columns={columns}
            pagination={false}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      <Pagination defaultCurrent={1} total={50} />
      {isModalOpen && (
        <DetailModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default Wallet;
