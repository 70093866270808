
const getIndividualPersonalInfo = (data) => {
    return [
        {
            text:"addressStreet", value: data.addressStreet?.value

        },
        {
            text:"addressStreet2", value: data.addressStreet2?.value

        },
       
        {
            text:"areYouPoliticallyExp", value: data.areYouPoliticallyExp?.value

        },
        {
            text:"cityTown", value: data.cityTown?.value

        },
        {
            text:"country", value: data.country?.value

        },
        {
            text:"firstName", value: data.firstName?.value

        },
        {
            text:"gender", value: data.gender?.value

        },
        {
            text:"lastName", value: data.lastName?.value

        },
        {
            text:"middleName", value: data.middleName?.value

        },
        {
            text:"nationalIdNumber", value: data.nationalIdNumber?.value

        },
        {
            text:"zipCode", value: data.zipCode?.value

        },

    ]
}


const getBusinessPersonalInfo = (data) => {
    return [
        {
            text:"companyName", value: data.companyName

        },
        {
            text:"country", value: data.country

        },
       
        {
            text:"registrationNumber", value: data.registrationNumber

        },
       

    ]
}



export const PersonalInfoService = {
    getBusinessPersonalInfo,
    getIndividualPersonalInfo
}