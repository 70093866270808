import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { CLIENTSUPPORT } = CONSTANTS.API_ENDPOINTS;

const getClientSupport = createAsyncThunk(
  "client/support/details",
  async (payload, { rejectWithValue, dispatch }) => {
    console.log(payload, "payload");
    return Utils.api.baseApiCall(
      {
        endpoint: `${CLIENTSUPPORT.GET_CLIENT_SUPPORT_DETAILS}/${payload.offset}/${payload.size}/${payload.status}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_SUPPORT",
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );
  }
);

const getUserDetail = createAsyncThunk(
  "client-support/getUserDetail",
  async ({ userId = -1 }, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${CLIENTSUPPORT.GET_USER_DETAIL}/${userId}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_SUPPORT",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateSupportTicketStatus = createAsyncThunk(
  "approve/Client/Support/Ticket",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${CLIENTSUPPORT.UPDATE_SUPPORT_TICKET_STATUS}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_SUPPORT",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getTicketDetail = createAsyncThunk(
  "client-support/getTicketDetail",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${CLIENTSUPPORT.GET_TICKET_DETAIL}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_SUPPORT",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

export const ClientSupportActions = {
  getClientSupport,
  getUserDetail,
  updateSupportTicketStatus,
  getTicketDetail
};
