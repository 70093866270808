import React from "react";
import { Table } from "antd";
import ScreenBreakpoints from "../CustomHook/ScreenBreakpoints.jsx";

const CustomTable = (props) => {
  const { dataSource, columns } = props;
  const [isLargeScreen, isSmallScreen] = ScreenBreakpoints();
  const tableSize = isLargeScreen
    ? "large"
    : isSmallScreen
    ? "middle"
    : "small";

  return (
    <>
      <div className="custom-table">
        <Table
          size={tableSize}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
        />
      </div>
    </>
  );
};

export default CustomTable;
