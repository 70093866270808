import React, { useEffect, useState } from "react";
import { Button, Space, Switch } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";
import { UserActions } from "../../redux/features/user/userActions";
import {
  getUserVerificationStatus,
  showName,
} from "../../services/user.service";
import DetailModal from "./DetailModal";
import { Utils } from "../../utils";
import {
  formatDateTime,
  handleHideId,
  iconColor,
  iconSize,
} from "../../utils/common.utils";
import toast from "../../services/toast.service";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import ScreenBreakpoints from "../../Components/CustomHook/ScreenBreakpoints.jsx";
import CustomTable from "../../Components/CustomTable/CustomTable.jsx";
// import FilterBar from "../../Components/FilterBar/FilterBar.jsx";
import UserDirectoryFilterBar from "./userDirectorytFilters/UserDirectoryFilterBar";

import CustomPagination from "../../Components/CustomPagination/CustomPagination.jsx";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard";
import PermissionCheck from "../../Components/PermissionCheck/PermissionCheck";
import { setSelectedApiData } from "../../redux/features/user/userSlice";
import UserDirectortFilters from "./userDirectorytFilters/Filters";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { DeleteIcon } from "../../Assets/Icons/Icons";

const UserDirectory = () => {
  const [isLargeScreen, isSmallScreen] = ScreenBreakpoints();
  const tableSize = isLargeScreen
    ? "large"
    : isSmallScreen
    ? "middle"
    : "small";

  const [isModalOpenBlocked, setIsModalOpenBlocked] = useState(false);
  const [blockUserId, setBlockUserId] = useState();
  const [blockChecked, setBlockChecked] = useState(false);
  const [blockReason, setBlockReason] = useState("");
  const [blockModalTitle, setBlockModalTitle] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [details, setDetails] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);

  const dispatch = useDispatch();
  const [users, setUsers] = useState({
    list: [],
    totalCount: 0,
  });

  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [val, setVal] = useState(-1);

  const { TextArea } = Input;
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBlockCancel = () => {
    const newUsers = [...users.list];
    newUsers[val].isBlocked = !users.list[val].isBlocked;
    setUsers((x) => {
      return {
        list: newUsers,
        totalCount: x.totalCount,
      };
    });
    setIsModalOpenBlocked(false);
  };

  const handleBlockOk = async () => {
    try {
      if (!blockReason) throw "Please enter reason";

      blockUnblockUser(blockUserId, blockChecked);
      setBlockReason("");
      setIsModalOpenBlocked(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleOnChange = (checked, record) => {
    console.log({ checked, record });
    const selectedUserIndex = users.list.findIndex(
      (rec) => rec.userId === record.userId
    );
    setVal(selectedUserIndex);

    setIsModalOpenBlocked(true);
    setBlockUserId(record.userId);
    setBlockChecked(checked);
    const newUsers = [...users.list];
    newUsers[selectedUserIndex].isBlocked = checked;
    setUsers((x) => {
      return {
        list: newUsers,
        totalCount: x.totalCount,
      };
    });

    if (record.isBlocked === true) setBlockModalTitle("Block");
    else setBlockModalTitle("Unblock");
  };
  const getUserDetails = async (id) => {
    try {
      if (!id) return;
      const data = await dispatch(
        UserActions.getUserDetail({ userId: id })
      ).unwrap();
      if (data && data?.user && data?.kyc) {
        dispatch(setSelectedApiData(data));
        console.log({ viewData: data });
        navigate("/user-directory-view");
      } else {
        toast.error("No data found");
      }
    } catch (error) {}
  };


  const blockUnblockUser = async (userId, checked) => {
    try {
      const data = {
        userId,
        status: checked ? "INACTIVE" : "ACTIVE",
        reason: blockReason ? blockReason : undefined,
      };
      await dispatch(startLoading());
      await dispatch(UserActions.blockUnblockUser(data)).unwrap();
      await dispatch(finishLoading());
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handleShowDeleteModal = (record) => {
    if (record.isDeleted) {
      toast.error("User is already deleted");
    } else {
      setDetails(record);
      setOpenDeleteModal(true);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const data = {
        apiAccessKey: "CLIENT_DIRECTORY",
        userId: details.userId,
      };

      await dispatch(startLoading());

      await dispatch(UserActions.deleteUser(data)).unwrap();

      await dispatch(finishLoading());

      const obj = {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        filter: location.state?.filter || {},
      };

      getUserData({ ...obj });

      setOpenDeleteModal(false);
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const columns = [
    {
      title: "UID",
      dataIndex: "uidString",
      key: "uidString",
      render: (_, record) => {
        return (
          <>
            {record?.uidString ? (
              <>
                {handleHideId(record?.uidString)}
                <CopyToClipboard userId={record?.uidString} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "System ID",
      dataIndex: "userId",
      key: "userId",
      render: (_, record) => {
        return (
          <>
            {record?.userId ? (
              <>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => getUserDetails(record.userId)}
                >
                  {handleHideId(record?.userId)}
                </span>
                <CopyToClipboard userId={record?.userId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Nationality ",
      dataIndex: "nationality",
      key: "nationality",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Verification Status",
      dataIndex: "userKycStatus",
      key: "userKycStatus",
      // width: 150,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        console.log(record, "checkDelete");
        if(record.isDeleted === true){
          return "INACTIVE"
        }
        else if(record.isBlocked === true){
          return "BLOCKED"
        }
        else{
         return "ACTIVE"
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        console.log(record, "checkDelete");
        return (
          <Space size={20}>
            <EyeOutlined
              style={{ color: iconColor, fontSize: iconSize }}
              onClick={() => getUserDetails(record.userId)}
            />

            {/* <PermissionCheck>
              <Switch
                size={isLargeScreen ? "default" : "small"}
                checkedChildren="Blocked"
                unCheckedChildren="Block"
                defaultChecked={record.isBlocked == true}
                checked={record.isBlocked}
                disabled={record.isDeleted}
                onChange={(checked) => {
                  handleOnChange(checked, record);
                }}
              />
            </PermissionCheck> */}
            <ButtonCommon
              icon={<DeleteIcon style={{ color: "red" }} />}
              onClick={() => handleShowDeleteModal(record)}
              type="text"
            />
          </Space>
        );
      },
    },
  ];

  const formatUserData = (data, userStatus) => {
    const { checkEmptyString, capitalizeFirstLetter } = Utils.common;
    return data.list.map((user, index) => {
      return {
        ...user,
        name: showName(user),
        number: index + 1,
        userKycStatus: getUserVerificationStatus(user.adminKycStatus),
        nationality: checkEmptyString(capitalizeFirstLetter(user.nationality)),
        isBlocked: user.isBlocked,
        createdAt: formatDateTime(user.createdAt),
        userType: checkEmptyString(user.accountType),
        uidString: user.uidString,
        // status: user.isDeleted ? "DELETED" : user.status,
        status:
          userStatus == "DELETED"
            ? "DELETED"
            : userStatus == "BLOCKED"
            ? "BLOCKED"
            : user.status,
      };
    });
  };
  const getUserData = async (obj = {}) => {
    let userStatus = obj?.filter?.status;
    console.log("running");
    try {
      const updatedState = { ...location.state, ...obj }; // Add or update state
      navigate("/user-directory", { state: { ...updatedState } }); // Navigate to the same path with new state

      console.log({
        filterData: obj,
      });
      await dispatch(startLoading());
      const res = await dispatch(
        UserActions.getUsers({
          ...obj,
        })
      ).unwrap();
      // debug({res})
      const totalCount = +res.totalCount;
      if (totalCount > 0) {
        const formattedUserData = formatUserData(res, userStatus);
        setUsers({
          list: formattedUserData,
          totalCount: totalCount,
        });
      } else {
        setUsers({
          list: [],
          totalCount: 0,
        });
      }
      await dispatch(finishLoading());
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
      console.log("14 mars ", users.list);
    }
  };

  const handlePageChange = (page, pageSize) => {
    const obj = {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      filter: location.state?.filter || {},
    };
    setPageSize(pageSize);
    setPage(page);
    getUserData({ ...obj });
  };

  const obj = { ...location.state };

  useEffect(() => {
    setPage(Math.ceil(obj.offset / pageSize) + 1 || 1);
    getUserData(obj);
  }, []);

  return (
    <>
      <div className="UserDirectory-page">
        <div className="table-design">
          <UserDirectoryFilterBar
            pageSize={pageSize}
            setPage={setPage}
            title="Client Directory"
            filterData={getUserData}
          />
          <CustomTable dataSource={users.list} columns={columns} />
        </div>
        <CustomPagination
          totalRecords={users.totalCount}
          defaultCurrent={page}
          total={users.totalCount}
          pageSize={pageSize}
          onChange={handlePageChange}
          current={page}
        />
      </div>

      {isModalOpen && (
        <DetailModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      )}

      {isModalOpenBlocked && (
        <CustomModal
          className="custom-modal verificationmodal"
          okText="Approve"
          title={
            <div style={{ display: "flex", justifyContent: "center" }}>
              Are you sure you want to {blockModalTitle}?
            </div>
          }
          width={500}
          open={isModalOpenBlocked}
          centered
          closeIcon={true}
          maskClosable={false}
          footer={[
            <Button
              key="cancel"
              type="default"
              htmlType="button"
              onClick={handleBlockCancel}
            >
              Cancel
            </Button>,
            <Button
              key="yes"
              type="primary"
              htmlType="button"
              onClick={handleBlockOk}
            >
              Yes
            </Button>,
          ]}
        >
          <TextArea
            rows={4}
            placeholder="Enter Reason"
            value={blockReason}
            onChange={(e) => setBlockReason(e.target.value)}
          />
        </CustomModal>
      )}

      <CustomModal
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            Are you sure you want to delete?
          </div>
        }
        open={openDeleteModal}
        footer={[
          <Button
            key="cancel"
            type="default"
            htmlType="button"
            onClick={() => setOpenDeleteModal(false)}
          >
            Cancel
          </Button>,
          <Button
            key="yes"
            type="primary"
            htmlType="button"
            onClick={handleDeleteUser}
          >
            Yes
          </Button>,
        ]}
      ></CustomModal>
    </>
  );
};

export default UserDirectory;
