import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./features/auth/authSlice";
import loadingSlice from "./features/loading/loadingSlice";
import adminSlice from "./features/admin/adminSlice";
import userSlice from "./features/user/userSlice";
import kycSlice from "./features/kyc/kycSlice";
import activitySlice from "./features/activity/activitySlice";
import settingSlice from "./features/setting/settingSlice";
import markettingSlice from "./features/marketting/markettingSlice";
import { WalletActions } from "./features/wallet/walletActions";
import { setupListeners } from "@reduxjs/toolkit/query";
import complianceSlice from "./features/compliance/complianceSlice";
import downloadSlice from "./features/download/downloadSlice";
import clientSupportSlice from "./features/clientSupport/clientSupportSlice";
import bankSlice from "./features/banking/bankingSlice";
import riskManagementSlice from "./features/riskManagement/riskManagementSlice";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import swapSlice from "./features/swap/swapSlice";
import nonRefernceSlice from "./features/nonReference/nonReferenceSlice";
import tradeManageMentSlice from "./features/setting/tradeManagementSlice";
const appReducer = combineReducers({
  dashboard: dashboardReducer,
  auth: authReducer,
  loading: loadingSlice,
  admin: adminSlice,
  user: userSlice,
  kyc: kycSlice,
  activity: activitySlice,
  setting: settingSlice,
  download: downloadSlice,
  marketting: markettingSlice,
  wallet: WalletActions.getLiquidityAndAssetsRtk.reducer,
  compliance: complianceSlice,
  clietSupport: clientSupportSlice,
  bank: bankSlice,
  riskManagement: riskManagementSlice,
  swapLiquidy: swapSlice,
  nonReferences: nonRefernceSlice,
  tradeManageMent: tradeManageMentSlice,
});

const persistConfig = {
  key: "root",
  storage,
  // Optionally, you can whitelist specific reducers to be persisted
  // whitelist: ['user'],
};

const rootReducer = (state, action) => {
  if (action.type === "admin/logout") {
    return appReducer({}, action);
  }
  return appReducer(state, action);
};
const stateSyncConfig = {
  whitelist: ["admin/logout"], // Log out action
  incomingActionTransformer: (action, state) => {
    return (state = undefined);
  },
  // outgoingActionTransformer: (action, state) => {
  //   return action; // Modify this as needed
  // },
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const stateSyncMiddleware = createStateSyncMiddleware(stateSyncConfig);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
      .concat(WalletActions.getLiquidityAndAssetsRtk.middleware)
      .concat(stateSyncMiddleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
