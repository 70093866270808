import React from "react";
import style from "./style.module.scss";

function Currencycard({ img, heading, data, rightdata }) {
  return (
    <div className={style.currencycard}>
      <div className={style.leftdata}>
        <span>{img}</span>
        <p>
          <h4>{heading}</h4>
          {data}
        </p>
      </div>
      <h4>{rightdata}</h4>
    </div>
  );
}

export default Currencycard;
