import React from "react";
import { Button, Modal } from "antd";
import style from "./style.module.scss";
import { EditOutlined } from "@ant-design/icons";
import InputCustom from "../../../Components/InputCustom/InputCustom";
import styles from "../../Login/style.module.scss";
import TextArea from "antd/es/input/TextArea";
import { getColorRiskManagement } from "../../../utils/common.utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { riskManagementActions } from "../../../redux/features/riskManagement/riskManagementActions";


const RiskManagementEditModal = ({ isModalOpen, handleCancel, questionnaire }) => {
  const [manualScore, setManualScore] = useState('');
  const [reason, setReason] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setManualScore(0);
    setReason("");
    handleCancel();
  };


  const handleUpdate = async() => {
    setLoading(true)
   await dispatch(
      riskManagementActions.updateManualScore({
        questionnaireId: questionnaire.id,
        manualScore: manualScore,
        text: reason,
      })
    );

    setLoading(false)
    handleModalClose()
  };
 
  const handleManualScoreChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (e.target.value === '' || (!isNaN(value) && value >= 0 && value <= 30)) {
      setManualScore(e.target.value);
      setValidationMessage("");
    } else if (value > 30) {
      setValidationMessage("The score must be between 0 and 30.");
    }
  };

  return (
    <>
      <Modal
        className="custom-modal edit"
        open={isModalOpen}
        onCancel={handleCancel}
        width={390}
        centered
        footer={[
          <Button
            loading={loading}
            key="1"
            type="primary"
            size="large"
            onClick={() => handleUpdate()}
            className={style.edit_btn}
            disabled={reason==='' || manualScore === "" }
          >
            Update
          </Button>,
        ]}
      >
        <div className={style.edit_header}>
          <EditOutlined /> Edit
        </div>

        <div className={style.hr} />

        <div>
          <p className={style.edit_label}>Item</p>
          <p className={style.edit_title}>{questionnaire?.question?.title}</p>
        </div>

        <div>
          <p className={style.edit_label}>Answer</p>
          <p className={style.edit_descript}>{questionnaire?.answer?.title}</p>
        </div>

        <div className={style.edit_score}>
          <div>
            <p className={style.edit_label}>System Sore</p>
            <div
              style={{ color: getColorRiskManagement(questionnaire?.answer?.score) }}
              className={style.edit_score_box}
            >
              {questionnaire?.answer?.score}
            </div>
          </div>

          <div>
            <p className={style.edit_label}>Manual Score</p>
            <InputCustom
              value={manualScore}
              onInput={handleManualScoreChange}
              customClass={`${styles.innerspacing}`}
              placeholder={"0"}
              borderRadius={10}
              height={40}
            />
            {validationMessage && (
              <div className={style.validation_message}>{validationMessage}</div>
            )}
          </div>
        </div>

        <div>
          <p className={style.edit_label}>Reason</p>
          <TextArea rows={4} value={reason} onChange={(e) => setReason(e.target.value)} />
        </div>
      </Modal>
    </>
  );
};

export default RiskManagementEditModal;
