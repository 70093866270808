import React, { useEffect, useState } from "react";
import InputCustom from "../InputCustom/InputCustom";
import { Button, Image, Modal, Switch } from "antd";
import ButtonCommon from "../Buttoncommon/ButtonCommon.jsx";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Validations } from "../../validations";
import { FormError } from "../FormErrors/FormError";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../redux/features/auth/authActions";
import { AdminActions } from "../../redux/features/admin/adminActions.js";
import { setNameOnProfileUpdate } from "../../redux/features/admin/adminSlice";
import { startLoading, finishLoading } from '../../redux/features/loading/loadingSlice';
import { formatDateTime } from "../../utils/common.utils";


const BasicSettings = ({ style, email, firstName, lastName, twoFa, lastLoginTime }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,

  } = useForm({
    resolver: yupResolver(Validations.settings(isModalOpen)),
    mode: "all",
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      emailId: email,
      twoFAEnabled: twoFa,
    },
  });


 
  const [twoFaValue, setTwoFaValue] = useState(twoFa);
  const [qrUrl, setQrUrl] = useState("");
  const [checkedValue, setCheckedValue] = useState(twoFa);

  const onChange = async (checked) => {

    try {
      dispatch(startLoading())
      setIsModalOpen(true);
      setValue("otp", "");
      if (checked === true) {
        const res = await dispatch(AdminActions.getQrCode()).unwrap()

        setQrUrl(res.link);
      }
      setCheckedValue(checked);

    } catch (error) {

    } finally {
     dispatch(finishLoading())
    }
  }

  const handlePinCancel = () => {

    setTwoFaValue(twoFa);
    setIsModalOpen(false);
    setQrUrl("");
  }

  const handlePinVerify = async (values) => {
    try {
      dispatch(startLoading())
      await dispatch(AdminActions.verifyPin(values.otp)).unwrap()
      setIsModalOpen(false);
      setQrUrl("");
      setTwoFaValue(checkedValue);
    } catch (error) {

    } finally {
      dispatch(finishLoading())
    }
  }

  const onSubmit = async (values) => {
    

    try {
      dispatch(startLoading())
      await dispatch(AuthActions.updateProfile({
        firstName: values.firstName,
        lastName: values.lastName,
        email: email,
        is2FaEnable: twoFaValue,
      })
      ).unwrap()

      dispatch(setNameOnProfileUpdate({
        firstName: values.firstName,
        lastName: values.lastName
      }))

    } catch (error) {

    } finally {
     dispatch(finishLoading())
    }
  };

  const resetForm = () => {
    reset({
      firstName: "",
      lastName: "",
    });
  };

  const numbersOnly = (e) => {
    if (isNaN(e.key)) e.preventDefault();
  }


  useEffect(() => {
    setTwoFaValue(twoFa)
  }, [twoFa])

  return (
    <div className={style.setting_left}>
      <h5>My Profile</h5>
      <h3>{email}</h3>
      <p>
        {/* Last Login <span>: 12:01:23 12:30:01 UTC</span> */}
        Last Login <span>: {lastLoginTime ? formatDateTime(lastLoginTime) : "12:01:23 12:30:01 UTC"}</span>

        
      </p>

      <div className={style.setting_left_input}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <InputCustom
                type="text"
                settingInput="settingInput"
                custommClass1="custommClass1"
                placeholder={"First Name"}
                label={"First Name"}
                borderRadius={10}
                height={46}
                {...field}
              />
            )}
          />
          <FormError message={errors?.firstName?.message} />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <InputCustom
                type="text"
                settingInput="settingInput"
                custommClass1="custommClass1"
                placeholder={"Last Name"}
                label={"Last Name"}
                borderRadius={10}
                height={46}
                {...field}
              />
            )}
          />
          <FormError message={errors?.lastName?.message} />
          <Controller
            name="emailId"
            control={control}
            render={({ field }) => (
              <InputCustom
                type="email"
                settingInput="settingInput"
                custommClass1="custommClass1"
                value={email}
                label={"Email ID"}
                borderRadius={10}
                height={46}
                {...field}
                disabled
              />
            )}
          />
          <FormError message={errors?.emailId?.message} />

          <div className={style.setting_left_input__2fa}>
            <h3>2FA enable</h3>
            <Controller
              control={control}
              name="twoFAEnabled"
              render={({ field: { value } }) => (
                <Switch defaultChecked={twoFa} checked={twoFaValue} onChange={(checked) => onChange(checked)} />
              )}
            />
          </div>
          <FormError message={errors?.twoFAEnabled?.message} />

          <div className={style.setting_right_input__button}>
            <ButtonCommon
              text="Reset"
              height={46}
              transBtn="transBtn"
              htmlType={"button"}
              onClick={resetForm}
            />
            <ButtonCommon
              text="Save Changes"
              height={46}
              customBlueBtn="customBlueBtn"
              htmlType={"submit"}
            />
          </div>
        </form>
      </div>

      {isModalOpen && (
        <Modal
          open={isModalOpen}
          className="custom-modal verificationmodal"
          width={403}
          centered
          closeIcon={true}
          maskClosable={false}
          footer={null}
        >

          {twoFaValue === false ?
            <>
              <p style={{ padding: "48px auto 18px auto", textAlign: "center", }}>
                <b>Scan The QR Code</b>
              </p>
              <img
                width={200}

                src={qrUrl}
                alt=" Qr Not Found"
                style={{ marginLeft: 'auto', display: 'block', marginRight: 'auto', marginBottom: '15px' }}
              />
            </>
            : <p style={{ padding: "48px auto 18px auto", textAlign: "center", }}>
              <b>Enter Code</b>
            </p>
          }



          <form onSubmit={handleSubmit(handlePinVerify)} noValidate>
            <Controller
              name="otp"
              control={control}
              render={({ field }) => (
                <InputCustom
                  type="tel"
                  settingInput="settingInput"
                  custommClass1="custommClass1"
                  placeholder={"6-Digit code"}
                  borderRadius={10}
                  height={46}
                  maxlength={6}
                  onKeyPress={(e) => numbersOnly(e)}
                  {...field}
                />
              )}
            />
            <FormError message={errors?.otp?.message} />


            <div className={style.setting_right_input__button}>

              <ButtonCommon text="Close" key="cancel" type="primary" danger={true} onClick={handlePinCancel}/>
    

              <ButtonCommon text="Verify" key="verifdy" type="primary" htmlType={"submit"}/>
          
            </div>

          </form>

        </Modal>
      )}
    </div>
  );
};

export default BasicSettings;
