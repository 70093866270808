import React, { useEffect, useState } from 'react';
import { Table, Modal, Popover, Input, Button, Select, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Edit from '../../../Assets/Images/greyEdit.svg';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Validations } from '../../../validations';
import { Utils } from '../../../utils';
import { useDispatch } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import { SettingActions } from '../../../redux/features/setting/settingActions';
import { TransactionFeeService } from '../../../services/transaction-fee.service';
import { FormError } from '../../../Components/FormErrors/FormError';
import PermissionCheck from '../../../Components/PermissionCheck/PermissionCheck.jsx';
import {
  checkEmptyString,
  createFullName,
  formatAdminNameWithRole,
} from '../../../utils/common.utils';
import { BankingActions } from '../../../redux/features/banking/bankingActions';

const CryptoTransationSystem = ({ settings, getWithdrawFee }) => {
  console.log({ settingData: settings });

  const [selectedCoin, setSelectedCoin] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switchValue, setSwitchValue] = useState({
    status: '',
  });
  const [feeType, setFeeType] = useState('');
  console.log('feeType::', feeType);
  const onChange = (checked) => {
    setSwitchValue({
      status: checked ? 1 : 0,
    });
    console.log(`switch to ${checked}`);
  };

  const dispatch = useDispatch();

  const [feeDetails, setFeeDetails] = useState({
    name: '',
    role: '',
    updatedAt: '',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(Validations.transactionLimits),
    mode: 'all',
  });
  const values = { ...selectedCoin };

  console.log('selectedCoin', selectedCoin);

  const initialValues = {
    withdrawalFee: values?.withdrawFee,
    withdrawFee: values?.withdrawFee,

    withdrawFeeType: values?.withdrawFeeType,

    internalTransferFee: values?.internalTransferFee,
    externalTransferFee: Number(values?.withdrawFee),

    internalMinimumWithdraw: TransactionFeeService.getFeeAmountAfterPrecision(
      values.coin,
      values?.internalMinimumWithdraw
    ),
    internalMaximumWithdraw: TransactionFeeService.getFeeAmountAfterPrecision(
      values.coin,
      values?.internalMaximumWithdraw
    ),
    miniExternalWithdraw: TransactionFeeService.getFeeAmountAfterPrecision(
      values.coin,
      values?.minimumWithdraw
    ),
    maxiExternalWithdraw: TransactionFeeService.getFeeAmountAfterPrecision(
      values.coin,
      values?.maximumWithdraw
    ),
    documentMaxLimitAmount: TransactionFeeService.getFeeAmountAfterPrecision(
      values.coin,
      values?.docMaximumWithdraw
    ),
  };

  const afterPoints = [
    {
      id: 1,
      title: 'Last Updated On:',
      Detail: Utils.common.formatDateTime(selectedCoin?.lastUpdatedBy),
    },
    {
      id: 2,
      title: 'Last Updated By:',
      Detail: formatAdminNameWithRole(feeDetails?.name, feeDetails?.role),
    },
  ];

  const showModal = (record) => {
    console.log('record', record);

    setSwitchValue({
      status: record.status,
    });
    setSelectedCoin(record);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setError('withdrawFee', '');
    reset({
      internalMinimumWithdraw: '',
      internalMaximumWithdraw: '',
      miniExternalWithdraw: '',
      maxiExternalWithdraw: '',
    });
  };

  const formatTransactionSettings = (data) => {
    if (data && data.length > 0) {
      return data.map((record) => {
        return {
          ...record,
          withdrawFee:
            record.withdrawFeeType === 'flat'
              ? TransactionFeeService.getFeeAmountAfterPrecision(
                record.coin,
                record.withdrawFee
              )
              : record.withdrawFee,
          isErc20token: record.isErc20token,
        };
      });
    }
    return [];
  };

  const columns = [
    {
      title: 'Currency',
      dataIndex: 'coin',
      key: 'coin',
      render: (_, record) => {
        return (
          <span className='inlineFlex' style={{ gap: '5px' }}>
            <img
              src={TransactionFeeService.getImageByTokenName(record.coin)}
              style={{ width: '30px', height: 'auto' }}
            />
            {checkEmptyString(record.coin?.toUpperCase())}
          </span>
        );
      },
    },
    {
      title: ' Fee Type',
      dataIndex: 'withdrawFeeType',
      key: 'withdrawFeeType',
    },
    {
      title: 'External Transfer Fee',
      dataIndex: 'withdrawFee',
      key: 'withdrawFee',
      render: (_, record) => record.withdrawFee,
    },

    {
      title: 'Internal Transfer Fee',
      dataIndex: 'internalTransferFee',
      key: 'internalTransferFee',
      render: (_, record) => record.internalTransferFee,
    },
    {
      title: 'Min External Withdrawal',
      dataIndex: 'minimumWithdraw',
      key: 'minimumWithdraw',
      render: (_, record) =>
        TransactionFeeService.getFeeAmountAfterPrecision(
          record.coin,
          record.minimumWithdraw
        ),
    },
    {
      title: 'Max External Withdrawal',
      dataIndex: 'maximumWithdraw',
      key: 'maximumWithdraw',
      render: (_, record) =>
        TransactionFeeService.getFeeAmountAfterPrecision(
          record.coin,
          record.maximumWithdraw
        ),
    },
    {
      title: 'Min Internal Withdrawal ',
      dataIndex: 'internalMinimumWithdraw',
      key: 'internalMinimumWithdraw',
      render: (_, record) =>
        TransactionFeeService.getFeeAmountAfterPrecision(
          record.coin,
          record.internalMinimumWithdraw
        ),
    },
    {
      title: 'Max Internal Withdrawal ',
      dataIndex: 'internalMaximumWithdraw',
      key: 'internalMaximumWithdraw',
      render: (_, record) =>
        TransactionFeeService.getFeeAmountAfterPrecision(
          record.coin,
          record.internalMaximumWithdraw
        ),
    },
    // {
    //   title: 'Daily Limit',
    //   dataIndex: 'dailyTrxLimit',
    //   key: 'dailyTrxLimit',
    //   render: (_, record) =>
    //     TransactionFeeService.getFeeAmountAfterPrecision(
    //       record.coin,
    //       record.dailyTrxLimit
    //     ),
    // },
    {
      title: 'Doc Maximum Withdraw',
      dataIndex: 'docMaximumWithdraw',
      key: 'docMaximumWithdraw',
      render: (_, record) =>
        TransactionFeeService.getFeeAmountAfterPrecision(
          record.coin,
          record.docMaximumWithdraw
        ),
    },

    {
      title: 'Action',
      render: (_, record) => (
        <PermissionCheck>
          <img
            src={Edit}
            style={{ cursor: 'pointer' }}
            alt='icon'
            onClick={() => {
              showModal(record);
              setFeeType(record?.withdrawFeeType);
            }}
          />
        </PermissionCheck>
      ),
    },
  ];

  const onSubmit = () => {
    const values = getValues();

    console.log(values);
    const data = {
      withdrawFee: values?.externalTransferFee,
      withdrawFeeType: values?.withdrawFeeType,
      coin: selectedCoin.coin,
      maximumWithdraw: values?.maxiExternalWithdraw,
      minimumWithdraw: values?.miniExternalWithdraw,
      internalMinimumWithdraw: values?.internalMinimumWithdraw,
      internalMaximumWithdraw: values?.internalMaximumWithdraw,
      internalTransferFee: values?.internalTransferFee,
      docMaximumWithdraw: values?.documentMaxLimitAmount,
    };

    editWithdrawFee(data);
  };

  const editWithdrawFee = async (data) => {
    try {
      await dispatch(startLoading());
      await dispatch(SettingActions.editWithdrawFee(data));
      setIsModalOpen(false);
      getWithdrawFee({
        status: 1,
      });
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const getFeeStatus = async () => {
    try {
      dispatch(startLoading());
      console.log({ selectedCoin });
      let data = {
        moduleId: selectedCoin?.coin,
        module: 'CRYPTO_FEE_SETTING',
      };
      const res = await dispatch(BankingActions.getFeeStatus(data)).unwrap();
      if (res) {
        setFeeDetails({
          name: createFullName(res.admin?.firstName, res.admin?.lastName),
          role: res.admin?.title,
          updatedAt: res.updatedAt,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    Object.keys(initialValues).forEach((item) => {
      setValue(item, initialValues[item]);
    });
    getFeeStatus();
  }, [selectedCoin]);

  return (
    <>
      <div className='table-design'>
        <div className='title-filter'>
          <h3>Coins | Tokens</h3>
        </div>
        <div className='custom-table'>
          <Table
            dataSource={formatTransactionSettings(settings)}
            columns={columns}
            pagination={false}
          //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>

      <Modal
        className='custom-modal small-btn'
        title='Crypto Wallet'
        open={isModalOpen}
        okText='Submit'
        closeIcon={true}
        centered
        footer={[
          <Button
            type='primary'
            htmlType='submit'
            key='1'
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>,
          <Button type='link' key='2' onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div className='modal-inner'>
          <div>
            <span
              style={{
                display: 'inline-flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <img
                src={TransactionFeeService.getImageByTokenName(
                  selectedCoin?.coin
                )}
                style={{ width: '30px', height: 'auto' }}
              />
              <b>{checkEmptyString(selectedCoin?.coin?.toUpperCase())}</b>
            </span>
          </div>
          <form className='withdrawFee'>
            <Controller
              name='withdrawFeeType'
              control={control}
              render={({ field }) => (
                <>
                  <span
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    Fee Type
                    <Popover
                      placement='topLeft'
                      content='Please select the fee type: Flat or Percentage '
                    >
                      <QuestionCircleOutlined style={{ color: 'grey' }} />
                    </Popover>
                  </span>
                  <div>
                    <Select
                      placeholder='Type'
                      {...field}
                      // disabled={switchValue.status === 0}
                      onChange={(value) => {
                        field.onChange(value); // Updates the form state
                        setFeeType(value); // Updates the component state
                      }}
                      style={{
                        minWidth: '150px',
                      }}

                      options={[
                        {
                          value: 'flat',
                          label: 'Flat',
                        },
                        {
                          value: 'percentage',
                          label: 'Percentage',
                        },
                      ]}
                    />
                  </div>

                  {/* {errors.withdrawFee && (
                    <span>{errors.withdrawFee.message}</span>
                  )} */}
                </>
              )}
            />

            {/* Withdrawal Fee */}
            <Space>
              <Controller
                name='externalTransferFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      External fee
                      <Popover
                        placement='topLeft'
                        content='Please set the fee for crypto external transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      suffix={
                        feeType === 'percentage'
                          ? '%'
                          : selectedCoin?.coin?.toUpperCase()
                      }
                    />
                    {errors.externalTransferFee && (
                      <FormError message={errors.externalTransferFee.message} />
                    )}
                  </>
                )}
              />
              <Controller
                name='internalTransferFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Internal Fee
                      <Popover
                        placement='topLeft'
                        content='Please set the fee for crypto internal transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      suffix={
                        feeType === 'percentage'
                          ? '%'
                          : selectedCoin?.coin?.toUpperCase()
                      }
                    />
                    {errors.internalTransferFee && (
                      <FormError message={errors.internalTransferFee.message} />
                    )}
                  </>
                )}
              />
            </Space>
            <Space>
              <Controller
                name='internalMinimumWithdraw'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Min Withdrawal Internal
                      <Popover
                        placement='topLeft'
                        content='Please set the minimum amount for crypto transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors('internalMinimumWithdraw');
                          clearErrors('internalMaximumWithdraw'); // Clear max fee error if conditions are met
                        }
                      }}
                      suffix={selectedCoin?.coin?.toUpperCase()}
                    // disabled={switchValue.status === 0}
                    />
                    {errors.internalMinimumWithdraw && (
                      <FormError
                        message={errors.internalMinimumWithdraw.message}
                      />
                    )}
                  </>
                )}
              />
              <Controller
                name='internalMaximumWithdraw'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Max Withdrawal Internal
                      <Popover
                        placement='topLeft'
                        content='Please set the maximum amount for crypto transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors('internalMaximumWithdraw'); // Clear max fee error if conditions are met
                          clearErrors('internalMinimumWithdraw');
                        }
                      }}
                      suffix={selectedCoin?.coin?.toUpperCase()}
                    // disabled={switchValue.status === 0}
                    />
                    {errors.internalMaximumWithdraw && (
                      <FormError
                        message={errors.internalMaximumWithdraw.message}
                      />
                    )}
                  </>
                )}
              />
            </Space>

            <Space>
              <Controller
                name='miniExternalWithdraw'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Min Withdrawal External
                      <Popover
                        placement='topLeft'
                        content='Please set the minimum amount for crypto transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors('miniExternalWithdraw'); // Clear max fee error if conditions are met
                          clearErrors('maxiExternalWithdraw');
                        }
                      }}
                      suffix={selectedCoin?.coin?.toUpperCase()}
                    // disabled={switchValue.status === 0}
                    />
                    {errors.miniExternalWithdraw && (
                      <FormError
                        message={errors.miniExternalWithdraw.message}
                      />
                    )}
                  </>
                )}
              />
              <Controller
                name='maxiExternalWithdraw'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Max Withdrawal External
                      <Popover
                        placement='topLeft'
                        content='Please set the maximum amount for crypto transfers'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e); // Update the value in form state
                        if (value && !isNaN(value)) {
                          clearErrors('maxiExternalWithdraw');
                          clearErrors('miniExternalWithdraw'); // Clear max fee error if conditions are met
                        }
                      }}
                      suffix={selectedCoin?.coin?.toUpperCase()}
                    // disabled={switchValue.status === 0}
                    />
                    {errors.maxiExternalWithdraw && (
                      <FormError
                        message={errors.maxiExternalWithdraw.message}
                      />
                    )}
                  </>
                )}
              />
            </Space>

            {/* For document and daily Limit */}

            <Space>
              {/* <Controller
                name="dailyLimitAmount"
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Daily  Limit (
                      <b>
                        {checkEmptyString(selectedCoin?.coin?.toUpperCase())}
                      </b>
                      )
                      <Popover
                        placement="topLeft"
                        content="Please set the daily transfer limit amout"
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder="0.00"
                      {...field}
                      suffix={<img src={Edit} alt="icon" />}
                      // disabled={switchValue.status === 0}
                    />
                    {errors.dailyLimitAmount && (
                      <FormError
                        message={errors.dailyLimitAmount.message}
                      />
                    )}
                  </>
                )}
              /> */}
              <Controller
                name='documentMaxLimitAmount'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Doc Verification Limit
                      <Popover
                        placement='topLeft'
                        content='Please set the maximum withdrawal limit with the document for transactions'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field}
                      suffix={selectedCoin?.coin?.toUpperCase()}
                    // disabled={switchValue.status === 0}
                    />
                    {errors.documentMaxLimitAmount && (
                      <FormError
                        message={errors.documentMaxLimitAmount.message}
                      />
                    )}
                  </>
                )}
              />
            </Space>
          </form>
          <ul style={{ marginTop: '100px' }}>
            {afterPoints.map((point) => (
              <li key={point.id}>
                <span>{point.title}</span>
                <span>{point.Detail}</span>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default CryptoTransationSystem;
