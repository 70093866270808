import { createSlice } from '@reduxjs/toolkit'
import {riskManagementActions} from './riskManagementActions'

const initialState = {
    data:null
}


export const riskManagementSlice = createSlice({
    name: 'riskManagement',
    initialState,
    reducers:{
    },
    extraReducers : (builder) =>  {
        builder
        .addCase(riskManagementActions.getQuestionnaire.fulfilled, (state, action) => {
            state.data = action.payload;
          })
        .addCase(riskManagementActions.getQuestionnaire.rejected, (state, action) => {
            state.data = null;   
        })
        .addCase(riskManagementActions.updateManualScore.fulfilled, (state, action) => {
       
          })
        .addCase(riskManagementActions.updateManualScore.rejected, (state, action) => {
            state.data = null;
            
        })
    }
})

export const { updateScore } = riskManagementSlice.actions;

export default riskManagementSlice.reducer