import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { SETTING } = CONSTANTS.API_ENDPOINTS;

const getTradeManagement = createAsyncThunk(
  "setting/trade/management",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.TRADE_MANAGEMENT}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateTradeManagementStatus = createAsyncThunk(
  "setting/update/trade/management",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...restData } = data;

    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.UPDATE_TRADE_MANAGEMENT_STATUS}`,
        data: restData,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

export const SettingTradeActions = {
  getTradeManagement,
  updateTradeManagementStatus,
};
