import * as CryptoJS from "crypto-js";
import { CONSTANTS } from "../constants";

class EncryptionService {
  encrypt(text) {
    const ciphertext = CryptoJS.AES.encrypt(text, CONSTANTS.ENV.ENCRYPTION_KEY).toString();
    return ciphertext;
  }

  decrypt(ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, CONSTANTS.ENV.ENCRYPTION_KEY);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  }
}

export default new EncryptionService();