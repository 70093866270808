import React from 'react';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import { Button } from 'antd';
import { UserActions } from '../../../redux/features/user/userActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setSelectedApiData } from '../../../redux/features/user/userSlice';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import { KycActions } from '../../../redux/features/kyc/kycActions';
import { useLocation } from 'react-router-dom';
import { setDocList } from '../../../redux/features/kyc/kycSlice';

function DeleteAdditionalDocuments({
  showDeleteDocModal,
  SetShowDeleteDocModal,
  deleteAdditionalDocDetails,
  setDeleteAdditionalDocDetails,
}) {
  const userId = deleteAdditionalDocDetails?.userId;
  const dispatch = useDispatch();
  const location = useLocation();
  const handleDeleteAdditionalDoc = async () => {
    try {
      dispatch(startLoading());

      let data = {};

      if (location.pathname === '/user-directory-view' || location.pathname === '/user-verification' || location.pathname === '/sanction-report-kyc') {
        data = {
          imagePath: deleteAdditionalDocDetails?.docPath,
          docId: deleteAdditionalDocDetails?.id,
          userId: deleteAdditionalDocDetails?.userId,
        };

        await dispatch(UserActions.deleteAdditionalDocuments(data)).unwrap();
        await getUserDetails(deleteAdditionalDocDetails?.userId);
      } 
      
      else {
        data = {
          id: deleteAdditionalDocDetails?.id,
        };
        await dispatch(UserActions.deleteAddtionalDocsKyt(data)).unwrap();


        const result = await getUploadedDocList({
          limit: '10',
          offset: '0',
          userId: deleteAdditionalDocDetails?.userId,
          txnId: location.state?.txnId,
          docType: 'ADDITIONAL',
        });
  
        dispatch(setDocList(result));
      }

      SetShowDeleteDocModal(false);
      setDeleteAdditionalDocDetails('');
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const getUserDetails = async (id) => {
    try {
      const data = await dispatch(
        UserActions.getUserDetail({ userId: id })
      ).unwrap();
      if (data?.user && data?.kyc) {
        await dispatch(setSelectedApiData(data));
      }
      return data;
    } catch (error) {
      toast.error(error);
    }
  };

  const getUploadedDocList = async (payload) => {
    try {
      const data = await dispatch(
        KycActions.getKytDocListByTxnId(payload)
      ).unwrap();
      return data;
    } catch (error) {
      return {};
    }
  };

  return (
    <div>
      <CustomModal
        title={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            Are you sure you want to delete?
          </div>
        }
        open={showDeleteDocModal}
        footer={[
          <Button
            key='cancel'
            type='default'
            htmlType='button'
            onClick={() => SetShowDeleteDocModal(false)}
          >
            Cancel
          </Button>,
          <Button
            key='yes'
            type='primary'
            htmlType='button'
            onClick={handleDeleteAdditionalDoc}
          >
            Yes
          </Button>,
        ]}
      ></CustomModal>
    </div>
  );
}

export default DeleteAdditionalDocuments;
