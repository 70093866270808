import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Individual from "./Individual";
import VerificationsScreeningMain from "../verificationsSrceening/VerificationsScreenMain";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedSanctionTab,
  setSelectedSanctionType,
} from "../../../redux/features/compliance/complianceSlice";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import { ComplianceActions } from "../../../redux/features/compliance/complianceAtion";
import Business from "./Business";
import KycVerification from "../../KycVerification/KycVerification";

const Sanaction = () => {
  const [activeKey, setActiveKey] = useState("1");
  const dispatch = useDispatch();

  const [isMngView, setIsMngView] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const selectedSanctionTab = useSelector(
    (state) => state?.compliance?.selectedSanctionTab
  );
  const onChange = (key) => {
    if (key == "1") {
      dispatch(setSelectedSanctionTab("Individual"));
    } else {
      dispatch(setSelectedSanctionTab("Business"));
    }
    setActiveKey(key);
  };

  useEffect(() => {
    if (selectedSanctionTab == "Individual") {
      setActiveKey("1");
    }
    if (selectedSanctionTab == "Business") {
      setActiveKey("2");
    }
  }, [selectedSanctionTab]);

  const [showVerificationScreen, setShowVerificationScreen] = useState(false);
  const { isInitialPageLoaded } = useSelector((state) => state.dashboard);
  const [sanctionAnalytics, setSanctionAnalytics] = useState({
    chartData: {},
    cardData: {},
    alertData: {
      rows: [],
      count: 0,
    },
  });

  const getComplianceAnalytics = async (data) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        ComplianceActions.getComplianceAnalytics(data)
      ).unwrap();

      setSanctionAnalytics({
        cardData: {
          alertRaised: res.alertRaised,
          clientVerified: res.clientVerified,
          doucmentsVerified: res.doucmentsVerified,
          rejectedRequest: res.rejectedRequest,
        },
        chartData: res.verificationChartData,
        alertData: res.allAlerts,
      });
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const items = [
    {
      key: "1",
      label: "Individual",
      children: (
        <Individual
          setShowVerificationScreen={setShowVerificationScreen}
          sanctionAnalytics={sanctionAnalytics}
          getComplianceAnalytics={getComplianceAnalytics}
          setIsMngView={setIsMngView}
          setSelectedData={setSelectedData}
        />
      ),
    },
    {
      key: "2",
      label: "Business",
      children: (
        <Business
          setShowVerificationScreen={setShowVerificationScreen}
          sanctionAnalytics={sanctionAnalytics}
          getComplianceAnalytics={getComplianceAnalytics}
          setIsMngView={setIsMngView}
          setSelectedData={setSelectedData}
        />
      ),
    },
  ];
  // useEffect(() => {
  //   if (showVerificationScreen) {
  //     dispatch(setSelectedSanctionTab("Completed"));
  //   } else {
  //     dispatch(setSelectedSanctionTab(""));
  //   }
  // }, [showVerificationScreen]);

  useEffect(() => {
    setShowVerificationScreen(false);
    // setActiveKey("1");
  }, [isInitialPageLoaded]);

  useEffect(() => {
    const data = {
      userType: activeKey === "1" ? "INDIVIDUAL" : "BUSINESS",
      limit: 10,
      offset: 0,
    };
    getComplianceAnalytics(data);
  }, [activeKey]);

  return (
    <>
      {!showVerificationScreen && !isMngView && (
        <div className="Custom-tabBtn">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            animated={true}
            activeKey={activeKey}
          />
        </div>
      )}
      {showVerificationScreen && !isMngView && (
        <VerificationsScreeningMain
          type={activeKey === "1" ? "INDIVIDUAL" : "BUSINESS"}
        />
      )}
      {isMngView && (
        <KycVerification
          userInformatoin={true}
          datamodalapprove={true}
          datamodalreject={true}
          innervalue={true}
          setIsMngView={setIsMngView}
          data={selectedData}
          setActiveKey={setActiveKey}
        />
      )}
    </>
  );
};

export default Sanaction;
