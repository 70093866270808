import React, { useCallback, useEffect, useState } from "react";
import { Table, Pagination, Select, Input, DatePicker, Button } from "antd";

import {
  DownloadOutlined,
  SearchOutlined,
  CopyOutlined,
  CloseOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";
import Filttercustom from "../Filttercustom";
import moment from "moment";
import TransactionFilters from "./TransactionFilters.jsx";
import SwapTransactionFilters from "./SwapTransactionFilters.jsx";
import TransactionDetails from "./TransactionDetails";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import CopyToClipboard from "../../../Components/CopyToClipboard/CopyToClipboard.jsx";
import {
  handleHideId,
  iconColor,
  iconSize,
} from "../../../utils/common.utils.js";
import { TransactionFeeService } from "../../../services/transaction-fee.service";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANTS } from "../../../constants";
import DownloadFile from "../../../Components/Common/DownloadFile";
import { useLocation, useNavigate } from "react-router-dom";
import { WalletActions } from "../../../redux/features/wallet/walletActions";
import { KycActions } from "../../../redux/features/kyc/kycActions";
import { finishLoading, startLoading } from "../../../redux/features/loading/loadingSlice";
import { setDocList } from "../../../redux/features/kyc/kycSlice";

const Swap = ({ data, totalCount, getSwapTransactionsOnly }) => {
  const dispatch = useDispatch();
  const [sortedInfo, setSortedInfo] = useState({});
  const [page, setPage] = useState(1);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const download = useSelector((state) => state.download);
  const userId = useSelector((state) => state.user?.data?.user?.userId);
  const location = useLocation();
  const navigate = useNavigate();


  function sortDateTimeMoment(data, isAscending = true) {
    return data.sort((a, b) => {
      const dateA = moment.utc(a.created);
      const dateB = moment.utc(b.created);

      if (isAscending) {
        return dateA.isSameOrBefore(dateB) ? -1 : 1;
      } else {
        return dateA.isSameOrAfter(dateB) ? -1 : 1;
      }
    });
  }

  const getUploadedDocList = async (payload) => {
    try {
      const data = await dispatch(
        KycActions.getKytDocListByTxnId(payload)
      ).unwrap();
      return data;
    } catch (error) {
      return {};
    }
  };

  const getTransactionDetails = async (obj) => {
    console.log('swap obj',obj);
    try {
      dispatch(startLoading())
      dispatch(setDocList({}));
      const res = await dispatch(
        WalletActions.getSwapTransactionDetails({
          typeId: obj?.typeId,
          apiAccessKey: "SWAP_TRANSACTIONS",
        })
      ).unwrap();

      let userId = res.userId;

      let uploadDocData = await getUploadedDocList({
        limit: "10",
        offset: "0",
        userId: userId,
        txnId: obj?.typeId,
        docType: "ADDITIONAL",
      });
  
      dispatch(setDocList(uploadDocData));

  
      navigate("transactions-main-detail", {state:{
        data:res,
        type:"SWAP",
        sumsubTxnFullPayload: {},
        userId:res.userId,
        txnId:obj?.typeId,
      }})
     
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading())
    }
  };

  const columns = [
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
      render: (_, record) => {
        return (
          <>
            {record?.userId ? (
              <>
                {handleHideId(record?.userId)}
                <CopyToClipboard userId={record?.userId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Txn Id",
      dataIndex: "orderId",
      key: "orderId",
      render: (_, record) => {
        return (
          <>
            {/* 
            {handleHideId(record?.orderId) !== "-" && (
              <CopyToClipboard userId={record?.orderId} position="top" />
            )} */}
            {record?.orderId ? (
              <>
                {handleHideId(record?.orderId)}
                <CopyToClipboard userId={record?.orderId} position="top" />
              </>
            ) : (
              "-"
            )}

            {/* {record?.orderId !== "-" && (
              <CopyToClipboard userId={record?.orderId} position="top" />
            )} */}
          </>
        );
      },
    },

    {
      title: "From Currency",
      dataIndex: "fromCurrency",
      key: "fromCurrency",
    },
    {
      title: "To Currency",
      dataIndex: "toCurrency",
      key: "toCurrency",
    },

    {
      title: "From Amount",
      dataIndex: "fromAmount",
      key: "fromAmount",
      render: (_, record) => {
        return record.fromAmount || 0;
      },
    },
    {
      title: "To Amount",
      dataIndex: "toAmount",
      key: "toAmount",
      render: (_, record) => {
        return record.toAmount || 0;
      },
    },
    {
      title: "Created on",
      dataIndex: "createdAt",
      key: "createdAt",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        // <ButtonCommon
        //   type="primary"
        //   onClick={() => getTransactionDetails(record)}
        // >
        //   View Details
        //   {console.log(record, "recordrecordSwap")}
        // </ButtonCommon>
        <EyeOutlined
          style={{ color: iconColor, fontSize: iconSize }}
          onClick={() => getTransactionDetails(record)}
        />
      ),
    },
  ];

 
  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  const handlePageChange = (page, limit) => {
    if (!download.isEnabled) setPage(page);
    const data = {
      offset: (page - 1) * limit,
      limit,
      userId: location.pathname === "/user-directory-view" ? userId : undefined,
    };
    getSwapTransactionsOnly(data);
  };

  const handleCancel = () => {
    setShowTransactionDetails(false);
  };

  useEffect(() => {
    const { isEnabled, path, headers, rows } = download;
    if (
      isEnabled &&
      path === "SWAP_TRANSACTIONS" &&
      headers.length === 0 &&
      rows.length === 0
    ) {
      handlePageChange(1, CONSTANTS.MAX_PAGE_LIMIT_CSV);
    }
  }, [download]);

  return (
    <>
      <div className="table-design">
        <div className="title-filter flexEnd">
          <div className="title-filter_rightSec">
            {/* <TransactionFilters getSwapTransactionsOnly={getSwapTransactionsOnly} /> */}
            {/* <SwapTransactionFilters
              getSwapTransactionsOnly={getSwapTransactionsOnly}
            /> */}

            {/* <ButtonCommon custmcls="height40">
              <DownloadOutlined style={{ color: "grey" }} />
            </ButtonCommon> */}
            {/* <Filttercustom /> */}
            <DownloadFile
              fileName={`swap_txns_${new Date().toDateString()}_${new Date().toTimeString()}.csv`}
              type="CSV"
              path="SWAP_TRANSACTIONS"
            />
          </div>
        </div>
        <div className="custom-table">
          <Table
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            pagination={false}
            sortDirections={["ascend", "descend", "ascend"]}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
            // Set default sorted column and order
            //defaultSorted={{ columnKey: 'name', order: 'ascend' }}
            // Set sorted column and order from state
            //sortedInfo={sortedInfo}
          />
        </div>
      </div>
      <div className="pagination_block">
        <span className="records" style={{ marginTop: "10px" }}>
          Total Records: {totalCount}
        </span>

        {totalCount > 10 && (
          <Pagination
            defaultCurrent={page}
            total={totalCount}
            onChange={handlePageChange}
          />
        )}

        
      </div>
    </>
  );
};

export default Swap;
