import React from "react";
import QuestionaireData from "./QuestionaireData";

const QuestionaireList = ({ data }) => {
  console.log({questionaireListData: data});
  return (
    <>
      <ul className="builtPoints">
        {data.length > 0 && data.map((item) => <QuestionaireData {...item} />)}
      </ul>
    </>
  );
};

export default QuestionaireList;
