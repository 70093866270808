import React, { useEffect, useState } from "react";
import { Table, Pagination, Button, Space } from "antd";
import TableFilterTittle from "../../../Components/TableFilterTittle/TableFilterTittle.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice.js";
import { WalletActions } from "../../../redux/features/wallet/walletActions.js";
import { useLocation, useNavigate } from "react-router-dom";
import { Utils } from "../../../utils/index.js";
import {
  checkEmptyString,
  handleHideId,
  iconColor,
  iconSize,
} from "../../../utils/common.utils.js";
import CopyToClipboard from "../../../Components/CopyToClipboard/CopyToClipboard.jsx";
import {
  formatTxnType,
  getUserIdFromTransaction,
} from "../../../services/transaction.service.js";
import FiatTransactionFilters from "../../UserDirectory/transations/FiatTransactionFilters.jsx";
import TransactionDetails from "../../UserDirectory/transations/TransactionDetails.jsx";
import CustomModal from "../../../Components/CustomModal/CustomModal.jsx";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon.jsx";
import { BankingActions } from "../../../redux/features/banking/bankingActions.js";
import { CheckOutlined, EyeOutlined, CloseOutlined } from "@ant-design/icons";
import ApproveFiatTxnModal from "../../UserDirectory/transations/ApproveFiatTxnModal.jsx";
import { KycActions } from "../../../redux/features/kyc/kycActions.js";
import { setDocList } from "../../../redux/features/kyc/kycSlice.js";
import { TransactionFeeService } from "../../../services/transaction-fee.service.js";

const FaitTransactions = () => {
  const [fiatTransactions, setFiatTransactions] = useState({
    list: [],
    totalCount: 0,
  });

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [clickedTransaction, setClickedTransaction] = useState(null);
  const [showCancelFiat, setShowCancelFiat] = useState(false);
  const [txnRecord, setTxnRecord] = useState(null);
  const [showApproveFiatTxn, setShowApproveFiatTxn] = useState(false);
  const selectedFiatTab = useSelector(
    (state) => state?.nonReferences?.selectedFiatTab
  );
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { formatFiatValues } = Utils.common;
  const [selectedType, setSelectedType] = useState({
    type: -1,
    isCrypto: 0,
  });

  const [filterData, setFilterData] = useState({});

  const handleCancel = () => {
    setShowTransactionDetails(false);
  };
  const showCancelFiatTxn = (record) => {
    setShowCancelFiat(true);

    setSelectedTransaction(record);
  };
  const handleCancelFiatTxn = async () => {
    if (showCancelFiat) {
      try {
        dispatch(startLoading());
        const res = await dispatch(
          BankingActions.cancelFiatTxnStatus({
            status: "REJECTED",
            transactionId: selectedTransaction?.txnId,
            apiAccessKey: "FIAT_TRANSACTIONS",
          })
        ).unwrap();
        setShowCancelFiat(false);
        let data = {
          clientId: "",
          coin: "",
          page,
          size: pageSize,
          txStatus: filterData.status || "ALL",
          type: filterData.type || "all",
        };

        getFiatTransactions(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(finishLoading());
      }
    }
  };

  const showApprovedFiatModal = (record) => {
    setShowApproveFiatTxn(true);
    setSelectedTransaction(record);
  };

  const columns = [
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (_, record) => {
        return typeof record.currency === "string"
          ? record.currency.toUpperCase()
          : "-";
      },
    },
    {
      title: "Reference Id",
      dataIndex: "referenceId",
      key: "referenceId",
      render: (_, record) => {
        return checkEmptyString(record.referenceId);
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        return formatFiatValues(Number(record?.amount));
      },
    },
    {
      title: "Type Id",
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        return (
          <>
            {record?.id ? (
              <>
                {handleHideId(record?.id)}
                <CopyToClipboard userId={record?.id} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => {
        return (
          <>
            <p>{formatTxnType(record?.type)}</p>
          </>
        );
      },
    },
    {
      title: "Created on",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => a.created.localeCompare(b.created),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (_, record) => {
        return (
          <>
            <Space className="groupTxnBtn">
              {record &&
                record?.status === "PENDING" &&
                record?.adminApproval == 1 &&
                record?.isCrypto == 0 && 
                !record?.kytStatus &&
                (
                  <ButtonCommon
                    className="filter-btn"
                    reject="approve"
                    width={45}
                    onClick={() => showApprovedFiatModal(record)}
                  >
                    <CheckOutlined />
                  </ButtonCommon>
                )}
              {record &&
                record?.status === "PENDING" &&
                record?.isCrypto == 0 &&
                record?.adminApproval === 1 && 
                !record?.kytStatus &&
                (
                  <ButtonCommon
                    className="filter-btn"
                    reject="reject"
                    onClick={() => showCancelFiatTxn(record)}
                  >
                    <CloseOutlined />
                  </ButtonCommon>
                )}

              <EyeOutlined
                style={{ color: iconColor, fontSize: iconSize }}
                onClick={() => getTransactionDetails(record)}
              />
            </Space>
          </>
        );
      },
    },
  ];

  const columnsAllDetail = [
    // {
    //   title: "Coin",
    //   dataIndex: "coin",
    //   key: "coin",
    //   sorter: (a, b) => a.created.localeCompare(b.created),
    // },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        let renderedAmount = 0;
        if (clickedTransaction?.type === 15) {
          renderedAmount = record.amountWithFee;
        } else {
          renderedAmount = record.amount;
        }
        return formatFiatValues(renderedAmount);
      },
    },

    // render: (_, record) => {

    //   return (
    //     <p>{Utils.common.divideBySmallestUnit(record.amount, record.coin)}</p>
    //   );
    // },

    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      render: (_, record) => {
        return formatFiatValues(record.fee);
      },
    },

    {
      title: "Txn ID",
      dataIndex: "txnId",
      key: "txnId",
      render: (_, record) => {
        return (
          <>
            {record?.txnId ? (
              <>
                {handleHideId(record?.txnId)}
                <CopyToClipboard userId={record?.txnId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "From",
      dataIndex: "fromAccount",
      key: "fromAccount",
      render: (_, record) => {
        console.log(record, "recordrecordss");
        return record?.fromAccount;
      },
    },
    {
      title: "To",
      dataIndex: "toAccount",
      key: "toAccount",
      render: (_, record) => {
        return record?.toAccount;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const columnsSwapDetail = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
      render: (_, record) => {
        return (
          <>
            {record?.orderId ? (
              <>
                {handleHideId(record?.orderId)}
                <CopyToClipboard userId={record?.orderId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "From Currency",
      dataIndex: "fromCurrency",
      key: "fromCurrency",
    },
    {
      title: "From Amount",
      dataIndex: "fromAmount",
      key: "fromAmount",
    },
    {
      title: "To Amount",
      dataIndex: "toAmount",
      key: "toAmount",
    },

    {
      title: "To Currency",
      dataIndex: "toCurrency",
      key: "toCurrency",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
      render: (_, record) => {
        return (
          <>
            {record?.userId ? (
              <>
                {handleHideId(record?.userId)}
                <CopyToClipboard userId={record?.userId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
    },
  ];

  const checkType = (obj) => {
    let selectedObj = typeof obj !== "undefined" ? obj : selectedType;
    let type = "";
    if (
      selectedObj.type !== 7 &&
      selectedObj.type !== 8 &&
      selectedObj.type !== 16 &&
      selectedObj.type !== 17
    ) {
      if (selectedObj.isCrypto === 1) {
        type = "CRYPTO";
      } else if (selectedObj.isCrypto === 0) {
        type = "FIAT";
      }
    } else {
      type = "SWAP";
    }
    return type;
  };

  const getUploadedDocList = async (payload) => {
    try {
      const data = await dispatch(
        KycActions.getKytDocListByTxnId(payload)
      ).unwrap();
      return data;
    } catch (error) {
      return {};
    }
  };

  const getTransactionDetails = async (obj) => {
    try {
      dispatch(startLoading());
      setClickedTransaction(obj);
      setSelectedType({
        type: obj?.type,
        isCrypto: obj?.isCrypto,
      });
      let type = checkType(obj);
      let res = null;
      if (type === "FIAT") {
        res = await dispatch(
          BankingActions.getFiatTransactionDetails({
            transactionId: obj.txnId,
            apiAccessKey: "FIAT_TRANSACTIONS",
          })
        ).unwrap();
      } else if (type === "SWAP") {
        res = await dispatch(
          WalletActions.getSwapTransactionDetails({
            typeId: obj.id,
            apiAccessKey: "FIAT_TRANSACTIONS",
          })
        ).unwrap();
      }
      let data = {};
      if (res) {
        if (res.data) {
          data = res.data;
        } else {
          data = res;
        }
      }

      let fiatDetailData = res.data;

      let newRes = {};
      if (type === "FIAT") {
        newRes = await dispatch(
          KycActions.getKycTxnDetails({
            txnId: obj?.txnId,
            apiAccessKey: "FIAT_TRANSACTIONS",
          })
        ).unwrap();
      }
      let userId = "";
      if (type === "FIAT") {
        userId = getUserIdFromTransaction(fiatDetailData, type, obj.type);
      } else if (type === "CRYPTO") {
        // userId = getUserIdFromTransaction()
      }

      let uploadDocData = await getUploadedDocList({
        limit: "10",
        offset: "0",
        userId: userId,
        txnId: obj.txnId,
        docType: "ADDITIONAL",
      });

      dispatch(setDocList(uploadDocData));

      navigate("transactions-main-detail", {
        state: {
          data,
          type,
          clickedType: obj?.type,
          sumsubTxnFullPayload: newRes,
          txnId: obj?.txnId,
          userId: userId,
          typeValue : obj.type,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const formatTransactionData = (data) => {
    const { formatDateTime, checkEmptyString } = Utils.common;
    return data.map((obj, index) => {
      return {
        key: index + 1,
        created: formatDateTime(obj.createdAt),
        amount: obj.amount,
        currency: obj.coin,
        txnId: checkEmptyString(obj.typeId),
        type: obj.type,
        fee: obj.fee,
        from: obj.fromAddress,
        to: obj.toAddress,
        id: obj.typeId,
        clientId: obj.clientId,
        status: obj.status,
        isCrypto: obj.isCrypto,
        adminApproval: obj.adminApproval,
        kytStatus : obj.kytStatus
      };
    });
  };

  const getFiatTransactions = async (data) => {
    try {
      const updatedState = { ...location.state, ...data }; // Add or update state
      navigate("/fiat-transaction", { state: { ...updatedState } }); // Navigate to the same path with new state

      dispatch(startLoading());
      const res = await dispatch(
        WalletActions.getFiatTransactions(data)
      ).unwrap();
      console.log({ fiatRes: res });
      if (res && (res.totalCount > 0 || res.list?.length > 0)) {
        const txnData = formatTransactionData(res.list);
        setFiatTransactions({
          list: txnData,
          totalCount: res.totalCount,
        });
      } else if (res && (!res.list || res.list?.length === 0)) {
        setFiatTransactions({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const handlePageChange = (page, pageSize) => {
    let data = {
      clientId: "",
      coin: "",
      page,
      size: pageSize,
      txStatus: "ALL",
      type: "credit,debit,internal-transfer",
    };

    if (Object.keys(filterData).length > 0) {
      data = { ...filterData, page, pageSize };
    }
    setPage(page);
    setPageSize(pageSize);

    getFiatTransactions(data);
  };

  const onSubmit = async (fiatAmount) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        BankingActions.cancelFiatTxnStatus({
          status: "APPROVED",
          transactionId: selectedTransaction?.txnId,
          amount: fiatAmount,
          apiAccessKey: "FIAT_TRANSACTIONS",
        })
      ).unwrap();
      setShowApproveFiatTxn(false);
      let data = {};
      if (Object.keys(filterData).length > 0) {
        data = { ...filterData };
      } else {
        data = {
          clientId: "",
          coin: "",
          page: page || 1,
          size: pageSize || 10,
          txStatus: "ALL",
          type: "all",
          trxnType: 'FIAT'
        };
      }
      getFiatTransactions(data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    const data = {
      clientId: "",
      coin: "",
      page: 1,
      size: 10,
      txStatus: "ALL",
      type: "credit,debit,internal-transfer",
    };
    if (selectedFiatTab === "Fiat Transactions") {
      getFiatTransactions(data);
    }
  }, [selectedFiatTab]);

  return (
    <>
      <div className="table-design">
        <TableFilterTittle title="Fiat Transactions" />
        <div className="table-designz">
          <div className="title-filter flexEnd">
            <div className="title-filter_rightSec">
              <FiatTransactionFilters
                setFilterData={setFilterData}
                pageSize={pageSize}
                getFiatTransactions={getFiatTransactions}
                clientId={""}
                setPage={setPage}
                data={fiatTransactions.list}
              />
            </div>
          </div>
          <div className="custom-table">
            <Table
              className="last-center"
              columns={columns}
              dataSource={fiatTransactions.list}
              pagination={false}
            />
          </div>
        </div>
      </div>

      <div className="pagination_block">
        <span className="records" style={{ margin: "20px 15px" }}>
          Total Records: {fiatTransactions.totalCount}
        </span>
        {fiatTransactions.totalCount > 10 && (
          <Pagination
            defaultCurrent={page}
            total={fiatTransactions.totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
            current={page}
          />
        )}
      </div>
      <CustomModal
        title="Are you sure, you want to cancel this transaction?"
        open={showCancelFiat}
        onOk={handleCancelFiatTxn}
        onCancel={() => setShowCancelFiat(false)}
      ></CustomModal>
      {console.log("selectedTransaction", selectedTransaction)}
      <ApproveFiatTxnModal
        amountToVerify={
          selectedTransaction?.isCrypto
            ? TransactionFeeService.getFeeAmountAfterPrecision(
                selectedTransaction.currency,
                selectedTransaction.amount
              )
            : formatFiatValues(Number(selectedTransaction?.amount))
        }
        showApproveFiatTxn={showApproveFiatTxn}
        setShowApproveFiatTxn={setShowApproveFiatTxn}
        callback={onSubmit}
      />
    </>
  );
};

export default FaitTransactions;
