import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlockCard from "../blocksCard/BlockCard";
import style from "../../Pages/Compliance/style.module.scss";
import client from "../../Assets/Images/client.svg";
import { Row, Col } from "antd";
// need complaince file css
import alertRaise from "../../Assets/Images/alertRaised.svg";
import DocomentVerify from "../../Assets/Images/doumentverify.svg";
import rejectRequest from "../../Assets/Images/RejectRequest.svg";

const Slickslider = () => {
  const cardsData = [
    {
      id: 1,
      icons: client,
      titleMain: 45,
      valueUp: "12%",
      valueUp1: "65%",
      title: "Clients Verified",
    },
    {
      id: 2,
      icons: DocomentVerify,
      titleMain: 180,
      valueUp: "12%",
      valueUp1: "65%",
      title: "Documents Verified",
    },
    {
      id: 3,
      icons: alertRaise,
      titleMain: 90,
      valueUp: "12%",
      valueUp1: "65%",
      title: "Alert Raised",
    },
    {
      id: 4,
      icons: rejectRequest,
      titleMain: 90,
      valueUp: "12%",
      valueUp1: "65%",
      title: "Requests Rejected",
    },
    {
      id: 5,
      icons: alertRaise,
      titleMain: 90,
      valueUp: "12%",
      valueUp1: "65%",
      title: "Alert Raised",
    },
    {
      id: 6,
      icons: rejectRequest,
      titleMain: 90,
      valueUp: "12%",
      valueUp1: "65%",
      title: "Requests Rejected",
    },
    {
      id: 7,
      icons: alertRaise,
      titleMain: 90,
      valueUp: "12%",
      valueUp1: "65%",
      title: "Alert Raised",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1504,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="custom-slider">
      <div className="slider-container">
        <Slider {...settings}>
          {cardsData.map((data) => (
            <div key={data.id}>
              <BlockCard
                style={{ marginRight: "20px" }}
                icons={data.icons}
                titleMain={data.titleMain}
                title={data.title}
                valueUp1={data.valueUp1}
                valueUp={data.valueUp}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Slickslider;
