import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";
import { setTransactionDetail } from "./userSlice";

const { USER, ADMIN } = CONSTANTS.API_ENDPOINTS;

const getUsers = createAsyncThunk(
  "user/getUsers",
  async (
    { offset = 0, limit = 10, filter = {} },
    { rejectWithValue, dispatch }
  ) => {
    const { nationality, userType, startDate, endDate, status, verification } =
      filter;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_USERS}/${offset}/${limit}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
        params: {
          nationality: nationality?.trim(),
          userType,
          startDate,
          endDate,
          status,
          verification,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getUserDetail = createAsyncThunk(
  "user/getUserDetail",
  async ({ userId = -1 }, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_USER_DETAIL}/${userId}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {

      return res;
    }
  }
);
const renameAddtionalDocs = createAsyncThunk(
  "user/editAddtionalDocs",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.RENAME_ADDTIONAL_DOCUMENT}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);


const renameAddtionalDocsKyt = createAsyncThunk(
  "user/renameAddtionalDocsKyt",
  async (data, { rejectWithValue, dispatch }) => {

    console.log('renameAddtionalDocsKyt', data);


    const res = Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.RENAME_KYT_DOCUMENT}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);


const deleteAddtionalDocsKyt = createAsyncThunk(
  "user/renameAddtionalDocsKyt",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${ADMIN.DELETE_KYT_DOCUMENT}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);


const deleteAdditionalDocuments = createAsyncThunk(
  "user/deleteAdditionalDocuments",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.DELETE_ADDTIONAL_DOCUMENT}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const blockUnblockUser = createAsyncThunk(
  "user/blockUnblockUser",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.BLOCK_UNBLOCK}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...restData } = data;
    console.log(data, "datadatadatadata");
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.DELETE_USERS}`,
        data: restData,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateFiat = createAsyncThunk(
  "update/fiat",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.ENABLE_DISABLE}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "respppppppppppp");
      return res;
    }
  }
);

const getUserCounts = createAsyncThunk(
  "user/getUserCounts",
  async (data = {}, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_USER_COUNTS}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "DASHBOARD",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getCryptoTransactions = createAsyncThunk(
  "user/getCryptoTransactions",
  async (data, { rejectWithValue, dispatch }) => {
    console.log({ dataHere: data });
    const { clientId, coin, page, size, txStatus, type, fromDate, toDate } =
      data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_ALL_CRYPTO_TRANSACTIONS}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
        params: {
          clientId,
          coin,
          page,
          size,
          txStatus,
          type,
          fromDate,
          toDate,
          trxnType: "CRYPTO",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getSwapTransactionsOnly = createAsyncThunk(
  "user/getSwapTransactionsOnly",
  async (data = {}, { rejectWithValue, dispatch }) => {
    const { offset, limit, userId } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_SWAP_TRANSACTIONS_ONLY}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
        params: { offset, limit, userId },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

const getBankAccount = createAsyncThunk(
  "user/getBankAccount",
  async (data = {}, { rejectWithValue, dispatch }) => {
    const { offset, limit, userId } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_BANK_ACCOUNT}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
        params: { userId },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

const updateAccountStatus = createAsyncThunk(
  "user/updateAccountStatus",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.UPDATE_ACCOUNT_STATUS}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "respppppppppppp");
      return res;
    }
  }
);

const getFiatTransactions = createAsyncThunk(
  "user/getFiatTransactions",
  async (data, { rejectWithValue, dispatch }) => {
    console.log({ dataHere: data });
    const {
      clientId,
      coin,
      page,
      size,
      txStatus,
      type,
      fromDate,
      toDate,
      trxnType,
    } = data;

    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_ALL_TRANSACTIONS}/${page}/${size}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
        params: {
          clientId,
          coin,
          txStatus,
          type,
          fromDate: !!fromDate ? fromDate : undefined,
          toDate: !!toDate ? toDate : undefined,
          trxnType: "FIAT",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const uploadKycDoc = createAsyncThunk(
  "user/uploadKycDoc",
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: `${USER.UPLOAD_KYC_DOC}`,
        data,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: true,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
      },
      { rejectWithValue, dispatch }
    );
  }
);

const getCryptoTransactionDetails = createAsyncThunk(
  "user/transactions/details/byId",
  async (data, { rejectWithValue, dispatch }) => {
    const { coin, clientId, type, apiKey, typeId, from, to } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_TRANSACTIONS_DETAILS_BY_ID}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
        params: { coin, clientId, type, apiKey, typeId, from, to },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

const getFiatTransactionDetails = createAsyncThunk(
  "user/transactions/details/byId",
  async (data, { rejectWithValue, dispatch }) => {
    const { coin, clientId, type, apiKey, typeId, from, to } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_TRANSACTIONS_DETAILS_BY_ID}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
        params: { coin, clientId, type, apiKey, typeId, from, to },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

const getUserIdByClientId = createAsyncThunk(
  "user/transactions/details/byClientId",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...rest } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_USER_ID_BY_CLIENT_ID}`,
        data: rest,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

const getFiatTransactionLimitsDetail = createAsyncThunk(
  "user/transactions/details/getFiatTransactionLimitsDetail",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...rest } = data;
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_TRANSACTION_LIMIT_DETAIL}`,
        data: rest,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      dispatch(setTransactionDetail(res))
      return res;
    }
  }
);

const getCryptoTransactionLimitsDetail = createAsyncThunk(
  "user/transactions/details/getCryptoTransactionLimitsDetail",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...rest } = data;
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${USER.GET_CRYPTO_TRANSACTION_LIMIT_DETAIL}`,
        data: rest,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      dispatch(setTransactionDetail(res))
      return res;
    }
  }
);


const updateFiatTransactionLimitsDetail = createAsyncThunk(
  "user/transactions/details/updateFiatTransactionLimitsDetail",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...rest } = data;
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${USER.UPDATE_USER_TRANSACTION_LIMIT_DETAIL}`,
        data: rest,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateCryptoTransactionLimitsDetail = createAsyncThunk(
  "user/transactions/details/updateCryptoTransactionLimitsDetail",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...rest } = data;
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${USER.UPDATE_CRYPTO_USER_TRANSACTION_LIMIT_DETAIL}`,
        data: rest,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

export const UserActions = {
  getUsers,
  getUserDetail,
  renameAddtionalDocs,
  deleteAdditionalDocuments,
  blockUnblockUser,
  deleteUser,
  getUserCounts,
  updateFiat,
  getCryptoTransactions,
  getSwapTransactionsOnly,
  getBankAccount,
  updateAccountStatus,
  uploadKycDoc,
  getFiatTransactions,
  getFiatTransactionDetails,
  getCryptoTransactionDetails,
  getUserIdByClientId,
  getFiatTransactionLimitsDetail,
  getCryptoTransactionLimitsDetail,
  updateFiatTransactionLimitsDetail,
  updateCryptoTransactionLimitsDetail,
  renameAddtionalDocsKyt,
  deleteAddtionalDocsKyt
};
