import { CONSTANTS } from "../constants";
import BigNumber from "bignumber.js";

// const getFeeAmountAfterPrecision = (coin, value) => {
//   const currency = coin?.toLowerCase();
//   if (value) {
//     let constantValue = 0;
//     if (
//       currency === "eth" ||
//       currency === "usdt(erc-20)" ||
//       currency === "usdc(erc-20)" ||
//       currency === "mat-usdt(erc-20)" ||
//       currency === "mat-usdc(erc-20)"
//     ) {
//       constantValue = CONSTANTS.SMALLEST_UNIT.ETH;
//     } else if (
//       currency === "bnb" ||
//       currency === "usdt(bep-20)" ||
//       currency === "usdc(bep-20)"
//     ) {
//       constantValue = CONSTANTS.SMALLEST_UNIT.BNB;
//     } else if (currency === "matic") {
//       constantValue = CONSTANTS.SMALLEST_UNIT.MATIC;
//     } else if (
//       currency === "usdc(trc-20)" ||
//       currency === "usdt(trc-20)" ||
//       currency === "trx"
//     ) {
//       constantValue = CONSTANTS.SMALLEST_UNIT.TRON;
//     } else if (currency === "btc") {
//       constantValue = CONSTANTS.SMALLEST_UNIT.BTC;
//     }
//     if (constantValue) {
//       return new BigNumber(value / constantValue).toFixed(
//         Math.log10(constantValue)
//       );
//     }
//   } else return 0;
// };


const result = (value) => {
  // Convert value to a number if it's a string and not an integer
  const number = (typeof value === 'string' && !isNaN(value) && value % 1 !== 0) ? parseFloat(value) : value;
  // console.log(number, "numbernumbernumber");
  return number;
};

const getFeeAmountAfterPrecision = (coin, value) => {
  const currency = coin?.toLowerCase();
  if (value && !isNaN(value)) {
    let constantValue = 0;

    // Define smallest unit based on currency
    if (
      currency === "eth" ||
      currency === "usdt(erc-20)" ||
      currency === "usdc(erc-20)" ||
      currency === "mat-usdt(erc-20)" ||
      currency === "mat-usdc(erc-20)"
    ) {
      constantValue = CONSTANTS.SMALLEST_UNIT.ETH;
    } else if (
      currency === "bnb" ||
      currency === "usdt(bep-20)" ||
      currency === "usdc(bep-20)"
    ) {
      constantValue = CONSTANTS.SMALLEST_UNIT.BNB;
    } else if (currency === "matic") {
      constantValue = CONSTANTS.SMALLEST_UNIT.MATIC;
    } else if (
      currency === "usdc(trc-20)" ||
      currency === "usdt(trc-20)" ||
      currency === "trx"
    ) {
      constantValue = CONSTANTS.SMALLEST_UNIT.TRON;
    } else if (currency === "btc") {
      constantValue = CONSTANTS.SMALLEST_UNIT.BTC;
    }

    if (constantValue) {
      // Calculate result using BigNumber
      const res = new BigNumber(value).dividedBy(constantValue).toFixed(
        Math.log10(constantValue)  // Number of decimal places
      );

      // Convert to string and remove trailing zeroes
      const formattedRes = res.toString().replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');

      // Process and return formatted value
      const decimalValue = result(formattedRes);
      return decimalValue;
    }
  }
  return 0;  // Return 0 if no value is provided
};


const divideByPrecision = (value, precision) => {
  let result = 0;
  if (precision && value) {
    const dividedByPrecision = Math.pow(10, precision);
    result = new BigNumber(value / dividedByPrecision).toFixed(precision);
  }
  return result;
};

const getImageByTokenName = (name) => {
  if (!name) return `${CONSTANTS.ENV.KYC_DOCS_URL}/stage-fcbank/all_icons/usdt.png`;
  return `${CONSTANTS.ENV.KYC_DOCS_URL}/stage-fcbank/all_icons/${CONSTANTS.COINS_TOKENS[name.toLowerCase()]}.png`;
};

export const TransactionFeeService = {
  getFeeAmountAfterPrecision,
  divideByPrecision,
  getImageByTokenName,
};
