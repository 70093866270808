import React, { useCallback, useEffect } from 'react';
import { convertJsonToCsv } from '../../services/csv-to-json.service';
import ButtonCommon from '../../Components/Buttoncommon/ButtonCommon';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDownloadStatus,
  setHeaderAndRows,
} from '../../redux/features/download/downloadSlice';
import { toast } from 'react-toastify';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';

const DownloadFile = ({ fileName, type, path, targetRef }) => {
  const dispatch = useDispatch();
  const download = useSelector((state) => state.download);
  const pdfOptions = {
    resolution: Resolution.HIGH,
    page: {
      method: 'save',
      margin: Margin.MEDIUM,
      orientation: 'portrait',
    },
    overrides: {
      pdf: {
        compress: false,
      },
      canvas: {
        useCORS: false,
      },
    },
  };

  const onDownloadFile = () => {
    dispatch(setDownloadStatus({ isEnabled: true, path }));
  };

  const onDownloadCsv = useCallback(
    (data) => {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // /** disable download */
      dispatch(setDownloadStatus({ isEnabled: false, path: 'NONE' }));
      dispatch(setHeaderAndRows({ headers: [], rows: [] }));
      toast.success('File downloaded successfully.');
    },
    [dispatch, fileName]
  );

  const downloadPdfOrCsv = (headers, rows) => {
    if (type === 'PDF') {
      generatePDF(targetRef, { ...pdfOptions, fileName });
      /** disable download */
      dispatch(setDownloadStatus({ isEnabled: false, path: 'NONE' }));
      dispatch(setHeaderAndRows({ headers: [], rows: [] }));
      toast.success('File downloaded successfully.');
    } else if (type === 'CSV' && headers.length > 0 && rows.length > 0) {
      const result = convertJsonToCsv(headers, rows);
      onDownloadCsv(result);
    }
  };

  useEffect(() => {
    const { isEnabled, headers, rows } = download;
    if (isEnabled) downloadPdfOrCsv(headers, rows);
  }, [download]);

  return <ButtonCommon onClick={onDownloadFile} icon={<DownloadOutlined />} />;
};

export default DownloadFile;
