import React from 'react';

function ReferralSupplyCount({ referralProgramList }) {
  return (
    <div
      className='pagination_block'
      style={{ marginRight: '30px', float: 'right' }}
    >
      <span className='records' style={{ marginTop: '10px', padding: '12px' }}>
        Total Supply:{' '}
        <span style={{ color: '#0c253d' }}>
          {referralProgramList?.totalSupply?.totalSupply
            ? Number(referralProgramList?.totalSupply?.totalSupply).toFixed(2)
            : 0}
        </span>
      </span>
      <span className='records' style={{ marginTop: '10px', padding: '12px' }}>
        Remaining Supply:{' '}
        <span style={{ color: '#0c253d' }}>
          {referralProgramList?.totalSupply?.remainingSupply
            ? Number(referralProgramList?.totalSupply?.remainingSupply).toFixed(
                2
              )
            : 0}
        </span>
      </span>
    </div>
  );
}

export default ReferralSupplyCount;
