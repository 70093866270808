import React, { useState } from "react";
import { Card, Space, Typography, Badge, Row, Col, Tag, Divider } from "antd";
import { PlaySquareOutlined } from "@ant-design/icons";
import style from "./style.module.scss";
import { useLocation } from "react-router-dom";
import { checkEmptyString } from "../../utils/common.utils";
import { checkFalsyValues } from "../../utils/common.utils";

const MatchRule = ({data}) => {
  const { Title } = Typography;
  const location = useLocation();
  const matchRules = data?.scoringResult?.matchedRules;

  return (
    <>
      {matchRules !== undefined && matchRules?.length !== 0 && (
        <section className={style.spaceMange}>
          <Title level={3}>Matched Rule { matchRules?.length}</Title>
          <Card>
            <Space
              wrap
              split={<Divider style={{ height: "3rem" }} type="vertical" />}
            >
              <span>
                score <br />
                <Badge
                  count={checkFalsyValues(
                    location.state?.sumsubTxnFullPayload?.scoringResult?.score
                  )}
                  showZero
                  color="#faad14"
                />
              </span>
              {console.log(location.state?.sumsubTxnFullPayload,"sumsubTxnFullPayload")}
              <span>
                Matched Rules <br />
                {0}
                {/* {checkFalsyValues(
                  location.state?.sumsubTxnFullPayload?.scoringResult?.ruleCnt
                )} */}
              </span>
              <span>
                Considered Rules <br />
                {checkFalsyValues(
                  location.state?.sumsubTxnFullPayload?.scoringResult
                    ?.dryRunRuleCnt
                )}
              </span>
            </Space>
            <Row
              style={{ marginTop: "20px", height: "100%" }}
              gutter={[20, 20]}
            >
              {matchRules !== undefined &&
                matchRules?.length !== 0 &&
                matchRules?.map((item, index) => {
                  return (
                    <Col key={index}>
                      <Card
                        style={{
                          width: 350,
                          height: "100%",
                        }}
                      >
                        <Tag bordered={false} color="grey">
                          <PlaySquareOutlined /> {"Pre-Scoring"}
                        </Tag>
                        <Tag bordered={false} color="purple">
                          {/* {checkFalsyValues(item.score)} */}
                          Only Score
                        </Tag>
                        <Tag bordered={false} color="yellow">
                          {item?.dryRun === true ? " Dry Run" : "-"}
                        </Tag>
                        <p style={{ marginTop: "10px" }}>
                          {checkEmptyString(item?.title)}
                        </p>
                        {item?.tags !== undefined &&
                          item?.tags?.length !== 0 &&
                          item?.tags?.map((tag, index) => {
                            return (
                              <Tag bordered={false} color="red" key={index}>
                                {checkEmptyString(tag)}
                              </Tag>
                            );
                          })}
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </Card>
        </section>
      )}
    </>
  );
};

export default MatchRule;
