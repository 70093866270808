import { createSlice } from '@reduxjs/toolkit'
import { SettingActions } from './settingActions'

const initialState = {
    data: null
}


export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
     
    },
    extraReducers : (builder) =>  {
      
    }
  })
  
  // Action creators are generated for each case reducer function
  export const {} = settingSlice.actions
  
  export default settingSlice.reducer