import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import doc from "../../../Assets/Images/documentimg.png";

const RemoveTable = ({ banners, page, setPage, type, getBanners }) => {
  const columns = [
    {
      title: "Image",
      dataIndex: "imageIcon",
      key: "imageIcon",
      render: (_, record) => (
        <>
          <img
            src={record.imageIcon}
            alt=""
            style={{ width: "200px", height: "auto" }}
          />
        </>
      ),
    },
    {
      title: "Deleted On",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Deleted By",
      dataIndex: "deletedBy",
      key: "deletedBy",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip ",
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
    const data = {
      offset: (page - 1) * 10,
      limit: 10,
      isDeleted: true,
      type,
    };
    getBanners(data);
  };

  return (
    <>
      <div className="custom-table">
        <Table
          dataSource={banners.list}
          columns={columns}
          pagination={false}
          // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
        />
      </div>

      <div className="pagination_block" style={{ margin: "20px" }}>
        <span className="records">Total Records: {banners.totalCounts}</span>
        {banners.totalCounts > 10 && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              defaultCurrent={page}
              total={banners.totalCounts}
              pageSize={10}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RemoveTable;
