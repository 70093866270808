import React, { useState } from 'react';
import LOGO2 from '../Assets/Images/LOGO2.svg';
import logoR from '../Assets/Images/logoR.png';
import { Grid, Layout, theme } from 'antd';
import Header from '../Components/Header/Index.jsx';
import { Link, Outlet } from 'react-router-dom';
import './style.module.scss';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import ScreenBreakpoints from '../Components/CustomHook/ScreenBreakpoints.jsx';
import SidebarMenu from './SidebarMenu';
import useCurrentWidth from '../Components/CustomHook/useCurrentWidth.jsx';

const { Sider, Content } = Layout;

const Index = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 991);

  const width = useCurrentWidth();

  const handleResize = () => {
    setCollapsed(window.innerWidth <= 991);
  };

  // Listen for window resize events
  window.addEventListener('resize', handleResize);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint();

  const [isExtraSmallScreen, isLargeScreen] = ScreenBreakpoints();

  return (
    <Layout>
      <Sider
        className='customSidebar'
        width={270}
        collapsedWidth={isExtraSmallScreen ? 80 : 80}
        trigger={null}
        collapsible
        collapsed={width < 991 ? true : collapsed}
      >
        <div className='logo'>
          <Link className='logoR' to='/'>
            <img src={LOGO2} alt='LOGO' />
          </Link>
          <Link className='logoIcon' to='/'>
            <img src={logoR} alt='LOGO' />
          </Link>
        </div>
        <SidebarMenu />
      </Sider>

      {/* </div> */}
      <Layout>
        <Header
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
        />
        <Content
          style={{
            height: '100vh',
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <div className='layout_main'>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Index;
